import React, { useMemo } from "react";
// import "./ProgressBar.module.css";

const BAR_COUNT = 7;

export default function ProgressBar({percentage}) {
  const quartilePercentage = 100 / BAR_COUNT;

  const truePercent = percentage;


  const quartileValue = Math.round(truePercent / quartilePercentage);

  const potentialLabel =
    quartileValue < 3 ? "low" : quartileValue <= 5 ? "medium" : "high";

  const rangeArray = useMemo(() => {
    let elementArr = [];
    let root = document.documentElement;
    let percent;
    let quartileValue;

    for (let i = 1; i <= BAR_COUNT; i++) {
      const currentPoint = Math.round(quartilePercentage * i);

      if (truePercent <= currentPoint) {
        //Check if percent and quartile value is present of not. This runs only one time
        if (!percent && !quartileValue) {
          const previousPoint = Math.round(quartilePercentage * (i - 1));
          const difference = currentPoint - previousPoint;
          quartileValue = i;

          percent = `${Math.round(
            ((truePercent - previousPoint) / difference) * 100
          )}%`;
        }

        if (percent && quartileValue) {
          if (i === quartileValue) {
            //set specified block value so that it can be used to make gradient
            root.style.setProperty(`--block-${i}`, '100%');
            //set left value to position the indicator and tooltip
            root.style.setProperty(`--left-value`, percent);
          }
          //Push element with class filled and current into array
          elementArr.push(
            <div
              className={`item ${i === quartileValue ? "current filled" : "filled"}`}
              key={i}
              data-content={`${percentage}%`}
            >
            </div>
          );
        }
      } else {
        root.style.setProperty(`--block-${i}`, "100%");
        elementArr.push(<div className="item filled" key={i} />);
      }
    }
    return elementArr;
  }, [potentialLabel, quartilePercentage, truePercent]);

  return (
    <div className="App">
      <div className="potential__range">
        <div className="range">{rangeArray}</div>
      </div>
    </div>
  );
}