import React, { useEffect, useState } from 'react';
import { Card, FormControlLabel, Checkbox, CardContent, Typography, CardActions, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PermissionModal from '../Modals/PermissionModal';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff',
    // height: 200
    marginTop: '15px'
  },
  cardMedia: {
    flex: 1,
    backgroundColor: '#fff',
    objectFit: 'none'
  },
  cardContent: {
    flex: 4
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '-8px',
    marginBottom: '-8px',
  }
});

function Permission(props) {
  const { classes, overview,permissionModal } = props;
  const [UserPermissionModal,setUserPermissionModal] = useState(false);
  let permission  = overview ? Object.values(overview.related_objects.persons) : null;
  let permissionSource  = overview ? Object.values(overview.related_objects.persons) : null;


  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" component="div">
            Email Permission
          </Typography>
          <Typography>
            <span style={{fontWeight: 'bold'}}>Consent:</span> {permission[0].permissions == 0 ? 'Unknown' : permission[0].permissions == 2 ? 'No' : 'Yes'} 
          </Typography>
          <Typography>
            <span style={{fontWeight: 'bold'}}>Source:</span> {permission[0]?.permission_source} 
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button variant="contained" color="secondary" endIcon={<ArrowRightAltIcon />} size="small" onClick={() => setUserPermissionModal(true)}>Update</Button>
        </CardActions>
      </Card>
      <PermissionModal
        open={UserPermissionModal || ([0,1].includes(permission[0].permissions) ? permissionModal : UserPermissionModal) }
        oppOverview={overview}
        handleClose={() => setUserPermissionModal(false)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
    permissionModal: opportunitySelectors.permissionModalStatus(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Permission));