import * as React from 'react';
import {connect} from "react-redux";
import {selectors as accountSelectors} from '../../Ducks/account';

function CapabilityChecker(props) {
    const {capabilities, user} = props;
    const [accessGranted, setAccessGranted] = React.useState(false);

    React.useEffect(() => {
        if (user) {
            // New implementation
            const user_capabilities = user.user_details.capabilities.map((item) => item.capability_title)
            if (capabilities.some(r => user_capabilities.includes(r))) {
                setAccessGranted(true);
            } else {
                setAccessGranted(false);
            }
        }
    }, [user])

    return (
        <>
            {
                accessGranted && props.children
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    user: accountSelectors.user(state),
});

export default connect(mapStateToProps, {})(CapabilityChecker);