import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { selectors as adminSelectors, actions as adminActions } from '../../../Ducks/admin';
import { selectors as accountSelectors, actions as accountActions } from '../../../Ducks/account';
import { withStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';

const useStyles = theme => ({
  container: {
    textAlign: 'left',
  },
  gridHeading: {
    margin: '0px 0px 10px 0px',
    fontSize: '29px',
    fontWeight: '300',
    color: '#3a3641',
    float: 'left'
  },
  noteForm: {
    width: '50%'
  },
  submitButton: {
    marginTop: '5px',
    float: 'right',
    marginRight: '5px'
  },
  resetButton: {
    marginTop: '5px',
    float: 'right'
  }
});

const editorConfiguration = {
  plugins: [ List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote ],
  toolbar: ['heading', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
  alignment: {
      options: ['left', 'right']
  },
  table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  }
};

function ReleaseNotes(props) {
  const { saveReleaseNotes,postLoader,classes,canManageReleaseNotes } = props;
  const [noteTitle, setNoteTitle] = useState('');
  const [noteDescription, setNoteDescription] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))
  const handleSubmit = (e) => {
    e.preventDefault();
    saveReleaseNotes({note_title: noteTitle,note_description: noteDescription })
    setNoteTitle('');
    setNoteDescription('');
  };

  const resetForm = (e) => {
    e.preventDefault();
    setNoteTitle('');
    setNoteDescription('');
  }

  return (
    <div>
      <h2 className={classes.gridHeading}>
          Release Notes
      </h2>
      <form onSubmit={handleSubmit} className={classes.noteForm}>
        <TextField
          label="Note Title"
          variant="outlined"
          fullWidth
          margin="normal"
          value={noteTitle}
          onChange={(e) => setNoteTitle(e.target.value)}
          required
        />
        <CKEditor
          editor={ClassicEditor}
          config={ editorConfiguration }
          data={noteDescription}
          onChange={(event, editor) => {
            setNoteDescription(editor.getData());
        }}
        />
        <Button className={classes.resetButton} onClick={resetForm}  variant="contained" color="warning">Reset</Button>
        <Button type="submit" className={classes.submitButton} disabled={postLoader || !canManageReleaseNotes} variant="contained" color="primary">{postLoader ? 'Loading' : 'submit'}</Button>

      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
    postLoader: adminSelectors.releaseNoteLoader(state),
    canManageReleaseNotes: accountSelectors.canManageReleaseNotes(state),
});
  
const mapDispatchToProps = (dispatch) => bindActionCreators(
{
    saveReleaseNotes: adminActions.saveReleaseNotes,
},
dispatch
);
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReleaseNotes));