import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel
} from '@mui/material';
import DateTimePicker from './DateTimePicker';
import {selectors as opportunitySelectors, actions as opportunityActions} from '../../../Ducks/opportunity';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import {withStyles} from '@mui/styles';

const types = [
    'phone_call',
    'phone_meeting'
]

const useStyles = _theme => ({
    select: {
        "& ul": {
            display: "grid",
        }
    },
});

function EditEventModal(props) {
    const [date, setDate] = React.useState(props.startDate);
    const [type, setType] = React.useState(props.type);
    const {classes, isUpcomingEvent} = props;
    const handleClose = () => {
        props.handleClose()
    };

    const handleSubmit = () => {
        const {opportunity, editCalendarEvent} = props;
        const obj = {
            opportunity_action_type: type === 'phone_call' ? 'follow_up' : type,
            opportunity_action_due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
            opportunity_action_due_time_precise: 1
        };
        let d = new Date();
        let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        editCalendarEvent({
            opportunityId: opportunity.main.opportunity_id,
            obj: obj,
            date: YearMonth,
            actionId: props.actionId
        });
        handleClose();
    }

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    const getPersonName = () => {
        const {opportunity} = props;
        if (opportunity) {
            const persons = opportunity.related_objects.persons
            if (persons) {
                const personId = Object.keys(persons)[0];
                return persons[personId].person_firstname + ' ' + persons[personId].person_lastname;
            }
        }
        return '';
    }

    return (
        <div>
            <Dialog width={700} open={props.open} onClose={handleClose}>
                <DialogContent>
                    {isUpcomingEvent &&
                        'You already have a planned action for this opportunity so you cannot plan a new one until this has been carried out'
                    }
                    {
                        !isUpcomingEvent &&
                        <>
                            Plan a <FormControl style={{width: '180px'}}>
                            <InputLabel id="demo-simple-select-label">Event type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                name="type"
                                id="demo-simple-select"
                                value={type}
                                label="Age"
                                onChange={(e) => setType(e.target.value)}
                                size={'small'}
                                MenuProps={{classes: {paper: classes.select}}}
                            >
                                {
                                    types.map(item => <MenuItem value={item}>{makeHeader(item)}</MenuItem>)
                                }
                            </Select>
                        </FormControl> on <b>{moment(date).format('MMMM D, YYYY, HH:mm')}</b> with <b>{getPersonName()}</b>
                            <DateTimePicker date={date} setDate={setDate}/>

                            {'Do you confirm the creation of event?'}
                        </>
                    }
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
                        {
                            !isUpcomingEvent &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                                style={{marginRight: '12px'}}
                            >
                                {'Confirm'}
                            </Button>
                        }
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        editCalendarEvent: opportunityActions.editCalendarEvent,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(EditEventModal));