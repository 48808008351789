import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
  asyncs: {
    getCompanies: (data) => data,
    getCompanyDetails: (data) => data,
    updateCompany: (data) => data,
    getCountries: (data) => data
  }
}, 'companies');

let initialState = asyncInitialState({
  companies: null,
  companyDetails: null,
  updatedCompany: null,
  countries: null,
  errorMessage: '',
  success: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getCompanies:
    case types.getCompanyDetails:
    case types.updateCompany:
    case types.getCountries:
      return asyncOnRequest(state, action);
    case types.saga.getCompanies.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          companies: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getCompanyDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          companyDetails: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.updateCompany.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          updatedCompany: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getCountries.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          countries: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getCompanies.failure:
    case types.saga.getCompanyDetails.failure:
    case types.saga.updateCompany.failure:
    case types.saga.getCountries.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.companies,
  {
    errorMessage: (data) => data.errorMessage,
  }
)

const syncSelector = {
  isLoading: (state) => state.companies.loading,
  companies: (state) => state.companies.data.companies,
  companyDetails: (state) => state.companies.data.companyDetails,
  countries: (state) => state.companies.data.countries
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)