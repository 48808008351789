import React, { useEffect } from "react";
import { Grid, TextField, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as ContactsSelectors, actions as contactsActions } from "../../../../Ducks/contacts";
import { selectors as opportunitySelectors, actions as opportunityActions } from "../../../../Ducks/opportunity";
import { selectors as usersSelectors, actions as usersActions } from "../../../../Ducks/users";
import { bindActionCreators } from "redux";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { ExpandMore } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { isEmpty } from 'lodash';
import ParticipantCard from "../../../Common/Overview/ParticipantCard";
import ParticipantPersonalDetails from "../../../Common/Overview/ParticipantPersonalDetails";
import ParticipantEventsOverview from "../../../Common/Overview/ParticipantEventsOverview";
import ParticipantJobs from "../../../Common/Overview/ParticipantJobs";
import ParticipantPurchases from "../../../Common/Overview/ParticipantPurchases";
import ParticipantOpportunities from "../../../Common/Overview/ParticipantOpportunities";
import PersonHistory from "../../EventsOverview/Widgets/PersonHistory";
import NextPlannedAction from "../../EventsOverview/Widgets/NextPlannedAction";
import UserList from "../../EventsOverview/Widgets/UserList";

const useStyles = (_theme) => ({
    title: {
        display: 'flex !important',
        alignItems: 'center !important'
    },
    card: {
        display: "flex !important",
        backgroundColor: "#fff !important",
    },
    cardMedia: {
        flex: 1,
        width: "85px !important",
        backgroundColor: "#F1F1F1 !important",
    },
    cardContent: {
        flex: 4,
        padding: '16px !important'
    },
    flexVertical: {
        display: "flex",
        alignItems: "center",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputField: {
        flex: 1
    },
    fieldSet: {
        display: 'flex',
        alignItems: 'end'
    },
    flex: {
        display: 'flex'
    },
    sendout: {
        display: 'flex',
        alignItems: 'center'
    },
    send: {
        background: 'green',
        width: '14px',
        height: '14px',
        marginRight: '5px',
    },
    sent: {
        background: '#2785e6',
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'dashed'
    },
    notsent: {
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'solid'
    },
    noBgDotted: {
        background: 'transparent',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        background: 'repeating-linear-gradient(45deg, red, red 2px, transparent 2px, transparent 8px)',
        border: 'red',
        borderStyle: 'solid'
    },
    lightBlueBgDotted: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        background: 'repeating-linear-gradient(45deg, red, red 2px, #5cd7ff 2px, #5cd7ff 8px)',
        borderStyle: 'solid',
        borderColor: 'red'
    },
    lightBlueBgSolid: {
        background: '#5cd7ff',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: '#42d142'

    },
    lightBlueBg: {
        background: '#5cd7ff',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    darkBlueBgSolid: {
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: '#42d142'
    },
    darkBlueBg: {
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px'
    },
    darkBlueBgDotted: {
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'red',
        background: 'repeating-linear-gradient(45deg, red, red 2px, #0047ab 2px, #0047ab 8px)'
    },
    noBgSolid: {
        background: 'transparent',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid'
    },
    cyanBgSolid: {
        background: '#FDDA0D',
        width: '19px',
        height: '19px',
        marginRight: '5px',  
    },
    cyanBg: {
        background: '#FDDA0D',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    grayBg: {
        background: '#757373',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    purpleBg: {
		background: "purple",
		width: "19px",
		height: "19px",
		marginRight: "5px",
	},
    empty: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: '#cacaca'
    },
    completed: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'green',
        background: 'green'
    },
    changed: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'red',
        background: 'green'
    },
    failed: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'red',
        background: 'red'
    },
    attending: {
        background: '#68a54d !important',
        color: '#fff !important',
        '&:hover': {
            background: '#68a54d !important',
        }
    },
    not_attending: {
        background: '#db6669 !important',
        color: '#fff !important',
        '&:hover': {
            background: '#db6669 !important',
        }
    },
    unknown: {
        background: '#7f7f7f !important',
        color: '#fff !important',
        '&:hover': {
            background: '#7f7f7f !important',
        }
    },
    tentative: {
        background: '#f9fd01 !important',
        color: '#000 !important',
        '&:hover': {
            background: '#f9fd01 !important',
        }
    },
    appeared: {
        background: '#2785e6',
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'solid'
    },
    nothing: {
        background: '#2785e6',
        width: '14px',
        height: '14px',
        marginRight: '5px',
    },
    happened: {
        background: 'green',
        width: '14px',
        height: '14px',
        marginRight: '5px',
    },
    notHappened: {
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'solid'
    },
    answer: {
        background: '#1976d2',
        color: '#fff',
        '&:hover': {
            color: '#000'
        }
    },
    accordionSummary: {
        '& > div': {
            flexDirection: 'column'
        }
    },
    historyCount: {
        background: 'red',
        borderRadius: '50%',
        color: '#fff',
        fontSize: '12px',
        marginLeft: '5px',
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    panel: {
        backgroundColor: '#fff',
        color: "rgba(0, 0, 0, 0.87)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "4px",
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        position: "relative",
        transition: " margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        overflowAnchor: "none",
        borderRadius: 0,
        padding: "15px"
    },
    checkbox: {
        width: "15px",
        height: "15px",
        cursor: "pointer"
    },
    personTable: {
        '& > tbody > tr > td': {
            padding: '5px',
            minWidth: '160px'
        }
    },
    details: {
        '& p': {
            margin: 0
        }
    },
    button: {
        height: "35px",
        marginTop: "20px",
        marginLeft: "10px"
    }
});

const personColumns = [
    "person_id",
    "erp_client_id",
    "region_id",
    "person_token",
    "person_firstname",
    "person_lastname",
    "person_never_contact",
    "person_mobile_number",
    "person_preferences_never_text",
    "person_preferences_limit_calls",
    "person_linkedin_url",
    "person_linkedin_member_id",
    "person_challenges",
    "person_food_considerations",
    "person_special_conditions",
    "person_industry",
    "person_country",
    "person_region",
    "person_language",
    "person_gender",
    "lead_source",
    "lead_target_id",
    "sf_person_id",
    "person_created_at",
    "person_last_update",
    "person_awaiting_sync",
    "person_last_sync",
    "person_flagged_from_salesview",
];


const PersonCaseOverviewTab = (props) => {
    const { classes, loading, person,updateAssignee,caseDecline,getCasesEventsOverview,casesEventsOverview, eventInfo, getContactDetails, contactDetails, getJobs, jobs, getPersonPurchases, personPurchases, getPersonOpportunities, personOpportunities, personHistory, foodConsiderations, isNetworkDirector, getPersonOpenOpportunity, personOpenOpportunity, getPendingCaseDetails, pendingCaseDetails, resolveCase, handleCaseClose,resetTeams } = props;

    const [personObj, setPersonObj] = React.useState({});
    const [personDetails, setpersonDetails] = React.useState({});
    const [expanded, setExpanded] = React.useState('panel4');
    const [isOpen, setIsOpen] = React.useState(false);
    const [neverContact, setNeverContact] = React.useState(false);
    const [neverSms, setNeverSms] = React.useState(false);
    const [limitCalls, setLimitCalls] = React.useState(false);
    const [limitEmails, setLimitEmails] = React.useState(false);
    const [personChallenges, setPersonChallenges] = React.useState("");
    const [personFoodConsiderations1, setPersonFoodConsiderations1] = React.useState("");
    const [personFoodConsiderations2, setPersonFoodConsiderations2] = React.useState([]);
    const [personFoodConsiderationsOther, setPersonFoodConsiderationsOther] = React.useState("");
    const [personPielineStage, setPersonPielineStage] = React.useState("")
    const [personPielineStages, setPersonPielineStages] = React.useState([])
    const [numberOfEvents, setNumberOfEvents] = React.useState(0);
    const [_overviewQuery, setOverviewQuery] = React.useState(null);
    const [assigee,setAssignee] = React.useState("");
    const [specialCaseDetail,setSpecialCaseDetail] = React.useState({});
    const history = React.useRef(null);

    

    useEffect(() => {
        props.getFoodConsiderations()
        // set query
        const _overviewQuery = getQuery();
        setOverviewQuery(_overviewQuery)
    }, [])

    useEffect(() => {
        if (person !== null && person !== undefined) {
            setPersonObj(person);
            getContactDetails({ id: person.person_id, columns: personColumns });
            getJobs(person.person_id);
            getPersonOpenOpportunity(person.person_id);
            getPersonPurchases(person.person_id);
            getPersonOpportunities(person.person_id);
            getCasesEventsOverview({product_id:person?.purchase.product_id, purchase_id: person?.purchase_id});
            getPendingCaseDetails(person.purchase_id);
        }

    }, [person, eventInfo]);

    useEffect(() => {
        if (contactDetails !== null && contactDetails !== undefined) {
            setpersonDetails(contactDetails.main[0]);

            // set checkboxes
            setNeverContact(contactDetails.main[0].person_never_contact);
            setNeverSms(contactDetails.main[0].person_preferences_never_text);
            setLimitCalls(contactDetails.main[0].person_preferences_limit_calls);
            setLimitCalls(contactDetails.main[0].person_preferences_limit_emails);

            // set fields
            setPersonChallenges(contactDetails.main[0].person_challenges);
            setPersonFoodConsiderations1(contactDetails.main[0].person_food_considerations_1 ? contactDetails.main[0].person_food_considerations_1 : "");
            setPersonFoodConsiderations2(contactDetails.main[0].person_food_considerations_2 ? contactDetails.main[0].person_food_considerations_2 : []);
            setPersonFoodConsiderationsOther(contactDetails.main[0].person_food_considerations_other ? contactDetails.main[0].person_food_considerations_other : "");
        }
    }, [contactDetails]);

    useEffect(() => {
        if (personOpenOpportunity) {
            setPersonPielineStage(personOpenOpportunity.pipeline_stage_id ? personOpenOpportunity.pipeline_stage_id : "")
            setPersonPielineStages(personOpenOpportunity.pipeline_stages ? personOpenOpportunity.pipeline_stages : []);
        }
    }, [personOpenOpportunity]);

    useEffect(() => {
        if (pendingCaseDetails) {
            setNumberOfEvents(pendingCaseDetails?.events_approved)
            setSpecialCaseDetail(pendingCaseDetails)
        }
    }, [pendingCaseDetails])

    const executeScroll = () => history.current.scrollIntoView()

    const showProgress = () => (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
        </div>
    );

    const getQuery = () => {
        const { query } = props;
        const start_date = moment(query.range[0]).format('YYYY-MM-DD');
        const end_date = moment(query.range[1]).format('YYYY-MM-DD');;
        let _query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (query.productType !== "" && query.productType !== null) {
            _query = `${_query}&equals[product_type_id]=${query.productType.product_type_id}`
        }
        if (query.product !== "" && query.product !== null && query.product !== undefined) {
            _query = `${_query}&equals[product_id]=${query.product.product_id}`
        }
        if (query.user !== "" && query.user !== null && query.user !== undefined) {
            _query = `${_query}&equals[user_id]=${query.user.user_id}&equals[user_type]=${query.user.user_type}`
        }
        if (query.country !== "" && query.country !== null && query.country !== undefined) {
            _query = `${_query}&equals[country_id]=${query.country.country_id}`
        }
        if (query.networkDirector !== "" && query.networkDirector !== null && query.networkDirector !== undefined) {
            _query = `${_query}&equals[network_directors]=${query.networkDirector.id}`
        }

        return _query;
    }

    const getStatusClass = (item) => {
        const { event_has_happened, showed, color, has_open_opportunity, purchase_id, freezed, case_status } = item;
        if (freezed && case_status === 'PENDING') {
			return "purpleBg";
		} else if(freezed && case_status === 'APPROVED')
		{
			return "grayBg"	
		} else {
            if (event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
                return 'noBgDotted';
            } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
                return 'lightBlueBgDotted';
            } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && showed) {
                return 'lightBlueBgSolid';
            } else if (!event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
                return 'lightBlueBg';
            } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && showed) {
                return 'darkBlueBgSolid';
            } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
                return 'darkBlueBgDotted';
            } else if (!event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
                return 'darkBlueBg';
            } else if (!event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
                return 'noBgSolid';
            } else if (!event_has_happened && color === "purchase_a" && has_open_opportunity && !showed) {
                return 'cyanBgSolid'
            } else if (!event_has_happened && color === "purchase_b" && has_open_opportunity && !showed) {
                return 'cyanBg'
            } else {
                return 'noBgSolid';
            }
        }
    }

    const getCompanyName = (companyID) => {
        return jobs.related_objects.companies[companyID].company_name;
    };

    const getYears = (date, end_date) => {
        let d = new Date(date);
        let start = d.getFullYear();

        if (end_date === null) {
            let c = new Date();
            let current = c.getFullYear();
            return Math.ceil(parseInt(current) - parseInt(start));
        }
        else {
            let e = new Date(end_date);
            let end = e.getFullYear();
            return Math.ceil(parseInt(end) - parseInt(start));
        }
    };

    if (!person) {
        return showProgress();
    }

    const handleChange = (panel) => (_event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "person_never_contact") {
            setNeverContact(value);
        }

        if (name === "person_preferences_never_text") {
            setNeverSms(value);
        }

        if (name === "person_preferences_limit_calls") {
            setLimitCalls(value);
        }

        if (name === "person_preferences_limit_emails") {
            setLimitEmails(value);
        }

        props.updateContact({
            id: person.person_id,
            obj: { [name]: value }
        });
    }

    const saveInfo = (field) => {
        if (field === "person_challenges") {
            props.updateContact({
                id: person.person_id,
                obj: { person_challenges: personChallenges }
            });
        }
        if (field === "person_food_considerations") {
            if (personFoodConsiderationsOther === "") {
                props.updateContact({
                    id: person.person_id,
                    obj: {
                        person_food_considerations_1: personFoodConsiderations1,
                        person_food_considerations_2: personFoodConsiderations2
                    }
                });
            } else {
                props.updateContact({
                    id: person.person_id,
                    obj: {
                        person_food_considerations_1: personFoodConsiderations1,
                        person_food_considerations_2: personFoodConsiderations2,
                        person_food_considerations_other: personFoodConsiderationsOther
                    }
                });
            }
        }
    }

    const caseResolve = () => {
        const data = {
            events_approved: parseInt(numberOfEvents)
        }
        Swal.fire({
            title: "Confirm Resolve",
            text: "Are you sure you want to resolve this case?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                resolveCase({ personId: person.person_id, purchase_id: person.purchase_id, case_id: person.case_id, data: person.case_type === "ongoing" ? {} : data, handleCaseClose });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    const caseDeclinefn = () => {
        const data = {
            events_approved: parseInt(numberOfEvents)
        }
        Swal.fire({
            title: "Confirm Decline",
            text: "Are you sure you want to Decline this case?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                caseDecline({ personId: person.person_id, purchase_id: person.purchase_id, case_id: person.case_id, data: person.case_type === "ongoing" ? {} : data, handleCaseClose });
                resetTeams();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    const updateAssigneFunc = () => {
        const data = {
            userId: assigee.user_id,
            caseId: specialCaseDetail['case_id'],
            purchaseId: specialCaseDetail['purchase_id']
        }
        updateAssignee(data);

    }
    
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                    <ParticipantCard
                        classes={classes}
                        person={person}
                        personDetails={personDetails}
                        booking={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ParticipantPersonalDetails
                        classes={classes}
                        jobs={jobs}
                        personDetails={personDetails}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        isNetworkDirector={isNetworkDirector}
                        handleInputChange={handleInputChange}
                        limitCalls={limitCalls}
                        limitEmails={limitEmails}
                        neverSms={neverSms}
                        neverContact={neverContact}
                        personChallenges={personChallenges}
                        setPersonChallenges={setPersonChallenges}
                        saveInfo={saveInfo}
                        setPersonFoodConsiderations1={setPersonFoodConsiderations1}
                        setPersonFoodConsiderations2={setPersonFoodConsiderations2}
                        setPersonFoodConsiderationsOther={setPersonFoodConsiderationsOther}
                        personFoodConsiderations1={personFoodConsiderations1}
                        personFoodConsiderations2={personFoodConsiderations2}
                        personFoodConsiderationsOther={personFoodConsiderationsOther}
                        foodConsiderations={foodConsiderations}
                        noAction={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ background: "#fff", borderRadius: '5px', border: "1px solid #cecece" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #cecece', padding: '10px' }}>
                            <h3 style={{ margin: 0 }}>Case {person.case_type === "pause" ? "Paused" : person.case_type === "ongoing" ? "Ongoing" : ""}</h3>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {
                                    person.case_type === "ongoing" && (
                                        <>
                                        <Button style={{marginRight: '5px'}} size="small" color="primary" variant="contained" onClick={executeScroll}>Add Notes</Button>
                                        <Button style={{marginRight: '5px'}} size="small" color="success" variant="contained" onClick={caseResolve}>Resolve</Button>
                                        </>
                                    )
                                    
                                }
                                {
                                    person.case_type === 'pause' && (
                                        <Button style={{marginRight: '5px'}} size="small" color="success" variant="contained" onClick={caseResolve}>Approve</Button>
                                    )
                                }
                                <Button size="small" color="error" variant="contained" onClick={caseDeclinefn}>Decline</Button>
                            </div>
                        </div>

                        <div className={classes.details} style={{ padding: '10px', margin: 0 }}>
                           
                            {specialCaseDetail && specialCaseDetail.type === "pause" ? (
                                <ul style={{ padding: 0, margin: 0 }}>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Start Date:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["start_date"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>End Date:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["end_date"]}
                                        </div>
                                    </li>
                                    {
                                        specialCaseDetail["due_date"] && (
                                            <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                                <div style={{ textTransform: 'capitalize' }}>
                                                    <b>Due Date:</b>
                                                </div>
                                                <div style={{ marginLeft: '10px' }}>
                                                    {specialCaseDetail["due_date"]}
                                                </div>
                                            </li>
                                        )
                                    }
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Events Approved:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["events_approved"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Receive summaries:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["notifications"] == 1 ? "Yes" : "No"}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Notes:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: specialCaseDetail["notes"] }}></div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Created at:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["created_at"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Created by:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["created_by"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Status:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                                            {specialCaseDetail["status"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <UserList 
                                        caseName="pause"
                                        capabilityId={47}
                                        assignee={specialCaseDetail["assignee"]}
                                        width={"50%"}
                                        setUserForParent={setAssignee}
                                        />
                                        <Button className={classes.button} variant="contained" onClick={updateAssigneFunc}>{loading ? 'saving...' : 'save'}</Button>

                                    </li>
                                </ul>
                                
                            ) : specialCaseDetail && specialCaseDetail.type === "ongoing" ? (
                                <ul style={{ padding: 0, margin: 0 }}>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>User</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["user"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Follow up date</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["call_due_date_time"]}
                                        </div>
                                    </li>
                                    {
                                        specialCaseDetail["due_date"] && (
                                            <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                                <div style={{ textTransform: 'capitalize' }}>
                                                    <b>Due Date:</b>
                                                </div>
                                                <div style={{ marginLeft: '10px' }}>
                                                    {specialCaseDetail["due_date"]}
                                                </div>
                                            </li>
                                        )
                                    }
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Reason:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["reason"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Notes:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: specialCaseDetail["notes"] }}></div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Created at:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["created_at"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Created by:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            {specialCaseDetail["created_by"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <b>Status:</b>
                                        </div>
                                        <div style={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                                            {specialCaseDetail["status"]}
                                        </div>
                                    </li>
                                    <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                        <UserList 
                                        caseName="ongoing"
                                        capabilityId={46}
                                        assignee={specialCaseDetail["assignee"]}
                                        width={"50%"}
                                        setUserForParent={setAssignee}
                                        />
                                        <Button className={classes.button} variant="contained" onClick={updateAssigneFunc}>{loading ? 'saving...' : 'save'}</Button>

                                    </li>
                                </ul>
                            ) : (
                                <></>
                            )
                            }
                        </div>

                        {
                            person.case_type === "pause" && (
                                <div>
                                    <div style={{ margin: "10px" }}>
                                        <TextField
                                            id="no_of_events"
                                            label="No of events to be added"
                                            variant="outlined"
                                            onChange={
                                                (e) => setNumberOfEvents(e.target.value)
                                            }
                                            value={numberOfEvents || ''}
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            )
                        }
                        
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ParticipantEventsOverview
                        classes={classes}
                        attendeeDetails={true}
                        eventOverview={casesEventsOverview?.sort(function (a, b) {
                            return new Date(a.event_start) - new Date(b.event_start);
                        })}
                        getStatusClass={getStatusClass}
                    />
                </Grid>

                {
                    personOpenOpportunity && !isNetworkDirector && !isEmpty(personOpenOpportunity) && (
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <NextPlannedAction opportunity={personOpenOpportunity} person={person} />

                                    <div style={{ borderTop: '1px solid #c8c8c8', padding: '10px 0', margin: '10px 0 0' }}></div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ marginRight: '10px' }}>
                                            <b>Current Open Opportunity:</b> {personOpenOpportunity && personOpenOpportunity.opportunity_id ? <a href={`${window.location.origin}/opportunities/id/${personOpenOpportunity ? personOpenOpportunity.opportunity_id : undefined}`} target="_blank">{personOpenOpportunity && personOpenOpportunity.opportunity_id}</a> : "None"}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography component="span" style={{ marginRight: '10px' }}>
                                                <b>Pipeline Stage:</b>
                                            </Typography>
                                            <FormControl size="small" sx={{ minWidth: '200px' }}>
                                                <InputLabel id="person_pipeline_stage">Pipeline Stage</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="person_pipeline_stage"
                                                    id="person_pipeline_stage"
                                                    value={personPielineStage}
                                                    onChange={(e) => {
                                                        const selected_stage = personOpenOpportunity.pipeline_stages.filter(item => item.pipeline_stage_id === e.target.value)[0]
                                                        const current_stage = personOpenOpportunity.pipeline_stages.filter(item => item.pipeline_stage_id === personPielineStage)[0]
                                                        Swal.fire({
                                                            customClass: {
                                                                title: 'custom-swal-title',
                                                                confirmButton: 'custom-swal-confirm',
                                                            },
                                                            title: 'You are going to change the stage of the opportunity',
                                                            html: `${current_stage.pipeline_stage_title} &#8594 ${selected_stage.pipeline_stage_title}`,
                                                            width: '600px',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#e0e0e0',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: "Cancel"
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                setPersonPielineStage(e.target.value);
                                                                props.updatePersonStage({
                                                                    person_id: person.person_id,
                                                                    opportunityId: personOpenOpportunity.opportunity_id,
                                                                    stageId: e.target.value
                                                                });
                                                            }
                                                        })
                                                    }}
                                                    input={<OutlinedInput label="Pipeline Stage" />}
                                                >
                                                    {personPielineStages.map((item) => (
                                                        <MenuItem
                                                            key={item.pipeline_stage_id}
                                                            value={item.pipeline_stage_id}
                                                        >
                                                            {item.pipeline_stage_title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }

                <Grid item xs={12}>
                    <ParticipantJobs
                        classes={classes}
                        jobs={jobs}
                        personObj={personObj}
                        getCompanyName={getCompanyName}
                        getYears={getYears}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ParticipantPurchases
                        classes={classes}
                        personPurchases={personPurchases}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ParticipantOpportunities
                        classes={classes}
                        personOpportunities={personOpportunities}
                    />
                </Grid>
                <Grid ref={history} item xs={12}>
                    <Accordion expanded={expanded === "panel4"} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography variant="h1" component="h5" sx={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: '500' }}>
                                History <span className={classes.historyCount}>{personHistory && personHistory.length}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ background: "#efefef" }}>
                            <PersonHistory person={personObj} isNetworkDirector={isNetworkDirector} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => ({
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    personPurchases: eventsOverviewSelectors.personPurchases(state),
    personOpportunities: eventsOverviewSelectors.personOpportunities(state),
    participantDetails: eventsOverviewSelectors.participantDetails(state),
    contactDetails: ContactsSelectors.contactDetails(state),
    foodConsiderations: ContactsSelectors.foodConsiderations(state),
    jobs: opportunitySelectors.jobs(state),
    personHistory: eventsOverviewSelectors.personHistory(state),
    personOpenOpportunity: eventsOverviewSelectors.personOpenOpportunity(state),
    pendingCaseDetails: eventsOverviewSelectors.pendingCaseDetails(state),
    casesEventsOverview: eventsOverviewSelectors.casesEventsOverview(state),
    loading: eventsOverviewSelectors.isLoading(state)

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateAttendanceStatus: eventsOverviewActions.updateAttendanceStatus,
            updateSendoutEmail: eventsOverviewActions.updateSendoutEmail,
            updatePersonStage: eventsOverviewActions.updatePersonStage,
            getPersonPurchases: eventsOverviewActions.getPersonPurchases,
            getPersonOpenOpportunity: eventsOverviewActions.getPersonOpenOpportunity,
            getPersonOpportunities: eventsOverviewActions.getPersonOpportunities,
            resolveCase: eventsOverviewActions.resolveCase,
            getPendingCaseDetails: eventsOverviewActions.getPendingCaseDetails,
            getContactDetails: contactsActions.getContactDetails,
            getFoodConsiderations: contactsActions.getFoodConsiderations,
            getJobs: opportunityActions.getJobs,
            updateContact: contactsActions.updateContact,
            resetTeams: usersActions.resetTeamDetails,
            caseDecline: eventsOverviewActions.caseDecline,
            getCasesEventsOverview: eventsOverviewActions.getCasesEventsOverview,
            updateAssignee: eventsOverviewActions.updateAssignee,

        },
        dispatch
    );

function WithNavigate(props) {
    let params = useParams();
    return <PersonCaseOverviewTab {...props} params={params} />
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(WithNavigate));
