import React, {useEffect, useState} from 'react';
import {Typography, FormControlLabel, Checkbox} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {selectors as adminSelectors, actions as adminActions} from '../../../Ducks/admin';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {cloneDeep} from "lodash";

function SettingsPage(props) {
    const {
        siteSettings,
        updateSiteSettings, siteSettingsLoader, updateSiteSettingsLoader, addSiteSettingsLoader
    } = props

    const [modules, setModules] = useState([]);

    const toggleModule = (moduleId) => {
        updateSiteSettings(moduleId);
    };

    let loaderArray = [siteSettingsLoader, updateSiteSettingsLoader, addSiteSettingsLoader];
    const isLoading = loaderArray.some(loader => loader);

    useEffect(() => {
        if (siteSettings) {
            setModules(siteSettings);
        }
    }, [siteSettings]);
    return (
        <div>
            <Typography variant="h2">System Settings</Typography>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography variant="h3">Modules</Typography>
                {isLoading && <LinearProgress style={{width: '13%'}}/>}

                {modules?.map((module, index) => (
                    <div key={index}
                         style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FormControlLabel id={module.meta_name}
                                          control={<Checkbox
                                              checked={JSON.parse(module.meta_value).module_status == 1 ? true : false}
                                              onChange={(e) => {
                                                  let _modules = cloneDeep(modules);
                                                  let value = JSON.parse(module.meta_value);
                                                  value.module_status = e.target.checked ? 1 : 0;
                                                  module.meta_value = JSON.stringify(value);
                                                  _modules[index] = module;
                                                  setModules(_modules);
                                                  toggleModule(module.id)
                                              }}/>}
                                          label={module.meta_name}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    siteSettings: adminSelectors.siteSettings(state),
    siteSettingsLoader: adminSelectors.siteSettingsLoader(state),
    updateSiteSettingsLoader: adminSelectors.updateSiteSettingsLoader(state),
    addSiteSettingsLoader: adminSelectors.addSiteSettingsLoader(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getSiteSettings: adminActions.getSiteSettings,
        addSiteSettings: adminActions.addSiteSettings,
        updateSiteSettings: adminActions.updateSiteSettings,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
