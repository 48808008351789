import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Phone } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Grid from '@mui/material/Grid';

const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff'
    // height: 200
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '-8px',
    marginBottom: '-8px',
  }
});

function Company(props) {
  const { classes, company } = props;
  const [companyObj, setCompanyObj] = React.useState({});
  const [divisionObj, setDivisionObj] = React.useState({});

  useEffect(() => {
    if (company.length !== 0) {
      const divisionID = company.related_objects.jobs[company.main.job_id].division_id;
      if (divisionID) {
        const division = company.related_objects.divisions ? company.related_objects.divisions[divisionID] : null;
        setCompanyObj(division && company.related_objects.companies[division.company_id] ? company.related_objects.companies[division.company_id] : {});
        setDivisionObj(division || {});
      } else {
        const job = company.related_objects.jobs[company.main.job_id];
        const _companyObj = {
          company_name: job.override_company_name || job.scraped_company_name,
          company_address_country: '',
          employees: '',
          members: ''
        }
        setCompanyObj(_companyObj);
        setDivisionObj({});
      }
    }
  }, [company]);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div">
          {companyObj.company_name}
        </Typography>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.typography} variant="body2" color="site.main">
              {companyObj.company_address_country}
            </Typography>
            {
              divisionObj && divisionObj.division_phone && (
                <Typography variant="body2" color="site.main">
                  <a href={`callto:${divisionObj.division_phone}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <Phone sx={{ color: 'icon.main', fontSize: "20px" }} />
                    <span style={{ marginLeft: '8px' }}>
                      {divisionObj.division_phone}
                    </span>
                  </a>
                </Typography>
              )
            }
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="site.main">
              Employees: {companyObj.employees}
            </Typography>
            <Typography variant="body2" color="site.main">
              Members: {companyObj.members}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button variant="contained" color="secondary" endIcon={<ArrowRightAltIcon />} size="small" onClick={() => props.handleTabChange('company')}>Company</Button>
      </CardActions>
    </Card>
  );
}

export default withStyles(useStyles)(Company);