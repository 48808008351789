import * as React from 'react';
import { Formik, Field, Form } from 'formik';
import {
    Button,
    LinearProgress,
    MenuItem,
    Typography,
    Container,
    Grid,
    ButtonGroup,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';
import {
    TextField
} from 'formik-material-ui';
import { withStyles } from '@mui/styles';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../../Ducks/opportunity';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Done } from '@mui/icons-material';

toast.configure();
const useStyles = theme => ({
    noPadding: {
        padding: 0
    },
    container: {
        textAlign: 'left',
    }
});

const Sms = (props) => {
    const { classes, opportunity, isLoading, smsTemplates, smsTemplate, sendSms, sentSms } = props;
    const [template, setTemplate] = React.useState(null);
    const [sent, setSent] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const formRef = React.createRef();

    React.useEffect(() => {
        if (smsTemplate !== null && formRef.current && template !== null) {
            formRef.current.resetForm();
            formRef.current.setFieldValue('template', template);
            formRef.current.setFieldValue('subject', smsTemplate.main.subject);
            formRef.current.setFieldValue('body', smsTemplate.main.body);
        }
    }, [smsTemplate])

    React.useEffect(() => {
        if (opportunity !== undefined) {
            props.getSmsTemplates(opportunity.main.product_id);
        }
    }, [opportunity]);

    React.useEffect(() => {
        if (sentSms !== null && sent) {
            // toast.success(sentSms.message, {
            //     position: toast.POSITION.BOTTOM_LEFT,
            //     hideProgressBar: true,
            //     autoClose: 2000
            //   });
            setMessage(sentSms.message);
            formRef.current.resetForm();
        }
    }, [sentSms]);

    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )

    const getTemplate = e => {
        const templateId = e.target.value;
        const opportunityId = props.params.id;
        setTemplate(templateId);
        props.applySmsTemplate({ templateId, opportunityId });
    }

    if (!smsTemplates || isLoading) {
        return showProgress()
    }

    return (
        <React.Fragment>
            <div>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        template: '', // smsTemplate && template,
                        subject: '', // smsTemplate && template && smsTemplate.main.subject,
                        body: '' // smsTemplate && template && smsTemplate.main.body
                    }}
                    validate={values => {

                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log('values----------------------------', values)
                        let data = {
                            message: values.body
                        }
                        sendSms({ id: props.params.id, obj: data });
                        setTemplate(null);
                        setSent(true);
                        setSubmitting(false);
                    }}
                    render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <Grid container sx={{ marginBottom: '15px', marginTop: '15px' }} spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        name="template"
                                        label="Please select a template"
                                        select
                                        variant="outlined"
                                        helperText=""
                                        margin="normal"
                                        component={TextField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        onChange={getTemplate}
                                    >
                                        {smsTemplates && smsTemplates.main.templates.map((option, index) => (
                                            <MenuItem key={index} value={option.template_id}>
                                                {option.template_name}
                                            </MenuItem>
                                        ))}
                                    </Field>

                                    <Field
                                        type="text"
                                        label=""
                                        name="body"
                                        multiline
                                        rows="4"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        component={TextField}
                                    />

                                </Grid>
                            </Grid>

                            {isSubmitting && <LinearProgress />}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Send SMS
                                </Button>
                                {
                                    sentSms !== null && sent && message !== '' ? (
                                        <p style={{ color: 'green', fontSize: '16px', textAlign: 'center', margin: '0 0 0 10px' }}><Done sx={{ color: 'green', fontSize: '16px' }} /> {message}</p>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        </Form>
                    )}
                />
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.applySmsTemplate_loading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    smsTemplates: opportunitySelectors.smsTemplates(state),
    smsTemplate: opportunitySelectors.smsTemplate(state),
    sentSms: opportunitySelectors.sentSms(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getSmsTemplates: opportunityActions.getSmsTemplates,
        applySmsTemplate: opportunityActions.applySmsTemplate,
        sendSms: opportunityActions.sendSms,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <Sms {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
