import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Persons from '../Widgets/Persons'
import Company from '../Widgets/Company'
import Action from '../Widgets/Action'
import History from '../Widgets/History'
import UpcomingEvents from '../Widgets/UpcomingEvents'
import ReadyToClose from '../Widgets/ReadyToClose'
import Permission from '../Widgets/Permission';
import {withStyles} from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ParticipantEventsOverview from "../../../Common/Overview/ParticipantEventsOverview";
import {cloneDeep} from "lodash-es";

const useStyles = _theme => ({
    flex: {
        display: 'flex'
    },
    card: {
        backgroundColor: '#fff'
    },
    grayBg: {
        background: '#757373',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    noBgDotted: {
        background: 'transparent',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        border: 'red',
        borderStyle: 'solid'
    },
    lightBlueBgDotted: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        background: 'repeating-linear-gradient(45deg, red, red 2px, #5cd7ff 2px, #5cd7ff 8px)',
        borderStyle: 'solid',
        borderColor: 'red'
    },
    lightBlueBgSolid: {
        background: '#5cd7ff',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: '#42d142'

    },
    lightBlueBg: {
        background: '#5cd7ff',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    darkBlueBgSolid: {
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: '#42d142'
    },
    darkBlueBg: {
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px'
    },
    darkBlueBgDotted: {
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'red',
    },
    noBgSolid: {
        background: 'transparent',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
    },
    cyanBgSolid: {
        background: '#FDDA0D',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    cyanBg: {
        background: '#FDDA0D',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    cyanBgSolidv2: {
        height: '19px',
        width: '19px',
        borderColor: 'red',
        borderStyle: 'solid',
        marginRight: '5px',
        background: 'repeating-linear-gradient(45deg, red, red 2px, #FDDA0D 2px, #FDDA0D 8px)'
    },
    cyanBgSolidv3: {
        height: '19px',
        width: '19px',
        borderColor: '#42d142',
        borderStyle: 'solid',
        marginRight: '5px',
        background: '#FDDA0D'
    },
});

function Overview(props) {
    const {classes, opportunity, isLoading, assignedOpportunity, checkEvent_loading} = props;
    const [eventOverview, setEventOverview] = React.useState([]);
    let person = opportunity ? Object.values(opportunity.related_objects.persons) : null;
    React.useEffect(() => {
        if (opportunity) {
            const _eventOverview = cloneDeep(opportunity.related_objects.events_overview);
            if (_eventOverview) {
                // Sort
                _eventOverview.sort(function (a, b) {
                    return new Date(a.event_start) - new Date(b.event_start);
                });
                setEventOverview(_eventOverview);
                // Sort
            }
        }
    }, [opportunity]);

    const getStatusClass = (item) => {
        const {event_has_happened, showed, color, has_open_opportunity, purchase_id, freezed} = item;
        if (freezed) {
            return 'grayBg';
        } else {
            if (event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
                return 'noBgDotted';
            } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
                return 'lightBlueBgDotted';
            } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && showed) {
                return 'lightBlueBgSolid';
            } else if (!event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
                return 'lightBlueBg';
            } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && showed) {
                return 'darkBlueBgSolid';
            } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
                return 'darkBlueBgDotted';
            } else if (!event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
                return 'darkBlueBg';
            } else if (!event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
                return 'noBgSolid';
            } else if (!event_has_happened && color === "purchase_a" && has_open_opportunity && !showed) {
                return 'cyanBgSolid'
            } else if (!event_has_happened && color === "purchase_b" && has_open_opportunity && !showed) {
                return 'cyanBg'
            } else if (event_has_happened && (color === "purchase_b" || color === "purchase_a") && has_open_opportunity && !showed) {
                return 'cyanBgSolidv2'
            } else if (event_has_happened && (color === "purchase_b" || color === "purchase_a") && has_open_opportunity && showed) {
                return 'cyanBgSolidv3'
            } else {
                return 'noBgSolid';
            }
        }
    }

    const showProgress = () => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </div>
    )

    if (!opportunity || isLoading) {
        return showProgress()
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Persons
                    person={opportunity}
                    handleTabChange={(data) => props.handleTabChange(data)}
                    showActiveTab={props.showActiveTab}
                />
            </Grid>
            <Grid item xs={6}>
                <Company company={opportunity} handleTabChange={props.handleTabChange}
                         showActiveTab={props.showActiveTab}/>
                {person[0].person_country == 'DK' ?
                    <Permission overview={opportunity}/>
                    : <></>
                }
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Events Overview
                        </Typography>
                        <ParticipantEventsOverview
                            classes={classes}
                            attendeeDetails={true}
                            eventOverview={eventOverview}
                            getStatusClass={getStatusClass}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Action overview={opportunity} handleTabChange={props.handleTabChange}
                        showActiveTab={props.showActiveTab}/>
            </Grid>
            <Grid item xs={6}>
                <History stage={opportunity} handleTabChange={props.handleTabChange}
                         showActiveTab={props.showActiveTab}/>
            </Grid>
            <Grid item xs={6}>
                <UpcomingEvents
                    overview={opportunity}
                    unCheckEvent={props.unCheckEvent}
                    checkEvent={props.checkEvent}
                    checkEvent_loading={checkEvent_loading}
                    handleTabChange={props.handleTabChange}
                    assignedOpportunity={assignedOpportunity}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadyToClose handleTabChange={props.handleTabChange} overview={opportunity}/>
            </Grid>
        </Grid>
    );
}

export default withStyles(useStyles)(Overview);