import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, CardMedia, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField, Radio } from '@mui/material';
import { Phone, Language, BusinessCenter, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt } from '@mui/icons-material';
import moment from 'moment';

export default function ParticipantOpportunities(props) {
    const { classes, personOpportunities } = props;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.accordionSummary}
            >
                <Typography variant="h1" component="h5" sx={{ fontSize: '18px', fontWeight: '500' }}>
                    Opportunities
                </Typography>
                {personOpportunities && personOpportunities.filter((item) => item.opportunity_status === 'open').map((opportunity, index) => {
                    return (
                        <Grid key={index} container spacing={2} sx={{ padding: '10px 0', marginTop: 0 }}>
                            <Grid item xs={6} sx={{ paddingTop: '0 !important' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                        {opportunity.product_name}
                                    </Typography>
                                    <Typography
                                        style={{ flexGrow: "1", fontSize: '11px' }}
                                        gutterBottom
                                        variant="span"
                                        component="span"
                                    >
                                        {opportunity.product_type_name}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>
                                <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                    {opportunity.opportunity_status}
                                </Typography>

                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>

                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {opportunity.opportunity_type}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>

                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {moment(opportunity.opportunity_creation_time).format("DD/MM/YYYY hh:mm")}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </AccordionSummary>
            <AccordionDetails>
                {personOpportunities && personOpportunities.filter((item) => item.opportunity_status !== 'open').map((opportunity, index) => {
                    return (
                        <Grid key={index} container spacing={2} sx={{ padding: '10px 0', borderTop: '1px solid #c2c2c2', marginTop: 0 }}>
                            <Grid item xs={6} sx={{ paddingTop: '0 !important' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                        {opportunity.product_name}
                                    </Typography>
                                    <Typography
                                        style={{ flexGrow: "1", fontSize: '11px' }}
                                        gutterBottom
                                        variant="span"
                                        component="span"
                                    >
                                        {opportunity.product_type_name}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>
                                <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                    {opportunity.opportunity_status}
                                </Typography>

                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>

                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {opportunity.opportunity_type}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>

                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {moment(opportunity.opportunity_creation_time).format("DD/MM/YYYY hh:mm")}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
}