import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { selectors as accountSelectors, actions as accountActions } from '../../Ducks/account';
import { actions as opportunityActions } from '../../Ducks/opportunity';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../Ducks/eventsOverview';
import { selectors as userSelectors, actions as userActions } from '../../Ducks/users';
import { selectors as adminSelectors, actions as adminActions } from '../../Ducks/admin';
import { actions as contactActions } from '../../Ducks/contacts';
import AnchorTemporaryDrawer from '../Containers/ReleaseNotes/ReleaseNotesBar';
import Fab from '@mui/material/Fab';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuButton from './MenuButton';
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete, Button, TextField } from "@mui/material";
import { fetchPost } from '../../API/util';
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import CapabilityChecker from './CapabilityChecker';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Tooltip from '@mui/material/Tooltip';
import { generateIDForAUT } from '../Utils/generateID';



function MenuAppBar(props) {
    const {
        user,
        parentUser,
        setParentUser,
        loginAs,
        loginAlias,
        getPendingCases,
        pendingCases,
        getActiveUsers,
        activeUsers,
        getReleaseNotes,
        toggleBar,
        getTags,
        releaseNotes,
        getSiteSettings,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [persons, setPersons] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [color, setColor] = React.useState("none")
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [shouldBlink, setShouldBlink] = React.useState(false);
    const open = Boolean(anchorEl);
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    React.useEffect(() => {
        getPendingCases();
        getActiveUsers();
        getReleaseNotes();
        getTags();
        getSiteSettings();
        getTags();
    }, []);

    React.useEffect(() => {
        if (pendingCases) {
            if (pendingCases.total_pending_cases > 0) {
                setColor("red");
            } else {
                setColor("green");
            }
        }
    }, [pendingCases])

    React.useEffect(() => {
        if (releaseNotes) {
            let createdAt = releaseNotes[0]?.created_at;
            const timeDifference = new Date().getTime() - new Date(createdAt).getTime();
            if (timeDifference < 86400000) {
                setShouldBlink(true);
            }
        }
    }, [releaseNotes])

    const _activeUsers = activeUsers?.map((user) => {
        user.key = user.user_id;
        user.label = `${user.user_firstname} ${user.user_lastname}`;
        return user;
    });

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleReleaseNotes = () => {
        toggleBar();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = (instance) => {
        const { navigate } = props;
        if (isAuthenticated) {
            localStorage.clear();
            instance.logoutRedirect().then((_res) => {
            }).catch(e => {
                return e;
            });
        } else {
            props.logout();
            handleClose();
            navigate("/");
        }
    }

    const navigateTo = () => {
        props.navigate("/");
    }

    const handleExitViewingAs = (_parentUser) => {
        setParentUser(undefined);
        loginAs({ obj: { username: _parentUser }, navigateTo });
    }

    const switchUser = (user) => {
        loginAlias({ obj: { username: user }, navigateTo });
    }

    const searchPerson = (e) => {
        if (e.target.value.length >= 3) {
            fetchPost(`/persons/search/${e.target.value}`, selectedUser !== null ? { "id": selectedUser.user_id } : '').then(({ data }) => {
                let arr = [], personsArray = [];
                Object.keys(data.main).map(key => {
                    arr.push(data.main[key])
                });
                if (arr.length) {
                    personsArray = arr.map((item) => {
                        item.label = `${item.firstname + " " + item.lastname}, ${item.job_title} - ${item.company_name}`;
                        item.key = item.opportunity_id
                        item.id = item.opportunity_id
                        return item;
                    });
                    setPersons(personsArray)
                } else {
                    setPersons([])
                }
            }).catch(() => {
            })
        } else {
            setPersons([])
        }
    }

    return (
        <div style={{ flexGrow: 1, zIndex: 1201 }}>
            <AppBar style={{ backgroundColor: window.location.href.indexOf("crm.f5") === -1 ? "#a30000" : "#002ea7" }}
                position="relative">
                <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" style={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: '600'
                    }}>
                        {
                            props.menuList.map((item, index) => (<MenuButton key={index} item={item} user={user} />))
                        }
                    </Typography>
                    {
                        releaseNotes.length > 0 ?
                            <Tooltip title="Release Notes">
                                <Fab color="primary" onClick={handleReleaseNotes} aria-label="add"
                                    style={{ position: 'fixed', right: '20px', bottom: '20px', color: 'white' }}
                                    className={shouldBlink ? 'blink' : ''}>
                                    <AssignmentIcon />
                                </Fab>
                            </Tooltip>
                            : <></>
                    }
                    <CapabilityChecker capabilities={['ongoing_case_reviewer', 'pause_case_reviewer']}>
                        <Link to="/case/overview" style={{ textDecoration: 'none', color: 'white' }} id={generateIDForAUT(`case/overview-Link`)}>
                            <Button className={color === "red" ? "blink" : ""} size="small" color="inherit"
                                style={{ background: `${color}`, marginRight: 20 }} id={generateIDForAUT(`case-overview-Button`)}>
                                Case
                            </Button>
                        </Link>
                    </CapabilityChecker>
                    <div>
                        <Autocomplete
                            disablePortal
                            id={generateIDForAUT(`Select User-Autocomplete`)}
                            sx={{ minWidth: '170px', marginLeft: "5px" }}
                            options={_activeUsers}
                            onChange={(_event, value, reason) => {
                                setSelectedUser(value);
                                if (reason === 'clear') {
                                    setSelectedUser(null);
                                }
                            }}
                            renderOption={(props, option, _state) => {
                                return (
                                    <div {...props} key={option.key}>
                                        <div style={{
                                            textAlign: 'left',
                                            padding: '0',
                                            fontSize: '13px',
                                            borderBottom: '1px solid #eaeaea'
                                        }}>
                                            <span><b>{`${option.user_firstname + " " + option.user_lastname}`}</b></span>
                                        </div>
                                    </div>
                                );
                            }}
                            size="small"

                            renderInput={(params) => <TextField
                                sx={{
                                    background: "#fff",
                                    width: '300px',
                                    marginRight: '10px',
                                    border: 'none',
                                    fontSize: '13px'
                                }} {...params} size="small" onChange={null} label="Select User" variant="filled" />}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            disablePortal
                            value={searchValue}
                            id={generateIDForAUT(`Search-Autocomplete`)}
                            sx={{ minWidth: '170px', marginLeft: "5px" }}
                            options={persons}
                            filterOptions={(options, _state) => options}
                            renderOption={(props, option, _state) => {
                                return (
                                    <div {...props}>
                                        <div style={{
                                            textAlign: 'left',
                                            padding: '0',
                                            fontSize: '13px',
                                            borderBottom: '1px solid #eaeaea'
                                        }}>
                                            <span><b>{`${option.firstname + " " + option.lastname}`}</b></span>
                                            <span>{`, ${option.job_title} - ${option.company_name}`}</span>
                                        </div>
                                    </div>
                                );
                            }}
                            size="small"
                            onFocus={(_event, value) => {
                                if (value === "") {
                                    setPersons([]);
                                }
                            }}
                            onChange={(_event, value) => {
                                // Navigate to new opportunity
                                if (value !== null) {
                                    const { navigate } = props;
                                    setSearchValue(value);
                                    navigate(`/opportunities/id/${value.opportunity_id}?search=${value.opportunity_id}`);
                                } else if (value === "") {
                                    setPersons([]);
                                    setSearchValue("")
                                } else {
                                    setPersons([]);
                                    setSearchValue("")
                                }
                            }}
                            onKeyUp={(event, value) => {
                                if (value !== null && value !== "") {
                                    setSearchValue(event.target.value);
                                } else {
                                    setSearchValue("")
                                }
                            }}
                            renderInput={(params) => <TextField sx={{
                                background: "#fff",
                                width: '300px',
                                marginRight: '10px',
                                border: 'none',
                                fontSize: '13px'
                            }} {...params} size="small" onChange={searchPerson} label="Search..." variant="filled" />}

                        />
                    </div>
                    {props.loggedIn && (
                        <div>
                            <IconButton
                                id={generateIDForAUT(`account of current user-IconButton`)}
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                style={{ color: '#fff', fontSize: '17px', fontWeight: '600' }}
                            >
                                <small style={{ marginRight: '5px' }}>
                                    {user && user.user_details.display_name ? user.user_details.display_name : user.user_details.user_firstname}
                                </small>
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                {
                                    parentUser && (
                                        <MenuItem id={generateIDForAUT(`Switch back to-MenuItem`)} onClick={() => handleExitViewingAs(parentUser)}>
                                            Switch back to {parentUser}
                                        </MenuItem>
                                    )
                                }
                                {
                                    user && user.alias_user_details && (
                                        <MenuItem id={generateIDForAUT(`Switch to-MenuItem`)} onClick={() => switchUser(user.alias_user_details.user_email)}>
                                            Switch to {user && user.alias_user_details.display_name ? user.alias_user_details.display_name : user.alias_user_details.user_firstname}
                                        </MenuItem>
                                    )
                                }
                                <MenuItem id={generateIDForAUT(`Log Out-MenuItem`)} onClick={() => handleLogOut(instance)}>Log Out</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
                <AnchorTemporaryDrawer />
            </AppBar>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loggedIn: accountSelectors.isLoggedIn(state),
    user: accountSelectors.user(state),
    parentUser: accountSelectors.parentUser(state),
    pendingCases: eventsOverviewSelectors.pendingCases(state),
    activeUsers: userSelectors.activeUsers(state),
    releaseNotes: adminSelectors.releaseNotes(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        logout: accountActions.logout,
        loginAs: accountActions.loginAs,
        loginAlias: accountActions.loginAlias,
        setParentUser: accountActions.setParentUser,
        getPersons: opportunityActions.getPersons,
        getPendingCases: eventsOverviewActions.getPendingCases,
        getActiveUsers: userActions.getActiveUsers,
        getReleaseNotes: adminActions.getReleaseNotes,
        toggleBar: adminActions.toggleBar,
        getTags: contactActions.getTags,
        getSiteSettings: adminActions.getSiteSettings,
    },
    dispatch);

function WithNavigate(props) {
    let navigate = useNavigate();
    return <MenuAppBar {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);