import React from 'react';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Products from '../Widgets/Products';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const useStyles = theme => ({
  card: {
      display: 'flex',
      backgroundColor: '#F1F1F1'
      // height: 200
  },
  cardMedia: {
      flex: 1,
      width: '85px',
      backgroundColor: '#F1F1F1'
  },
  cardContent: {
      flex: 4
  },
  flexVertical: {
      display: "flex",
      alignItems: "center"
  },
  flexCenter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
  }
});

function Product(props) {
  const { opportunity, classes } = props;

  if (!opportunity) {
    return <div></div>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Products opportunity={opportunity} />
      </Grid>
    </Grid>
  );
}

export default withStyles(useStyles)(Product);