import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

const useStyles = () => ({
    dialogWrapper: {
        position: 'absolute',
    },
    dialogTitle: {
        paddingRight: '0px'
    }
});

export default function AiContentSuggestionDialog(props) {

    const { openPopup, setOpenPopup, popupContent, acceptSuggestions } = props;
    const classes = useStyles();

    const closeDialog = () => setOpenPopup(false);

    return (
        <Dialog open={openPopup} fullWidth maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle id="scroll-dialog-title">Email Content Suggestions
                <IconButton aria-label="close" onClick={closeDialog} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {<div dangerouslySetInnerHTML={{ __html: popupContent }} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>
                    Cancel
                </Button>
                <Button onClick={acceptSuggestions} autoFocus>
                    Accept Suggestions
                </Button>
            </DialogActions>
        </Dialog>
    )
}