import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as adminTypes, actions as adminActions } from '../Ducks/admin';

import { callApi } from './utils';
import * as api from '../API/admin';
import Swal from 'sweetalert2';


function* getAdminTemplates(action) {
  const { success, failure } = adminActions.saga.getAdminTemplates;

  yield callApi(
    call(api.getAdminTemplates, action.data),
    success,
    failure
  )
}

function* getAdminTemplateTags(action) {
  const { success, failure } = adminActions.saga.getAdminTemplateTags;

  yield callApi(
    call(api.getAdminTemplateTags, action.data),
    success,
    failure
  )
}

function* saveAdminTemplate(action) {
  const { success, failure } = adminActions.saga.saveAdminTemplate;

  yield callApi(
    call(api.saveAdminTemplate, action.data),
    success,
    failure,
  )
}

function* createAdminTemplate(action) {
  const { success, failure } = adminActions.saga.createAdminTemplate;
  
  yield callApi(
    call(api.createAdminTemplate, action.data),
    success,
    failure,
  )
}

function* deleteAdminTemplate(action) {
  const { success, failure } = adminActions.saga.deleteAdminTemplate;

  function* postApiSuccessCallEffect() {
    yield call(getAdminTemplates, action);
    yield call(getMyTemplates);
  }

  yield callApi(
    call(api.deleteAdminTemplate, action.data),
    success,
    failure,
{ postApiSuccessCallEffect }
  )
}

function* deleteUserRole(action) {
  const { success, failure } = adminActions.saga.deleteUserRole;

  function* postApiSuccessCallEffect(res) {
    const message = res;
    const icon = res.success === "Success" ? "success" : "warning";
    
    Swal.fire({
      title: "",
      text: message.success,
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Ok",
    });

    if(res === "Success") {
      yield call(getUserRoles, action);
    }
  }

  yield callApi(
    call(api.deleteUserRole, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getUserRoles(action) {
  const { success, failure } = adminActions.saga.getUserRoles;

  yield callApi(
    call(api.getUserRoles, action.data),
    success,
    failure
  )
}

function* getCapabilitiesList(action) {
  const { success, failure } = adminActions.saga.getCapabilitiesList;

  yield callApi(
    call(api.getCapabilitiesList, action.data),
    success,
    failure
  )
}

function* getRoleCapabilities(action) {
  const { success, failure } = adminActions.saga.getRoleCapabilities;

  yield callApi(
    call(api.getRoleCapabilities, action.data),
    success,
    failure
  )
}

function* addNewRole(action) {
  const { success, failure } = adminActions.saga.addNewRole;

  function* postApiSuccessCallEffect() {
    yield call(getUserRoles, action);
  }

  yield callApi(
    call(api.addNewRole, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* saveRoleCapabilities(action) {
  const { success, failure } = adminActions.saga.saveRoleCapabilities;

  yield callApi(
    call(api.saveRoleCapabilities, action.data),
    success,
    failure
  )
}

function* getGmailData(action){
  const { success, failure } = adminActions.saga.getGmailData;

  yield callApi(
    call(api.getGmailData,action.data),
    success,
    failure
  )
}

function* getMyTemplates()
{
  const { success, failure } = adminActions.saga.getMyTemplates;

  yield callApi(
    call(api.getMyTemplates),
    success,
    failure
  )
}

function* getSiteSettings()
{
  const { success, failure } = adminActions.saga.getSiteSettings;

  yield callApi(
    call(api.getSiteSettings),
    success,
    failure
  )
}

function* addSiteSettings(action)
{
  const { success, failure } = adminActions.saga.addSiteSettings;

  yield callApi(
    call(api.addSiteSettings, action.data),
    success,
    failure
  )
}

function* updateSiteSettings(action)
{
  const { success, failure } = adminActions.saga.updateSiteSettings;

  yield callApi(
    call(api.updateSiteSettings, action.data),
    success,
    failure
  )
}

function* getReleaseNotes()
{
  const { success, failure } = adminActions.saga.getReleaseNotes;

  yield callApi(
    call(api.getReleaseNotes),
    success,
    failure
  )
}

function* saveReleaseNotes(action)
{
  const { success, failure } = adminActions.saga.saveReleaseNotes;

  yield callApi(
    call(api.saveReleaseNotes,action.data),
    success,
    failure
  )
}

function* getBouncedEmails(action)
{
  const { success, failure } = adminActions.saga.getBouncedEmails;

  yield callApi(
    call(api.getBouncedEmails,action.data),
    success,
    failure
  )
}

function* postBouncedEmails(action) {
  const { success, failure } = adminActions.saga.postBouncedEmails;

  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Processed successfully!',
      showConfirmButton: false,
      timer: 1500
    });
  }

  yield callApi(
    call(api.postBouncedEmails,action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getBouncedEmailFiles(action) {
  const { success, failure } = adminActions.saga.getBouncedEmailFiles;

  yield callApi(
    call(api.getBouncedEmailFiles,action.data),
    success,
    failure
  )
}
export default function* rootCustomerSaga() {
  yield takeLatest(adminTypes.getAdminTemplates, getAdminTemplates);
  yield takeLatest(adminTypes.getAdminTemplateTags, getAdminTemplateTags);
  yield takeLatest(adminTypes.saveAdminTemplate, saveAdminTemplate);
  yield takeLatest(adminTypes.createAdminTemplate, createAdminTemplate);
  yield takeLatest(adminTypes.deleteAdminTemplate, deleteAdminTemplate);
  yield takeLatest(adminTypes.getUserRoles, getUserRoles);
  yield takeLatest(adminTypes.getCapabilitiesList, getCapabilitiesList);
  yield takeLatest(adminTypes.getRoleCapabilities, getRoleCapabilities);
  yield takeLatest(adminTypes.addNewRole, addNewRole);
  yield takeLatest(adminTypes.saveRoleCapabilities, saveRoleCapabilities);
  yield takeLatest(adminTypes.deleteUserRole, deleteUserRole);
  yield takeLatest(adminTypes.getGmailData,getGmailData);
  yield takeLatest(adminTypes.getMyTemplates,getMyTemplates);
  yield takeLatest(adminTypes.getSiteSettings,getSiteSettings);
  yield takeLatest(adminTypes.addSiteSettings,addSiteSettings);
  yield takeLatest(adminTypes.updateSiteSettings,updateSiteSettings);
  yield takeLatest(adminTypes.getReleaseNotes,getReleaseNotes);
  yield takeLatest(adminTypes.saveReleaseNotes,saveReleaseNotes);
  yield takeLatest(adminTypes.getBouncedEmails,getBouncedEmails);
  yield takeLatest(adminTypes.postBouncedEmails,postBouncedEmails);
  yield takeLatest(adminTypes.getBouncedEmailFiles,getBouncedEmailFiles);


}