import React from "react";
import {withStyles} from "@mui/styles";
import {MenuItem} from "@mui/material";
import {TextField} from "formik-material-ui";
import {Field} from "formik";
import RichTextEditor from "../Common/RichTextEditor";
import SelectBox from "./SelectBox";

const useStyles = (_theme) => ({
    container: {
        display: "flex",
        margin: "16px 0",
        '&:hover button': {
            display: 'block !important'
        }
    },
    typography: {
        flex: 2,
        marginRight: '5px'
    },
    field: {
        flex: 7,
        marginRight: "12px",
    },
    actions: {
        flex: 2,
    },
    formBtn: {
        display: 'none !important'
    }
});

function FormikFieldElement(props) {

    return (
        <>
            {getInputComponent(props)}
        </>
    );
}

const getInputComponent = (props) => {
    switch (props.type) {
        case "textfield":
            return (
                <Field
                    component={TextField}
                    fullWidth
                    size="small"
                    {...props}
                    type={"text"}
                />
            );
        case "textarea":
            return (
                <Field
                    component={TextField}
                    fullWidth
                    size="large"
                    {...props}
                    type={"textarea"}
                />
            );
        case "richText":
            return <RichTextEditor {...props} />;
        case "datetime-local":
            return (
                <Field
                    {...props}
                    component={TextField}
                    type="time"
                    sx={{width: "100%"}}
                />
            );
        case "checkbox":
            return (
                <Field style={{width: '30px', height: '30px'}} name={props.name} type="checkbox"/>
            )
        case "dropdown":
            return (
                <Field component={TextField} select fullWidth size="small" {...props}>
                    {props.list &&
                        props.list.map((option) => (
                            <MenuItem
                                key={option.Id}
                                value={option.id}
                                disabled={option.disabled}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                </Field>
            );
        case "foodconsideration":
            return (
                <>
                    <div className="food-consideration-section">
                        <SelectBox
                            multiple={false}
                            name="person_food_considerations_1"
                            type="type1_food"
                            label="Select Type"
                            labelId="select-type"
                            options={props.options}
                        />
                        <SelectBox
                            multiple={true}
                            name="person_food_considerations_2"
                            type="type2_food"
                            label="Select Allergy"
                            labelId="select-allergy"
                            options={props.options}
                            onChange={props.onChange}
                        />
                    </div>
                    {
                        props.formData && props.formData.person_food_considerations_2.filter((item) => item === 16).length ? (
                            <Field
                                component="textarea"
                                fullWidth
                                rows="5"
                                size="large"
                                name="person_food_considerations_other"
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderColor: "#c4c4c4",
                                    borderRadius: '5px'
                                }}
                                placeholder="Please write other allergies"
                            />
                        ) : (
                            <></>
                        )
                    }

                </>
            );
        default:
            return <Field component={TextField} fullWidth size="small" {...props} />;
    }
};

export default withStyles(useStyles)(FormikFieldElement);
