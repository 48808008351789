import {useState, useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useFormik} from "formik";
import * as yup from "yup";
import {
    Button,
    InputLabel,
    TextField,
    Box,
    CircularProgress,
    Typography,
    DialogTitle,
} from "@mui/material";

import {selectors as accountSelectors} from "../../../../../Ducks/account";
import {
    selectors as opportunitySelectors,
    actions as opportunityActions,
} from "../../../../../Ducks/opportunity";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@mui/styles";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Placeholder from "ckeditor5-placeholder";
import Image from "@ckeditor/ckeditor5-image/src/image";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";

const editorConfiguration = {
    plugins: [
        List,
        Placeholder,
        Essentials,
        Bold,
        Italic,
        Paragraph,
        SimpleUploadAdapter,
        ImageUpload,
        Image,
        Heading,
        Link,
        AutoLink,
        Indent,
        IndentBlock,
        Font,
        Alignment,
        Underline,
        Strikethrough,
        Table,
        TableToolbar,
        HorizontalLine,
        BlockQuote,
    ],
    toolbar: [
        "heading",
        "|",
        "Placeholder",
        "|",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "outdent",
        "indent",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "horizontalLine",
        "blockQuote",
        "uploadImage",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "alignment:left",
        "alignment:right",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
    ],
    alignment: {
        options: ["left", "right"],
    },
    placeholderProps: {
        types: [
            "FROM_FULLNAME",
            "FROM_FIRSTNAME",
            "FROM_LASTNAME",
            "EMAIL_SUBJECT",
            "FROM_EMAIL",
            "TO_FULLNAME",
            "TO_FIRSTNAME",
            "FIRST_MEETING_DATE",
            "FIRST_MEETING_START_TIME",
            "FIRST_MEETING_END_TIME",
            "FIRST_MEETING_DATE_AND_TIME",
            "PRODUCT_NAME",
            "PRODUCT_LOCATION",
            "PRODUCT_ADDRESS",
            "PRODUCT_ZIP_CODE",
            "PRODUCT_CITY",
            "NETWORK_DIRECTOR_1_FIRSTNAME",
            "NETWORK_DIRECTOR_1_FULLNAME",
            "NETWORK_DIRECTOR_2_FIRSTNAME",
            "NETWORK_DIRECTOR_2_FULLNAME",
        ],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: "https://erp2.f5.dk/api/events/uploadimage",
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    },
};

const useStyles = (_theme) => ({
    select: {
        "& ul": {
            display: "grid",
        },
    },
});

function SendEmailModal(props) {
    const [editor, setEditor] = useState(null);
    const {
        isLoading,
        emailTemplate,
        opportunity,
        handleClose,
        sendActionEmail,
        getMails,
    } = props;
    const template = "";
    const validationSchema = yup.object({
        subject: yup.string().required(),
        message: yup.string().required(),
    });

    const handleSubmit = (values) => {
        if (values.message.length >= 4096)
            return alert("Email content exceed maximum character limit.");

        sendActionEmail({
            id: opportunity.main.opportunity_id,
            obj: {
                subject: values.subject.trim(),
                message: values.message.trim(),
            },
        });
        handleClose();
    };

    const formik = useFormik({
        validationSchema,
        onSubmit: handleSubmit,
        initialValues: {
            subject: "",
            message: "",
        },
    });

    useEffect(() => {
        if (opportunity) {
            var payload = {
                productId: opportunity.main.product_id,
                type: "mail",
            };
            getMails(payload);
        }
    }, [opportunity]);

    useEffect(() => {
        if (emailTemplate !== null && template !== null) {
            formik.setFieldValue("message", emailTemplate.main.body);
        }
    }, [emailTemplate]);

    useEffect(() => {
        return () => {
            formik.resetForm();
            editor?.setData("");
        };
    }, [editor]);

    return (
        <div>
            <Dialog
                maxWidth={"lg"}
                fullWidth={!isLoading}
                open={props.open}
                onClose={handleClose}
            >
                {isLoading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        <DialogTitle>Send Email</DialogTitle>
                        <DialogContent>
                            <form onSubmit={formik.handleSubmit}>
                                <>
                                    <Box
                                        sx={{
                                            "& .MuiTextField-root": {
                                                mt: 1,
                                                width: "100%",
                                            },
                                        }}
                                    >
                                        <TextField
                                            label="Subject"
                                            variant="outlined"
                                            id="subject"
                                            name="subject"
                                            value={formik.values.subject}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.subject &&
                                                Boolean(formik.errors.subject)
                                            }
                                        />
                                        {formik.touched.subject &&
                                            formik.errors.subject && (
                                                <Typography
                                                    variant="body2"
                                                    color="error"
                                                >
                                                    {formik.errors.subject}
                                                </Typography>
                                            )}

                                        <InputLabel
                                            htmlFor="message"
                                            sx={{
                                                color:
                                                    formik.touched.message &&
                                                    formik.errors.message
                                                        ? "red"
                                                        : "inherit",
                                                marginTop: 2,
                                            }}
                                        >
                                            Message
                                        </InputLabel>
                                        <CKEditor
                                            id="message"
                                            name="message"
                                            editor={ClassicEditor}
                                            config={editorConfiguration}
                                            data={formik.values.message}
                                            onReady={setEditor}
                                            onChange={(_event, editor) => {
                                                formik.setFieldValue(
                                                    "message",
                                                    editor.getData()
                                                );
                                            }}
                                        />
                                        {formik.touched.message &&
                                            formik.errors.message && (
                                                <Typography
                                                    variant="body2"
                                                    color="error"
                                                >
                                                    {formik.errors.message}
                                                </Typography>
                                            )}
                                    </Box>
                                </>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "12px",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        style={{marginRight: "12px"}}
                                    >
                                        Send
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={handleClose}
                                    >
                                        {"Cancel"}
                                    </Button>
                                </div>
                            </form>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.applyEmailTemplate_loading(state),
    calendar: opportunitySelectors.calendar(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    mails: opportunitySelectors.mails(state),
    emailTemplate: opportunitySelectors.emailTemplate(state),
    cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            sendActionEmail: opportunityActions.sendActionEmail,
            getMails: opportunityActions.getMails,
            applyEmailTemplate: opportunityActions.applyEmailTemplate,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(SendEmailModal));
