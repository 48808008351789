import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    TextField,
    Box,
    CircularProgress,
    Grid,
    FormControlLabel,
    Switch
} from '@mui/material';
import DateTimePicker from './DateTimePicker';
import {selectors as accountSelectors} from '../../../Ducks/account';
import {selectors as opportunitySelectors, actions as opportunityActions} from '../../../Ducks/opportunity';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import {withStyles} from '@mui/styles';
import {getAllCalendars} from '../../../API/opportunity';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Placeholder from "ckeditor5-placeholder";
import {useParams} from 'react-router-dom';
import Image from '@ckeditor/ckeditor5-image/src/image';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';

const types = [
    'phone_call',
    'phone_meeting'
]

const editorConfiguration = {
    plugins: [List, Placeholder, Essentials, Bold, Italic, Paragraph, SimpleUploadAdapter, ImageUpload, Image, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
    toolbar: ['heading', '|', 'Placeholder', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', 'uploadImage', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    placeholderProps: {
        types: ['FROM_FULLNAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'EMAIL_SUBJECT', 'FROM_EMAIL', 'TO_FULLNAME', 'TO_FIRSTNAME', 'FIRST_MEETING_DATE', 'FIRST_MEETING_START_TIME', 'FIRST_MEETING_END_TIME', 'FIRST_MEETING_DATE_AND_TIME', 'PRODUCT_NAME', 'PRODUCT_LOCATION', 'PRODUCT_ADDRESS', 'PRODUCT_ZIP_CODE', 'PRODUCT_CITY', 'NETWORK_DIRECTOR_1_FIRSTNAME', 'NETWORK_DIRECTOR_1_FULLNAME', 'NETWORK_DIRECTOR_2_FIRSTNAME', 'NETWORK_DIRECTOR_2_FULLNAME'],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: 'https://erp2.f5.dk/api/events/uploadimage',
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem('token')
        }
    },
};

const useStyles = _theme => ({
    select: {
        "& ul": {
            display: "grid",
        }
    },
});

function AddEventModal(props) {
    const [date, setDate] = React.useState(props.startDate);
    const [type, setType] = React.useState(types[0]);
    const [template, setTemplate] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [userCalendars, setUserCalendars] = React.useState([]);
    const {
        mails, getMails, isLoading, user, emailTemplate, opportunity,
        addCalendarEvent, classes, isUpcomingEvent, tab, cronofyAuthorized
    } = props;
    let {id: opportunityId} = useParams();
    const validationSchema = cronofyAuthorized ? yup.object({
        create_event: yup.boolean(),
        subject: yup.string().when('create_event', {
            is: true,
            then: () => yup.string().required('Subject is required when creating an event.'),
        }),
        summary: yup.string().when('create_event', {
            is: true,
            then: () => yup.string()
                .max(4096, 'Maximum of 4096 characters are allowed.'),
        }),
        calendar: yup.string().when('create_event', {
            is: true,
            then: () => yup.string().required('Calendar is required when creating an event.'),
        })
    }) : yup.object({});

    const handleClose = () => {
        props.handleClose()
    };

    React.useEffect(() => {
        setType(tab);
    }, [tab])

    React.useEffect(() => {
        if (opportunity !== undefined && cronofyAuthorized)
            var payload = {
                productId: opportunity.main.product_id,
                type: 'calender',
            }
        getMails(payload);
    }, [opportunity, cronofyAuthorized]);

    const loadCalendars = async () => {
        const {data} = await getAllCalendars();
        setUserCalendars(data?.calendars);
        setLoading(false);
    };

    const handleSubmit = (values) => {
        values = {
            ...values,
            subject: values.subject.trim(),
            summary: values.summary.trim()
        }
        const obj = {
            action_type: type === 'phone_call' ? 'follow_up' : type,
            due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
            due_time_precise: 1
        };
        let d = new Date();
        let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        addCalendarEvent({
            id: opportunity.main.opportunity_id,
            obj: {...obj, ...values},
            date: YearMonth
        });
        handleClose();
    }

    const formik = useFormik({
        validationSchema,
        onSubmit: handleSubmit,
        initialValues: {
            subject: '',
            summary: '',
            calendar: '',
            create_event: true
        }
    });

    React.useEffect(() => {
        if (emailTemplate !== null && template !== null) {
            formik.setFieldValue('summary', emailTemplate.main.body)
        }
    }, [emailTemplate])


    React.useEffect(() => {
        if (cronofyAuthorized)
            loadCalendars();
        else
            setLoading(false);
        return () => {
            formik.setFieldValue('summary', '')
        };
    }, [cronofyAuthorized]);

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    const getTemplate = e => {
        formik.setFieldValue('summary', '')
        const templateId = e.target.value;
        setTemplate(templateId);
        props.applyEmailTemplate({templateId, opportunityId});
    }

    const getPersonName = () => {
        if (opportunity) {
            const persons = opportunity.related_objects.persons
            if (persons) {
                const personId = Object.keys(persons)[0];
                return persons[personId].person_firstname + ' ' + persons[personId].person_lastname;
            }
        }
        return '';
    }


    return (
        <div>
            <Dialog maxWidth={cronofyAuthorized ? 'lg' : 'md'} fullWidth={!(loading || isLoading)} open={props.open}
                    onClose={handleClose}>
                <DialogContent>
                    {(loading || isLoading) ? <CircularProgress/> :
                        <form onSubmit={formik.handleSubmit}>
                            {isUpcomingEvent &&
                                'You already have a planned action for this opportunity so you cannot plan a new one until this has been carried out'
                            }
                            {
                                !isUpcomingEvent &&
                                <>
                                    Plan a <FormControl style={{width: '180px'}}>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        name="type"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Age"
                                        onChange={(e) => setType(e.target.value)}
                                        size={'small'}
                                        MenuProps={{classes: {paper: classes.select}}}
                                    >
                                        {
                                            types.map(item => <MenuItem value={item}>{makeHeader(item)}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl> on <b>{moment(date).format('MMMM D, YYYY, HH:mm')}</b> with <b>{getPersonName()}</b>
                                    <Box
                                        sx={{
                                            '& .MuiTextField-root': {m: 1, width: '100%'},
                                        }}>
                                        <Grid container spacing={2}>
                                            {cronofyAuthorized &&
                                                <Grid item xs={6}>
                                                    <FormControlLabel control={<Switch
                                                        id="create_event"
                                                        name="create_event"
                                                        checked={formik.values.create_event}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.create_event && Boolean(formik.errors.create_event)}
                                                        helperText={formik.touched.create_event && formik.errors.create_event}/>}
                                                                      label="Create Event on Google Calendar"/>
                                                    {formik.values.create_event && <>
                                                        <TextField
                                                            id="calendar"
                                                            name="calendar"
                                                            select
                                                            label="Select Calendar"
                                                            value={formik.values.calendar}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.calendar && Boolean(formik.errors.calendar)}
                                                            helperText={formik.touched.calendar && formik.errors.calendar}
                                                        >
                                                            {userCalendars.map((option) => (
                                                                <MenuItem key={option.calendar_id}
                                                                          value={option.calendar_id}>
                                                                    {option.calendar_name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>


                                                        <TextField
                                                            id="template"
                                                            name="template"
                                                            select
                                                            label="Select Template"
                                                            value={template}
                                                            onChange={getTemplate}
                                                        >
                                                            <MenuItem disabled>
                                                                MY TEMPLATES
                                                            </MenuItem>
                                                            {mails &&
                                                                mails.main.templates
                                                                    .filter(option => option.created_by !== null && option.created_by === user?.user_details?.user_id)
                                                                    .map((option) => (
                                                                        <MenuItem key={option.template_id}
                                                                                  value={option.template_id}>
                                                                            {option.template_name}
                                                                        </MenuItem>
                                                                    ))
                                                            }
                                                        </TextField>

                                                        <TextField
                                                            label="Subject"
                                                            variant="outlined"
                                                            id="subject"
                                                            name="subject"
                                                            value={formik.values.subject}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                                                            helperText={formik.touched.subject && formik.errors.subject}
                                                        />

                                                        <CKEditor
                                                            id="summary"
                                                            name="summary"
                                                            editor={ClassicEditor}
                                                            config={editorConfiguration}
                                                            data={formik.values.summary}
                                                            onChange={(_event, editor) => {
                                                                formik.setFieldValue('summary', editor.getData());
                                                            }}
                                                        />
                                                    </>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item xs={cronofyAuthorized ? 6 : 12}>
                                                <DateTimePicker date={date} setDate={setDate}/>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {'Do you confirm the creation of event?'}
                                </>
                            }
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
                                {
                                    !isUpcomingEvent &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        style={{marginRight: '12px'}}
                                    >
                                        {'Confirm'}
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose}
                                >
                                    {'Cancel'}
                                </Button>
                            </div>
                        </form>
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.applyEmailTemplate_loading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    mails: opportunitySelectors.mails(state),
    emailTemplate: opportunitySelectors.emailTemplate(state),
    cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
    user: accountSelectors.user(state),

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        addCalendarEvent: opportunityActions.addCalendarEvent,
        getMails: opportunityActions.getMails,
        applyEmailTemplate: opportunityActions.applyEmailTemplate
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AddEventModal));