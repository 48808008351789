import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  selectors as accountSelectors,
  actions as accountActions,
} from "../../Ducks/account";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: "pointer",
    width: "100%",
    textDecoration: "none",
  },
});

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      LastName: "",
      UserName: "",
      Password: "",
      errorMessage: "",
      message: null,
    };
  }

  handleForgotPassword = () => {
    const { success } = this.props;
    const { UserName, Password, FirstName, LastName } = this.state;
    if (UserName || Password || FirstName || LastName) {
      this.props.forgotPassword({ email: this.state.UserName });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resetPassword !== this.props.resetPassword) {
      this.setState({
        message:
          this.props.resetPassword &&
          "A mail has been sent with a new password.",
      });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({
        message: this.props.error !== null && "Sorry, we are unable to  find a user with this email address. Please enter correct information!",
      });
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { classes, resetPassword } = this.props;
    const { Password, FirstName, LastName, UserName, errorMessage, message } =
      this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginBottom: "10px" }}
          >
            Forgot your Password
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={UserName}
                onChange={this.handleChange}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="UserName"
                autoComplete="email"
                type="email"
              />
            </Grid>
          </Grid>
          {(message || this.props.message) && (
            <span style={{ color: "red" }}>
              {message || this.props.message}{" "}
            </span>
          )}
          <br />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.handleForgotPassword}
          >
            Continue
          </Button>
          <br />
          <Link className={classes.link} to="/" variant="body2">
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Back
            </Button>
          </Link>
          <Grid container justify="flex-end">
            <Grid item></Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: accountSelectors.isLoading(state),
  errorMessage: accountSelectors.errorMessage(state),
  success: accountSelectors.success(state),
  resetPassword: accountSelectors.resetPassword(state),
  error: accountSelectors.error(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPassword: accountActions.forgotPassword,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ForgotPassword));
