import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Build, AccessTime, PersonOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const useStyles = theme => ({
  card: {
    backgroundColor: '#fff',
    marginBottom: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cardBody: {
    clear: 'both',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '13px'
  },
  title: {
    fontSize: '17px',
    fontWeight: 400,
    marginBottom: '5px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    alignItems: 'center'
  },
  headline: {
    fontSize: '12px',
    margin: '0 0 5px'
  },
  paragraph: {
    fontSize: '11px',
    margin: '0 0 10px'
  },
  timeline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

function OpportunityCard(props) {
  const { classes, opportunityId, stages, navigate, stage } = props;
  const { job_id, opportunity_source, product_id, next_planned_action_time } = stages.related_objects.opportunities[opportunityId];
  const job = stages.related_objects.jobs[job_id];
  const { product_abbreviation } = stages.related_objects.products[product_id];
  const { person_id } = job;
  const person = stages.related_objects.persons[person_id];
  let opportunityCount = 0;
  if (stage.opportunities.length) {
    stage.opportunities.forEach(element => {
      if (element === opportunityId) {
        opportunityCount++
      }
    });
  }

  const getTime = (date) => {
    return moment(date).format('HH:mm');
  }

  return (
    <>
      <div>
        <div style={{ marginTop: '10px' }}>
          <h6 className={classes.headline}>{next_planned_action_time ? moment(next_planned_action_time).format("DD/MM/YYYY") : "Without planned actions"}</h6>
        </div>
        <div>
          <p className={classes.paragraph}>{opportunityCount} opportunity</p>
        </div>
      </div>
      <Card className={classes.card} onClick={() => navigate("/opportunities/id/" + opportunityId)}>
        <CardContent className={classes.cardContent} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: '180px', minHeight: '180px'}}>
          <Typography className={classes.title} align="left" variant="h6" component="div">
            {person.person_firstname} {person.person_lastname}
          </Typography>
          <Typography className={classes.cardBody} align="left" variant="body2" color="site.main">
            {job.job_title}
          </Typography>
          <Typography className={classes.cardBody} align="left" variant="body2" color="site.main">
            {stages && stages.related_objects.companies[stages.related_objects.divisions[job.division_id].company_id] ? stages.related_objects.companies[stages.related_objects.divisions[job.division_id].company_id].company_name : ''}
          </Typography>
          <div className={classes.footer}>
            <Typography className={classes.timeline} align="right" variant="body2" color="site.main">
              {next_planned_action_time && <AccessTime sx={{ fontSize: '17px', marginRight: '5px' }} />}
              {next_planned_action_time && getTime(next_planned_action_time)}
            </Typography>
            <Typography align="right" variant="body2" color="site.main">
              <span style={{ color: '#696969', marginRight: '15px' }}>{product_abbreviation}</span>

            </Typography>
            <Typography align="right" variant="body2" color="site.main">
              {
                opportunity_source && opportunity_source.includes("SalesView") ? (
                  <Tooltip title={opportunity_source}>
                    <PersonOutline sx={{ color: next_planned_action_time ? 'green' : 'red', fontSize: "16px" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title={opportunity_source}>
                    <Build sx={{ color: next_planned_action_time ? 'green' : 'red', fontSize: "13px" }} />
                  </Tooltip>
                )
              }

            </Typography>
          </div>

        </CardContent>
      </Card >
    </>
  );
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <OpportunityCard {...props} navigate={navigate} />
}


export default withStyles(useStyles)(WithNavigate);