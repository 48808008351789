import {
    Button, Card,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {addTodo, getTodo, getTodoActionItems, updateTodo} from "../../../API/clientRetention";
import {toast} from "react-toastify";
import ManageTodoActionItem from "./ManageTodoActionItem";
import {LoadingButton} from "@mui/lab";
import TodoActionItemForm from "./TodoActionItemForm";

const ManageTodoModal = (props) => {
    const {open, data} = props;
    const formRef = useRef(null);
    const [todo, setTodo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [todoActionItemOptions, setTodoActionItemOptions] = useState([]);
    const [toActionItemOptionsLoading, setToActionItemOptionsLoading] = useState(false);
    const [showAddTodoActionItem, setShowAddTodoActionItem] = useState(false);

    useEffect(() => {
        loadOptions();
    }, []);

    useEffect(() => {
        if (data?.id) {
            fetchTodo(data.id);
        }
    }, [data]);

    const loadOptions = () => {
        setToActionItemOptionsLoading(true);

        getTodoActionItems().then((res) => {
            setTodoActionItemOptions(res.data.main);
            setToActionItemOptionsLoading(false);
        }).catch((err) => {
            toast.error(err.error);
            setToActionItemOptionsLoading(false);
        });
    }

    const fetchTodo = (todoId) => {
        setLoading(true);
        getTodo(todoId).then((res) => {
            if (res?.data?.main) {
                if (res.data.main.actions?.length > 0) {
                    let _actions = res.data.main.actions[0].actions;
                    res.data.main.actions = _actions;
                    setTodo(res.data.main)
                    populateForm(res.data.main);
                }
            }
            setLoading(false);
        }).catch((err) => {
            toast.error(err.error);
            setLoading(false);
        });
    }

    const populateForm = (formData) => {
        formRef.current.setFieldValue("title", formData?.title);
        formRef.current.setFieldValue("description", formData?.description);
        formRef.current.setFieldValue("actions", formData?.actions);
    }

    const handleClose = (refreshGrid = false) => {
        setShowAddTodoActionItem(false);
        props.handleClose(refreshGrid);
    }

    const addTodoActionItemOptionCallback = (option) => {
        let _todoActionItemOptions = [...todoActionItemOptions];
        _todoActionItemOptions.push(option);

        setTodoActionItemOptions(_todoActionItemOptions);
        setShowAddTodoActionItem(false);
    }

    return (
        <Dialog open={open} scroll="paper" fullWidth maxWidth={'lg'} sx={{'& .MuiDialog-paper': {maxHeight: '80vh'}}}>
            <DialogTitle sx={{backgroundColor: '#1565c0', color: 'white'}}>Manage Todo</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500, 100],
                })}
            >
                <Close/>
            </IconButton>
            <DialogContent sx={{padding: '14px', backgroundColor: '#F7F7F7'}}>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <Card sx={{padding: '14px', marginRight: '7px', backgroundColor: 'white', minHeight: '600px'}}>
                            <Typography
                                variant="span"
                                component="span"
                                sx={{fontWeight: 'bold', marginBottom: '10px'}}
                            >
                                {data ? 'Edit' : 'Add'} Todo
                            </Typography>

                            <Formik
                                innerRef={formRef}
                                initialValues={{
                                    title: "",
                                    description: "",
                                    actions: [],
                                }}
                                validate={(values) => {
                                    const errors = {};

                                    if (!values.title) {
                                        errors.title = "Required"; // Check if array is empty
                                    }

                                    if (!values.actions.length) {
                                        errors.action = "At least one action must be added"; // Check if array is empty
                                    } else {
                                        values.actions.forEach((action, actionIndex) => {
                                            if (action.options?.length > 0) {
                                                action.options.forEach((option, optionIndex) => {
                                                    if (!option.value) {
                                                        if (!errors.actions) {
                                                            errors.actions = [];
                                                        }

                                                        if (!errors.actions[actionIndex]) {
                                                            errors.actions[actionIndex] = {options: []};
                                                        }

                                                        errors.actions[actionIndex].options[optionIndex] = {};
                                                        errors.actions[actionIndex].options[optionIndex].value = "Required";
                                                    }
                                                });
                                            }
                                        });
                                    }

                                    return errors;
                                }}
                                onSubmit={(values, {setSubmitting}) => {
                                    if (data) {
                                        updateTodo(data.id, values).then((res) => {
                                            toast.success(res.message);
                                            setSubmitting(false);
                                            handleClose(true);
                                        }).catch((err) => {
                                            toast.error(err.error);
                                            setSubmitting(false);
                                        })
                                    } else {
                                        addTodo(values).then((res) => {
                                            toast.success(res.message);
                                            setSubmitting(false);
                                            handleClose(true);
                                        }).catch((err) => {
                                            toast.error(err.error);
                                            setSubmitting(false);
                                        })
                                    }
                                }}
                            >
                                {({values, isSubmitting, setFieldValue, errors, submitCount}) => (
                                    <Form>
                                        <Grid container spacing={2} sx={{marginTop: '10px'}}>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography
                                                    variant='span'
                                                    component='span'
                                                >
                                                    Title*
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{margin: 'auto'}}>
                                                <Field
                                                    name='title'
                                                    type='text'
                                                    label='Todo Item Title*'
                                                    variant='outlined'
                                                    margin='normal'
                                                    fullWidth={true}
                                                    component={TextField}
                                                    size='small'
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} style={{margin: "auto"}}>
                                                <Typography
                                                    variant="span"
                                                    component="span">
                                                    Description
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{margin: "auto", paddingTop: 25}}>
                                                <Field
                                                    name="description"
                                                    component={TextField}
                                                    multiline
                                                    minRows={4}
                                                    fullWidth
                                                    type={"textarea"}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                        </Grid>
                                        <ManageTodoActionItem formRef={formRef}
                                                              todoActionItemOptions={todoActionItemOptions}
                                                              values={values}
                                                              setFieldValue={setFieldValue}
                                                              errors={errors} submitCount={submitCount}
                                                              todo={todo}
                                                              setShowAddTodoActionItem={setShowAddTodoActionItem}/>
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "0.75rem",
                                                margin: "4px 14px 0px",
                                            }}
                                        >
                                            <ErrorMessage
                                                name='action'
                                            />
                                        </div>
                                        <Grid item xs={12} style={{marginTop: "20px"}}>
                                            <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "right",
                                                    }}
                                                >
                                                    <Button
                                                        variant='contained'
                                                        color='error'
                                                        onClick={() => {
                                                            handleClose();
                                                        }}
                                                        style={{
                                                            margin: "10px",
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <LoadingButton
                                                        variant='contained'
                                                        color='primary'
                                                        type='submit'
                                                        style={{
                                                            margin: "10px",
                                                        }}
                                                        loading={isSubmitting}
                                                        disabled={loading || toActionItemOptionsLoading}
                                                    >
                                                        {data ? 'Update' : 'Save'}
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Grid>
                    <Grid item xs={5}>
                        {
                            showAddTodoActionItem &&
                            <Card sx={{padding: '14px', marginLeft: '7px', backgroundColor: 'white'}}>
                                <TodoActionItemForm
                                    todoActionOptionCallback={addTodoActionItemOptionCallback}/>
                            </Card>

                        }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ManageTodoModal;