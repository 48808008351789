import * as React from 'react';
import { withStyles } from '@mui/styles';
import { selectors as salesSelectors, actions as salesActions } from '../../../Ducks/sales';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import { selectors as accountSelectors } from '../../../Ducks/account';
import DetailsForm from './DetailsForm';
import AgreementForm from './AgreementForm';
import InvoiceForm from './InvoiceForm';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = theme => ({
  container: {
    textAlign: 'left',
  }
});

class SaleDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSale: props.params.id,
      // purchaseColumns: props.user.tableSettings.purchases.columns,
      purchaseColumns: [
        'purchase_id','erp_client_division_id','opportunity_id','product_id','job_id','user_id','purchase_retention_user_id','purchase_type','purchase_source','purchase_creation_time','purchase_status','purchase_start','purchase_stop','purchase_cancelled_from','purchase_price_currency','purchase_price','purchase_description','purchase_challenges','purchase_special_conditions','purchase_food_considerations','purchase_bookkeeper_email','purchase_ean','purchase_po_number','purchase_payment_terms','purchase_delayed_invoice','purchase_split_payment','purchase_calendar_events_downloaded','purchase_payment_date','purchase_invoice_no','purchase_invoice_date','purchase_invoice_due_date','purchase_credit_note_no','purchase_credit_note_date'
      ],
      saleDetails: null
    }
  }

  componentDidMount() {
    this.getSaleDetail();
    // getStages({ id: pipelineId });
  }

  getSaleDetail = () => {
    const { getSaleDetails, getStages } = this.props;
    const { selectedSale, purchaseColumns } = this.state;
    getSaleDetails({ id: selectedSale, columns: purchaseColumns });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saleDetails !== this.props.saleDetails) {
      this.setState({ saleDetails: this.props.saleDetails && this.props.saleDetails.main[0]})
    }
  }

  handleSaveClick = (obj) => {
    this.props.updateSale({ id: this.state.selectedSale, obj: obj});
  }

  render() {
    const { classes } = this.props;
    const { saleDetails, selectedSale } = this.state;

    if(!saleDetails) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
    }
    return (
      <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <DetailsForm saleDetails={saleDetails} onClick={this.handleSaveClick}/>
            <InvoiceForm saleDetails={saleDetails} onClick={this.handleSaveClick}/>
          </Grid>
        <Grid item xs={6}>
          <AgreementForm saleDetails={saleDetails} onClick={this.handleSaveClick}/>
        </Grid>
      </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: salesSelectors.isLoading(state),
  user: accountSelectors.user(state),
  saleDetails: salesSelectors.saleDetails(state),

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSaleDetails: salesActions.getSaleDetails,
    updateSale: salesActions.updateSale,
  },
  dispatch);

function WithNavigate(props) {
  let params = useParams();
  return <SaleDetails {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
