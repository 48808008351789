import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as salesTypes, actions as salesActions } from '../Ducks/sales';

import { callApi } from './utils';
import * as api from '../API/sales';


function* getSales(action) {
  const { success, failure } = salesActions.saga.getSales;

  yield callApi(
    call(api.getSales, action.data),
    success,
    failure
  )
}

function* getSaleDetails(action) {
  const { success, failure } = salesActions.saga.getSaleDetails;

  yield callApi(
    call(api.getSaleDetails, action.data),
    success,
    failure
  )
}

function* updateSale(action) {
  const { success, failure } = salesActions.saga.updateSale;

  yield callApi(
    call(api.updateSale, action.data),
    success,
    failure
  )
}

function* getUserStages(action) {
  const { success, failure } = salesActions.saga.getUserStages;

  function* postApiSuccessCallEffect(data) {
    yield call(getPipelineContent, data);
  }

  yield callApi(
    call(api.getUserStages, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getPipelineContent(action) {
  const { success, failure } = salesActions.saga.getPipelineContent;

  yield callApi(
    call(api.getPipelineContent, action),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(salesTypes.getSales, getSales);
  yield takeLatest(salesTypes.getSaleDetails, getSaleDetails);
  yield takeLatest(salesTypes.updateSale, updateSale);
  yield takeLatest(salesTypes.getUserStages, getUserStages);
  yield takeLatest(salesTypes.getPipelineContent, getPipelineContent);
}