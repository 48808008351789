import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as dashboardTypes, actions as dashboardActions } from '../Ducks/dashboard';

import { callApi } from './utils';
import * as api from '../API/dashboard';


function* getDashboard(action) {
  const { success, failure } = dashboardActions.saga.getDashboard;

  yield callApi(
    call(api.getDashboard, action.data),
    success,
    failure
  )
}

function* getPipelines(action) {
  const { success, failure } = dashboardActions.saga.getPipelines;

  yield callApi(
    call(api.getPipelines, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(dashboardTypes.getDashboard, getDashboard);
  yield takeLatest(dashboardTypes.getPipelines, getPipelines);
}