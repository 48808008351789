import * as React from 'react';
import {withStyles} from '@mui/styles';
import {selectors as opportunitySelectors, actions as opportunityActions} from '../../../Ducks/opportunity';
import {selectors as eventsOverviewSelectors, actions as eventsOverviewActions} from '../../../Ducks/eventsOverview';
import {selectors as productsSelectors, actions as productsActions} from '../../../Ducks/products';
import {selectors as accountSelectors} from '../../../Ducks/account';
import {selectors as companiesSelectors, actions as companiesActions} from '../../../Ducks/companies';
import {selectors as usersSelectors, actions as usersActions} from '../../../Ducks/users';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    Tabs,
    Tab,
    Typography,
    Grid,
    Autocomplete,
    TextField as MaterialTextField,
    Tooltip
} from '@mui/material';
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PersonCaseOverviewTab from './Tabs/PersonCaseOverviewTab';
import {
    Close,
    FiberNew,
    Pause,
    Star,
    Timelapse
} from '@mui/icons-material';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import addWeeks from 'date-fns/addWeeks';
import {cloneDeep} from 'lodash';
import CaseOverviewStepper from './Widgets/CaseOverviewStepper';
import TeamsDropDowns from "./Widgets/TeamsDropDowns";

toast.configure();

const useStyles = _theme => ({
    container: {
        textAlign: 'left',
    },
    tabs: {
        marginTop: '0'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    headline: {
        margin: '0 0 10px',
        minWidth: '300px',
        fontWeight: '400',
        fontSize: '20px',
        position: 'relative',
        flex: 1
    },
    stepper: {
        flex: 2
    },
    status: {
        color: '#fff',
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: '400',
        padding: '3px 10px',
        borderRadius: '4px',
        marginBottom: '4px',
        position: 'absolute',
        top: '11px'
    },
    opportunityID: {
        margin: '0 5px'
    },
    tabsStyling: {
        padding: '12px 0 !important',
        backgroundColor: '#f5f5f5 !important',
        color: '#3a3641 !important',
        border: '1px solid #dcdbdb !important',
        opacity: '1 !important',
        fontWeight: '600 !important',
        textTransform: 'capitalize'
    },
    selectBox: {
        marginLeft: '10px',
        padding: '10px'
    },
    attending: {
        background: '#68a54d',
        color: '#fff'
    },
    not_attending: {
        background: '#db6669',
        color: '#fff'
    },
    unknown: {
        background: '',
        color: '#000'
    },
    tentative: {
        background: '#f9fd01',
        color: '#000'
    },
    resale_attending: {
        background: '#5ecd5e',
        color: '#fff'
    },
    resale_not_attending: {
        background: '#e44848',
        color: '#fff'
    },
    resale_unknown: {
        background: '#e2e23b',
        color: '#000'
    },
    resale_tentative: {
        background: '#f1b851',
        color: '#000'
    },
    hide: {
        display: 'none'
    },
    form: {
        display: 'flex'
    },
    member: {
        position: 'relative'
    },
    indicator: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '15px',
        height: '15px',
        background: 'blue'
    }
});

const tabListData = [
    {
        name: 'person',
        disabled: false,
        hidden: false,
    }
];

const months = [
    {
        name: 'January',
        value: 1
    },
    {
        name: 'February',
        value: 2
    },
    {
        name: 'March',
        value: 3
    },
    {
        name: 'April',
        value: 4
    },
    {
        name: 'May',
        value: 5
    },
    {
        name: 'June',
        value: 6
    },
    {
        name: 'July',
        value: 7
    },
    {
        name: 'August',
        value: 8
    },
    {
        name: 'September',
        value: 9
    },
    {
        name: 'October',
        value: 10
    },
    {
        name: 'November',
        value: 11
    },
    {
        name: 'December',
        value: 12
    },
]


class CaseOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            range: [null, null],
            selectedTab: 'person',
            product: "",
            productType: "",
            user: "",
            country: "",
            productList: [],
            productTypesList: [],
            countryList: [
                {
                    country_aliases: "Danmark,Denemarken",
                    country_code: "DK",
                    country_id: 1,
                    country_name: "Denmark",
                    label: "Denmark",
                    key: 1
                },
                {
                    country_aliases: "Sverige",
                    country_code: "SE",
                    country_id: 8,
                    country_name: "Sweden",
                    label: "Sweden",
                    key: 1
                }
            ],
            users: [],
            teams: [],
            parentTeamDetails: [],
            networkDirectors: [],
            networkDirector: "",
            selected: null,
            pipelineStageId: null,
            assignedOpportunity: false,
            tabList: tabListData,
            split: false,
            overview: [],
            person: {},
            eventInfo: {},
            currentMonth: "",
            currentYear: moment().year(),
            loading: false,
            scrollPercentage: 0,
            sortOrder: "",
            sortOrderOptions: [
                {
                    label: 'By Order',
                    key: 1,
                    value: 1
                },
                {
                    label: 'By Name',
                    key: 2,
                    value: 2
                }
            ]
        }
        this.scrollingDiv = React.createRef();
    }

    componentWillUnmount() {
        const {resetTeamDetails} = this.props
        resetTeamDetails();
    }

    componentDidMount() {
        this.initialize();
        this.fetchProductTypes();
        this.fetchUsers();
        this.fetchCountries();
        this.fetchNetworkDirectors();
        this.getCaseOverviewSheet();
        this.fetchTeams();
    }

    componentDidUpdate(prevProps, _prevState) {
        const {
            getFilteredProducts,
            products,
            productTypes,
            eventsOverviewUsers,
            filteredProducts,
            casesOverview,
            user,
            countries,
            networkDirectors,
            isNetworkDirector,
            team,
        } = this.props;
        if (prevProps.products !== products) {
            let sorted = products.sort(function (a, b) {
                if (a.product_name < b.product_name) {
                    return -1;
                }
                if (a.product_name > b.product_name) {
                    return 1;
                }
                return 0;
            });
            let productSortedList = sorted.map((item) => {
                item.label = item.product_name;
                item.key = item.product_id;
                return item;
            });

            this.setState({productList: productSortedList})
        }
        if (prevProps.productTypes !== productTypes) {
            let productTypesList = productTypes.main.map((item) => {
                item.label = item.product_type_name;
                item.key = item.product_type_id
                return item;
            });
            const productType = productTypesList.filter((item) => item.product_type_id === 1)[0];
            const queryString = `results=500&equals[product_show_in_sales]=1&equals[product_type_id]=${productType.product_type_id}&equals[country_id]=${user.user_details.country_id}`
            getFilteredProducts(queryString);
            this.setState({productTypesList, productType});
        }
        if (prevProps.eventsOverviewUsers !== eventsOverviewUsers) {
            let _salesRep = eventsOverviewUsers.salesrep.map(v => ({...v, type: "Resale Sales Rep", user_type: 1}));
            let _member_service_agents = eventsOverviewUsers.member_service_agents.map(v => ({
                ...v,
                type: "Member Service",
                user_type: 2
            }));
            const _merged_users = _salesRep.concat(_member_service_agents);

            let _users = _merged_users.filter(item => item.user_deactivated === null && !item.user_lastname.includes("WRONG")).map((item, index) => {
                item.label = item.user_firstname + " " + item.user_lastname;
                item.key = index
                return item;
            });


            const _user = _users.filter((item) => item.user_id === user.user_details.user_id)[0];
            this.setState({users: _users, user: isNetworkDirector ? "" : _user});
        }
        if (prevProps.team !== team) {
            let _teams = team['rows']?.map((item) => {
                item.key = item.key
                item.label = item.team_name;
                return item;
            });
            this.setState({teams: _teams});
        }
        if (prevProps.filteredProducts !== filteredProducts) {
            let sorted = filteredProducts.sort(function (a, b) {
                if (a.product_name < b.product_name) {
                    return -1;
                }
                if (a.product_name > b.product_name) {
                    return 1;
                }
                return 0;
            });
            let productSortedList = sorted.map((item) => {
                item.label = item.product_name;
                item.key = item.product_id;
                return item;
            });

            this.setState({productList: productSortedList})
        }
        if (prevProps.countries !== countries) {
            let _countries = countries.main.map((item) => {
                item.label = item.country_name;
                item.key = item.country_id
                return item;
            });
            const _country = _countries.filter((item) => item.country_id === user.user_details.country_id)[0];
            this.setState({countryList: _countries, country: _country});
        }
        if (prevProps.networkDirectors !== networkDirectors) {
            let _networkDirectors = networkDirectors.map((item) => {
                item.label = item.person_name;
                item.key = Math.random()
                return item;
            });
            const _networkDirector = _networkDirectors.filter((item) => item.id === user.user_details.job_id)[0];
            this.setState({
                networkDirectors: _networkDirectors,
                networkDirector: isNetworkDirector ? _networkDirector : ""
            });
        }
        if (prevProps.casesOverview !== casesOverview) {
            this.setState({loading: false});

            // Chronological Ordering starts
            const _events = cloneDeep(casesOverview);

            _events?.sort(function (a, b) {
                return new Date(a.event_start) - new Date(b.event_start);
            });

            // sort participants
            if (this.state.sortOrder.value === 2) {
                _events.forEach((item) => {
                    const _participants_details = item.participants_details
                    _participants_details.sort(function (a, b) {
                        if (a.person_firstname < b.person_firstname) {
                            return -1;
                        }
                        if (a.person_firstname > b.person_firstname) {
                            return 1;
                        }
                        return 0;
                    })
                })
            }
            // sort participants

            // Chronological Ordering Ends

            this.setState({overview: casesOverview});
        }
    }

    initialize = () => {
        const {isNetworkDirector} = this.props;
        const start = new Date(new Date().setHours(0, 0, 0, 0));
        const end = isNetworkDirector ? new Date(new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0));
        const range = [start, end]
        const m = moment(start).format("MM");
        const current = months.filter((item) => item.value === parseInt(m));
        this.setState({currentMonth: current[0], range});
    }

    fetchProducts = () => {
        const {getProducts, user} = this.props;
        let countryID = user.user_details.country_id;
        let productShowInSales = 0;
        let results = 100;
        getProducts({countryID, productShowInSales, results});
    }

    fetchProductTypes = () => {
        const {getProductTypes} = this.props;
        getProductTypes();
    }

    fetchUsers = () => {
        const {getEventsOverviewUsers} = this.props;
        getEventsOverviewUsers();
    }

    fetchCountries = () => {
        const {user} = this.props;
        const {countryList} = this.state;
        // This is only until an API is called
        const _country = countryList.filter((item) => item.country_id === user.user_details.country_id)[0];
        this.setState({country: _country});
    }

    fetchNetworkDirectors = () => {
        const {getNetworkDirectors} = this.props;
        getNetworkDirectors();
    }

    fetchTeams = () => {
        const {getTeams} = this.props;
        getTeams();
    }

    getCaseOverviewSheet = () => {
        const {getCasesOverview, user, isNetworkDirector} = this.props;
        const capabilities = user.user_details.capabilities;
        const isSalesRep = capabilities.filter(item => item.capability_title === "salesrep").length
        let query;

        const d = new Date();
        const start = moment(d).format('YYYY-MM-DD');
        const end = isNetworkDirector ? moment(new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000))).format('YYYY-MM-DD') : moment(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000))).format('YYYY-MM-DD');
        const query1 = `equals[event_start_date]=${start}&equals[event_end_date]=${end}`;
        const query2 = `equals[product_type_id]=1`;
        const query3 = `equals[user_id]=${user.user_details.user_id}&equals[user_type]=${isSalesRep ? 1 : 2}`;
        const query4 = `equals[country_id]=${user.user_details.country_id}`;
        const query5 = `equals[network_directors]=${user.user_details.job_id}`;
        if (capabilities.filter(item => item.capability_title === "event_overview").length) {
            query = `${query1}&${query2}&${query3}&${query4}`;
        } else {
            query = `${query1}&${query2}&${query4}`;
        }
        if (isNetworkDirector) {
            query = `${query}&${query5}`;
        }
        this.setState({loading: true});
        getCasesOverview();
    }

    handleChange = (e, newValue) => {
        if (newValue !== this.state.selectedTab2) {
            this.setState({selectedTab: newValue});
        } else {
            toast.error('Tab is already opened!', {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            });
        }
    }

    handleChange2 = (_e, newValue) => {
        if (newValue !== this.state.selectedTab) {
            this.setState({ selectedTab2: newValue });
        } else {
            toast.error('Tab is already opened!', {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            });
        }
    }

    renderSelectedComponent = (selectedTab) => {
        const {
            person,
            eventInfo,
            currentMonth,
            currentYear,
            range,
            product,
            productType,
            user,
            country,
            networkDirector
        } = this.state;
        const query = {
            range,
            product,
            productType,
            user,
            country,
            networkDirector
        }
        switch (selectedTab) {
            case 'person':
                return <PersonCaseOverviewTab person={person} eventInfo={eventInfo} selectedMonth={currentMonth}
                                              selectedYear={currentYear} query={query}
                                              isNetworkDirector={this.props.isNetworkDirector}
                                              handleCaseClose={this.handleCaseClose}/>;
            default:
                return <PersonCaseOverviewTab person={person} eventInfo={eventInfo} selectedMonth={currentMonth}
                                              selectedYear={currentYear} query={query}
                                              isNetworkDirector={this.props.isNetworkDirector}
                                              handleCaseClose={this.handleCaseClose}/>;
        }
    }

    filterTabList = (tabList) => {
        const {assignedOpportunity} = this.state;
        return tabList.filter(item => {
            // Enable this if need to hide the tab initially

            if (item.name === 'actions' && !assignedOpportunity) {
                item.disabled = true;
            } else if (item.name === 'actions') {
                item.disabled = false;
            }

            return true;
        })
    }

    columnVisibility = (index) => {
        const {selected, split} = this.state;
        if (split && selected !== null) {
            if (selected <= index && selected - index > -4) {
                return 'show';
            } else {
                return 'hide';
            }
        } else {
            return 'show';
        }
    }

    filter = (name, _event, value) => {
        const {getFilteredProducts, user} = this.props;
        if (value !== null) {
            if (name === 'productType') {
                let queryString = `results=100&equals[product_show_in_sales]=0&equals[product_type_id]=${value.product_type_id}&equals[country_id]=${user.user_details.country_id}`
                this.setState({product: ""});
                getFilteredProducts(queryString);
            }
            this.setState({[name]: value, split: false, selected: null});
        } else {
            if (name === 'productType') {
                this.fetchProducts();
            }
            this.setState({[name]: "", split: false, selected: null});
        }
    }

    handleCaseClick = (person) => {
        this.setState({split: true, selected: person.potential_participant_id, person: person, eventInfo: {}})
    }

    handleCaseClose = () => {
        this.setState({split: false, person: {}, eventInfo: {}, selected: null})
    }

    renderParticipants = (eventInfo, participants, _idx) => {
        const {selected} = this.state;
        return participants.map((person) => {
            return (
                <li key={Math.random()} onClick={() => {
                    this.setState({
                        split: true,
                        selected: person.potential_participant_id,
                        person: person,
                        eventInfo: eventInfo
                    }, () => {
                        document.getElementsByClassName('scrolling_box')[0].scrollTo({
                            left: document.getElementsByClassName('selected_person')[0].offsetLeft - document.getElementsByClassName('fixed_bar')[0].clientWidth - 24,
                            top: document.getElementsByClassName('scrolling_box')[0].scrollTop,
                            behavior: 'smooth'
                        })
                    })
                }} className={`${selected === person.potential_participant_id ? 'selected_person' : ''}`} style={{
                    outline: "1px solid #000",
                    cursor: "pointer"
                }}>{this.getParticipant(person.special_case_type, person)}</li>
            )
        })
    }

    getParticipant = (member_info, person) => {
        if (member_info === "pause") {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Tooltip title="Paused">
                            <Pause sx={{fontSize: '20px', color: "#000"}}/>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else if (member_info === "ongoing") {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Tooltip title="Ongoing">
                            <Timelapse sx={{fontSize: '20px', color: "#000"}}/>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else if (member_info === "Resale") {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Tooltip title="Resale">
                            <Star sx={{fontSize: '20px', color: "#fbd400"}}/>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else if (member_info === "New") {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Tooltip title="New">
                            <FiberNew sx={{fontSize: '20px', color: "#000"}}/>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else if (member_info === "New | Resale") {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Tooltip title="New Resale">
                            <React.Fragment>
                                <FiberNew sx={{fontSize: '20px', color: "#000"}}/>
                                <Star sx={{fontSize: '20px', color: "#fbd400"}}/>
                            </React.Fragment>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else {
            return (
                <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
            )
        }
    }

    getWeeksAfter = (date, amount) => {
        return date ? addWeeks(date, amount) : undefined;
    }

    getMonthData = navigation => {
        this.setState({loading: true});
        const {getCasesOverview} = this.props;
        const {currentMonth, currentYear} = this.state;
        if (navigation === 'previous') {
            if (currentMonth.value === 1) {
                this.setState({
                    currentYear: currentYear - 1,
                    currentMonth: months.filter((item) => item.value === 12)[0]
                });
                const date = currentYear - 1 + "-" + this.zeroPad(months.filter((item) => item.value === 12)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getCasesOverview(query);
            } else {
                this.setState({
                    currentMonth: months.filter((item) => item.value === currentMonth.value - 1)[0]
                })
                const date = currentYear + "-" + this.zeroPad(months.filter((item) => item.value === currentMonth.value - 1)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getCasesOverview(query);
            }
        }

        if (navigation === 'next') {
            if (currentMonth.value === 12) {
                this.setState({
                    currentYear: currentYear + 1,
                    currentMonth: months.filter((item) => item.value === 1)[0]
                })
                const date = currentYear + 1 + "-" + this.zeroPad(months.filter((item) => item.value === 1)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getCasesOverview(query);
            } else {
                this.setState({
                    currentMonth: months.filter((item) => item.value === currentMonth.value + 1)[0]
                })
                const date = currentYear + "-" + this.zeroPad(months.filter((item) => item.value === currentMonth.value + 1)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getCasesOverview(query);
            }
        }
    }

    // helper
    zeroPad = (num, places) => String(num).padStart(places, '0');

    resetMonthFilter = () => {
        this.setState({overview: []});
        const {getCasesOverview} = this.props;
        const d = new Date();
        const year = moment(d).format('YYYY');
        const month = moment(d).format('MM');
        const date = year + "-" + month;
        const query = `contains[event_start]=${date}`

        this.setState({
            currentYear: year,
            currentMonth: months.filter((item) => item.value === parseInt(month))[0]
        });

        getCasesOverview(query);
    }

    getQuery = (filter, value, start = null, end = null) => {
        const {user, country, productType, product, range, networkDirector} = this.state;
        var start_date = "", end_date = "";
        if (start === null && end === null) {
            start_date = moment(range[0]).format('YYYY-MM-DD');
            end_date = moment(range[1]).format('YYYY-MM-DD');
        } else {
            start_date = moment(start).format('YYYY-MM-DD');
            end_date = moment(end).format('YYYY-MM-DD');
        }
        this.setState({loading: true});

        let query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (filter === "productType" && value !== null) {
            query = `${query}&equals[product_type_id]=${value.product_type_id}`
        }
        if (filter === "product" && value !== null) {
            query = `${query}&equals[product_id]=${value.product_id}`
        }
        if (filter === "user" && value !== null) {
            query = `${query}&equals[user_id]=${value.user_id}&equals[user_type]=${value.user_type}`
        }
        if (filter === "country" && value !== null) {
            query = `${query}&equals[country_id]=${value.country_id}`
        }
        if (filter === "networkDirector" && value !== null) {
            query = `${query}&equals[network_directors]=${value.id}`
        }

        if (productType !== "" && productType !== null && productType !== undefined && filter !== "productType") {
            query = `${query}&equals[product_type_id]=${productType.product_type_id}`
        }
        if (product !== "" && product !== null && product !== undefined && (filter !== "product" && filter !== "productType")) {
            query = `${query}&equals[product_id]=${product.product_id}`
        }
        if (user !== "" && user !== null && user !== undefined && filter !== "user") {
            query = `${query}&equals[user_id]=${user.user_id}&equals[user_type]=${user.user_type}`
        }
        if (country !== "" && country !== null && country !== undefined && filter !== "country") {
            query = `${query}&equals[country_id]=${country.country_id}`
        }
        if (networkDirector !== "" && networkDirector !== null && networkDirector !== undefined && filter !== "networkDirector") {
            query = `${query}&equals[network_directors]=${networkDirector.id}`
        }
        return query;
    }

    render() {
        const {
            classes,
            getTeamDetails,
            teamDetails,
            resetTeamDetails,
            getCasesOverview,
            canViewTeamStates
        } = this.props;
        const {
            selectedTab,
            tabList,
            split,
            overview,
            loading,
            teams
        } = this.state;
        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h2 className={classes.headline}>
                        Cases Overview
                    </h2>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}
                              sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Grid item xs={8} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                                {
                                    canViewTeamStates ?
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={teams}
                                            sx={{width: 200, marginRight: '10px'}}
                                            onChange={(_event, value) => {
                                                if (value === null) {
                                                    resetTeamDetails();
                                                    getCasesOverview();

                                                } else {
                                                    resetTeamDetails();
                                                    getTeamDetails({"team_id": value?.team_id});
                                                    let query = `equals[team_id]=${value?.team_id}`;
                                                    getCasesOverview(query);
                                                }
                                            }}
                                            renderOption={(props, option, _state) => {
                                                return (
                                                    <div {...props}>
                                                        <div style={{
                                                            textAlign: 'left',
                                                            padding: '0',
                                                            fontSize: '13px',
                                                            borderBottom: '1px solid #eaeaea'
                                                        }}>
                                                            <span>{`${option.label}`}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                            renderInput={(params) => <MaterialTextField {...params} label="Teams"/>}
                                        /> : <></>
                                }
                                {teamDetails.map((item, index) => (
                                    <TeamsDropDowns
                                        teamDetails={item}
                                        key={index}
                                        dropDownKey={index}
                                        getTeamDetails={getTeamDetails}
                                        resetTeamDetails={resetTeamDetails}
                                    />
                                ))

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={split ? 6 : 12}>
                        <Grid container spacing={2}>
                            {
                                loading ? (
                                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <CircularProgress/>
                                    </Grid>
                                ) : (
                                    overview?.length ? (
                                        <Grid item xs={12} sx={{minHeight: '300px'}}>
                                            <div style={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                overflowY: 'auto',
                                                maxHeight: split ? window.innerHeight - 200 : window.innerHeight - 200
                                            }}>
                                                <CaseOverviewStepper overview={overview}
                                                                     handleCaseClick={this.handleCaseClick}
                                                                     selected={this.state.selected}/>
                                            </div>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                                            <Typography component="h1" variant="h5"
                                                        sx={{margin: '20px', textAlign: 'center'}}>
                                                No Record Found
                                            </Typography>
                                        </Grid>
                                    )
                                )
                            }

                        </Grid>
                    </Grid>
                    {
                        split ? (
                            <Grid item xs={6} sx={{position: 'relative'}}>
                                <div style={{
                                    position: 'absolute',
                                    right: '8px',
                                    top: '20px',
                                    zIndex: '9999',
                                    cursor: 'pointer'
                                }}>
                                    <Close onClick={this.handleCaseClose}/>
                                </div>
                                <Tabs
                                    className={classes.tabs}
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="inherit"
                                    style={{backgroundColor: '#F1F1F1'}}
                                >
                                    {
                                        this.filterTabList(tabList).map((item, index) => <Tab key={index}
                                                                                              className={classes.tabsStyling}
                                                                                              label={item.name}
                                                                                              value={item.name}
                                                                                              disabled={item.disabled}/>)
                                    }
                                </Tabs>
                                <div
                                    role="tabpanel"
                                    id={`full-width-tabpanel`}
                                    aria-labelledby={`full-width-tab`}
                                    style={{
                                        padding: '10px',
                                        background: '#efefef',
                                        overflowY: 'auto',
                                        height: window.innerHeight - 273
                                    }}
                                >
                                    {
                                        this.renderSelectedComponent(selectedTab)
                                    }
                                </div>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }

                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    productsLoading: opportunitySelectors.getProducts_loading(state),
    products: opportunitySelectors.products(state),
    productTypes: productsSelectors.productTypes(state),
    filteredProducts: opportunitySelectors.filteredProducts(state),
    casesOverview: eventsOverviewSelectors.casesOverview(state),
    networkDirectors: eventsOverviewSelectors.networkDirectors(state),
    eventsOverviewUsers: eventsOverviewSelectors.eventsOverviewUsers(state),
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    countries: companiesSelectors.countries(state),
    user: accountSelectors.user(state),
    canViewTeamStates: accountSelectors.canViewTeamStates(state),
    team: usersSelectors.caseTeams(state),
    teamDetails: usersSelectors.teamDetails(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getProducts: opportunityActions.getProducts,
        getProductTypes: productsActions.getProductTypes,
        getFilteredProducts: opportunityActions.getFilteredProducts,
        getCasesOverview: eventsOverviewActions.getCasesOverview,
        getNetworkDirectors: eventsOverviewActions.getNetworkDirectors,
        getEventsOverviewUsers: eventsOverviewActions.getEventsOverviewUsers,
        getCountries: companiesActions.getCountries,
        getTeams: usersActions.getCaseTeams,
        getTeamDetails: usersActions.getTeamDetails,
        resetTeamDetails: usersActions.resetTeamDetails,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <CaseOverview {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
