import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as companiesTypes, actions as companiesActions } from '../Ducks/companies';

import { callApi } from './utils';
import * as api from '../API/companies';


function* getCompanies(action) {
  const { success, failure } = companiesActions.saga.getCompanies;

  yield callApi(
    call(api.getCompanies, action.data),
    success,
    failure
  )
}

function* getCompanyDetails(action) {
  const { success, failure } = companiesActions.saga.getCompanyDetails;

  yield callApi(
    call(api.getCompanyDetails, action.data),
    success,
    failure
  )
}

function* updateCompany(action) {
  const { success, failure } = companiesActions.saga.updateCompany;

  yield callApi(
    call(api.updateCompany, action.data),
    success,
    failure
  )
}

function* getCountries(action) {
  const { success, failure } = companiesActions.saga.getCountries;

  yield callApi(
    call(api.getCountries, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(companiesTypes.getCompanies, getCompanies);
  yield takeLatest(companiesTypes.getCompanyDetails, getCompanyDetails);
  yield takeLatest(companiesTypes.updateCompany, updateCompany);
  yield takeLatest(companiesTypes.getCountries, getCountries);
}