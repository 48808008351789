import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { LinkedIn } from "@mui/icons-material";
import Swal from "sweetalert2";
import { selectors as accountSelectors, actions as accountActions } from "../../../Ducks/account";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = (_theme) => ({
	card: {
		display: "flex",
		backgroundColor: "#fff",
		marginBottom: "20px",
	},
	cardMedia: {
		flex: 1,
		width: "85px",
		backgroundColor: "#F1F1F1",
	},
	cardContent: {
		flex: 4,
	},
	cardContentHeader: {
		display: "flex",
		justifyContent: "flex-end",
	},
	flexVertical: {
		display: "flex",
	},
	flexCenter: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
});

function UserCard(props) {
	const { user, parentUser, setParentUser, classes, person, loginAs } = props;
	const [personObj, setPersonObj] = React.useState([]);

	useEffect(() => {
		if (person && person.length !== 0) {
			setPersonObj(person);
		}
	}, [person]);

	const loginAsUser = () => {
		Swal.fire({
			title: `Want to login as ${personObj.user_firstname}?`,
			text: "",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		}).then((result) => {
			if (result.isConfirmed) {
				setParentUser(user.user_details.user_email);
				loginAs({ obj: { username: personObj.user_email }, navigateTo });
			}
		});
	};

	const navigateTo = () => {
		props.navigate("/");
	};

	return (
		<Card className={personObj.user_deactivated ? "deactivated usercard" : "active usercard"}>
			<CardMedia
				className={classes.cardMedia}
				component='img'
				image={`https://erp.f5.dk/uploads/salesreps_imgs/${personObj.user_id}/Almindelig-web.jpg`}
			/>
			<CardContent className={classes.cardContent}>
				<div className={classes.cardContentHeader}>
					{personObj.user_deactivated ? (
						<Typography variant='h6' component='div' sx={{ fontSize: "13px", fontStyle: "italic" }}>
							Deactivated since {personObj.user_deactivated}
						</Typography>
					) : !parentUser ? (
						<Button variant='contained' color='primary' size='small' sx={{ textTransform: "none" }} onClick={loginAsUser}>
							Login as {personObj.user_firstname}
						</Button>
					) : (
						<></>
					)}
				</div>

				<Typography variant='h6' component='div'>
					{personObj.user_firstname} {personObj.user_lastname}
				</Typography>
				<Typography variant='body2' color='site.main'>
					{personObj.user_title}
				</Typography>
				<Box className={classes.flexCenter}>
					<Box>
						<a target='_blank' href={personObj.user_linkedin}>
							<LinkedIn sx={{ color: "icon.main" }} />
						</a>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
}

const mapStateToProps = (state) => ({
	isLoading: accountSelectors.isLoading(state),
	errorMessage: accountSelectors.errorMessage(state),
	user: accountSelectors.user(state),
	parentUser: accountSelectors.parentUser(state),
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			loginAs: accountActions.loginAs,
			setParentUser: accountActions.setParentUser,
		},
		dispatch
	);

function WithNavigate(props) {
	let navigate = useNavigate();
	return <UserCard {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
