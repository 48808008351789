import * as React from "react";
import { Card, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';

const editorConfiguration = {
  plugins: [List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
  toolbar: ['heading', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', '|', 'link', 'horizontalLine', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'undo', 'redo'],
  alignment: {
      options: ['left', 'right']
  },
  table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  }
};

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    padding: "16px",
    marginTop: 0,
  },
});

class ProductAdditionalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetGroupDefinition: props.productDetails.target_group_definition,
      painsAndGains: props.productDetails.pains_gains,
      thematics: props.productDetails.thematics,
      remarks: props.productDetails.remarks,
    };
    this.formRef = React.createRef();
    this.handleSaveChanges = this.handleSaveChanges.bind(this);
  }

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values;
    this.props.onClick({ [name]: formData[name] });
  };

  handleSaveChanges(event) {
    event.preventDefault();
    const { targetGroupDefinition, painsAndGains, thematics, remarks } =
      this.state;
    const data = {
      target_group_definition: targetGroupDefinition,
      remarks: remarks,
      thematics: thematics,
      pains_gains: painsAndGains,
    };
    this.props.onClick(data);
  }

  render() {
    const { targetGroupDefinition, painsAndGains, thematics, remarks } =
      this.state;
    const { classes, productDetails } = this.props;

    return (
      <React.Fragment>
        <Card className={classes.paper}>
          <form onSubmit={this.handleSaveChanges}>
            <div style={{ marginBottom: "20px" }}>
              <Typography
                component="h6"
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Target group definition
              </Typography>
              <CKEditor
                editor={ClassicEditor}
                config={ editorConfiguration }
                data={targetGroupDefinition}
                onReady={(editor) => {
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({ targetGroupDefinition: data });
                  console.log({ event, editor, data });
                }}
              />
            </div>
            <FormGroup style={{ marginBottom: "20px" }}>
              <Typography
                component="h6"
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Pains and gains
              </Typography>
              <CKEditor
                editor={ClassicEditor}
                config={ editorConfiguration }
                data={painsAndGains}
                onReady={(editor) => {
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({ painsAndGains: data });
                  console.log({ event, editor, data });
                }}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: "20px" }}>
              <Typography
                component="h6"
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Thematics
              </Typography>
              <CKEditor
                editor={ClassicEditor}
                config={ editorConfiguration }
                data={thematics}
                onReady={(editor) => {
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({ thematics: data });
                  console.log({ event, editor, data });
                }}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: "20px" }}>
              <Typography
                component="h6"
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Remarks
              </Typography>
              <CKEditor
                editor={ClassicEditor}
                config={ editorConfiguration }
                data={remarks}
                onReady={(editor) => {
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({ remarks: data });
                  console.log({ event, editor, data });
                }}
              />
            </FormGroup>
            <div style={{ float: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </div>
          </form>
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ProductAdditionalForm);
