import {
  createActions,
  asyncInitialState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError,
  asyncSelectors,
} from "./utils";

export const { types, actions } = createActions(
  {
    asyncs: {
      getOpportunitiesList: (data) => data,
    },
  },
  "opportunitiesList"
);

let initialState = asyncInitialState({
  opportunitiesList: null,
  errorMessage: "",
  success: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getOpportunitiesList:
      return asyncOnRequest(state, action);
    case types.saga.getOpportunitiesList.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          opportunitiesList: action.payload.data,
          errorMessage: "",
        };
      });
    case types.saga.getOpportunitiesList.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors((state) => state.opportunitiesList, {
  errorMessage: (data) => data.errorMessage,
});

const syncSelector = {
  isLoading: (state) => state.opportunitiesList.loading,
  opportunitiesList: (state) => state.opportunitiesList.data.opportunitiesList,
};

export const selectors = Object.assign({}, asyncSelector, syncSelector);
