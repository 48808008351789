import React, {useEffect} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import {connect} from 'react-redux';
import {actions as opportunityActions, selectors as opportunitySelectors} from '../../../../Ducks/opportunity';
import {bindActionCreators} from 'redux';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from '@mui/lab';
import {
    Add,
    Call,
    Email,
    ExpandMore as ExpandMoreIcon,
    Help,
    Launch,
    Lock,
    Phone,
    PriorityHigh,
    PushPin,
    PushPinOutlined,
    StickyNote2
} from '@mui/icons-material';
import {toDateTime} from '../../../Utils/dateUtils';
import AddEventModal from '../Modals/AddEventModal';
import {selectors as accountSelectors} from '../../../../Ducks/account';
import EditEventModal from '../../../Common/CalendarWidget/EditEventModal';
import moment from 'moment';

const useStyles = theme => ({});

function HistoryTab(props) {
    const {opportunity, history, classes, isLoading, user, togglePin, togglePinLoading} = props;
    const [selectedFilter, setSelectedFilter] = React.useState('all');
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);

    useEffect(() => {
        opportunity && props.getHistory({id: opportunity.main.opportunity_id});
    }, [opportunity]);

    const showProgress = () => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </div>
    )

    if (isLoading || !history) {
        return showProgress()
    }

    let logs = history && history.main ? history.main.logs : [];
    logs.forEach(item => {
        if (item.person_log_code === undefined) {
            if (item.opportunity_log_new_status !== null && item.opportunity_log_new_status !== "open") {
                item.person_log_code = item.opportunity_log_new_status;
            }
        }
    });

    let relatedObjects = history && history.main ? history.main.related_objects : {};
    let upcomingActions = (relatedObjects.opportunity_actions && relatedObjects.opportunity_actions[Object.keys(relatedObjects.opportunity_actions)[0]]) || null;
    let job = (relatedObjects.jobs && relatedObjects.jobs[Object.keys(relatedObjects.jobs)[0]]) || null;

    const getUsername = (id) => {
        let obj = relatedObjects.users[id];
        if (!obj)
            return 'System automatically';

        if (obj.user_id === user.user_details.user_id)
            return 'You';

        return obj.user_firstname + ' ' + obj.user_lastname;
    }
    const getPersonName = (id) => {
        let obj = relatedObjects.persons[id];
        if (!obj)
            return ''
        return obj.person_firstname + ' ' + obj.person_lastname;
    }

    const getPipelineStatus = (id) => {
        let status = relatedObjects.pipeline_stages[id];
        return status === undefined ? "" : ((status.pipeline_stage_title === 'Won' || status.pipeline_stage_title === 'Lost') ? 'Closed:' : '') + status.pipeline_stage_title;
    }

    const handleChange = (e) => {
        setSelectedFilter(e.target.value);
    }

    let result = logs.reduce(function (r, a) {
        let key = a.person_log_code;
        if (a.opportunity_log_new_stage_id) {
            key = 'stage_change';
        }
        if (key !== undefined) {
            r[key] = r[key] || [];
            r[key].push(a);
        }
        return r;
    }, Object.create(null));

    result['all'] = logs;

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    const getCard = (log) => {
        switch (log.person_log_code) {
            case 'person_food_considerations':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} updated
                        food considerations for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'person_tagged':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> updated tag(s) for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/><br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'missed_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a missed
                        call for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'unknown_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} an
                        unknown
                        call for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'rating_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a rating
                        call note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'welcome_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a
                        welcome
                        call note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'coordinator_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a
                        coordinator call note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'no_show_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a no
                        show
                        call note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'sent_sms':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a sent
                        sms
                        for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2">outgoing sms 1 test template</span>
                    </Typography>
                )
            case 'sent_email':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} sent an
                        email to <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'inbound_email_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} an
                        inbound
                        email note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'inbound_email':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} an
                        inbound
                        email note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'outbound_email_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} an
                        outbound
                        email note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'outbound_email':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} an
                        outbound
                        email note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'inbound_call_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a
                        inbound
                        call note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'lead_sheet_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a lead
                        sheet
                        note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'lead_sheet_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a lead
                        sheet
                        call for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'completed_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a
                        completed
                        call for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.person_log_content}}></div>
                    </Typography>
                )
            case 'rescheduled':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} rescheduled
                        the event.
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                )
            case 'closed:lost':
            case 'closed:won':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} changed
                        the
                        opportunity <b>status</b> to <b>{log.opportunity_log_new_stage_id === null ? log.opportunity_log_new_status : getPipelineStatus(log.opportunity_log_new_stage_id)}</b>
                        <br/> <span
                        className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                )
            case "person_erp_note":
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} person
                        erp
                        note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case "event_update":
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{log.person_log_title}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'successful_campaign':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} a
                        successfull campaign note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case 'unknown_email':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} an
                        unknown
                        email note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            case "special_case":
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} special
                        case
                        note for <b>{getPersonName(log.person_id)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.person_log_content}}></span>
                    </Typography>
                )
            default:
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log.user_id)}</b> {getUsername(log.user_id) === 'You' ? 'have' : 'has'} changed
                        the
                        opportunity <b>stage</b> to <b>{log.opportunity_log_new_stage_id === null ? log.opportunity_log_new_status : getPipelineStatus(log.opportunity_log_new_stage_id)}</b>
                        <br/> <span
                        className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                )
        }
    }

    const getIcon = (log) => {

        switch (log.person_log_code) {
            case 'missed_call':
                return <Call style={{color: 'red'}}/>
            case 'completed_call':
                return <Call style={{color: 'green'}}/>
            case 'sent_email':
                return <Email/>
            case 'inbound_email_note':
                return <StickyNote2/>
            default: {
                let status = relatedObjects.pipeline_stages && relatedObjects.pipeline_stages[log.opportunity_log_new_stage_id];
                if (status) {
                    return (status.pipeline_stage_title === 'Won' || status.pipeline_stage_title === 'Lost') ? <Lock/> :
                        <PriorityHigh/>
                }
                return <PriorityHigh/>
            }
        }
    }
    return (
        <>
            <div style={{display: 'flex', paddingRight: '30px', marginLeft: '50px', marginTop: '16px'}}>
                <div>
                    {
                        props.assignedOpportunity && opportunity.main.opportunity_status === "open" &&
                        <Button variant="outlined" startIcon={<Add/>} onClick={e => setOpenAddModal(!openAddModal)}>
                            {'Add Event'}
                        </Button>
                    }
                </div>
                <div style={{flexGrow: 1}}>

                </div>
                <FormControl style={{width: '200px'}}>
                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFilter}
                        label="Filter"
                        onChange={handleChange}
                        style={{}}
                    >
                        <MenuItem value={"all"}>Filter Timeline</MenuItem>
                        {
                            Object.keys(result).filter(k => k !== 'all').map(x => <MenuItem
                                value={x}>{makeHeader(x) + ' (' + result[x].length + ')'}</MenuItem>)
                        }
                        {/* <MenuItem value={"stage_change"}>Stage Change</MenuItem> */}
                    </Select>
                </FormControl>
            </div>
            <Timeline>
                {
                    <TimelineItem>
                        <TimelineOppositeContent style={{flex: 0}}>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <Help/>
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography style={{marginBottom: '8px'}} className={classes.typography} variant="h5">
                                Upcoming
                            </Typography>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    {
                                        !!upcomingActions ?
                                            <div style={{display: 'flex'}}>
                                                <div style={{marginRight: '8px'}}>
                                                    <img style={{borderRadius: '50%'}} width={35} height={35}
                                                         src={`https://erp.f5.dk/uploads/salesreps_imgs/${upcomingActions.user_id}/Almindelig-web.jpg`}/>
                                                </div>
                                                <Typography style={{width: '100%'}} className={classes.typography}
                                                            variant="body2" color="site.main">
                                                    <div style={{display: 'flex'}}>
                                                        <div>
                                                            <b>{getUsername(upcomingActions.opportunity_action_created_by)}</b> have
                                                            a <b>{upcomingActions.opportunity_action_type}</b> for <b>{relatedObjects.persons[Object.keys(relatedObjects.persons)[0]].person_firstname} {relatedObjects.persons[Object.keys(relatedObjects.persons)[0]].person_lastname}</b>
                                                        </div>
                                                        <div style={{flexGrow: 1}}></div>
                                                        <span>
                              {props.assignedOpportunity && opportunity.main.opportunity_status === "open" &&
                                  <Launch sx={{fontSize: "20px", marginLeft: "5px",}}
                                          onClick={() => setOpenEditModal(!openEditModal)}/>}
                            </span>
                                                    </div>
                                                    <br/>
                                                    <span
                                                        className="card-text mt-2 mb-2">{upcomingActions.opportunity_action_due_time && toDateTime(upcomingActions.opportunity_action_due_time)}</span>
                                                    {job && job.job_phone &&
                                                        <Typography variant="body2" color="site.main">
                                                            <a href={`callto:${job.job_phone}`}
                                                               onClick={props.showActiveTab} style={{
                                                                textDecoration: 'none',
                                                                color: 'inherit',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                <Phone sx={{color: 'icon.main', fontSize: "20px"}}/>
                                                                <span style={{marginLeft: '8px'}}>
                                  {job.job_phone}
                                </span>
                                                            </a>
                                                        </Typography>
                                                    }
                                                </Typography>
                                            </div>
                                            :
                                            <Typography display={'flex'} justifyContent={'center'} variant="body2"
                                                        color="site.main">
                                                No activities
                                            </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </TimelineContent>
                    </TimelineItem>
                }
                {
                    result[selectedFilter].filter(item => (item.person_log_code !== undefined || !!item.opportunity_log_new_stage_id) && item.is_pinned === 1).reverse().map((pinlog, index) => {
                        return (<TimelineItem>
                            <TimelineOppositeContent style={{flex: 0}}>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot>
                                    {index === 0 ? <PushPin/> : getIcon(pinlog)}
                                </TimelineDot>
                                <TimelineConnector/>
                            </TimelineSeparator>
                            <TimelineContent>
                                {index === 0 && <Typography style={{marginBottom: '8px', marginTop: '4px'}}
                                                            className={classes.typography} variant="h5">
                                    Pinned
                                </Typography>}
                                <GetCardContent log={pinlog} index={index} getCard={getCard} togglePin={togglePin}
                                                oppId={opportunity.main.opportunity_id} loading={togglePinLoading}/>
                            </TimelineContent>
                        </TimelineItem>)
                    })
                }
                {
                    result[selectedFilter].filter(x => (x.person_log_code !== undefined || !!x.opportunity_log_new_stage_id) && x.is_pinned === 0).reverse().map((log, index) => {
                        return (
                            <TimelineItem>
                                <TimelineOppositeContent style={{flex: 0}}>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot>
                                        {getIcon(log)}
                                    </TimelineDot>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    {index === 0 && <Typography style={{marginBottom: '8px', marginTop: '4px'}}
                                                                className={classes.typography} variant="h5">
                                        Previous
                                    </Typography>}
                                    <GetCardContent log={log} index={index} getCard={getCard} togglePin={togglePin}
                                                    oppId={opportunity.main.opportunity_id} loading={togglePinLoading}/>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })
                }
            </Timeline>
            <AddEventModal
                open={openAddModal}
                handleClose={() => setOpenAddModal(!openAddModal)}
                addEventLog={props.addEventLog}
                personId={job ? job.person_id : undefined}
                opportunityId={opportunity && opportunity.main.opportunity_id}
            />
            {openEditModal && <EditEventModal
                open={openEditModal}
                handleClose={() => setOpenEditModal(false)}
                startDate={new Date(upcomingActions && upcomingActions.opportunity_action_due_time)}
                opportunity={opportunity}
                type={upcomingActions && (upcomingActions.opportunity_action_type === 'follow_up' ? 'phone_call' : upcomingActions.opportunity_action_type)}
                isUpcomingEvent={false}
                actionId={Object.keys(relatedObjects.opportunity_actions)[0]}
            />
            }
        </>
    );
}

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function GetCardContent({log, index, getCard, togglePin, oppId, loading}) {
    const [expanded, setExpanded] = React.useState(false);
    const [id, setId] = React.useState(null);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const togglePinFunc = (log) => {
        setId(log.person_log_id);
        var payload = {
            logId: log.person_log_id,
            oppId: oppId
        }
        togglePin(payload);
    }
    return (
        <Card>
            <CardContent>
                <div style={{
                    height: (log.person_log_code === 'person_erp_note' || log.person_log_code === 'sent_email' || log.person_log_code === 'inbound_email' || log.person_log_code === 'outbound_email') ? expanded ? 'auto' : '144px' : 'auto',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: (log.person_log_code === 'person_erp_note' || log.person_log_code === 'sent_email' || log.person_log_code === 'inbound_email' || log.person_log_code === 'outbound_email' || log.person_log_code === 'missed_call' || log.person_log_code === 'completed_call') ? 'space-between' : 'flex-start'
                }}>
                    <div style={{marginRight: '8px'}}>
                        <img style={{borderRadius: '50%'}} width={35} height={35}
                             src={`https://erp.f5.dk/uploads/salesreps_imgs/${log.user_id}/Almindelig-web.jpg`}/>
                    </div>
                    {
                        getCard(log)
                    }
                    {
                        (log.person_log_code === 'person_erp_note' || log.person_log_code === 'sent_email' || log.person_log_code === 'inbound_email' || log.person_log_code === 'outbound_email' || log.person_log_code === 'missed_call' || log.person_log_code === 'completed_call') ?
                            log.is_pinned ? <PushPin style={{cursor: "pointer", marginLeft: "auto", color: "red"}}
                                                     onClick={() => togglePinFunc(log)}/> :
                                <PushPinOutlined style={{cursor: "pointer", marginLeft: "auto", color: "red"}}
                                                 onClick={() => togglePinFunc(log)}/> : ''
                    }
                    {
                        loading && log.person_log_id && log.person_log_id === id ?
                            <CircularProgress style={{width: "15px", height: "15px"}}/> : ''
                    }
                </div>
            </CardContent>
            {
                (log.person_log_code === 'person_erp_note' || log.person_log_code === 'sent_email' || log.person_log_code === 'inbound_email' || log.person_log_code === 'outbound_email') &&
                <CardActions style={{height: '20px', display: 'flex', justifyContent: 'center'}}>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        style={{marginTop: '-14px'}}
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>
            }
        </Card>
    )
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.getHistory_loading(state),
    opportunity: opportunitySelectors.opportunity(state),
    history: opportunitySelectors.history(state),
    user: accountSelectors.user(state),
    togglePinLoading: opportunitySelectors.togglePinLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getHistory: opportunityActions.getHistory,
        addEventLog: opportunityActions.addEventLog,
        togglePin: opportunityActions.togglePin,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(HistoryTab));