import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";

const useStyles = (theme) => ({
});

function Placement(props) {
  const { classes, dashboard } = props;
  const [dashboardObj, setDashboardObj] = React.useState({});

  useEffect(() => {
    if (dashboard !== null) {
      let placement = dashboard.placement;
      setDashboardObj(placement || {});
      console.log(placement);
    }
  }, [dashboard]);

  const reMoveUnderScore = (key) => {
    key = key.replaceAll("_", " ");
    let titleCaseLetter = null;
    let splitStr = key.toLowerCase().split(" ");

    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() +
        splitStr[i].substring(1).toLowerCase();

      key = splitStr.join(" ");
      titleCaseLetter = makeTitleCase(key);
    }

    return titleCaseLetter;
  };

  const makeTitleCase = (string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Card style={{textAlign: "initial",fontSize: "14px",letterSpacing: ".1px",fontWeight: 400,lineHeight: "2.5rem"}}>
      <CardContent>
        <hr style={{marginTop: "20px", height: "1px", border: "none", background: "#ddd"}} />
        <Grid container sx={{ color: "text.primary" }}>
          <Grid item xs={6}>
            {Object.keys(dashboardObj).map((key, index) => {
              return (
                <Grid key={key} container sx={{ color: "text.primary" }}>
                  {index != 3 ? (
                    <>
                      <Grid item xs={8}>
                        <Typography
                          gutterBottom
                          variant="span"
                          component="text.secondary"
                          mr={3}
                        >
                          {reMoveUnderScore(key)} :
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="span" color="site.main">
                          {reMoveUnderScore(dashboardObj[key])}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    " "
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={6} className={classes.centerGridCss}>
            {Object.keys(dashboardObj).map((key, index) => {
              return (
                <Grid key={key} item xs={12} sx={{ color: "text.primary" }}>
                  {index === 3 ? (
                    <Grid item xs={12}>
                      <Typography variant="span" color="site.main">
                        {reMoveUnderScore(dashboardObj[key])}
                      </Typography>
                    </Grid>
                  ) : (
                    " "
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withStyles(useStyles)(Placement);
