import React, {useEffect} from 'react';
import Routes from './Routes';
import './App.css';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import OpenSans from './Assets/fonts/OpenSans-Regular.ttf';
import {grey} from '@mui/material/colors';
import {fetchPost} from "./API/util";
import {selectors as accountSelectors} from './Ducks/account';
import {actions as opportunityActions} from './Ducks/opportunity';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

const colors = {
    text: "#3a3641",
    icon: "#626262",
    link: "#2a77ea",
    blue: "#4492e2"
}

const theme = createTheme({
    typography: {
        fontFamily: 'OpenSans, Arial',
        color: colors.text
    },
    palette: {
        site: {
            main: colors.text,
            link: colors.link,
        },
        icon: {
            main: colors.icon,
            linkedin: colors.blue
        },
        secondary: {
            main: '#c7c7c769',
            light: '#c7c7c769',
            dark: '#c7c7c7',
            cotrastText: grey[800],
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'OpenSans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${OpenSans}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
        },
    },
});

const App = (props) => {
    const {loggedIn, getAllEvents, getCalendar, cronofyAuthorized} = props;

    async function registerServiceWorker() {
        const register = await navigator.serviceWorker.register('/sw.js', {
            scope: '/'
        });
        const subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: "BFlncsi0pelEn2acYr-AYpdCRC4gMe8hBXwcmHz0p7yYVXuoy5ZoyymuszXphrxbvU7wytpGV0rYawLbtsKjYgc",
        });
        try {
            await fetchPost("/subscriptions/web-push", {subscription: JSON.stringify(subscription)})
        } catch (_err) {
        }
    }

    useEffect(() => {
        if (!loggedIn) return;

        let d = new Date();
        let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);

        cronofyAuthorized && getAllEvents();
        getCalendar(YearMonth);
        registerServiceWorker();
    }, [loggedIn, cronofyAuthorized]);

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <Routes/>
            </ThemeProvider>
        </div>
    );
}


const mapStateToProps = (state) => ({
    loggedIn: accountSelectors.isLoggedIn(state),
    cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
});


const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getAllEvents: opportunityActions.getAllEvents,
        getCalendar: opportunityActions.getCalendar
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
