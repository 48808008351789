import * as React from "react";
import { Formik, Form } from "formik";
import { Card, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import InputElement from "../../Common/InputElement";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: "16px",
  },
});

const fieldList = [
  {
    title: "Invoice No",
    value: "",
    name: "purchase_invoice_no",
    type: "textfield",
  },
];

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values;
    this.props.onClick({ [name]: formData[name] });
  };
  render() {
    const { classes, saleDetails } = this.props;

    return (
      <React.Fragment>
        <Card className={classes.paper}>
          <Typography
            style={{ marginBottom: "30px" }}
            component="h6"
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            Invoice Info
          </Typography>
          <Formik
            innerRef={this.formRef}
            initialValues={saleDetails}
            validate={(values) => {
              const errors = {};
              if (!values.Title) {
                errors.Title = "Required";
              } else if (!values.Message) {
                errors.Message = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.props.saveNews(values);
              }, 500);
            }}
            render={({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form>
                {fieldList.map((item, index) => {
                  return (
                    <InputElement
                      {...item}
                      key={index}
                      onSaveClick={this.handleSaveClick}
                    />
                  );
                })}
              </Form>
            )}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(InvoiceForm);
