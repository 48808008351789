import {
    Alert,
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {dateToString} from "../../Utils/dateUtils";
import EventGrid from "./EventGrid";

const ResaleOpportunityOverview = (props) => {
    const {
        newResaleEvents,
        connectedResale,
        openResaleNextToResoldPurchase,
        newNextResaleEvents,
        connectedResalePurchaseStart,
        connectedResalePurchaseStop,
        nextConnectedResalePurchaseStart,
        nextConnectedResalePurchaseStop,
        plannedAction,
        nextConnectedResalePlannedAction
    } = props;

    return (
        <div
            style={{
                paddingTop: "30px"
            }}>
            {
                connectedResale ?
                    <>
                        <Grid container sx={{padding: "20px", textAlign: "left"}} spacing={2}>
                            <Grid item xs={12} sx={{paddingTop: "20px"}}>
                                <Card style={{padding: "12px"}}>
                                    <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                gutterBottom>
                                        {openResaleNextToResoldPurchase ? '1.' : ''} Resale opportunity
                                        <Alert variant="outlined" severity="info"
                                               style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                            Connected resale will be updated according to new group.
                                        </Alert>
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        {connectedResale?.opportunity && <TableRow>
                                                            <TableCell><b>Opportunity Id</b></TableCell>
                                                            <TableCell><a
                                                                href={`${window.location.origin}/opportunities/id/${connectedResale.opportunity.opportunity_id}`}
                                                                target="_blank">{connectedResale.opportunity.opportunity_id}</a></TableCell>
                                                        </TableRow>}
                                                        <TableRow>
                                                            <TableCell><b>Pipeline stage</b></TableCell>
                                                            <TableCell>{connectedResale?.opportunity?.pipeline_stage?.pipeline_stage_title}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Opportunity status</b></TableCell>
                                                            <TableCell>{connectedResale?.opportunity?.opportunity_status}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Opportunity type</b></TableCell>
                                                            <TableCell>{connectedResale?.opportunity?.opportunity_type}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Planned action</b></TableCell>
                                                            <TableCell>{plannedAction}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell><b>Purchase Id</b></TableCell>
                                                            <TableCell><a
                                                                href={`${window.location.origin}/sales/id/${connectedResale.purchase_id}`}
                                                                target="_blank">{connectedResale.purchase_id}</a></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{width: "30%"}}><b>Purchase
                                                                start</b></TableCell>
                                                            <TableCell>{dateToString(connectedResalePurchaseStart)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Purchase stop</b></TableCell>
                                                            <TableCell>{dateToString(connectedResalePurchaseStop)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Purchase status</b></TableCell>
                                                            <TableCell>{connectedResale?.purchase_status}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item xs={6} sx={{paddingTop: "20px"}}>
                                <Card style={{padding: "12px"}}>
                                    <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                gutterBottom>
                                        Resale old events
                                        <Alert variant="outlined" severity="info"
                                               style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                            Connected resale events to old group.
                                        </Alert>
                                    </Typography>
                                    <EventGrid events={connectedResale?.upcoming_events}/>
                                </Card>
                            </Grid>
                            <Grid item xs={6} sx={{paddingTop: "20px"}}>
                                <Card style={{padding: "12px"}}>
                                    <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                gutterBottom>
                                        Resale new events
                                        <Alert variant="outlined" severity="info"
                                               style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                            Connected resale events according to new group.
                                        </Alert>
                                    </Typography>
                                    <EventGrid events={newResaleEvents}/>
                                </Card>
                            </Grid>
                        </Grid>
                        {openResaleNextToResoldPurchase &&
                            <Grid container sx={{padding: "20px", textAlign: "left"}} spacing={2}>
                                <Grid item xs={12} sx={{paddingTop: "20px"}}>
                                    <Card style={{padding: "12px"}}>
                                        <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                    gutterBottom>
                                            2. Resale opportunity
                                            <Alert variant="outlined" severity="info"
                                                   style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                                Next connected resale will be updated according to new group.
                                            </Alert>
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6}><TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell><b>Pipeline Stage</b></TableCell>
                                                            <TableCell>{openResaleNextToResoldPurchase?.opportunity?.pipeline_stage?.pipeline_stage_title}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Opportunity Status</b></TableCell>
                                                            <TableCell>{openResaleNextToResoldPurchase?.opportunity?.opportunity_status}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Opportunity Type</b></TableCell>
                                                            <TableCell>{openResaleNextToResoldPurchase?.opportunity?.opportunity_type}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Planned action</b></TableCell>
                                                            <TableCell>{nextConnectedResalePlannedAction}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer></Grid>
                                            <Grid item xs={6}><TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell><b>Purchase Start</b></TableCell>
                                                            <TableCell>{dateToString(nextConnectedResalePurchaseStart)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Purchase Stop</b></TableCell>
                                                            <TableCell>{dateToString(nextConnectedResalePurchaseStop)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Purchase Status</b></TableCell>
                                                            <TableCell>{openResaleNextToResoldPurchase?.purchase_status}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer></Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sx={{paddingTop: "20px"}}>
                                    <Card style={{padding: "12px"}}>
                                        <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                    gutterBottom>
                                            Next resale old events
                                            <Alert variant="outlined" severity="info"
                                                   style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                                Next connected resale events to old group.
                                            </Alert>
                                        </Typography>
                                        <EventGrid events={openResaleNextToResoldPurchase?.upcoming_events}/>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sx={{paddingTop: "20px"}}>
                                    <Card style={{padding: "12px"}}>
                                        <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                    gutterBottom>
                                            Next resale new events
                                            <Alert variant="outlined" severity="info"
                                                   style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                                Connected resale events according to new group.
                                            </Alert>
                                        </Typography>
                                        <EventGrid events={newNextResaleEvents}/>
                                    </Card>
                                </Grid>
                            </Grid>
                        }
                    </>
                    :
                    <Grid container sx={{padding: "20px", textAlign: "left"}} spacing={2}>
                        <Grid item xs={12} sx={{paddingTop: "20px"}}>
                            <Card style={{padding: "12px"}}>
                                <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                            gutterBottom>
                                    Opportunity overview
                                    <Alert variant="outlined" severity="info"
                                           style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                        Connected resale does not exits for this person.
                                    </Alert>
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
            }

        </div>
    );
}

export default ResaleOpportunityOverview;