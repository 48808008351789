import * as React from 'react';
import { Tooltip, Card, CardContent, CardMedia, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField } from '@mui/material';

const OutboundOverview = (props) => {
    const { classes, item, name } = props;
    var _class = "", _changed = false, _text = [];
    if (item === undefined) {
        _class = "empty"
    } else if (item[0].mail_queue_status === "complete" && !item.changed) {
        _class = "completed"
    } else if (item[0].mail_queue_status === "completed" && item.changed) {
        _class = "changed"
    } else if (item[0].mail_queue_status.includes("failed")) {
        _class = "failed"
    }

    // Get content and check for status change
    if (item) {
        item.forEach((element) => {
            if (element.changed) {
                _changed = true;
                _text.push(element.created_at + ": " + element.changed_text);
            }
            _text.push(element.mail_queue_attempted_send + ": " + element.mail_queue_status);
        });
    }

    if (item === undefined) {
        return (
            <Tooltip title={name}>
                <div className={classes[_class]}></div>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={<React.Fragment><div>{name}</div><div>{_text.map((text) => <div>{text}</div>)}</div></React.Fragment>}>
                <div style={_changed ? { border: '1px solid red' } : { border: 'none' }} className={classes[_class]}></div>
            </Tooltip>
        );
    }
}

export default OutboundOverview;