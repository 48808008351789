import React from 'react';
import {connect} from 'react-redux';
import {actions as opportunityActions, selectors as opportunitySelectors} from '../../../../Ducks/opportunity';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {bindActionCreators} from 'redux';
import {Button, DialogActions, IconButton} from '@mui/material';
import {withStyles} from '@mui/styles';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {useParams} from "react-router-dom";
import * as Survey from "survey-core";
import "survey-core/modern.css";

Survey.StylesManager.applyTheme("modern");

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    }
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    }
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const UnsuccessfulModal = (props) => {
    const {onClose, selectedValue, open, opportunity, classes, formDataValues} = props;
    const [closedLostOptions, _setClosedLostOptions] = React.useState([]);
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleClosedLost = (event, never_contact = null) => {
        let closed_lost_reasons_ids = closedLostOptions;
        let unSuccessfulCampaignData = {
            closed_lost_reasons_ids,
            id: props.params.id,
            userStageId: opportunity.main.pipeline_stage_id,
            opportunityId: opportunity.main.opportunityId,
            person_food_considerations_1: formDataValues?.person_food_considerations_1,
            person_food_considerations_2: formDataValues?.person_food_considerations_2,
            person_food_considerations_other: formDataValues?.person_food_considerations_other
        };

        props.updateOpportunityStatus({
            opportunity_status: 'closed:lost',
            person_food_considerations_1: formDataValues?.person_food_considerations_1,
            person_food_considerations_2: formDataValues?.person_food_considerations_2,
            person_food_considerations_other: formDataValues?.person_food_considerations_other
        });

        if (never_contact !== null) {
            unSuccessfulCampaignData['never_contact'] = true;
        }

        props.postUnsuccessfulCampaign(unSuccessfulCampaignData);

        handleClose();
    }

    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BootstrapDialogTitle sx={{textAlign: 'center'}}><b>Unsuccessful Campaign</b></BootstrapDialogTitle>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color='warning' onClick={handleClosedLost}>Unsuccessful
                    campaign:lost</Button>
                <Button className={classes.button} variant="contained" color="error"
                        onClick={(e) => handleClosedLost(e, true)}>Unsuccessful campaign:lost never contact</Button>
                <Button className={classes.button} variant="contained" color="primary" onClick={handleClose}>Cancel
                    Unsuccessful campaign:lost action</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        postUnsuccessfulCampaign: opportunityActions.postUnsuccessfulCampaign,
        updateOpportunityStatus: opportunityActions.updateOpportunityStatus
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <UnsuccessfulModal {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));