import * as React from 'react';
import { withStyles } from '@mui/styles';
import { selectors as salesSelectors, actions as salesActions } from '../../../Ducks/sales';
import { selectors as accountSelectors } from '../../../Ducks/account';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Stepper from './Stepper';
import CircularProgress from '@mui/material/CircularProgress';
import cloneDeep from 'lodash/cloneDeep';
import { InputLabel, MenuItem, FormControl, Select, Autocomplete, TextField } from '@mui/material';
import moment from 'moment';

const useStyles = theme => ({
  container: {
    textAlign: 'left',
  },
  title: {
    marginBottom: '10px',
    color: '#3a3641',
    margin: '0px 0px 10px 0px',
    fontSize: '29px',
    fontWeight: '300'
  },
  filters: {
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectStyling: {
    width: '100%',
    padding: '6px 8px',
    border: '1px solid #ddd',
    marginBottom: '1px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '4px',
    marginRight: '5px'
  },
});

class PipelineStages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pipelineFilter: "all",
      productFilter: -1,
      phoneFilter: -1,
      isProcessing: true,
      companyFilter: -1,
      filterPipelineOptions: [
        {
          value: "all",
          title: "Show All"
        },
        {
          value: -1,
          title: "Default"
        },
        {
          value: "overdue_today",
          title: "Show overdue + today"
        }
      ],
      comapanySectorFilter: [
        {value: -1,title: "Show All"},
        {value: 'private', title: 'Private'},
        {value: 'public',title:'Public'},
      ],
      filterProductsOptions: [],
      userStages: null,
      persons: []
    }
  }

  componentDidMount() {
    this.getUserStages(1);
  }

  componentDidUpdate(prevProps, prevState) {
    const { stages } = this.props;
    if (prevProps.stages !== stages) {
      this.setState({ isProcessing: true });
      let persons = [];

      if (stages?.related_objects.opportunities) {
        Object.keys(stages.related_objects.opportunities).forEach(element => {
          const opportunity = stages.related_objects.opportunities[element];
          const job = stages.related_objects.jobs[opportunity.job_id];
          const person = stages.related_objects.persons[job.person_id];
          const company = stages.related_objects.companies[stages.related_objects.divisions[job.division_id].company_id];

          // Entire Object
          // persons.push(
          //   {
          //     person,
          //     opportunity,
          //     job,
          //     company
          //   }
          // )

          // Related data only
          persons.push(
            {
              company_name: company ? company.company_name : '',
              firstname: person ? person.person_firstname : '',
              job_title: job ? job.job_title : '',
              lastname: person ? person.person_lastname : '',
              opportunity_id: opportunity ? opportunity.opportunity_id : '',
              label: company && person && job ? `${person.person_firstname + " " + person.person_lastname}, ${job.job_title} - ${company.company_name}` : '',
              key: opportunity ? opportunity.opportunity_id : '',
              id: opportunity ? opportunity.opportunity_id : '',
            }
          )
        });
        this.setState({ persons });
      }

      this.setState({
        filterProductsOptions: stages && stages?.related_objects?.products && Object.keys(stages.related_objects.products).map((element) => stages.related_objects.products[element]),
        userStages: stages
      })

      // Set filters if exists
      const filters = localStorage.getItem("sales_overview_filters")
      if (filters !== null) {
        const _filters = JSON.parse(filters);
        this.setState(_filters, () => {
          this.filterOnLoad(stages)
        });
      } else {
        this.filterOnLoad(stages)
      }

      this.setState({ isProcessing: false });
    }
  }

  getUserStages = (newPage) => {
    const { getUserStages, user } = this.props;
    getUserStages({ id: user.user_details.user_id });
  }

  handleChange(state, event) {
    let myStages = cloneDeep(this.props.stages);
    myStages.main.pipeline_stages.forEach(stageId => {
      if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
        myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
          if (parseInt(event.target.value) !== -1 && myStages.related_objects.opportunities[opportunityId].product_id !== parseInt(event.target.value)) {
            myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => item !== opportunityId);
          }
        });
      }
    })
    this.setState({
      [state]: event.target.value,
      userStages: myStages
    });
  }

  filterPipeline(state, event) {
    let myStages = cloneDeep(this.props.stages);
    if (event.target.value === 'overdue_today') {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
              if (myStages.related_objects.opportunities[item].next_planned_action_time) {
                return true;
              }
              return false;
            });
          });
        }
      })
    }
    this.setState({
      [state]: event.target.value,
      userStages: myStages
    });
  }

  handlePhoneFilter(state, event) {
    let myStages = cloneDeep(this.props.stages);
    myStages.main.pipeline_stages.forEach(stageId => {
      if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
        myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
          if (parseInt(event.target.value) !== -1) {
            myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
              const opportunityItem = myStages.related_objects.opportunities[item];
              if (opportunityItem[event.target.value] !== "" && opportunityItem[event.target.value] !== null && opportunityItem[event.target.value] !== undefined) {
                return true;
              }
              return false;
            });
          }
        });
      }
    })
    this.setState({
      [state]: event.target.value,
      userStages: myStages
    });
  }

  filterHandler = (state, event) => {
    let myStages = cloneDeep(this.props.stages);
    const { pipelineFilter, productFilter, phoneFilter,companyFilter } = this.state;
    const filters = ["pipelineFilter", "productFilter", "phoneFilter","companyFilter"];
    const _filters = filters.filter(item => item !== state);

    if (state === "pipelineFilter") {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
              if ((event.target.value === 'overdue_today' && myStages.related_objects.opportunities[item].next_planned_action_time <= moment().set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD H:m:i')) || (event.target.value === 'all') || (event.target.value === -1 && myStages.related_objects.opportunities[item].show_in_grid)) {
                return true;
              } else {
                return false;
              }
            });
          });
        }
      })
    }

    if (state === "productFilter" && event.target.value !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if (parseInt(event.target.value) !== -1 && myStages.related_objects.opportunities[opportunityId].product_id !== parseInt(event.target.value)) {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => item !== opportunityId);
            }
          });
        }
      })
    }

    if (state === "companyFilter" && event.target.value !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if(event.target.value === 'private' && !['private','unknown',undefined,""].includes(myStages.related_objects.opportunities[opportunityId].company_type?.toLowerCase()))
            {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => item !== opportunityId);
            }

            if(event.target.value === 'public'  && myStages.related_objects.opportunities[opportunityId].company_type?.toLowerCase() !== event.target.value)
            {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => item !== opportunityId);
            }
          });
          
        }
      })
    }

    if (state === "phoneFilter" && event.target.value !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if (parseInt(event.target.value) !== -1) {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
                const opportunityItem = myStages.related_objects.opportunities[item];
                if (opportunityItem[event.target.value] !== "" && opportunityItem[event.target.value] !== null && opportunityItem[event.target.value] !== undefined) {
                  return true;
                }
                return false;
              });
            }
          });
        }
      })
    }

    // Filter from states
    if (state !== "pipelineFilter") {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
              if ((pipelineFilter === 'overdue_today' && myStages.related_objects.opportunities[item].next_planned_action_time <= moment().set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD H:m:i')) || (pipelineFilter === 'all') || (pipelineFilter === -1 && myStages.related_objects.opportunities[item].show_in_grid)) {
                return true;
              }
              return false;
            });
          });
        }
      })
    }

    if (state !== "productFilter" && productFilter !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if (parseInt(productFilter) !== -1 && myStages.related_objects.opportunities[opportunityId].product_id !== parseInt(productFilter)) {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => item !== opportunityId);
            }
          });
        }
      })
    }

    if (state !== "companyFilter" && companyFilter !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if(companyFilter !== -1 && myStages.related_objects.opportunities[opportunityId].company_type !== companyFilter)
            {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
                const opportunityItem = myStages.related_objects.opportunities[item];
                if(companyFilter === 'private')
                {
                  return ['private','unknown',undefined,""].includes(opportunityItem.company_type?.toLowerCase())
                }
                return opportunityItem.company_type?.toLowerCase() === companyFilter?.toLowerCase();
              });
            }
          });
          
        }
      })
    }

    if (state !== "phoneFilter" && phoneFilter !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if (parseInt(phoneFilter) !== -1) {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
                const opportunityItem = myStages.related_objects.opportunities[item];
                if (opportunityItem[phoneFilter] !== "" && opportunityItem[phoneFilter] !== null && opportunityItem[phoneFilter] !== undefined) {
                  return true;
                }
                return false;
              });
            }
          });
        }
      })
    }

    const filterObj = {
      [state]: event.target.value,
      [_filters[0]]: this.state[_filters[0]],
      [_filters[1]]: this.state[_filters[1]],
      [_filters[2]]: this.state[_filters[2]]
    }

    localStorage.setItem('sales_overview_filters', JSON.stringify(filterObj));
    // localStorage.setItem('sales_overview_opportunities', JSON.stringify(myStages.related_objects.pipeline_stages));

    this.setState({
      [state]: event.target.value,
      userStages: myStages
    });
  }

  filterOnLoad = (stages) => {
    let myStages = cloneDeep(stages);

    // Pipeline filter
    myStages.main.pipeline_stages.forEach(stageId => {
      if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
        myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
          myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
            if ((this.state.pipelineFilter === 'overdue_today' && myStages.related_objects.opportunities[item].next_planned_action_time <= moment().set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD H:m:i')) || (this.state.pipelineFilter === -1 && myStages.related_objects.opportunities[item].show_in_grid) || (this.state.pipelineFilter === 'all')) {
              return true;
            }
            return false;
          });
        });
      }
    })
    // Pipeline filter

    if (this.state.productFilter !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if (parseInt(this.state.productFilter) !== -1 && myStages.related_objects.opportunities[opportunityId].product_id !== parseInt(this.state.productFilter)) {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => item !== opportunityId);
            }
          });
        }
      })
    }

    if (this.state.companyFilter !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if(this.state.companyFilter !== -1 && myStages.related_objects.opportunities[opportunityId].company_type !== this.state.companyFilter)
            {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
                const opportunityItem = myStages.related_objects.opportunities[item];
                if(this.state.companyFilter === 'private')
                {
                  return ['private','unknown',undefined,""].includes(opportunityItem.company_type?.toLowerCase())
                }
                return opportunityItem.company_type?.toLowerCase() === this.state.companyFilter?.toLowerCase();
              });
            }
          });
          
        }
      })
    }

    if (this.state.phoneFilter !== -1) {
      myStages.main.pipeline_stages.forEach(stageId => {
        if (myStages.related_objects.pipeline_stages[stageId].opportunities) {
          myStages.related_objects.pipeline_stages[stageId].opportunities.forEach(opportunityId => {
            if (parseInt(this.state.phoneFilter) !== -1) {
              myStages.related_objects.pipeline_stages[stageId].opportunities = myStages.related_objects.pipeline_stages[stageId].opportunities.filter(item => {
                const opportunityItem = myStages.related_objects.opportunities[item];
                if (opportunityItem[this.state.phoneFilter] !== "" && opportunityItem[this.state.phoneFilter] !== null && opportunityItem[this.state.phoneFilter] !== undefined) {
                  return true;
                }
                return false;
              });
            }
          });
        }
      })
    }

    // Set filtered pipeline opportunites on load
    // localStorage.setItem('sales_overview_opportunities', JSON.stringify(myStages.related_objects.pipeline_stages));

    this.setState({
      userStages: myStages
    });
  }

  render() {
    const { classes, stages, isLoading, navigate } = this.props;
    const { filterPipelineOptions,comapanySectorFilter, filterProductsOptions,isProcessing, userStages, pipelineFilter, productFilter,companyFilter,phoneFilter, persons } = this.state;

    const showProgress = () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    )

    return (
      <div className={classes.container}>
        <div className={classes.filters}>
          <div>
            <h2 className={classes.title}>
              Pipeline
            </h2>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ minWidth: '170px' }}
                options={persons}
                // filterOptions={(options, state) => options}
                renderOption={(props, option, state) => {
                  return (
                    <div {...props} key={option.opportunity_id}>
                      <div style={{ textAlign: 'left', padding: '0', fontSize: '13px', borderBottom: '1px solid #eaeaea' }}>
                        <span><b>{`${option.firstname + " " + option.lastname}`}</b></span>
                        <span>{`, ${option.job_title} - ${option.company_name}`}</span>
                      </div>
                    </div>
                  );
                }}
                size="small"
                onChange={(event, value) => {
                  // Navigate to new opportunity
                  if (value !== null) {
                    navigate(`/opportunities/id/${value.opportunity_id}`);
                  }
                }}
                renderInput={(params) => <TextField sx={{ background: "#fff", width: '300px', marginRight: '10px', border: 'none', fontSize: '13px' }} {...params} size="small" label="Search..." variant="outlined" />}
              />
            </div>
            <FormControl fullWidth sx={{ marginRight: '5px', minWidth: '200px' }}>
              <InputLabel id="filter-pipeline">Filter Pipeline</InputLabel>
              <Select
                size="small"
                labelId="filter-pipeline"
                id="filter-pipeline-select"
                value={pipelineFilter}
                label="Filter Pipeline"
                onChange={(e) => this.filterHandler('pipelineFilter', e)}
              >
                {filterPipelineOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginRight: '5px', minWidth: '200px' }}>
              <InputLabel id="filter-company-sector">Filter Company Sector</InputLabel>
              <Select
                size="small"
                labelId="filter-company-sector"
                id="filter-company-sector-select"
                value={companyFilter}
                label="Filter Pipeline"
                onChange={(e) => this.filterHandler('companyFilter', e)}
              >
                {comapanySectorFilter.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <select className={classes.selectStyling} value={this.state.pipelineFilter} onChange={(e) => this.filterHandler('pipelineFilter', e)}>
              {filterPipelineOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              ))}
            </select> */}
            <FormControl fullWidth sx={{ marginRight: '5px', minWidth: '200px' }}>
              <InputLabel id="filter-product">Filter Product</InputLabel>
              <Select
                size="small"
                labelId="filter-product"
                id="filter-product-select"
                value={productFilter}
                label="Filter Product"
                onChange={(e) => this.filterHandler('productFilter', e)}
              >
                <MenuItem value={-1}>Show All</MenuItem>
                {filterProductsOptions && filterProductsOptions.map((option, index) => (
                  <MenuItem key={index} value={option.product_id}>
                    {option.product_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <select className={classes.selectStyling} value={this.state.productFilter} onChange={(e) => this.filterHandler('productFilter', e)}>
              <option value={-1}>Filter Product</option>
              <option value={-1}>Show All</option>
              {filterProductsOptions.map((option, index) => (
                <option key={index} value={option.product_id}>
                  {option.product_name}
                </option>
              ))}
            </select> */}
            <FormControl fullWidth sx={{ marginRight: '5px', minWidth: '200px' }}>
              <InputLabel id="filter-number">Filter By Number</InputLabel>
              <Select
                size="small"
                labelId="filter-number"
                id="filter-number-select"
                value={phoneFilter}
                label="Filter By Number"
                onChange={(e) => this.filterHandler('phoneFilter', e)}
              >
                <MenuItem value={-1}>Show All</MenuItem>
                <MenuItem value="person_mobile_number">
                  Mobile Number
                </MenuItem>
                <MenuItem value="job_phone">
                  Direct Number
                </MenuItem>
                <MenuItem value="company_phone">
                  Company Number
                </MenuItem>
              </Select>
            </FormControl>
            {/* <select className={classes.selectStyling} value={this.state.phoneFilter} onChange={(e) => this.filterHandler('phoneFilter', e)}>
              <option value={-1}>Filter by Number</option>
              <option value={-1}>Show All</option>
              <option value="person_mobile_number">
                Mobile Number
              </option>
              <option value="job_phone">
                Direct Number
              </option>
              <option value="company_phone">
                Company Number
              </option>
            </select> */}
          </div>
        </div>
        {
          (isLoading || isProcessing) && showProgress()
        }
        {
          ((!(isLoading || isProcessing)) && userStages) && <div className="stepper-container">
            <Stepper stages={userStages} />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: salesSelectors.isLoading(state),
  errorMessage: salesSelectors.errorMessage(state),
  stages: salesSelectors.stages(state),
  content: salesSelectors.content(state),
  user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUserStages: salesActions.getUserStages,
  },
  dispatch);

function WithNavigate(props) {
  let navigate = useNavigate();
  return <PipelineStages {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
