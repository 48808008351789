import { fetchGet, fetchPost, fetchPatch, fetchDelete } from "./util";

export const getUsersList = () => {
  return fetchGet(`/capabilities/salesrep`).then((res) => res);
};

export const getUserDetails = (id) => {
  return fetchGet(`/users/${id}/profile`).then((res) => res);
};

export const updateUser = ({id, obj}) => {
  return fetchPatch(`/users/${id}/profile`, obj).then((res) => res);
};

export const updateTeam = (data) => {
  return fetchPatch(`/teams/${data.id}`, data.obj).then((res) => res);
};

export const createUser = (data) => {
  return fetchPost(`/users`, data.formData).then((res) => res);
};

export const addTeam = (data) => {
  return fetchPost(`/teams`, data).then((res) => res);
};

export const getTeams = () => {
  return fetchGet(`/teams`).then((res) => res);
};

export const deleteTeam = (obj) => {
  return fetchDelete(`/teams/${obj.id}`, {}).then(res => res);
}

export const getCaseTeams = () => {
  return fetchGet(`/teams/list`).then((res) => res);
}

export const getTeamDetails = (data) => {
  return fetchPost(`/teams/hierarchy`,data).then(res => res);
}

export const getActiveTeamUsers = (data) => {
  return fetchGet(`/users/active?${data}`).then(res => res);
}

export const getActiveUsers = () => {
  return fetchGet(`/users/active`).then(res => res);
}