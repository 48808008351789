import * as React from 'react';
import moment from 'moment';
import { Tooltip, Card, CardContent, CardMedia, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { Language, BusinessCenter, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt } from '@mui/icons-material';
import OutboundOverview from './OutboundOverview';

export default function ParticipantSendoutOverview(props) {
    const { classes, attendeeDetails } = props;

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography component="span" style={{ marginRight: '10px' }}>
                <b>Sendout Overview</b>
            </Typography>
            <div className={classes.flex}>
                {
                    attendeeDetails && attendeeDetails.outbound_overview && attendeeDetails.outbound_overview.event_calendar_ph_email.length ? (
                        <OutboundOverview item={attendeeDetails.outbound_overview.event_calendar_ph_email} name="Calendar Placeholder Invitation" classes={classes} />
                    ) : (
                        <OutboundOverview name="Calendar Placeholder Invitation" classes={classes} />
                    )
                }
                {
                    attendeeDetails && attendeeDetails.outbound_overview && attendeeDetails.outbound_overview.event_calendar_email.length ? (
                        <OutboundOverview item={attendeeDetails.outbound_overview.event_calendar_email} name="Calendar Invitation" classes={classes} />
                    ) : (
                        <OutboundOverview name="Calendar Invitation" classes={classes} />
                    )
                }
                {
                    attendeeDetails && attendeeDetails.outbound_overview && attendeeDetails.outbound_overview.event_invitation_email.length ? (
                        <OutboundOverview item={attendeeDetails.outbound_overview.event_invitation_email} name="Invitation Email" classes={classes} />
                    ) : (
                        <OutboundOverview name="Invitation Email" classes={classes} />
                    )
                }
                {
                    attendeeDetails && attendeeDetails.outbound_overview && attendeeDetails.outbound_overview.event_reminder_email.length ? (
                        <OutboundOverview item={attendeeDetails.outbound_overview.event_reminder_email} name="Reminder Email" classes={classes} />
                    ) : (
                        <OutboundOverview name="Reminder Email" classes={classes} />
                    )
                }
                {
                    attendeeDetails && attendeeDetails.outbound_overview && attendeeDetails.outbound_overview.event_reminder_sms.length ? (
                        <OutboundOverview item={attendeeDetails.outbound_overview.event_reminder_sms} name="Reminder SMS" classes={classes} />
                    ) : (
                        <OutboundOverview name="Reminder SMS" classes={classes} />
                    )
                }
                {
                    attendeeDetails && attendeeDetails.outbound_overview && attendeeDetails.outbound_overview.event_summary_email.length ? (
                        <OutboundOverview item={attendeeDetails.outbound_overview.event_summary_email} name="Summary Email" classes={classes} />
                    ) : (
                        <OutboundOverview name="Summary Email" classes={classes} />
                    )
                }
            </div>
        </div>
    );
}