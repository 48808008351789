import * as React from 'react';
import { Formik, Form } from 'formik';
import {
  Card,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import InputElement from '../../Common/InputElement';

const useStyles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: '16px',
    marginTop: 0
  }
});

const fieldList = [
  {
    title: 'Start Time',
    name: 'product_events_start',
    type: 'datetime-local'
  },
  {
    title: 'End Time',
    name: 'product_events_stop',
    type: 'datetime-local'
  },
  {
    title: 'Location',
    name: 'product_location',
    type: 'textfield'
  },
  {
    title: 'Address',
    name: 'product_address',
    type: 'textfield'
  },
  {
    title: 'Zip Code',
    name: 'product_zip_code',
    type: 'textfield'
  },
  {
    title: 'Country',
    name: 'product_country',
    type: 'textfield',
    disabled: true,
    disableButton: true
  },
  {
    title: 'Default Attendance',
    name: 'product_default_attendance_value',
    type: 'textfield'
  },
  {
    title: 'Network Coordinator',
    name: 'product_network_coordinator',
    type: 'textfield'
  },
  {
    title: 'Network Director 1',
    name: 'product_network_director_1',
    type: 'textfield'
  },
  {
    title: 'Network Director 2',
    name: 'product_network_director_2',
    type: 'textfield'
  },
  {
    title: 'Sales Rep',
    name: 'product_sales_rep',
    type: 'textfield'
  }
]

class ProductInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values
    this.props.onClick({ [name]: formData[name] });
  }
  render() {
    const { classes, productDetails } = this.props;

    return (
      <React.Fragment>
        <Card className={classes.paper}>
          <Typography style={{marginBottom: '30px'}} component="h6" variant="h6" color="textPrimary" gutterBottom>
            Product Specific Information
          </Typography>
          <Formik
            innerRef={this.formRef}
            initialValues={productDetails}
            validate={values => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.props.saveNews(values);
              }, 500);
            }}
            render={({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form>
                {
                  fieldList.map((item, index) => {
                    return <InputElement {...item} key={index} onSaveClick={this.handleSaveClick} />
                  })
                }
              </Form>
            )}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ProductInformationForm);
