import * as React from 'react';
import { Card, CardContent, CardMedia, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { Language, BusinessCenter, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt,OpenInNewSharp } from '@mui/icons-material';
import logo from "../../../Assets/icons/f5-logo.svg";

export default function ParticipantCard(props) {
    const { classes, person, personDetails, setBookMeetingModal, status, booking } = props;

    return (
        <Card className={classes.card}>
            <CardMedia
                className={classes.cardMedia}
                component="img"
                alt=""
                image={`https://erp.f5.dk/uploads/images/persons/${personDetails.person_token}.jpg`}
            />
            <CardContent className={classes.cardContent}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6" component="div">
                            {personDetails.person_firstname} {personDetails.person_lastname}
                        </Typography>
                        {
                            personDetails.title && (
                                <Typography variant="body2" color="site.main">
                                    <BusinessCenter sx={{ color: 'icon.main', fontSize: "20px" }} />
                                    <span style={{ marginLeft: '8px' }}>{personDetails.title}</span>
                                </Typography>
                            )
                        }
                        {
                            personDetails.person_region && (
                                <Typography variant="body2" color="site.main" className={classes.flexVertical}>
                                    <Language sx={{ color: 'icon.main', fontSize: "20px" }} />
                                    <span style={{ marginLeft: '8px' }}>{personDetails.person_region}</span>
                                </Typography>
                            )
                        }
                    </div>
                    {/* {
                        booking && (
                            <div>
                                <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }} onClick={() => setBookMeetingModal(true)}>Book meeting</Button>
                            </div>
                        )
                    } */}
                </div>
                <div className={classes.flexCenter}>
                    {
                        personDetails.mobile &&
                        <Typography variant="body2" color="site.main">
                            <a href={`callto:${personDetails.mobile}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                <PhoneAndroid sx={{ color: 'icon.main', fontSize: "20px" }} />
                                <span style={{ marginLeft: '8px' }}>
                                    {personDetails.mobile}
                                </span>
                            </a>
                        </Typography>
                    }
                    {
                        personDetails.email &&
                        <Typography variant="body2" color="site.link">
                            <a href={`mailto:${personDetails.email}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                <Email sx={{ color: 'icon.main', fontSize: "20px" }} />
                                <span style={{ marginLeft: '8px' }}>
                                    {personDetails.email}
                                </span>
                            </a>
                        </Typography>
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Typography variant="body2" color="site.main">
                        <a target="_blank" href={personDetails.person_linkedin_url}><LinkedIn sx={{ color: 'icon.linkedin', fontSize: "44px" }} /></a>
                    </Typography>
                    <Typography variant="body2" color="site.main">
                        <a target="_blank" href={"https://erp.f5.dk/#/contacts/persons/" + personDetails.person_id}>
                            <img alt="" src={logo} className="logo-link"></img>
</a>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
}
