import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import { selectors as adminSelectors, actions as adminActions } from '../../../Ducks/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';

function AnchorTemporaryDrawer(props) {
  const { releaseNote,drawerStatus,toggleBar } = props;

  return (
    <div>
          
      <Dialog onClose={() =>toggleBar()} 
        open={drawerStatus}
      >
      <DialogTitle id="scroll-dialog-title">Release Notes
      <IconButton aria-label="close" onClick={() =>toggleBar()} style={{ position: 'absolute', top: '8px', right: '8px' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }} style={{minWidth:"500px"}}>
        {!releaseNote.length ? 'No Record' :
        releaseNote && releaseNote.map(item => 
              <>
              <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar style={{background: '#029b18'}}>
                      <AssignmentIcon />
                    </Avatar>                
                  </ListItemAvatar>
                  <ListItemText
                  primary={`${item.note_title} - ${moment(item.created_at).format('DD/MM/YYYY')}`}
                  secondary={
                    <div dangerouslySetInnerHTML={{ __html: item.note_description }} />
                  }
                  />
              </ListItem>
              <Divider component="li" />
              </>
        )}
        </List>
      </DialogContent>
    </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  releaseNote: adminSelectors.releaseNotes(state),
  drawerStatus: adminSelectors.barState(state),
});
  
const mapDispatchToProps = (dispatch) => bindActionCreators(
{
  toggleBar: adminActions.toggleBar,
},
dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(AnchorTemporaryDrawer);
