import React from "react";
import {Grid} from "@mui/material";
import errorBg from '../../../Assets/images/error.gif'

class ErrorBoundaryInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidUpdate(prevProps, _previousState) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({hasError: false});
        }
    }

    componentDidCatch(_error, _errorInfo) {
        this.props.setHasError(true);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Grid container spacing={2} sx={{background: '#fff', padding: 5}}>
                    <Grid item xs={12}>
                        <div style={{backgroundImage: `url(${errorBg})`}} className="four_zero_four_bg">
                            <h1 className="text-center ">Oops</h1>
                        </div>

                        <div className="contant_box_404">
                            <h3 className="h2">
                                Something went wrong..!
                            </h3>
                        </div>
                    </Grid>
                </Grid>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundaryInner;