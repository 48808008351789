import React from 'react';
import { Select } from "formik-material-ui";
import { Field } from "formik";
import { MenuItem } from "@mui/material";

function SelectBox(props) {
    const { name, multiple, type, label, labelId, options,onChange } = props;
    const [selectBoxOptions, setSelectBoxOptions] = React.useState([])

    React.useEffect(() => {
        if (options) {
            type ? setSelectBoxOptions(options[type]) : setSelectBoxOptions(options)
        }
    }, [options])

    return (
        <Field
            component={Select}
            multiple={multiple}
            fullWidth size="large"
            name={name}
            label={label}
            labelId={labelId}
            inputProps={{
                id: labelId,
            }}
            onChange={onChange}
        >
            {!multiple && <MenuItem value="">-- Select --</MenuItem>}
            {selectBoxOptions?.map((option) => (
                <MenuItem
                    key={option.id}
                    value={option.id}
                >
                    {option.title}
                </MenuItem>
            ))}
        </Field>
    );
}

export default SelectBox;