import React, { useCallback,useMemo, useState } from 'react'
import GridElement from '../../Common/GidElement';
import { bindActionCreators } from 'redux';
import { selectors as adminSelectors, actions as adminsActions } from '../../../Ducks/admin';
import { withStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { Delete } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { fetchDelete } from '../../../API/util';


const useStyles = () => ({});

const BouncedEmail = (props) => {
  const { getBouncedEmails,loading,bouncedEmailData } = props;
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [inputKey, setInputKey] = useState(Date.now());


  const cols = useMemo(() => [
    {
      Header: 'Email',
      accessor: 'email',
      column: 'email',
      minWidth: '150',
      filter: 'fuzzyText'
    },
    {
      Header: 'Type',
      accessor: 'type',
      column: 'type',
      minWidth: '150',
      filter: 'fuzzyText'
    },
    {
      Header: 'Status',
      accessor: 'status',
      column: 'status',
      minWidth: '150',
      filter: 'fuzzyText'
    },
    {
      Header: 'Created By',
      accessor: 'created_by',
      column: 'created_by',
      minWidth: '150',
      filter: 'fuzzyText',
      Cell: ({row}) => (
        `${row.original.user_firstname} ${row.original.user_lastname}`
      ),

    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      column: 'created_at',
      minWidth: '150',
      filter: 'fuzzyText'
    },
    {
      Header: 'Action',
      accessor: 'action',
      column: 'action',
      minWidth: '150',
      filter: 'fuzzyText',
      Cell: ({row}) => (
        <Delete onClick={() => handleDelete(row.original.id)} style={{color: 'red',cursor:'pointer'}}/>
      ),
    }
  ],[]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await fetchDelete(`/bounced-emails/${id}`)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Bounced email has been deleted.",
              icon: "success"
            });

            setInputKey(Date.now()); 
          });
        } catch (error) {
          return error;
        } 
      }
    });
  }

  const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {

    const _columns = cols.filter(item => item.column != 'action').map((item) => item.column);
    _columns.push('user_firstname','user_lastname');

    let query = "", api = "", sort = "", filterPrefix = "";

    filters.forEach((item, _index) => {
        const _filterColumn = columns.filter((column) => column.accessor === item.id);
        if(_filterColumn[0].column.includes(".")) {
            filterPrefix = _filterColumn[0].column.split(".")[0]
        } else {
            filterPrefix = "bounced_emails"
        }
        query = query + `&contains[${filterPrefix}.${item.id}]=${item.value}`
    });

    //Check sorting
    if (sortBy.length) {
        let _sortByPrefix = "", _sortByColumn = columns?.filter((column) => column.accessor === sortBy[0].id);
        if (_sortByColumn[0]?.column.includes(".")) {
            _sortByPrefix = _sortByColumn[0].column.split(".")[0]
            _sortByPrefix = `${_sortByPrefix}.`
        }
        sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${_sortByPrefix}${sortBy[0].id}`;
    }
    api = `results=${pageSize}&columns=${_columns}&offset=${pageIndex + 1}${query}${sort}`
    
    getBouncedEmails(api);
  },[inputKey]);

  return (
    <>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <h2 className="page-title">Bounced Emails</h2>
      </div>
      <GridElement
        loading={loading}
        columns={cols}
        data={bouncedEmailData?.main?.length ? bouncedEmailData?.main : []}
        fetchData={fetchData}
        pageCount={bouncedEmailData?.offset?.highest_offset}
        rowsCount={bouncedEmailData?.offset?.rows_count}
        filters={filters}
        setFilters={setFilters}
        controlledPageIndex={controlledPageIndex}
        setControlledPageIndex={setControlledPageIndex}
        initialSortBy="id"
        handleRowClick={() => {}}
      /> 
    </>  
  )
}

const mapStateToProps = (state) => ({
  loading: adminSelectors.isLoading(state),
  bouncedEmailData: adminSelectors.bouncedEmails(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
      getBouncedEmails: adminsActions.getBouncedEmails
  },
  dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(useStyles)(BouncedEmail));