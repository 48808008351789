import {Autocomplete, Button, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MULTIPLE_CHOICE, SINGLE_CHOICE} from "../../Utils/constants";
import TodoActionItemOption from "./TodoActionItemOption";
import {ErrorMessage} from "formik";
import {Add, AddBox} from "@mui/icons-material";

const ManageTodoActionItem = (props) => {
    const {
        formRef,
        todoActionItemOptions,
        values,
        setFieldValue,
        errors,
        submitCount,
        todo,
        setShowAddTodoActionItem
    } = props;

    const [singleChoice, setSingleChoice] = useState(false);
    const [multipleChoice, setMultipleChoice] = useState(false);
    const [singleChoiceIndex, setSingleChoiceIndex] = useState('');
    const [multipleChoiceIndex, setMultipleChoiceIndex] = useState('');
    const [todoActionItems, setTodoActionItems] = useState([]);

    useEffect(() => {
        const selected = todoActionItems ? todoActionItems.map((todoActionItem, index) => {
            if (todoActionItem.title === SINGLE_CHOICE) {
                setSingleChoiceIndex(index);
            } else if (todoActionItem.title === MULTIPLE_CHOICE) {
                setMultipleChoiceIndex(index);
            }
            return todoActionItem.title
        }) : "";

        const singleChoiceFound = selected.includes(SINGLE_CHOICE);
        const multipleChoiceFound = selected.includes(MULTIPLE_CHOICE);

        if (multipleChoiceFound) {
            setMultipleChoice(true);
        } else {
            setMultipleChoice(false);
        }

        if (singleChoiceFound) {
            setSingleChoice(true);
        } else {
            setSingleChoice(false);
        }

        if (!singleChoiceFound && !multipleChoiceFound) {
            setSingleChoice(false);
            setMultipleChoice(false);
        }
    }, [todoActionItems]);

    useEffect(() => {
        if (todo?.actions) {
            const selected = todoActionItemOptions.filter((todoActionOption) => values.actions.map(action => action.action_id).includes(todoActionOption.id))

            setTodoActionItems(selected);
        }
    }, [values]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{margin: "auto", paddingTop: 35}}>
                    <Typography
                        variant="span"
                        component="span">
                        Actions*
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{margin: "auto", paddingTop: 35}}>
                    <Autocomplete
                        id='todo-action-items'
                        name='actions'
                        multiple
                        filterSelectedOptions
                        disablePortal
                        margin='normal'
                        size='small'
                        options={todoActionItemOptions}
                        value={todoActionItemOptions.filter((todoActionOption) => values.actions.map(action => action.action_id).includes(todoActionOption.id))}
                        getOptionLabel={(option) => option.title}
                        onChange={(_event, newValues) => {
                            let _actions = newValues.map((newValue) => {
                                let actionExists = values.actions.find((action) => action.action_id === newValue.id);
                                if (actionExists) {
                                    return actionExists;
                                } else {
                                    return {"action_id": newValue.id}
                                }
                            });

                            setFieldValue('actions', _actions);
                            setTodoActionItems(newValues);

                            const selected = newValues ? newValues.map((newValue, index) => {
                                if (newValue.title === SINGLE_CHOICE) {
                                    setSingleChoiceIndex(index);
                                } else if (newValue.title === MULTIPLE_CHOICE) {
                                    setMultipleChoiceIndex(index);
                                }
                                return newValue.title
                            }) : "";

                            const singleChoiceFound = selected.includes(SINGLE_CHOICE);
                            const multipleChoiceFound = selected.includes(MULTIPLE_CHOICE);

                            if (multipleChoiceFound) {
                                setMultipleChoice(true);
                            } else {
                                setMultipleChoice(false);
                            }

                            if (singleChoiceFound) {
                                setSingleChoice(true);
                            } else {
                                setSingleChoice(false);
                            }

                            if (!singleChoiceFound && !multipleChoiceFound) {
                                setSingleChoice(false);
                                setMultipleChoice(false);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Todo Action Item*'
                                error={submitCount > 0 && errors.action}
                            />
                        )}
                    />
                    <div
                        style={{
                            color: "red",
                            fontSize: "0.75rem",
                            margin: "4px 14px 0px",
                        }}
                    >
                        <ErrorMessage name={"action"}/>
                    </div>
                </Grid>
                <Grid item xs={1} style={{paddingLeft: "0px"}}>
                    <Tooltip title='Add new action todo item' arrow placement='top'>
                        <IconButton
                            variant='contained'
                            color='primary'
                            style={{marginTop: "25px"}}
                            onClick={() => {
                                setShowAddTodoActionItem(true);
                            }}><AddBox color='white'/></IconButton>
                    </Tooltip>

                </Grid>
            </Grid>
            {
                singleChoice &&
                <Grid container spacing={2}>
                    <Grid item xs={3} style={{marginLeft: "auto", marginRight: "auto", marginTop: "45px"}}>
                        <Typography
                            variant="span"
                            component="span"
                        >
                            {SINGLE_CHOICE}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} style={{margin: "auto", paddingTop: 35}}>
                        <TodoActionItemOption formRef={formRef} values={values} actionItem={SINGLE_CHOICE}
                                              actionItemIndex={singleChoiceIndex}
                                              setFieldValue={setFieldValue}
                                              todo={todo}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            }
            {
                multipleChoice &&
                <Grid container spacing={2}>
                    <Grid item xs={3} style={{marginLeft: "auto", marginRight: "auto", marginTop: "45px"}}>
                        <Typography
                            variant="span"
                            component="span">
                            {MULTIPLE_CHOICE}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} style={{margin: "auto", paddingTop: 35}}>
                        <TodoActionItemOption formRef={formRef} values={values} actionItem={MULTIPLE_CHOICE}
                                              actionItemIndex={multipleChoiceIndex}
                                              setFieldValue={setFieldValue}
                                              todo={todo}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            }
        </>

    );
}

export default ManageTodoActionItem;