import {Tooltip} from "@mui/material";

export function replaceMergeTag(replaceText, mergeTag, replaceWith) {
    return replaceText.replace(mergeTag, replaceWith);
}

export function showEllipsis(text, length) {
    return (
        <Tooltip title={text} placement="top">
            <p>{text.substring(0, length - 3)}...</p>
        </Tooltip>
    )
}