import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Autocomplete,
    TextField as MaterialTextField,
    DialogTitle,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DateTimePicker from '../../../Common/CalendarWidget/DateTimePicker';
// import { toDateTime } from '../../Utils/dateUtils';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as accountSelectors, actions as accountActions } from '../../../../Ducks/account';
import { selectors as ContactsSelectors, actions as contactsActions } from "../../../../Ducks/contacts";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import TimePickerWidget from '../../../Common/TimePickerWidget';
import { fetchGet } from '../../../../API/util';

const editorConfiguration = {
    plugins: [List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
    toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    placeholder: "Add notes here ..."
};

const types = [
    'Welcome Call',
    'Survey'
]

const useStyles = theme => ({
    select: {
        "& ul": {
            display: "grid",
        }
    },
});

function PlannedCallModal(props) {
    const { classes, isUpcomingEvent, person, users, createPlannedCall, onGoingCase, loggedin_user, getPlannedCallTypes, plannedCallTypes, getContactDetails, columns, query, edit, row, getPlannedCall, plannedCall, updatePlannedCall, reset } = props;
    const [date, setDate] = React.useState(new Date());
    const [type, setType] = React.useState("");
    const [user, setUser] = React.useState("");
    const [content, setContent] = React.useState("");

    React.useEffect(() => {
        getPlannedCallTypes();
    }, []);

    React.useEffect(() => {
        if (row && edit) {
            getPlannedCall(row.planned_call_id);
        }
    }, [row])

    React.useEffect(() => {
        if (!edit) {
            restFields();
        }
    }, [reset])

    React.useEffect(() => {
        if (plannedCall) {
            // Set data here
            const _parsed = Date.parse(plannedCall[0].planned_call_due_time)
            const _user = users.filter((item) => item.user_id === plannedCall[0].user_id)[0];
            setDate(new Date(_parsed));
            setType(plannedCall[0].planned_call_reason);
            setUser(_user);

        }
    }, [plannedCall]);

    const handleClose = () => {
        if (!edit) {
            restFields();
        }
        props.handleClose();
    };

    const handleSubmit = () => {
        const data = {
            person_id: person.person_id,
            user_id: user.user_id,
            purchase_id: person.purchase_id,
            planned_call_priority: 200,
            planned_call_hidden: 0,
            planned_call_reason: type,
            planned_call_creation_timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            planned_call_due_time: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            planned_call_sequence_number: 1,
        };

        if (edit) {
            updatePlannedCall({ person_id: person.person_id, planned_call_id: row.planned_call_id, data });
        } else {
            createPlannedCall({ person_id: person.person_id, data });
        }
        handleClose();
    }

    const restFields = () => {
        setDate(new Date());
        setType("");
        setUser("");
        setContent("")
    }

    return (
        <div>
            <Dialog width={700} open={props.open} onClose={handleClose}>
                <DialogTitle style={{ paddingTop: '10px', paddingBottom: '10px', boxShadow: '0px 0px 20px 2px #ebebeb' }}>{edit ? "Update" : "Create"} Follow up Action</DialogTitle>
                <DialogContent>
                    <div>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            value={user}
                            id="combo-box-demo"
                            options={users}
                            groupBy={(option) => option.type}
                            sx={{ width: "100%", margin: '10px 0' }}
                            onChange={(event, value) => {
                                console.log("user", value)
                                setUser(value)
                            }}
                            renderOption={(props, option, state) => {
                                return (
                                    <div {...props}>
                                        <div style={{ textAlign: 'left', padding: '0', fontSize: '13px', borderBottom: '1px solid #eaeaea' }}>
                                            <span>{`${option.label}`}</span>
                                        </div>
                                    </div>
                                );
                            }}
                            renderInput={(params) => <MaterialTextField {...params} label="User" />}
                        />
                    </div>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="reason-label">Reason</InputLabel>
                            <Select
                                labelId="reason-label"
                                id="type"
                                value={type}
                                label="Reason"
                                onChange={(e) => setType(e.target.value)}
                            >
                                {
                                    plannedCallTypes && plannedCallTypes.map(item => <MenuItem value={item.planned_call_type}>{item.planned_call_type}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date"
                                inputFormat="yyyy/MM/dd"
                                value={date}
                                onChange={(value) => setDate(value)}
                                renderInput={(params) => <MaterialTextField fullWidth size='small' {...params} />}
                            />
                        </LocalizationProvider>
                        <div style={{ display: 'flex', justifyContent: 'center', zIndex: 100, alignItems: 'center', marginLeft: '10px' }}>
                            <TimePickerWidget min={0} max={23} handleChange={(n) => (setDate(moment(date).set({ hours: n })))} number={moment(date).hours()} />
                            <h1 style={{ padding: "0px 8px", margin: 0 }}>:</h1>
                            <TimePickerWidget min={0} max={59} handleChange={(n) => (setDate(moment(date).set({ minute: n })))} number={moment(date).minutes()} />
                        </div>
                    </div>
                    <div>
                        <CKEditor
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={content}
                            onReady={(editor) => {
                                console.log("Ready.", editor)
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setContent(data)
                            }}
                            onBlur={(event, editor) => {
                                console.log("Blur.", editor)
                            }}
                            onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        {
                            !isUpcomingEvent &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                                style={{ marginRight: '12px' }}
                            >
                                {'Confirm'}
                            </Button>
                        }
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
const mapStateToProps = (state) => ({
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    noteTypes: eventsOverviewSelectors.noteTypes(state),
    plannedCallTypes: eventsOverviewSelectors.plannedCallTypes(state),
    plannedCall: eventsOverviewSelectors.plannedCall(state),
    onGoingCase: eventsOverviewSelectors.onGoingCase(state),
    loggedin_user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        registerOnGoingCase: eventsOverviewActions.registerOnGoingCase,
        addHistoryLog: eventsOverviewActions.addHistoryLog,
        getNoteTypes: eventsOverviewActions.getNoteTypes,
        getPlannedCallTypes: eventsOverviewActions.getPlannedCallTypes,
        createPlannedCall: eventsOverviewActions.createPlannedCall,
        updatePlannedCall: eventsOverviewActions.updatePlannedCall,
        getPlannedCall: eventsOverviewActions.getPlannedCall,
        getContactDetails: contactsActions.getContactDetails,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(PlannedCallModal));