import {fetchGet, fetchPatch, fetchPost} from "./util";

export const getPersonInfo = (personId, searchQuery = '') => {
    return fetchGet(`/persons/job-changes/${personId}/info${searchQuery}`).then(res => res)
}

export const getNewMembers = ({results, columns, offset, filter, sort}) => {
    return fetchGet(`/persons/job-changes/search?results=${results}&columns=${columns}&offset=${offset}${filter}${sort}`).then(res => res);
}

export const changeJob = (personId, data) => {
    return fetchPost(`/persons/job-changes/${personId}`, data).then(res => res);
}

export const getPendingPersonJobChange = (personId) => {
    return fetchGet(`/persons/job-changes/${personId}/pending`).then(res => res);
}

export const getChangePersonJobs = ({results, columns, offset, filter, sort}) => {
    return fetchGet(`/persons/job-changes?results=${results}&columns=${columns}&offset=${offset}${filter}${sort}`).then(res => res);
}

export const delayTransferMembership = (personJobChangeId, data) => {
    return fetchPatch(`/persons/job-changes/${personJobChangeId}`, data).then(res => res);
}

export const cancelPurchase = (personId, data) => {
    return fetchPost(`/persons/job-changes/${personId}/cancel`, data).then(res => res);
}