// export const tabList = [
//     'overview',
//     'company',
//     'person',
//     'history',
//     'actions',
//     'product',
//     'deal',
//     'active call'
// ]

export const tabListData = [
    {
        name: 'overview',
        disabled: false,
        hidden: false,
    },
    {
        name: 'company',
        disabled: false,
        hidden: false,
    },
    {
        name: 'person',
        disabled: false,
        hidden: false,
    },
    {
        name: 'history',
        disabled: false,
        hidden: false,
    },
    {
        name: 'actions',
        disabled: false,
        hidden: false,
    },
    {
        name: 'product',
        disabled: false,
        hidden: false,
    },
    {
        name: 'deal',
        disabled: false,
        hidden: false,
    },
    {
        name: 'active call',
        disabled: false,
        hidden: true,
    },
]

export const actionsTabList = [
    'email',
    'sms',
    'meeting',
    'call',
]

export const country = {
    'DK': 'Denmark'
}