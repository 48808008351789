import {useState, useEffect} from 'react';
import {Autocomplete, TextField as MaterialTextField} from '@mui/material';
import {actions as eventsOverviewActions} from '../../../../Ducks/eventsOverview';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


const TeamsDropDowns = (props) => {
    const [parentDetails, setParentDetails] = useState([]);

    const {
        teamDetails,
        getTeamDetails,
        dropDownKey,
        resetTeamDetails,
        getCasesOverview,
        filterWithTeamsCallback
    } = props;

    useEffect(() => {
        let childTeams = teamDetails?.child_team?.map(item => ({...item, type: "Child Teams"}));
        let parentTeamMembers = teamDetails?.members?.map(item => ({...item, type: "Members"}))
        let finalArray = childTeams?.concat(parentTeamMembers);

        let parentTeamDetails = finalArray?.map((item, index) => {
            item.label = item.team_name ? item.team_name : item.user_firstname + " " + item.user_lastname;
            item.key = index
            return item;
        });
        setParentDetails(parentTeamDetails);
    }, [teamDetails])

    const getRecords = (value, teamId) => {
        let key;

        if (value !== undefined && value !== null) {
            key = Object.keys(value);

            if (key && key[0] === 'team_id') {
                resetTeamDetails(dropDownKey);
                getTeamDetails({"team_id": value?.team_id});

                if (filterWithTeamsCallback) {
                    filterWithTeamsCallback({"team_id": value?.team_id})
                } else {
                    let query = `equals[team_id]=${value?.team_id}`;
                    getCasesOverview(query);
                }

            } else {
                resetTeamDetails(dropDownKey);

                if (filterWithTeamsCallback) {
                    filterWithTeamsCallback({"user_id": value?.user_id})
                } else {
                    let query = `equals[user_id]=${value?.user_id}`;
                    getCasesOverview(query);
                }
            }
        }

        if (value == null) {
            resetTeamDetails(dropDownKey);

            if (filterWithTeamsCallback) {
                filterWithTeamsCallback({"team_id": teamId});
            } else {
                let query = `equals[team_id]=${teamId}`
                getCasesOverview(query);
            }
        }

        if (!filterWithTeamsCallback) {
            if (value == null && dropDownKey == 0) {
                if (filterWithTeamsCallback) {
                    filterWithTeamsCallback();
                } else {
                    getCasesOverview();
                }
            }
        }
    }

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={parentDetails}
            groupBy={(option) => option.type}
            sx={{width: 200, marginRight: '10px'}}
            onChange={(_event, value, reason) => {
                if (reason === 'clear') {
                    getRecords(null, parentDetails[0].parent_id ? parentDetails[0].parent_id : parentDetails[0].team_id)
                } else {
                    getRecords(value);
                }
            }}
            renderOption={(props, option) => {
                return (
                    <div {...props} key={option.key}>
                        <div style={{
                            textAlign: 'left',
                            padding: '0',
                            fontSize: '13px',
                            borderBottom: '1px solid #eaeaea'
                        }}>
                            <span>{`${option.label}`}</span>
                        </div>
                    </div>
                );
            }}
            renderInput={(params) => <MaterialTextField {...params} label="Team Detail"/>}
        />
    )
}

const mapStateToProps = (_) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getCasesOverview: eventsOverviewActions.getCasesOverview,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDropDowns);