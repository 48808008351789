import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import CalendarPicker from '@mui/lab/CalendarPicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';


export default function TimePickerWidget(props) {
    const { number } = props;
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        setValue(number);
    }, [number])

    const handleChange = (n) => {
        if (parseInt(n) > props.max) {
            n = props.max;
        }
        if (parseInt(n) < props.min) {
            n = props.min;
        }
        setValue(n);
        props.handleChange(n);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {/* <IconButton disabled={props.max <= number} style={{ width: '40px', height: '40px', marginLeft: "6px" }} onClick={() => props.max > number && handleChange(number + 1)}>
                <KeyboardArrowUpIcon style={{ width: '40px', height: '40px' }} />
            </IconButton> */}
            <TextField
                type="number"
                className="time-input"
                InputProps={{
                    inputProps: {
                        max: props.max, min: props.min
                    }
                }}
                value={value}
                onChange={(e) => {
                    const _value = parseInt(e.target.value);
                    if (!_value || (_value >= props.min && _value <= props.max)) {
                        setValue(_value);
                        handleChange(_value);
                    }
                }}
                size={'small'}
                style={{ width: '50px' }}
            />
            {/* <IconButton disabled={props.min >= number} style={{ width: '40px', height: '40px', marginLeft: "6px" }} onClick={() => props.min < number && handleChange(number - 1)}>
                <KeyboardArrowDownIcon style={{ width: '40px', height: '40px' }} />
            </IconButton> */}

        </div>
    )
}
