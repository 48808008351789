import {
    Box,
    Checkbox, FormControlLabel, FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Info} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";


function TodoActionTextInput(props) {
    const {todoId, actionId, handleTextInputCallback} = props;
    const [text, setText] = useState('');

    return (
        <Grid item sx={{padding: '15px'}}>
            <TextField
                name='text'
                size='small'
                onInput={(event) => setText(event.target.value)}
            />
            <LoadingButton
                variant='outlined'
                onClick={() => handleTextInputCallback(todoId, actionId, text)}
                sx={{marginLeft: '10px'}}>Save</LoadingButton>
        </Grid>
    );
}

function TodoActionSingleRadioGroup(props) {
    const {todoId, actionId, handleSingleRadioClickCallback} = props;
    const [responseYesOrNo, setResponseYesOrNo] = useState('');

    return (
        <Grid item sx={{padding: '10px', display: 'flex'}}>
            <RadioGroup
                row
                size="small"
                aria-labelledby='todo-overview-single-radio-buttons-group-label'
                name='yes-no'
                onChange={(event) => {
                    setResponseYesOrNo(event.target.value);
                }}>
                <FormControlLabel
                    value='Yes'
                    control={<Radio/>}
                    label='Yes'/>
                <FormControlLabel
                    value='No'
                    control={<Radio/>}
                    label='No'/>
            </RadioGroup>
            <LoadingButton
                variant='outlined'
                onClick={() => handleSingleRadioClickCallback(todoId, actionId, responseYesOrNo)}
                sx={{marginLeft: '10px'}}>Save</LoadingButton>
        </Grid>
    )
}

function TodoActionMultiRadioGroup(props) {
    const {action, todoId, handleMultiRadioClickCallback} = props;
    const [selected, setSelected] = useState('');

    return (
        <Grid item sx={{padding: '15px'}}>
            <RadioGroup
                row
                size="small"
                aria-labelledby='todo-overview-multi-radio-buttons-group-label'
                name={action.options[0].key}
                onChange={(value) => {
                    setSelected(value);
                }}>
                {
                    action.options.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            name={option.key}
                            value={option.value}
                            control={<Radio/>}
                            label={option.value}/>
                    ))
                }
            </RadioGroup>
            <LoadingButton
                variant='outlined'
                onClick={() => handleMultiRadioClickCallback(todoId, action.action_id, selected)}
                sx={{marginLeft: '10px'}}>Save</LoadingButton>
        </Grid>
    )
}

function TodoActionTextArea(props) {
    const {todoId, actionId, handleTextAreaCallback} = props;
    const [text, setText] = useState('');

    return (
        <Grid item sx={{padding: '15px'}}>
            <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                onChange={(event) => setText(event.target.value)}
            />
            <LoadingButton
                variant='outlined'
                onClick={() => {
                    handleTextAreaCallback(todoId, actionId, text)
                }}
                sx={{marginTop: '10px'}}>Save</LoadingButton>
        </Grid>
    )
}

function TodoActionCheckbox(props) {
    const {todoId, action, handleCheckboxChangeCallback} = props;
    const [selected, setSelected] = useState([]);
    return (
        <Grid item sx={{padding: '15px'}}>
            <FormGroup>
                {
                    action?.options.map((option) => (
                        <FormControlLabel
                            control={<Checkbox color="primary"/>}
                            label={option.value}
                            labelPlacement="end"  // Positions label to the right of the checkbox
                            onChange={(event) => {
                                const {name, checked} = event.target;
                                let prevSelected = [...selected];
                                let nameFound = false;
                                prevSelected.forEach((selectedOption) => {
                                    if (selectedOption.key == name) {
                                        selectedOption.checked = checked;
                                        nameFound = true;
                                    }
                                });

                                if (!nameFound) {
                                    prevSelected.push({'key': name, 'checked': nameFound});
                                }

                                setSelected(prevSelected);
                            }}
                        />
                        // <Checkbox
                        //     name={option.key}
                        //     value={option.value}
                        //     label={option.value}

                    ))
                }
            </FormGroup>

            <LoadingButton
                variant='outlined'
                onClick={() => handleCheckboxChangeCallback(todoId, action.action_id, selected)}>Save</LoadingButton>
        </Grid>
    );
}

const TodoOverviewTab = () => {
    const [personTodos, setPersonTodos] = useState([
        {
            id: 1,
            title: 'Make a call',
            description: 'Any description',
            actions: [
                {
                    'action_id': 3,
                    'title': 'Planned Action',
                    'description': 'Create a planned action in ERP',
                    'type': 'erp_action',
                    'completed': false
                }
            ],
            kpi: {id: 1, title: 'Number of Meetings, Contact/Dialog', color_code: '#00ff00'},
            completed: false,
            due_date: '10/11/2024'
        },
        {
            id: 2,
            title: 'Make If interested',
            description: 'Mark if customer is interested',
            actions: [
                {
                    'action_id': '6',
                    'title': 'Yes/No',
                    'description': 'Show a list of options to choose from',
                    'type': 'single_radio',
                    'completed': false
                }
            ],
            kpi: {id: 1, title: 'Unity', color_code: '#ffff00'},
            completed: false,
            due_date: '10/11/2024'
        },
        {
            id: 3,
            title: 'Create a note in history',
            description: 'Create note in history',

            actions: [
                {
                    'action_id': 1,
                    'title': 'History Note Input',
                    'description': 'Show an input field to enter text',
                    'type': 'input',
                    'completed': false
                }
            ],
            kpi: {id: 1, title: 'Number of Meetings', color_code: '#e69138'},
            completed: false,
            due_date: '10/11/2024'
        },
        {
            id: 4,
            title: 'Show text with customer',
            description: 'Add customers comments on review',
            actions: [
                {
                    'action_id': 2,
                    'title': 'Just Show Text',
                    'description': 'Displays text only',
                    'type': 'text_only',
                    'completed': false
                },
                {
                    'action_id': 4,
                    'title': 'Multiple Choice',
                    'type': 'checkbox',
                    'options': [
                        {"key": "checkbox_option_a", "value": "I like the event"},
                        {"key": "checkbox_option_b", "value": "I do not like the event"},
                        {"key": "checkbox_option_c", "value": "May be I will attend next event"}
                    ],
                    'completed': false
                }
            ],
            kpi: {id: 1, title: 'Number of Meetings', color_code: '#ffff00'},
            completed: false,
            due_date: '10/11/2024'
        },
    ]);

    const handleTextInputCallback = (todoId, actionId, text) => {
        if (text?.length) {
            markDone(todoId, actionId);
        }
    }

    const handleSingleRadioClickCallback = (todoId, actionId, responseYesOrNo) => {
        if (responseYesOrNo?.length) {
            markDone(todoId, actionId);
        }
    }

    const handleMultiRadioClickCallback = (todoId, actionId, selected) => {
        if (selected?.length) {
            markDone(todoId, actionId);
        }
    }

    const handleTextAreaCallback = (todoId, actionId, text) => {
        if (text?.length) {
            markDone(todoId, actionId);
        }
    }

    const handleCheckboxChangeCallback = (todoId, actionId, selected) => {
        if (selected?.length) {
            markDone(todoId, actionId);
        }
    }

    const markDone = (todoId, actionId) => {
        let prevTodos = [...personTodos];
        prevTodos.forEach((todo) => {
            if (todo.id === todoId) {
                let todoDone = true;
                todo.actions.map((action) => {
                    if (action.action_id == actionId) {
                        action.completed = true;
                    } else if (!action.completed) {
                        todoDone = false;
                    }
                });
               
                todo.completed = todoDone;
            }
        });

        setPersonTodos(prevTodos);
    }

    const renderActionComponent = (action, todoId) => {
        switch (action.type) {
            case 'input':
                return <TodoActionTextInput todoId={todoId} actionId={action.action_id}
                                            handleTextInputCallback={handleTextInputCallback}/>;
            case 'single_radio':
                return <TodoActionSingleRadioGroup todoId={todoId} actionId={action.action_id}
                                                   handleSingleRadioClickCallback={handleSingleRadioClickCallback}/>;
            case 'multiple_radio':
                return <TodoActionMultiRadioGroup action={action} todoId={todoId}
                                                  handleMultiRadioClickCallback={handleMultiRadioClickCallback}/>;
            case 'text_only':
                return <TodoActionTextArea todoId={todoId} actionId={action.action_id}
                                           handleTextAreaCallback={handleTextAreaCallback}/>
            case 'checkbox':
                return <TodoActionCheckbox todoId={todoId} action={action}
                                           handleCheckboxChangeCallback={handleCheckboxChangeCallback}/>
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={9}>
                    <Typography
                        variant='span'
                        component='span'
                        sx={{fontWeight: 'bold'}}>
                        Todo Items
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        variant='span'
                        component='span'
                        sx={{fontWeight: 'bold'}}>
                        Due Date
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <List sx={{width: '100%'}}>
                    {
                        personTodos?.map((todo) => (
                            <>
                                <ListItem
                                    key={todo.id}
                                    sx={{
                                        width: '100%',
                                        margin: '8px 0',
                                        padding: 0,
                                        borderRadius: '4px',
                                        backgroundColor: todo.completed ? '#f5f5f5' : 'white',
                                    }}>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Box display='flex' justifyContent='left'>
                                                <Box
                                                    sx={{
                                                        width: '5px',
                                                        height: '80px',
                                                        backgroundColor: `${todo.kpi?.color_code}`,
                                                        borderRadius: '4px 0 0 4px', // Rounds left edges of the bar
                                                    }}
                                                />
                                                <Checkbox
                                                    edge="end"
                                                    checked={todo.completed}
                                                />
                                                <ListItemText
                                                    primary={
                                                        <Box
                                                            sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                                            {todo.title}
                                                            <Tooltip title={todo.description} arrow position='top'>
                                                                <IconButton size="small" sx={{marginLeft: 1}}>
                                                                    <Info fontSize="small" color="action"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    }
                                                    secondary={`Associated KPIs: ${todo.kpi?.title}`}
                                                    sx={{
                                                        marginLeft: 2, // Add spacing after color bar
                                                        marginTop: '15px',
                                                        textDecoration: todo.completed ? 'line-through' : 'none',
                                                        color: todo.completed ? 'gray' : 'inherit',
                                                    }}
                                                >
                                                </ListItemText>
                                            </Box>
                                            {
                                                !todo.completed && todo.actions?.map((action) => (
                                                        <Box display='flex' justifyContent='left' padding='10'>
                                                            {renderActionComponent(action, todo.id)}
                                                        </Box>
                                                    )
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={3} sx={{margin: '25px 0px'}}>
                                            {todo.due_date}
                                        </Grid>
                                    </Grid>
                                </ListItem>

                            </>

                        ))
                    }
                </List>
            </Grid>
        </>

    )
}

export default TodoOverviewTab;