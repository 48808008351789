import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Button from '@mui/material/Button';

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const AzureSignInButton = (props) => {
    const { instance } = useMsal();

    return (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            {...props}
            onClick={() => handleLogin(instance)}
          >
            Login with Office 365
          </Button>
    );
}