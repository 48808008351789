import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  selectors as accountSelectors,
  actions as accountActions,
} from "../../Ducks/account";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Swal from 'sweetalert2'
import { useParams } from "react-router-dom";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: "pointer",
    width: "100%",
    textDecoration: "none",
  },
});

class CreateResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      errorMessage: "",
      message: null,
    };

  }

  handleResetPassword = () => {
    const {newPassword,confirmNewPassword} = this.state
    
    if(newPassword != confirmNewPassword)
    {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password not matched",
      });
    }

    this.props.createNewPassword({password:newPassword,token:this.props.params.token})
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { classes,isLoading,success,error } = this.props;
    const { newPassword,confirmNewPassword, } =
      this.state;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginBottom: "10px" }}
          >
            Create new Password
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={newPassword}
                onChange={this.handleChange}
                variant="outlined"
                required
                fullWidth
                id="password"
                label="New Password"
                name="newPassword"
                autoComplete=""
                type="password"
              />
              <TextField
                value={confirmNewPassword}
                onChange={this.handleChange}
                variant="outlined"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmNewPassword"
                autoComplete="number"
                type="password"
                style={{marginTop:"10px"}}
              />
            </Grid>
          </Grid>
          <br />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={(newPassword && confirmNewPassword) == ''}
            className={classes.submit}
            onClick={this.handleResetPassword}
          >
            {isLoading ? 'Please wait...' : 'Reset'}
          </Button>
          <br />
          <Grid container justify="flex-end">
            <Grid item></Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}


const mapStateToProps = (state) => ({
  isLoading: accountSelectors.isLoading(state),
  errorMessage: accountSelectors.errorMessage(state),
  success: accountSelectors.success(state),
  error: accountSelectors.error(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewPassword: accountActions.createNewPassword,
    },
    dispatch
  );

  function WithNavigate(props) {
    let params = useParams();
    return <CreateResetPassword {...props} params={params} />
  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(WithNavigate));
