import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {actions as opportunityActions, selectors as opportunitySelectors} from '../../../../Ducks/opportunity';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {bindActionCreators} from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    List,
    Radio,
    RadioGroup
} from '@mui/material';
import {withStyles} from '@mui/styles';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {useParams} from "react-router-dom";

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    },
    center: {
        textAlign: 'center'
    }
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    }
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const RadioBoxItem = ({stages, onClick}) => {
    return (
        <FormControl component="fieldset">
            <RadioGroup
                defaultValue=""
                name="radio-buttons-group"
            >
                {
                    stages && stages.map(option => <FormControlLabel value={option.pipeline_stage_id}
                                                                     control={<Radio onClick={onClick}/>}
                                                                     label={option.pipeline_stage_title}/>)
                }
            </RadioGroup>
        </FormControl>
    )
}

const TransferModal = (props) => {
    const {
        onClose,
        selectedValue,
        open,
        isLoading,
        classes,
        pipelineID,
        userPipelineStages,
        formDataValues
    } = props;
    const [pipelineStage, setPipelineStage] = React.useState([]);
    const [stages, setStages] = React.useState([]);

    useEffect(() => {

        if (pipelineID !== null && pipelineID !== "") {
            props.getUserPipelineStages({id: pipelineID})
        }

    }, [pipelineID]);

    useEffect(() => {

        if (userPipelineStages !== null) {
            let sortedArray = userPipelineStages.sort(function (a, b) {
                return a.pipeline_stage_order - b.pipeline_stage_order;
            });
            setStages(sortedArray);
        }

    }, [userPipelineStages])

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleConfirm = () => {
        let pipeline_stage_id = pipelineStage;

        const formData = {
            ...formDataValues,
            pipeline_stage_id
        };
        props.postOpportunityData({
            formData,
            id: props.params.id,
            showLoader: true
        });
        props.emptySalesRep();
        handleClose();
    }

    const handleListItemClick = (value, e) => {
        let selected = "";

        if (e.target.type === 'radio') {
            selected = value.filter(item => item.pipeline_stage_id === parseInt(e.target.value))[0];
        }

        setPipelineStage(parseInt(selected.pipeline_stage_id));
    };


    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BootstrapDialogTitle className={classes.center}>Pick the <b>pipeline stage</b> in which you would like to
                transfer the opportunity</BootstrapDialogTitle>
            <DialogContent>
                {
                    isLoading ? (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </div>
                    ) : (

                        <List sx={{pt: 0}}>
                            <RadioBoxItem onClick={(e) => handleListItemClick(stages, e)} stages={stages}/>
                        </List>
                    )
                }
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color='inherit'
                        onClick={handleConfirm}>Confirm</Button>
                <Button className={classes.button} variant="contained" color="error"
                        onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.isLoading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    userPipelineStages: opportunitySelectors.userPipelineStages(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        postOpportunityData: opportunityActions.postOpportunityData,
        getUserPipelineStages: opportunityActions.getUserPipelineStages
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <TransferModal {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));