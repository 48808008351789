import React from "react";
import Typography from "@mui/material/Typography";
import {withStyles} from "@mui/styles";
import {MenuItem} from "@mui/material";
import {TextField} from "formik-material-ui";
import {Field} from "formik";
import RichTextEditor from "../Common/RichTextEditor";
import SelectBox from "./SelectBox";

const useStyles = (_theme) => ({
    container: {
        display: "flex",
        margin: "16px 0",
    },
    typography: {
        flex: 1,
    },
    field: {
        flex: 4
    },
});

function FieldElement(props) {
    const {classes, title, required} = props;

    return (
        <div className={classes.container}>
            <Typography
                className={classes.typography}
                gutterBottom
                variant="span"
                component="span"
            >
                {title}{required && (<span style={{color: 'red', marginLeft: '2px'}}>*</span>)}
            </Typography>
            <div className={classes.field}>{getInputComponent(props)}</div>
        </div>
    );
}

const getInputComponent = (props) => {
    switch (props.type) {
        case "text":
            return (
                <Field
                    component={TextField}
                    size="small"
                    {...props}
                    fullWidth
                />
            );
        case "textarea":
            return (
                <Field
                    component={TextField}
                    fullWidth
                    size="large"
                    {...props}
                    type={"textarea"}
                />
            );
        case "richText":
            return <RichTextEditor {...props} />;
        case "datetime-local":
            return (
                <Field
                    {...props}
                    component={TextField}
                    type="time"
                    sx={{width: "100%"}}
                />
            );
        case "checkbox":
            return (
                <Field style={{width: '30px', height: '30px'}} name={props.name} type="checkbox"/>
            )
        case "dropdown":
            return (
                <Field component={TextField} select fullWidth size="small" {...props}>
                    {props.list &&
                        props.list.map((option) => (
                            <MenuItem
                                key={option.Id}
                                value={option.id}
                                disabled={option.disabled}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                </Field>
            );
        case "foodconsideration":
            return (
                <>
                    <div className="food-consideration-section">
                        <SelectBox
                            multiple={false}
                            name="person_food_considerations_1"
                            type="type1_food"
                            label="Select Type"
                            labelId="select-type"
                        />
                        <SelectBox
                            multiple={true}
                            name="person_food_considerations_2"
                            type="type2_food"
                            label="Select Allergy"
                            labelId="select-allergy"
                        />
                    </div>
                    {
                        props.formData && props.formData.person_food_considerations_2.filter((item) => item === 16).length ? (
                            <Field
                                component="textarea"
                                fullWidth
                                rows="10"
                                size="large"
                                name="person_food_considerations_other"
                                style={{width: '100%', padding: '10px', fontSize: '16px'}}
                                placeholder="Please write other allergies"
                            />
                        ) : (
                            <></>
                        )
                    }

                </>
            );
        default:
            return <Field component={TextField} fullWidth size="small" {...props} />;
    }
};

export default withStyles(useStyles)(FieldElement);
