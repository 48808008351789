import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as productsTypes, actions as productsActions } from '../Ducks/products';
import { types as companiesTypes, actions as companiesActions } from '../Ducks/companies';

import { callApi } from './utils';
import * as api from '../API/products';
import * as apiComp from '../API/companies';


function* getProducts(action) {
  const { success, failure } = productsActions.saga.getProducts;

  yield callApi(
    call(api.getProducts, action.data),
    success,
    failure
  )
}

function* getProductDetails(action) {
  const { success, failure } = productsActions.saga.getProductDetails;

  yield callApi(
    call(api.getProductDetails, action.data),
    success,
    failure
  )
}

function* getProductTypes(action) {
  const { success, failure } = productsActions.saga.getProductTypes;

  yield callApi(
    call(api.getProductTypes, action.data),
    success,
    failure
  )
}

function* getCountries(action) {
  const { success, failure } = companiesActions.saga.getCountries;

  yield callApi(
    call(apiComp.getCountries, action.data),
    success,
    failure
  )
}

function* updateProduct(action) {
  const { success, failure } = productsActions.saga.updateProduct;

  yield callApi(
    call(api.updateProduct, action.data),
    success,
    failure
  )
}

function* getEvents(action) {
  const {success, failure} = productsActions.saga.getEvents;

  yield callApi(
    call(api.getEvents, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(productsTypes.getProducts, getProducts);
  yield takeLatest(productsTypes.getProductDetails, getProductDetails);
  yield takeLatest(productsTypes.getProductTypes, getProductTypes);
  yield takeLatest(companiesTypes.getCountries, getCountries);
  yield takeLatest(productsTypes.updateProduct, updateProduct);
  yield takeLatest(productsTypes.getEvents, getEvents);
}