import * as React from 'react';
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Chip from '@mui/material/Chip';
import OpportunityCardfrom from './Cards/OpportunityCard';
import moment from 'moment';
import { cloneDeep } from 'lodash-es';
import CircularProgress from '@mui/material/CircularProgress';
import PipelineOpportunityCard from './Cards/PipelineOpportunityCard';

const useStyles = theme => ({
    stage: {
        // background: '#d8d7d7'
    },
    step: {
        textAlign: 'center',
        minWidth: '180px'
    },
    headline: {
        fontSize: '12px',
        margin: '0 0 5px'
    },
    paragraph: {
        fontSize: '11px',
        margin: 0
    },
    chip: {
        backgroundColor: '#d7d7d7',
        padding: '5px',
        border: 0,
        borderRadius: 0,
        height: 'auto',
        marginTop: '0px',
        color: '#888'
    }
});

function HorizontalLinearStepper(props) {
    const { stages, classes, isLoading } = props;
    const [isProcessing, setIsProcessing] = React.useState(true);
    const [processedStages, setProcessedStages] = React.useState([]);
    var _stage = [];
    const _ordered_stages = {};

    React.useEffect(() => {
        if (!stages || isLoading) return;
        setIsProcessing(true);

        const userStages = stages.related_objects.pipeline_stages;

        let sortedArray = Object.keys(userStages).sort(function (a, b) {
            return userStages[a].pipeline_stage_order - userStages[b].pipeline_stage_order;
        });

        // ordering of opportunities for opportunities switching
        sortedArray.map((item, index) => {
            const stage_item = userStages[item];
            // Sort chronologically starts here
            let _stages_array = [];
            let _sorted_array = [];

            if (stage_item.opportunities !== undefined) {
                stage_item.opportunities.map((item) => {
                    _stages_array.push(stages.related_objects.opportunities[item])
                });
                if (_stages_array.length > 1) {
                    _sorted_array = _stages_array.sort((a, b) => a.sort_order - b.sort_order);
                }
                else {
                    _sorted_array = _stages_array;
                }

                // Create ordered opportunities
                _ordered_stages[item] = { "opportunities": _sorted_array.map((_item) => _item.opportunity_id) }
                // Create ordered opportunities
            }
        })

        localStorage.setItem('sales_overview_opportunities', JSON.stringify(_ordered_stages));

        const _processedData = sortedArray.map((item, index) => {
            const stage = userStages[item];
            // Sort chronologically starts here
            let _stages = [];
            let _sorted = [];
            let _grouped = {}
            if (stage.opportunities !== undefined) {
                stage.opportunities.map((item) => {
                    _stages.push(stages.related_objects.opportunities[item])
                });
                if (_stages.length > 1) {
                    _sorted = _stages.sort((a, b) => a.sort_order - b.sort_order);
                }
                else {
                    _sorted = _stages;
                }
                // Grouping the opportunities
                const _sorted_copy = cloneDeep(_sorted);
                const _sorted_formatted = _sorted_copy.map(opportunity => ({ ...opportunity, groupedBy: opportunity.next_planned_action_time === null ? "none" : moment(opportunity.next_planned_action_time).format("DD/MM/YYYY") }))
                _grouped = _sorted_formatted.reduce(function (r, a) {
                    r[a.groupedBy] = r[a.groupedBy] || [];
                    r[a.groupedBy].push(a);
                    return r;
                }, Object.create(null));
                console.log("_grouped", _grouped);
                // Grouping the opportunities
                _stage = _sorted.map(item => item.opportunity_id);
                _stages = [];
            }
            return { _grouped, _stages, _sorted, stage };
        });

        setProcessedStages(_processedData);
        setIsProcessing(false);
    }, [stages, isLoading]);


    // ordering of opportunities for opportunities switching

    const getLabel = (stage) => {
        return (
            <>
                <div style={{ fontSize: '14px', fontWeight: '700' }}>
                    {stage.pipeline_stage_title}
                </div>
                <div>
                    <p className={classes.paragraph}>{stage.opportunities ? stage.opportunities.length : 0} oppt.</p>
                </div>
            </>
        )
    }

    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )


    return isProcessing ? showProgress() : (
        <Box sx={{ width: '100%', overflowX: 'auto', paddingRight: '10px' }}>
            <Stepper alternativeLabel>
                {processedStages.map(({ _grouped, _stages, _sorted, stage }) => {
                    // Sort chronologically ends here
                    return (
                        <Step className={classes.step} key={stage.pipeline_stage_title}>
                            <StepButton
                                className={classes.stage}
                                style={{ cursor: 'default', zIndex: 1000 }}
                                icon={<Chip style={{ padding: '0', height: '70px', width: '170px', borderRadius: '0' }} label={getLabel(stage)} variant={'filled'} color={'primary'} />}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                                {
                                    stage.opportunities !== undefined && stage.opportunities.length > 0 ? (
                                        // _stage.map(oppr =>
                                        //     <OpportunityCardfrom stage={stage} opportunityId={oppr} stages={stages} />
                                        // )
                                        Object.keys(_grouped).map((key) => {
                                            const _opportunities = _grouped[key];
                                            return (
                                                <>
                                                    <div style={{ margin: '10px 0' }}>
                                                        <div>
                                                            <h6 className={classes.headline}>{key === "none" ? "Without planned actions" : key}</h6>
                                                        </div>
                                                        <div>
                                                            <p className={classes.paragraph}>{_opportunities.length} opportunity</p>
                                                        </div>
                                                    </div>
                                                    {_opportunities.map((opportunity) => <PipelineOpportunityCard opportunityId={opportunity.opportunity_id} stages={stages} opportunities={_opportunities} />)}
                                                </>
                                            )
                                        })
                                    ) : (
                                        <div>
                                            <div style={{ marginTop: '10px' }}>
                                                <h6 className={classes.headline}>Without planned actions</h6>
                                            </div>
                                            <div>
                                                <p className={classes.paragraph}>0 opportunity</p>
                                            </div>
                                        </div>
                                    )

                                }
                            </div>

                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}

export default withStyles(useStyles)(HorizontalLinearStepper)