import React, { useEffect } from "react";
import {
  selectors as opportunitySelectors,
  actions as opportunityActions,
} from "../../../../../Ducks/opportunity";
import {
  selectors as accountSelectors
} from "../../../../../Ducks/account";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import CalendarWidget from "../../../../Containers/Calendar/Widgets/CalendarWidget/Calendar";

function CallCalendar(props) {
  const { calendar, getCalendar, tab, getAllEvents_loading, getCalendar_loading, events, cronofyAuthorized } = props;

  useEffect(() => {
    renderCalendarData()
  },[]);

  const renderCalendarData = () => {
    let d = new Date();
    let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth()+1)).slice(-2);
    getCalendar(YearMonth);
  }

  return (
      <>
      {
        <CalendarWidget {...props} isClickable tab={tab} events={cronofyAuthorized ? events : []} legacy_events={calendar?.data.main} />
      }
        {/* {
          props.calendar !== null &&
          (
            <Calendar {...props} calendar={props.calendar} isClickable isLoading={props.getCalendar_loading} tab={tab} />
          )
        } */}
      </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: opportunitySelectors.isLoading(state),
  errorMessage: opportunitySelectors.errorMessage(state),
  calendar: opportunitySelectors.calendar(state),
  getCalendar_loading: opportunitySelectors.getCalendar_loading(state),
  events: opportunitySelectors.events(state),
  getAllEvents_loading: opportunitySelectors.getAllEvents_loading(state),
  getCalendar_loading: opportunitySelectors.getCalendar_loading(state),
  getAllEvenets_inProgress: opportunitySelectors.getAllEvenets_inProgress(state),
  cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCalendar: opportunityActions.getCalendar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CallCalendar);
