import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';

const editorConfiguration = {
  plugins: [List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
  toolbar: ['heading', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
  alignment: {
    options: ['left', 'right']
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  }
};

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.title,
    };
  }
  componentDidMount() { }

  render() {
    const { content } = this.state;
    return (
      <div className="App" style={{ width: "98%" }}>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={content}
          onReady={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      </div>
    );
  }
}

export default RichTextEditor;
