import {fetchGet, fetchPatch} from "./util";

export const getCompanies = ({results, columns, offset}) => {
    return fetchGet(
        `/companies?results=${results}&columns=${columns}&offset=${offset}`
    ).then((res) => res);
};

export const getCompanyDetails = ({id, columns}) => {
    return fetchGet(`/companies/${id}?columns=${columns}`).then((res) => res);
};

export const updateCompany = (data) => {
    return fetchPatch(`/companies/${data.id}`, data.obj).then((res) => res);
};

export const getCountries = () => {
    return fetchGet(`/countries?results=500&columns=country_id,country_name`).then((res) => res);
}

export const getDivisions = ({id, columns}) => {
    return fetchGet(
        `/divisions?columns=${columns}&equals[company_id]=${id}`
    ).then((res) => res);
};
