import {useCallback, useRef, useState} from "react";
import {useNavigate} from "react-router";
import GridElement from "../../Common/GidElement";
import {deleteChangePersonGroup, getChangePersonGroups} from "../../../API/changePersonGroup";
import {
    PROCESSED
} from "../../Utils/constants";
import {IconButton, Tooltip} from "@mui/material";
import {Delete, Visibility} from "@mui/icons-material";
import {selectors as accountSelectors} from "../../../Ducks/account";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {showEllipsis} from "../../Utils/stringUtils";
import * as React from "react";

const makeHeader = (title) => {
    let _title = "";
    if (title.includes(".")) {
        _title = title.split(".")[1].split("_");
    } else {
        _title = title.split("_");
    }

    return _title.join(" ");
};

const makeAccessor = (key) => {
    if (key.includes(".")) {
        return key.split(".")[1];
    } else {
        return key;
    }
};
const ChangePersonGroupGrid = (props) => {
    const {navigate, canReviewGroupChange} = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);
    const [filters, setFilters] = useState([]);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [refreshGridData, setRefreshGridData] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [cols, _setCols] = useState([
        {
            column: "request_time",
            accessor: makeAccessor("request_time"),
            minWidth: 150,
            Header: "Request Time",
            filter: "fuzzyText"
        },
        {
            column: "purchases.purchase_id",
            accessor: makeAccessor("purchase_id"),
            minWidth: 150,
            Header: makeHeader("purchase_id"),
            filter: "fuzzyText",
            Cell: ({value, row}) => (
                <a href={`${window.location.origin}/sales/id/${row.original.purchase_id}`} target='blank'>
                    {value}
                </a>
            ),
        },
        {
            column: "initiated_by",
            accessor: makeAccessor("initiated_by"),
            minWidth: 150,
            Header: "Initiated By",
            filter: "fuzzyText",
        },
        {
            column: "person_name",
            accessor: makeAccessor("person_name"),
            minWidth: 150,
            Header: "Member Name",
            filter: "fuzzyText",
            Cell: ({value, row}) => (
                <a href={`${window.location.origin}/contact/id/${row.original.person_id}`} target='blank'>
                    {value}
                </a>
            ),
        },
        {
            column: "old_product_name",
            accessor: makeAccessor("old_product_name"),
            minWidth: 150,
            Header: "Old Group",
            filter: "fuzzyText",
        },
        {
            column: "new_product_name",
            accessor: makeAccessor("new_product_name"),
            minWidth: 150,
            Header: "New Group",
            filter: "fuzzyText",
        },
        {
            column: "status",
            accessor: makeAccessor("status"),
            minWidth: 150,
            Header: "Status",
            filter: "fuzzyText",
        },
        {
            column: "first_meeting_date",
            accessor: makeAccessor("first_meeting_date"),
            minWidth: 150,
            Header: makeHeader("first_meeting_date"),
            filter: "fuzzyText",
        },
        {
            column: "meetings_left",
            accessor: makeAccessor("meetings_left"),
            minWidth: 150,
            Header: "Number of Events",
            filter: "fuzzyText",
        },
        {
            column: "comments",
            accessor: makeAccessor("comments"),
            minWidth: 150,
            Header: "Comments",
            filter: "fuzzyText",
            Cell: ({row}) => (
                <div>
                    {
                        row.original.comments?.length >= 50 ? showEllipsis(row.original.comments, 50) : row.original.comments
                    }
                </div>
            )
        },
        {
            Header: "Action",
            filter: "fuzzyText",
            Cell: ({row}) => (
                <div>
                    {row.original.status !== PROCESSED && canReviewGroupChange && (
                        <Tooltip title='Review memeber group change request' arrow placement='top'>
                            <IconButton
                                aria-label='process'
                                onClick={() => navigate("/change-member-group/id/" + row.original.person_group_change_id)}
                                disabled={isDeleting}
                            >
                                <Visibility color='primary' disabled={isDeleting}/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {
                        row.original.status !== PROCESSED && canReviewGroupChange && (
                            <Tooltip title='Delete Request' arrow placement='top'>
                                <IconButton
                                    aria-label='delete'
                                    aria-haspopup='true'
                                    onClick={() => handleDeleteGroupChangeClick(row.original.person_group_change_id)}
                                    color="error"
                                    disabled={isDeleting}>
                                    <Delete disabled={isDeleting}/>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </div>
            )
        }
    ]);

    const fetchIdRef = useRef(0);
    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {
        //fetchData will be called whenever table needs new data.
        let _columns = columns.map((item) => item.column);
        _columns = _columns.filter((item) => item !== undefined);
        _columns.push('persons.person_id');

        const fetchId = ++fetchIdRef.current;

        setLoading(true);

        // Check filter data
        let query = "",
            sort = "",
            filterPrefix = "";
        filters.forEach((item) => {
            const _filterColumn = columns.filter((column) => column.accessor === item.id);
            let filter = '';

            if (_filterColumn[0].column.includes(".")) {
                filterPrefix = _filterColumn[0].column.split(".")[0];
                filter = `${filterPrefix}.${item.id}`;
            } else if (_filterColumn[0].column === 'person_name' || _filterColumn[0].column === 'old_product_name' || _filterColumn[0].column === 'new_product_name') {
                filter = `${item.id}`;
            } else {
                filter = `person_group_changes.${item.id}`;
            }

            if (filter.length > 0) {
                query = query + `&contains[${filter}]=${item.value}`;
            }
        });

        // Check sorting
        if (sortBy.length) {
            let _sortByPrefix = "",
                _sortByColumn = columns.filter((column) => column.accessor === sortBy[0].id);
            if (_sortByColumn[0].column.includes(".")) {
                _sortByPrefix = _sortByColumn[0].column.split(".")[0];
                _sortByPrefix = `${_sortByPrefix}.`;
            }

            sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${_sortByPrefix}${sortBy[0].id}`;
        }

        getChangePersonGroups(pageSize, _columns, pageIndex, query, sort)
            .then((res) => {
                if (fetchId == fetchIdRef.current && res) {
                    setData(res.data.main);
                    setPageCount(res.data.offset.highest_offset);
                    setRowsCount(res.data.offset.rows_count);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });

        setRefreshGridData(false); //if grid fetches latest data then set refreshGridData to false
    }, []);

    const resetFilters = useCallback(() => setFilters([]), [setFilters]);

    const handleDeleteGroupChangeClick = (changePersonGroupId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsDeleting(true);
                deleteChangePersonGroup(changePersonGroupId).then((res) => {
                    toast.success(res.message);
                    setRefreshGridData(true);
                    setIsDeleting(false);
                }).catch((error) => {
                    toast.error(error);
                    setIsDeleting(false);
                });
            }
        });
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className='page-title'>Member Changes Group</h2>
            </div>

            <GridElement
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={() => {
                }}
                initialSortBy='request_time'
                sortOrder='desc'
                hideSelection={true}
                refreshGridData={refreshGridData}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    canReviewGroupChange: accountSelectors.canReviewGroupChange(state)
});

function WithNavigate(props) {
    let navigate = useNavigate();
    return <ChangePersonGroupGrid {...props} navigate={navigate}/>;
}

export default connect(mapStateToProps)(WithNavigate);