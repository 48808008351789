import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Button, Grid, TextField} from "@mui/material";
import * as React from "react";
import {LoadingButton} from "@mui/lab";
import {useRef} from "react";
import {toast} from "react-toastify";
import {TRANSFER_LATER_CANCEL_NOW, TRANSFER_MEMBERSHIP} from "../../Utils/constants";
import {changeJob} from "../../../API/changePersonJob";
import {Form, Formik} from "formik";
import {dateToString} from "../../Utils/dateUtils";

const TransferLater = (props) => {
    const {
        person,
        purchase,
        handleCancelClick
    } = props;
    const formRef = useRef(null);

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={{
                    tentative_transfer_date: null
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.tentative_transfer_date) {
                        errors.tentative_transfer_date = 'Required';
                    }

                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {
                    let postData = {};
                    postData.change_job_type = TRANSFER_MEMBERSHIP;
                    postData.transfer_type = TRANSFER_LATER_CANCEL_NOW;
                    postData.purchase_id = purchase.purchase_id;
                    postData.tentative_transfer_date = dateToString(new Date(values.tentative_transfer_date), 'yyyy-MM-dd');

                    changeJob(person.person_id, postData).then((res) => {
                        if (res) {
                            toast.success("Change member job request has been initiated");
                            handleCancelClick(true);
                        }

                        setSubmitting(false);
                    }).catch((error) => {
                        setSubmitting(false);
                        toast.error(error.message);
                    });
                }}>
                {({isSubmitting, values, setFieldValue, errors}) => (
                    <Form>
                        <Grid container
                              sx={{padding: "20px", textAlign: "left", display: "flex", justifyContent: "center"}}
                              spacing={3}>
                            <Grid item xs={5}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="tentative_transfer_date"
                                        label='Tentative date of transfer*'
                                        inputFormat='dd/MM/yyyy'
                                        className='custom-datepicker'
                                        value={values.tentative_transfer_date} // Set the initial value
                                        onChange={(newValue) => {
                                            setFieldValue("tentative_transfer_date", newValue);
                                        }}
                                        renderInput={(startProps) => (
                                            <>
                                                <TextField
                                                    {...startProps}
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                    padding={{
                                                        top: "1px",
                                                    }}
                                                    error={
                                                        errors.tentative_transfer_date
                                                    }
                                                />
                                            </>
                                        )}
                                    />
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "0.75rem",
                                            margin: "4px 14px 0px",
                                        }}
                                    >
                                                    <span>
                                                        {errors.tentative_transfer_date}
                                                    </span>
                                    </div>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button type='submit'
                                        variant='contained'
                                        color='secondary'
                                        onClick={handleCancelClick}
                                        style={{marginTop: "10px", marginRight: "6px"}}
                                        disabled={isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton type='submit'
                                               variant='contained'
                                               color='primary'
                                               loading={isSubmitting}
                                               style={{marginTop: "10px", marginRight: "5px"}}
                                >
                                    Process
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default TransferLater;