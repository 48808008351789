import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";

const useStyles = (theme) => ({
});

function SalesRep(props) {
  const { classes, dashboard } = props;
  const [dashboardObj, setDashboardObj] = React.useState({});
  const [salesRep, setSalesRep] = React.useState({});
  const [weeklyGoals, setWeeklyGoals] = React.useState({});
  const [weeklyActual, setWeeklyActual] = React.useState({});
  const [weeklyDifference, setWeeklyDifference] = React.useState({});

  useEffect(() => {
    if (dashboard !== null) {
      let salesRepObj = dashboard.sales_rep;
      let weeklyGoalsObj = dashboard.weekly_goals;
      let weeklyActualObj = dashboard.weekly_actual;
      let weeklyDifferenceObj = dashboard.weekly_difference;
      setDashboardObj(dashboard || {});
      setSalesRep(salesRepObj || {});
      setWeeklyGoals(weeklyGoalsObj || {});
      setWeeklyActual(weeklyActualObj || {});
      setWeeklyDifference(weeklyDifferenceObj || {});
    }
  }, [dashboard]);

  const reMoveUnderScore = (key) => {
    key = key.replaceAll("_", " ");
    let titleCaseLetter = null;
    let splitStr = key.toLowerCase().split(" ");

    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() +
        splitStr[i].substring(1).toLowerCase();

      key = splitStr.join(" ");
      titleCaseLetter = makeTitleCase(key);
    }

    return titleCaseLetter;
  };

  const makeTitleCase = (string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  };

  // const removeUnderScoreFromString = (str) => {
  //   var newStr = str.replace(/_/g, " ");
  //   return newStr
  // }

  return salesRep && weeklyGoals && weeklyActual && weeklyDifference ? (
    <Card style={{ fontFamily: "Open Sans,sans-serif !important", textAlign: "initial", fontSize: "12px", letterSpacing: ".1px", fontWeight: 400 }}>
      <CardContent>
        <div style={{paddingTop: "0px"}}>
          <Typography
            style={{fontSize: "18px",letterSpacing: ".15px",fontWeight: 400,borderBottom: "1px solid #ddd",paddingBottom: "5px",}}
            gutterBottom
            variant="h6"
            component="div"
          >
            Sales Rep
          </Typography>
          <Grid
            container
            sx={{ color: "text.primary" }}
            style={{paddingBottom: "16px", borderBottom: "1px solid #ddd"}}
          >
            <Grid item xs={6}>
              {Object.keys(salesRep).map(function (key, index) {
                return (
                  <>
                    {index == 0 || index == 1 ? (
                      <Grid container sx={{ color: "text.primary" }}>
                        <Grid item xs={8}>
                          <Typography
                            gutterBottom
                            variant="span"
                          >
                            {reMoveUnderScore(key)} :
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="span" color="site.main">
                            {salesRep[key]}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      " "
                    )}
                  </>
                );
              })}
            </Grid>
            <Grid item xs={6}>
              {Object.keys(salesRep).map(function (key, index) {
                return (
                  <>
                    {index == 2 || index == 3 || index == 4 ? (
                      <Grid container sx={{ color: "text.primary" }}>
                        <Grid item xs={8}>
                          <Typography
                            gutterBottom
                            variant="span"
                          >
                            {reMoveUnderScore(key)} :
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="span" color="site.main">
                            {salesRep[key]}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      " "
                    )}
                  </>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </CardContent>

      <CardContent

        style={{ paddingTop: "0px", marginTop: "-7px" }}
      >
        <div style={{paddingTop: "0px"}}>
          <Typography
            style={{fontSize: "16px",}}
            gutterBottom
            variant="h6"
            component="div"
          >
            Weekly Goals
          </Typography>
          <Grid item xs={6} style={{paddingBottom: "16px", borderBottom: "1px solid #ddd"}}>
            {Object.keys(weeklyGoals).map(function (key) {
              return (
                <Grid container sx={{ color: "text.primary" }}>
                  <Grid item xs={8}>
                    <Typography
                      gutterBottom
                      variant="span"
                    >
                      {reMoveUnderScore(key)} :
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="span" color="site.main">
                      {weeklyGoals[key]}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </CardContent>

      <CardContent

        style={{ paddingTop: "0px", marginTop: "-7px" }}
      >
        <Grid style={{paddingTop: "0px"}}>
          <Typography
            style={{fontSize: "16px",}}
            gutterBottom
            variant="h6"
            component="div"
          >
            Weekly Actual
          </Typography>
          <Grid container sx={{ color: "text.primary" }}>
            <Grid item xs={6} style={{paddingBottom: "16px", borderBottom: "1px solid #ddd"}}>
              {Object.keys(weeklyActual).map(function (key) {
                return (
                  <Grid container sx={{ color: "text.primary" }}>
                    <Grid item xs={8}>
                      <Typography
                        gutterBottom
                        variant="span"
                      >
                        {reMoveUnderScore(key)} :
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="span" color="site.main">
                        {weeklyActual[key]}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={6} style={{fontSize: "14px",margin: "auto !important"}}>
              <Grid container sx={{ color: "text.primary" }}>
                <Grid item xs={12}>
                  <Typography
                    style={{fontSize: "16px"}}
                    gutterBottom
                    variant="span"
                  >
                    Your next sale: {dashboardObj.next_sales_provision}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent

        style={{ paddingTop: "0px", marginTop: "-7px", border: "none" }}
      >
        <div style={{paddingTop: "0px"}} style={{ border: "none" }}>
          <Typography
            style={{fontSize: "16px",}}
            gutterBottom
            variant="h6"
            component="div"
          >
            Weekly Difference
          </Typography>
          {Object.keys(weeklyDifference).map(function (key) {
            return (
              <Grid container sx={{ color: "text.primary" }}>
                <Grid item xs={4}>
                  <Typography
                    gutterBottom
                    variant="span"
                  >
                    {reMoveUnderScore(key)}: {reMoveUnderScore(key)} activity -{" "}
                    {reMoveUnderScore(key)} target :
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="span" color="site.main">
                    {weeklyDifference[key]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </div>
      </CardContent>
    </Card>
  ) : (
    " "
  );
}

export default withStyles(useStyles)(SalesRep);
