import {Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {dateToString} from "../../Utils/dateUtils";
import {LoadingButton} from "@mui/lab";
import {connect} from "react-redux";
import {selectors as accountSelectors} from "../../../Ducks/account";
import {PROCESSED} from "../../Utils/constants";

const ChangePersonGroupOverview = (props) => {
    const {
        data,
        loading,
        processing,
        deleting,
        meetingsToBeAdded,
        handleProcessClick,
        handleDeleteClick,
        handleCancelClick,
        canReviewGroupChange,
        firstMeetingDate
    } = props;

    return (
        <div style={{
            paddingTop: "30px"
        }}>
            <Grid container sx={{padding: "20px 20px 20px", textAlign: "left"}}>
                <Grid item xs={12}>
                    <Card style={{padding: "12px"}}>
                        <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                    gutterBottom>
                            Person Details
                        </Typography>
                        <Grid container sx={{marginTop: "1px", marginBottom: "15px"}} spacing={2}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><b>Person Name</b></TableCell>
                                            <TableCell>{data?.person_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Job Title</b></TableCell>
                                            <TableCell>{data?.purchase?.job?.job_title}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Company Name</b></TableCell>
                                            <TableCell>{data?.purchase?.job?.company?.company_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Old Group</b></TableCell>
                                            <TableCell>{data?.old_product_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>New Group</b></TableCell>
                                            <TableCell>{data?.new_product_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>First Meeting</b></TableCell>
                                            <TableCell>{dateToString(new Date(firstMeetingDate), 'dd/MM/yyyy H:mm')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Meetings to be Added</b></TableCell>
                                            <TableCell>{meetingsToBeAdded}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Comments</b></TableCell>
                                            <TableCell>{data?.comments}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button type='submit'
                                        variant='contained'
                                        color='secondary'
                                        onClick={handleCancelClick}
                                        style={{marginTop: "10px", marginRight: "6px"}}
                                >
                                    Cancel
                                </Button>
                                {
                                    canReviewGroupChange &&
                                    <LoadingButton type='button'
                                                   variant='contained'
                                                   color='error'
                                                   onClick={handleDeleteClick}
                                                   loading={deleting}
                                                   disabled={data?.status === PROCESSED || loading || processing}
                                                   style={{marginTop: "10px", marginRight: "5px"}}
                                    >
                                        Delete
                                    </LoadingButton>
                                }
                                {
                                    canReviewGroupChange &&
                                    <LoadingButton type='submit'
                                                   variant='contained'
                                                   color='primary'
                                                   onClick={handleProcessClick}
                                                   loading={processing}
                                                   disabled={data?.status === PROCESSED || loading || deleting || !(meetingsToBeAdded > 0)}
                                                   style={{marginTop: "10px", marginRight: "5px"}}
                                    >
                                        Process
                                    </LoadingButton>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    canReviewGroupChange: accountSelectors.canReviewGroupChange(state)
})
export default connect(mapStateToProps)(ChangePersonGroupOverview);