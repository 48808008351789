// import * as React from "react";
// import { withStyles } from "@mui/styles";
// import divisions, {
//   selectors as DivisionsSelectors,
//   actions as divisionsActions,
// } from "../../../../Ducks/divisions";
// import { selectors as accountSelectors } from "../../../../Ducks/account";
// import { bindActionCreators } from "redux";
// import { useParams } from "react-router-dom";
// import { connect } from "react-redux";
// import DataGrid from "../../../Common/DataGrid";
// import { tryParseDate } from "../../../Utils/dateUtils";

// const useStyles = (theme) => ({
//   container: {
//     textAlign: "left",
//     background: "#fff",
//     marginTop: "25px"
//   },
//   gridHeading: {
//     padding: "15px 0px 15px 10px",
//     fontSize: '29px',
//     fontWeight: '300',
//     color: '#3a3641'
//   }
// });

// class Divisions extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       selectedCompany: props.params.id,
//       tableColumns: props.user.tableSettings.divisions.columns,
//     };
//   }

//   componentDidMount() {
//     this.getDivisionsList(1);
//   }

//   getDivisionsList = (newPage) => {
//     const { getDivisions } = this.props;
//     const { selectedCompany, tableColumns } = this.state;
//     getDivisions({
//       id: selectedCompany,
//       results: 50,
//       columns: tableColumns,
//       offset: newPage,
//     });
//   };

//   makeHeader = (key) => {
//     key = key.replaceAll("_", " ");
//     let splitStr = key.toLowerCase().split(" ");

//     for (let i = 0; i < splitStr.length; i++) {
//       splitStr[i] =
//         splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

//       key = splitStr.join(" ");
//     }

//     return key;
//   };

//   parseData = (divisions) => {
//     return divisions.main.map((item) => {
//       Object.keys(item).map((key) => {
//         item[key] = tryParseDate(item[key]);
//       });
//       return { ...item, id: item.division_id };
//     });
//   };

//   render() {
//     const { classes, isLoading, divisions } = this.props;
//     const { selectedCompany, tableColumns } = this.state;

//     return (
//       <div className={classes.container}>
//         <div className={classes.gridHeading}>Divisions</div>
//         <DataGrid
//           className={classes.heightGrid}
//           loading={isLoading}
//           data={{
//             columns: tableColumns.map((key) => ({
//               field: key,
//               minWidth: 150,
//               flex: 1,
//               headerName: this.makeHeader(key),
//             })),
//             rows: divisions && !isLoading ? this.parseData(divisions) : [],
//           }}
//           rowCount={divisions ? divisions.offset.rows_count : 0}
//           onPageChange={(newPage) => this.getDivisionsList(newPage)}
//         />
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   isLoading: DivisionsSelectors.isLoading(state),
//   errorMessage: DivisionsSelectors.errorMessage(state),
//   divisions: DivisionsSelectors.divisions(state),
//   user: accountSelectors.user(state),
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       getDivisions: divisionsActions.getDivisions,
//     },
//     dispatch
//   );

// function WithNavigate(props) {
//   let params = useParams();
//   return <Divisions {...props} params={params} />;
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(useStyles)(WithNavigate));


import React from 'react'
import { selectors as DivisionsSelectors, actions as divisionsActions } from "../../../../Ducks/divisions";
import { selectors as accountSelectors } from '../../../../Ducks/account';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchGet } from '../../../../API/util';
import GridElement from '../../../Common/GidElement';

const makeHeader = (key) => {
  key = key.replaceAll('_', ' ');
  let splitStr = key.toLowerCase().split(" ");
  splitStr.shift();
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

    key = splitStr.join(" ");
  }

  return key;
}

const Divisions = (props) => {
  const { user, navigate } = props;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [rowsCount, setRowsCount] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const columns = user.tableSettings.divisions.columns.filter((item, index, self) => self.indexOf(item) === index);
  const _columns = columns.map(key => ({ accessor: key, minWidth: 150, Header: makeHeader(key), filter: 'fuzzyText' }));
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current

    // Set the loading state
    setLoading(true)

    // Check filter data
    let query = "", api = "", sort = "";
    filters.forEach((item, index) => {
      query = query + `&contains[divisions.${item.id}]=${item.value}`
    });

    // Check sorting
    if (sortBy.length) {
      sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`;
    }

    api = `/divisions?results=${pageSize}&columns=${columns}&equals[company_id]=${props.params.id}&offset=${pageIndex + 1}${query}${sort}`

    fetchGet(api).then(contacts => {
      if (fetchId === fetchIdRef.current && contacts) {
        setData(contacts.data.main);
        setPageCount(contacts.data.offset.highest_offset);
        setRowsCount(contacts.data.offset.rows_count);
        setLoading(false);
      }
    }).catch((error) => {
      console.log("error.....")
      setLoading(false);
    })
  }, []);

  const resetFilters = React.useCallback(() => setFilters([]), [setFilters]);

  const handleRowClick = (row) => {
    console.log(row)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <h2 className="page-title">
        Divisions
      </h2>

      <GridElement
        columns={React.useMemo(() => _columns, [])}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        controlledPageIndex={controlledPageIndex}
        setControlledPageIndex={setControlledPageIndex}
        filters={filters}
        setFilters={setFilters}
        resetFilters={resetFilters}
        rowsCount={rowsCount}
        handleRowClick={handleRowClick}
        initialSortBy={user.tableSettings.divisions.filters.sort}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: DivisionsSelectors.isLoading(state),
  errorMessage: DivisionsSelectors.errorMessage(state),
  divisions: DivisionsSelectors.divisions(state),
  user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDivisions: divisionsActions.getDivisions,
    },
    dispatch
  );

function WithNavigate(props) {
  let navigate = useNavigate();
  let params = useParams();
  return <Divisions {...props} params={params} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);
