import * as React from 'react';
import EventsOverview from '../EventsOverview/EventsOverview';

const NetworkDirectorOverview = (props) => {

    return (
        <EventsOverview isNetworkDirector={true} />
    );
}

export default NetworkDirectorOverview;