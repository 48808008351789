import {fetchDelete, fetchGet, fetchPatch, fetchPost} from "./util";

export const getTodos = (results, columns, pageIndex, query, sort) => {
    return fetchGet(`/todos?results=${results}&columns=${columns}&offset=${pageIndex}${query}${sort}`).then(res => res);
}

export const getAllTodos = (query) => {
    return fetchGet(`/todos${query}`).then(res => res);
}

export const deleteTodo = (todo_id) => {
    return fetchDelete(`/todos/${todo_id}`).then(res => res);
}

export const addTodo = (data) => {
    return fetchPost(`/todos`, data).then(res => res);
}

export const updateTodo = (todoId, data) => {
    return fetchPatch(`/todos/${todoId}`, data).then(res => res);
}

export const getTodo = (todoId) => {
    return fetchGet(`/todos/${todoId}`).then(res => res);
}

export const getTodoActionItems = () => {
    return fetchGet(`/todos/action-items`).then(res => res);
}

export const addTodoActionItem = (data) => {
    return fetchPost(`/todos/action-items`, data).then(res => res);
}

export const getKpis = () => {
    return fetchGet(`/kpis`).then(res => res);
}
export const assignTodosToKpis = (data) => {
    return fetchPost(`/kpis/todo-items`, data).then(res => res);
}