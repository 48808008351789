import * as React from 'react';
import { withStyles } from '@mui/styles';
import { Stepper, Step, StepButton, Typography, Box, Chip, Card, CardContent } from '@mui/material';
import { groupBy } from 'lodash';
import { AccessTime, Circle } from '@mui/icons-material';

const useStyles = _theme => ({
  step: {
    textAlign: 'center',
    minWidth: '180px'
  },
  headline: {
    fontSize: '12px',
    margin: '0 0 5px'
  },
  paragraph: {
    fontSize: '11px',
    margin: 0
  },
  chip: {
    backgroundColor: '#d7d7d7',
    padding: '5px',
    border: 0,
    borderRadius: 0,
    height: 'auto',
    marginTop: '0px',
    color: '#888'
  },
  stepper: {
    '& .MuiStepConnector-root': {
      flex: 0,
      minWidth: 50
    }
  },
  card: {
    backgroundColor: '#fff',
    marginBottom: '12px !important',
    minWidth: '225px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cardBody: {
    clear: 'both',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '13px !important'
  },
  title: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    alignItems: 'center'
  },
  timeline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  assignee: {
    fontSize: '12px',
    marginTop: '10px'
  },
  assignlabel: {
    fontWeight: 'bold',
  }
});

const steps = ['pause', 'ongoing'];

function CaseOverviewStepper(props) {
  const { classes, overview, selected } = props;
  const [activeStep, _setActiveStep] = React.useState(0);

  const getLabel = (label) => {
    const count = overview.filter((item) => item.case_type === label);
    return (
      <>
        <div style={{ fontSize: '14px', fontWeight: '700' }}>
          {label === 'pause' ? 'Paused' : 'Ongoing'}
        </div>
        <div>
          <p className={classes.paragraph}>{count ? count.length : 0} Cases.</p>
        </div>
      </>
    )
  }

  return (
    <Box className={classes.stepper} sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} sx={{ alignItems: 'baseline' }}>
        {
          steps.map((step) => {
            const _cases = overview.filter((item) => item.case_type === step);
            const _grouped = groupBy(_cases, "due_date");

            return (
              <Step className={classes.step} key={step}>
                <StepButton
                  className={classes.stage}
                  style={{ cursor: 'default', zIndex: 1000 }}
                  icon={<Chip style={{ padding: '0', height: '70px', minWidth: 225, borderRadius: '0' }} label={getLabel(step)} variant={'filled'} color={'primary'} />}
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px', paddingRight: 8 }}>
                  {
                    _grouped ? (
                      Object.keys(_grouped).map((key) => {
                        const _grouped_cases = _grouped[key];
                        return (
                          <>
                            <div style={{ margin: '10px 0' }}>
                              <div>
                                <h6 className={classes.headline}>{key === "none" ? "Without planned actions" : key}</h6>
                              </div>
                              <div>
                                <p className={classes.paragraph}>{_grouped_cases.length} Cases</p>
                              </div>
                            </div>
                            {_grouped_cases.map((__case) => {
                              return (
                                <Card className={`${classes.card} ${selected === __case.potential_participant_id ? 'selected_case' : ''}`} onClick={() => props.handleCaseClick(__case)}>
                                  <CardContent className={classes.cardContent} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: '185px', minHeight: '100px' }}>
                                    <div className={classes.header}>
                                      <Typography sx={{ display: 'flex' }} align="right" variant="body2">
                                        <Circle sx={{ color: __case.indicator, fontSize: "15px" }} />
                                      </Typography>
                                      <Typography className={classes.title} align="left" variant="h5" >
                                        {__case.person_firstname} {__case.person_lastname}
                                      </Typography>
                                      
                                    </div>
                                    <Typography className={classes.assignee}>
                                      <span className={classes.assignlabel}>Assigned To:</span> {__case.assignee}
                                    </Typography>
                                    <div className={classes.footer}>
                                      <Typography className={classes.timeline} align="right" variant="body2">
                                        {__case.due_date && <AccessTime sx={{ fontSize: 17, marginRight: '5px' }} />}
                                        {__case.due_date && (<span className='case-due-date' style={{ fontSize: 13 }}>{__case.due_date}</span>)}
                                      </Typography>
                                      <Typography align="right" variant="body2">
                                        <span className='case-status' style={{ fontSize: 13, textTransform: 'capitalize' }}>{__case.status}</span>
                                      </Typography>
                                    </div>
                                  </CardContent>
                                </Card >
                              )
                            })}
                          </>
                        )
                      })
                    ) : (
                      <div>
                        <p className={classes.paragraph} style={{ textAlign: 'center' }}>No Case Found</p>
                      </div>
                    )
                  }
                </div>
              </Step>
            );
          })}
      </Stepper >
    </Box >
  );
}

export default withStyles(useStyles)(CaseOverviewStepper)