import React from 'react';
import Grid from '@mui/material/Grid';
import {withStyles} from '@mui/styles';
import Jobs from '../Widgets/Jobs';
import ParticipantPurchases from '../../../Common/Overview/ParticipantPurchases';
import ParticipantOpportunities from '../../../Common/Overview/ParticipantOpportunities';
import {actions as eventsOverviewActions, selectors as eventsOverviewSelectors} from '../../../../Ducks/eventsOverview';
import {connect} from 'react-redux';
import {useParams} from 'react-router';
import {bindActionCreators} from 'redux';

const useStyles = _theme => ({
    accordionSummary: {
        '& > div': {
            flexDirection: 'column'
        }
    },
});

function PersonTab(props) {
    const {
        opportunity,
        classes,
        getPersonPurchases,
        getPersonOpportunities,
        personPurchases,
        personOpportunities
    } = props;
    React.useEffect(() => {
        if (opportunity) {
            const personID = opportunity.related_objects.jobs[opportunity.main.job_id].person_id;
            getPersonPurchases(personID);
            getPersonOpportunities(personID);
        }
    }, [opportunity])

    if (!opportunity) {
        return <div></div>
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Jobs showActiveTab={props.showActiveTab}/>
            </Grid>
            {
                personPurchases &&
                <Grid item xs={12}>
                    <ParticipantPurchases
                        classes={classes}
                        personPurchases={personPurchases}
                    />
                </Grid>
            }
            {
                personOpportunities &&
                <Grid item xs={12}>
                    <ParticipantOpportunities
                        classes={classes}
                        personOpportunities={personOpportunities}
                    />
                </Grid>
            }
        </Grid>
    );
}

// export default withStyles(useStyles)(PersonTab);
const mapStateToProps = (state) => ({
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    personPurchases: eventsOverviewSelectors.personPurchases(state),
    personOpportunities: eventsOverviewSelectors.personOpportunities(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getPersonPurchases: eventsOverviewActions.getPersonPurchases,
        getPersonOpportunities: eventsOverviewActions.getPersonOpportunities,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <PersonTab {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));