import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {dateToString} from "../../Utils/dateUtils";
import Chip from "@mui/material/Chip";
import {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import { clone } from "lodash";

const EventGrid = (props) => {
    const {events, selectable, selectableEventCount,getFirstEventDate,selectedEventReference,firstMeetingDate} = props;
    const [selectedEvents, setSelectedEvents] = useState([]);

    useEffect(() => {
        if (events && selectableEventCount) {
            if(firstMeetingDate !== undefined)
            {
                const _selectedEvents = events.filter((e) => e.event_start >= firstMeetingDate).splice(0,selectableEventCount);
                setSelectedEvents((_selectedEvents));
            

                if(selectedEventReference)
                {
                    selectedEventReference(_selectedEvents);
                }
            }else {
                let clonedEvent = clone(events);
                const _selectedEvents = clonedEvent.splice(0,selectableEventCount);
                setSelectedEvents((_selectedEvents));
            }
        }
    }, [events, selectableEventCount]);

    useEffect(() => {
        if (selectedEvents.length > 0) {

            const sortedEvents = [...selectedEvents].sort((a, b) => {
                return new Date(a.event_start) - new Date(b.event_start);
            });

            const firstEvent = sortedEvents[0];

            if(getFirstEventDate)
            {
                getFirstEventDate(firstEvent?.event_start);
            }
        }
    },[selectedEvents])

    const isEventSelected = (eventId) => {
        return selectedEvents.filter((e) => e.event_id === eventId).length > 0
    }

    const handleCheckboxChange = (event,index) => {
        setSelectedEvents((prevSelected) =>{
            if(prevSelected.find((e) => e.event_id === event.event_id))
            {
                return [];
            }else {
                let arr = [];
                for(let i=index; i < events.length; i++)
                {                
                    if(i >= index)
                    {
                        arr.push(events[i]);
                    }  
                    
                    if(index+parseInt(selectableEventCount)-1 === i)
                    {
                        break;
                    } 
                }
                if(selectedEventReference)
                {
                    selectedEventReference([...prevSelected, ...arr]);
                }
                return [...prevSelected, ...arr]
            }
            
                
        });
    };

    const isDisabled = (eventId) => {
        return selectedEvents[0]?.event_id == eventId ? false : selectedEvents.length ? true : false
    };

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            selectable && <TableCell></TableCell>
                        }
                        <TableCell>Event name</TableCell>
                        <TableCell>Event start</TableCell>
                        <TableCell>Event end</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events?.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            {

                                selectable &&
                                <TableCell>
                                    <Checkbox
                                    checked={isEventSelected(row.event_id)}
                                    onChange={() => handleCheckboxChange(row,index)}
                                    disabled={isDisabled(row.event_id,index)}
                                    />
                                </TableCell>
                            }
                            <TableCell component="th" scope="row">
                                {row.event_name} {row.event_has_special_case === 1 &&
                                <Chip label="Paused" size="small"
                                      style={{height: "20px", padding: "8px"}}/>}
                            </TableCell>
                            <TableCell>{dateToString(new Date(row.event_start), 'dd/MM/yyyy H:mm')}</TableCell>
                            <TableCell>{dateToString(new Date(row.event_stop), 'dd/MM/yyyy H:mm')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default EventGrid;
