import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, CardMedia, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField, Radio } from '@mui/material';
import { Phone, Language, BusinessCenter, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt } from '@mui/icons-material';
import moment from 'moment';

export default function ParticipantJobs(props) {
    const { classes, jobs, personObj, getCompanyName, getYears } = props;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordionSummary}
            >
                <Typography variant="h1" component="h5" sx={{ fontSize: '18px', fontWeight: '500', marginBottom: '10px' }}>
                    Jobs
                </Typography>
                {jobs && jobs.main.filter((item) => item.job_is_primary).map((job, index) => {
                    return (
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                    {job.override_company_name || !job.division ? job.override_company_name : getCompanyName(job.division.company_id)}
                                </Typography>

                                <Typography
                                    style={{ flexGrow: "1", fontSize: '11px' }}
                                    gutterBottom
                                    variant="span"
                                    component="span"
                                >
                                    {moment(job.job_start).format("DD/MM/YYYY")} -{" "}
                                    {job.job_stop === null ? (
                                        <>
                                            Present - {getYears(job.job_start, null)} <span>years</span>
                                        </>
                                    ) : (
                                        <>
                                            {moment(job.job_stop).format("DD/MM/YYYY")} - {getYears(job.job_start, job.job_stop)} <span>years</span>
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>

                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {job.job_title}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>

                                {job.job_phone !== "" ? (
                                    <Typography
                                        sx={{
                                            padding: "0 20px 0 0",
                                            fontSize: '13px',
                                        }}
                                        gutterBottom
                                        variant="div"
                                        component="div"
                                    >
                                        <a href={`callto:${job.job_phone}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                            <span
                                                style={{ marginRight: "5px", lineHeight: "1px" }}
                                            >
                                                <Phone
                                                    sx={{ color: "icon.main", fontSize: "15px" }}
                                                />
                                            </span>
                                            <span>{job.job_phone}</span>
                                        </a>
                                    </Typography>

                                ) : (
                                    <div style={{ flex: 1 }}></div>
                                )}
                            </Grid>
                            <Grid item xs={4}>

                                {job.job_email !== "" ? (
                                    <Typography
                                        sx={{
                                            padding: "0 20px 0 0",
                                            fontSize: '13px',
                                            color: 'site.link'
                                        }}
                                        gutterBottom
                                        variant="div"
                                        component="div"
                                    >
                                        <a href={`mailto:${personObj.email}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                            <span
                                                style={{ marginRight: "5px", lineHeight: "1px" }}
                                            >
                                                <Email
                                                    sx={{ color: "icon.main", fontSize: "18px" }}
                                                />
                                            </span>
                                            <span>{job.job_email}</span>
                                        </a>
                                    </Typography>
                                ) : (
                                    <div style={{ flex: 2 }}></div>
                                )}
                            </Grid>
                            {/* <Grid item xs={1}>
                                <Radio
                                    checked={job.job_is_primary === 1 ? true : false}
                                    size="small"
                                    name="job_is_primary"
                                    value={job.job_is_primary}
                                    style={{ float: "right" }}
                                    disabled={true}
                                />
                            </Grid> */}
                        </Grid>
                    );
                })}
            </AccordionSummary>
            <AccordionDetails>
                {jobs && jobs.main.filter((item) => !item.job_is_primary).map((job, index) => {
                    return (
                        <Grid container spacing={2} sx={{ padding: '10px 0', borderTop: '1px solid #c2c2c2', marginTop: 0 }}>
                            <Grid item xs={4} sx={{ paddingTop: '0 !important' }}>
                                <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                    {job.division && getCompanyName(job.division.company_id)}
                                </Typography>

                                <Typography
                                    style={{ flexGrow: "1", fontSize: '11px' }}
                                    gutterBottom
                                    variant="span"
                                    component="span"
                                >
                                    {moment(job.job_start).format("DD/MM/YYYY")} -{" "}
                                    {job.job_stop === null ? (
                                        <>
                                            Present - {getYears(job.job_start, null)} <span>years</span>
                                        </>
                                    ) : (
                                        <>
                                            {moment(job.job_stop).format("DD/MM/YYYY")} - {getYears(job.job_start, job.job_stop)} <span>years</span>
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>

                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {job.job_title}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>

                                {job.job_phone !== "" ? (
                                    <Typography
                                        sx={{
                                            padding: "0 20px 0 0",
                                            fontSize: '13px',
                                        }}
                                        gutterBottom
                                        variant="div"
                                        component="div"
                                    >
                                        <a href={`callto:${job.job_phone}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                            <span
                                                style={{ marginRight: "5px", lineHeight: "1px" }}
                                            >
                                                <Phone
                                                    sx={{ color: "icon.main", fontSize: "15px" }}
                                                />
                                            </span>
                                            <span>{job.job_phone}</span>
                                        </a>
                                    </Typography>

                                ) : (
                                    <div style={{ flex: 1 }}></div>
                                )}
                            </Grid>
                            <Grid item xs={4} sx={{ paddingTop: '0 !important' }}>

                                {job.job_email !== "" ? (
                                    <Typography
                                        sx={{
                                            padding: "0 20px 0 0",
                                            fontSize: '13px',
                                            color: 'site.link'
                                        }}
                                        gutterBottom
                                        variant="div"
                                        component="div"
                                    >
                                        <a href={`mailto:${personObj.email}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                            <span
                                                style={{ marginRight: "5px", lineHeight: "1px" }}
                                            >
                                                <Email
                                                    sx={{ color: "icon.main", fontSize: "18px" }}
                                                />
                                            </span>
                                            <span>{job.job_email}</span>
                                        </a>
                                    </Typography>
                                ) : (
                                    <div style={{ flex: 2 }}></div>
                                )}
                            </Grid>
                        </Grid>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
}