import * as React from "react";
import { Formik, Form } from "formik";
import { Card, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import InputElement from "../../Common/InputElement";
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../Ducks/opportunity';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import CvrModal from "../Opportunity/Modals/CvrModal";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: "16px",
    marginTop: 0,
  },
});
class CompanyDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      fieldListData: null,
      openCvr: false,
      cvrData: []
    };
  }

  componentDidMount() {
    this.dataFormation();
  }

  componentDidUpdate(prevProps) {
    const { cvr } = this.props;
    if (prevProps.cvr !== cvr) {
      this.parseCVR(cvr);
    }
  }

  dataFormation = () => {
    const { countries } = this.props;
    let objCountry = {};
    let list = [];
    countries.main.map((item, key) => {
      return list.push({ id: item.country_id, name: item.country_name });
    });
    objCountry.list = list;
    const fieldList = [
      {
        title: "Company Name",
        name: "company_name",
        type: "textfield",
      },
      {
        title: "Address 1",
        name: "company_address_1",
        type: "textfield",
      },
      {
        title: "Address 2",
        name: "company_address_2",
        type: "textfield",
      },
      {
        title: "City",
        name: "company_city",
        type: "textfield",
      },
      {
        title: "Country",
        name: "company_address_country",
        type: "dropdown",
        list: objCountry.list,
      },
      {
        title: "ZIP Code",
        name: "company_zip_code",
        type: "textfield",
      },
      {
        title: "Bookkeeper Email",
        name: "company_bookkeeper_email",
        type: "textfield",
      },
      {
        title: "EAN",
        name: "company_ean",
        type: "textfield",
      },
      {
        title: "CVR",
        name: "company_cvr",
        type: "textfield",
        onBlur: this.handleCVR
      },
    ];
    this.setState({ fieldListData: fieldList });
  };

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values;
    this.props.onClick({ [name]: formData[name] });
  };

  handleModal = (modal) => {
    this.setState({ [modal]: true });
  };

  handleCVR = (e) => {
    this.props.searchCvr(e.target.value);
  }

  parseCVR = (cvr) => {
    if(cvr.length === undefined && typeof cvr === "object") {
      let cvr_data = [];
      let data_vrvirksomhed = cvr['hits']['hits'][0]['_source']['Vrvirksomhed'];
      if (data_vrvirksomhed['navne'].length && data_vrvirksomhed['beliggenhedsadresse'].length) {
        let company_name_data = data_vrvirksomhed['navne'][data_vrvirksomhed['navne'].length - 1];
        let location_address_data = data_vrvirksomhed['beliggenhedsadresse'][data_vrvirksomhed['beliggenhedsadresse'].length - 1];
        cvr_data['company_name'] = company_name_data['navn'];
        // cvr_data['company_address_country'] = this.getCountryByCode(location_address_data['landekode']);
        cvr_data['company_city'] = location_address_data['kommune'].kommuneNavn; // => City Name
        cvr_data['company_zip_code'] = location_address_data['postnummer']; // => ZipCode
        let floor = location_address_data['etage'] === null ? "" : location_address_data['etage'];
        floor = floor === "" ? "" : `, ${floor}`;
        cvr_data['company_address_1'] = location_address_data['vejnavn'] + " " + location_address_data['husnummerFra'] + floor;
        cvr_data['company_cvr'] = this.formRef.current.values.company_cvr;
        //
  
        this.setState({
          cvrData: cvr_data
        }, () => {
          console.log('form........................', this.formRef.current.values)
          this.handleModal('openCvr')
        })
      }
    }
  }

  handleClose = () => {
    this.setState({ openCvr: false });
  };

  getCountryByCode = (code) => {
    if (code === "DK") {
      return "Denmark"
    }
    return code;
  }

  render() {
    const { classes, companyDetails } = this.props;
    const { fieldListData } = this.state;

    return (
      <React.Fragment>
        {fieldListData && (
          <Card className={classes.paper}>
            <Formik
              innerRef={this.formRef}
              initialValues={companyDetails}
              validate={(values) => {
                const errors = {};
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  this.props.saveNews(values);
                }, 500);
              }}
              render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form>
                  {fieldListData.map((item, index) => {
                    return (
                      <InputElement
                        {...item}
                        key={index}
                        onSaveClick={this.handleSaveClick}
                      />
                    );
                  })}
                </Form>
              )}
            />
          </Card>
        )}
        <CvrModal
          open={this.state.openCvr}
          onClose={this.handleClose}
          opportunity={this.props.opportunity}
          formData={this.formRef.current === null ? null : this.formRef.current.values}
          cvrData={this.state.cvrData}
          formReference={this.formRef}
          updateCompanyData={true}
          companyID={this.props.params.id}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: opportunitySelectors.isLoading(state),
  cvr: opportunitySelectors.cvr(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    searchCvr: opportunityActions.searchCvr,
  },
  dispatch);

function WithNavigate(props) {
  let params = useParams();
  return <CompanyDetailsForm {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));