import Swal from "sweetalert2";

export const validateEmail = (email) => {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return regex.test(email);
};

export const checkIfPersonFoodConsiderationHasChanged = (initialData, formRef, foodConsiderations, component, props) => {
    const formValues = component === 'event_overview' ? formRef.obj : formRef.current.values
    let currentTypeValue = component === 'opportunity' ?
        initialData.opportunity_food_considerations_1 : initialData.person_food_considerations_1;
    let currentAllergiesValue = component === 'opportunity' ? initialData.opportunity_food_considerations_2 : initialData.person_food_considerations_2;
    let updatedTypeValue = formValues.person_food_considerations_1;
    let updatedAllergiesValue = formValues.person_food_considerations_2;
    let text = "Are you sure you want to change the food considerations?<br><br>";
    let currentFoodConsiderationsOptionValue = '';
    let updatedFoodConsiderationsOptionValue = '';
    let formValuesChanged = false;

    if (currentTypeValue !== "" && currentTypeValue !== formValues.person_food_considerations_1) {
        formValuesChanged = true;
    }

    if (currentAllergiesValue.length > 0 && JSON.stringify(currentAllergiesValue) !== JSON.stringify(formValues.person_food_considerations_2)) {
        formValuesChanged = true;
    }

    if (formValuesChanged) {
        if (currentTypeValue !== "") {
            currentFoodConsiderationsOptionValue += foodConsiderations?.type1_food?.find(item => item.id == currentTypeValue)?.title;
        }

        if (currentAllergiesValue?.length > 0) {
            for (let i = 0; i < currentAllergiesValue.length; i++) {
                currentFoodConsiderationsOptionValue += ", " + foodConsiderations?.type2_food?.find(item => item.id == currentAllergiesValue[i])?.title;
            }
        }

        updatedFoodConsiderationsOptionValue = foodConsiderations?.type1_food?.find(item => item.id == updatedTypeValue)?.title;
        updatedFoodConsiderationsOptionValue = (updatedFoodConsiderationsOptionValue === '' || updatedFoodConsiderationsOptionValue === undefined)
            ? 'None' : updatedFoodConsiderationsOptionValue;

        if (updatedAllergiesValue?.length > 0) {
            for (let i = 0; i < updatedAllergiesValue.length; i++) {
                updatedFoodConsiderationsOptionValue += ", " + foodConsiderations?.type2_food?.find(item => item.id == updatedAllergiesValue[i])?.title;
            }
        }

        text += "<b>Current:</b> " + (currentFoodConsiderationsOptionValue === '' ? 'None, None' : currentFoodConsiderationsOptionValue) + "<br><hr>";

        if (updatedFoodConsiderationsOptionValue !== '') {
            text += "<b>Updated:</b> " + updatedFoodConsiderationsOptionValue + "<br>";
        }

        Swal.fire({
            title: 'Food Considerations',
            icon: 'warning',
            html: text,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes, Save Changes!',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#2e7d32',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then((result) => {
            if (result.value) {
                if (component === 'contact') {
                    formRef.current.handleSubmit();
                } else if (component === 'opportunity' && props !== undefined) {
                    props.postOpportunityData({formData: formValues, id: initialData.opportunity_id});
                } else if (component === 'event_overview' && props !== undefined) {
                    props.updateContact(formRef);
                }
            }
        });
    }

    return formValuesChanged;
}