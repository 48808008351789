import {
  createActions,
  asyncInitialState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError,
  asyncSelectors,
} from "./utils";

export const { types, actions } = createActions(
  {
    asyncs: {
      getDivisions: (data) => data,
    },
  },
  "divisions"
);

let initialState = asyncInitialState({
  divisions: null,
  errorMessage: "",
  success: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getDivisions:
      return asyncOnRequest(state, action);
    case types.saga.getDivisions.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          divisions: action.payload.data,
          errorMessage: "",
        };
      });
    case types.saga.getDivisions.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors((state) => state.divisions, {
  errorMessage: (data) => data.errorMessage,
});

const syncSelector = {
  isLoading: (state) => state.divisions.loading,
  divisions: (state) => state.divisions.data.divisions,
};

export const selectors = Object.assign({}, asyncSelector, syncSelector);
