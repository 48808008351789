import * as React from "react";
import { Formik, Form } from "formik";
import { Card } from "@mui/material";
import { withStyles } from "@mui/styles";
import InputElement from "../../Common/InputElement";
import { cloneDeep } from "lodash";

const useStyles = (theme) => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        padding: "16px",
    },
});

class UserDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            fieldListData: null,
            openCvr: false,
            cvrData: []
        };
    }

    componentDidMount() {
        this.dataFormation();
    }

    dataFormation = () => {
        const { countries, userRoles, teams, salesReps } = this.props;
        let objCountry = {};
        let objRoles = {};
        let objTeams = {};

        let list = [];
        let rolesList = [];
        let teamList = [];
        let usersList = [];

        countries && countries.main.map((item, _key) => {
            return list.push({ id: item.country_id, name: item.country_name });
        });

        userRoles && userRoles.map((item, _key) => {
            return rolesList.push({ id: item.role_id, name: item.role_title });
        });

        teams && teams.rows.map((item, _key) => {
            return teamList.push({ id: item.team_id, name: item.team_name });
        });

        salesReps && salesReps.main.map((item, _key) => {
            return usersList.push({ id: item.user_id, name: item.user_email });
        });

        objCountry.list = list;
        objRoles.list = rolesList;
        objTeams.list = teamList;

        const fieldList = [
            {
                title: "First Name",
                name: "user_firstname",
                type: "textfield",
            },
            {
                title: "Last Name",
                name: "user_lastname",
                type: "textfield",
            },
            {
                title: "Display Name",
                name: "display_name",
                type: "textfield",
            },
            {
                title: "Email",
                name: "user_email",
                type: "textfield",
            },
            {
                title: "Title",
                name: "user_title",
                type: "textfield",
            },
            {
                title: "Role",
                name: "role_id",
                type: "dropdown",
                list: objRoles.list,
            },
            {
                title: "Phone",
                name: "user_phone",
                type: "textfield",
            },
            {
                title: "LinkedIn",
                name: "user_linkedin",
                type: "textfield",
            },
            {
                title: "Team",
                name: "team_id",
                type: "dropdown",
                list: objTeams.list,
            },
            {
                title: "Country",
                name: "country_id",
                type: "dropdown",
                list: objCountry.list,
            },
            {
                title: "Alias",
                name: "alias_account_id",
                type: "dropdown",
                list: usersList,
            },
            {
                title: 'Deactivate',
                name: 'user_deactivate',
                type: 'checkbox'
            },
            {
                title: 'Is Parent Account?',
                name: 'is_parent',
                type: 'checkbox'
            },
            {
                title:'Economic Id',
                name:'user_economic_id',
                type:'textfield'
            }
        ];

        this.setState({ fieldListData: fieldList });
    };

    handleSaveClick = (_e, name) => {
        let formData = this.formRef.current.values;
        this.props.onClick({ [name]: formData[name] });
    };

    render() {
        const { classes, userDetails } = this.props;
        const { fieldListData } = this.state;
        var initialData = cloneDeep(userDetails);

        // Handle checkboxes
        initialData.user_deactivate = !!initialData.user_deactivated
        initialData.is_parent = !!initialData.is_parent

        return (
            <React.Fragment>
                {fieldListData && (
                    <Card className={classes.paper}>
                        <Formik
                            innerRef={this.formRef}
                            initialValues={initialData}
                            validate={(_values) => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    this.props.saveNews(values);
                                }, 500);
                            }}
                            render={({ _submitForm, _isSubmitting, _values, _setFieldValue }) => (
                                <Form>
                                    {fieldListData.map((item, index) => {
                                        return (
                                            <InputElement
                                                {...item}
                                                key={index}
                                                onSaveClick={this.handleSaveClick}
                                            />
                                        );
                                    })}
                                </Form>
                            )}
                        />
                    </Card>
                )}

            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(UserDetailsForm);