import {useFormik} from "formik";
import {validateEmail} from "../../Utils/validations";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {useEffect, useState} from "react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Placeholder from "ckeditor5-placeholder";
import Image from "@ckeditor/ckeditor5-image/src/image";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import {Attachment} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

export const editorConfiguration = {
    plugins: [
        List,
        Placeholder,
        Essentials,
        Bold,
        Italic,
        Paragraph,
        SimpleUploadAdapter,
        ImageUpload,
        Image,
        Heading,
        Link,
        AutoLink,
        Indent,
        IndentBlock,
        Font,
        Alignment,
        Underline,
        Strikethrough,
        Table,
        TableToolbar,
        HorizontalLine,
        BlockQuote,
    ],
    toolbar: [
        "heading",
        "|",
        "Placeholder",
        "|",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "outdent",
        "indent",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "horizontalLine",
        "blockQuote",
        "uploadImage",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "alignment:left",
        "alignment:right",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
    ],
    alignment: {
        options: ["left", "right"],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: "https://erp2.f5.dk/api/events/uploadimage",
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    },
    link: {
        decorators: {
            addTargetToExternalLinks: {
                mode: 'automatic',
                callback: url => /^(https?:)?\/\//.test(url),
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            }
        }
    },
    // Allow all HTML tags
    htmlSupport: {
        allow: [
            {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true
            }
        ]
    }
};

const EmailModal = (props) => {
    const [_editor, setEditor] = useState(null);
    const formik = useFormik({
        initialValues: {
            recipients: [],
            template: {},
            subject: "",
            message: "",
            attachment: null,
        },
        validate: (values) => {
            const errors = {};

            if (!values.recipients || !values.recipients.length) {
                errors.recipients = "Recipients are required";
            } else {
                values.recipients.forEach((email, index) => {
                    if (!validateEmail(email)) {
                        errors.recipients[index] = "Invalid email address";
                    }
                });
            }

            if (!values.subject) {
                errors.subject = "Subject is Required";
            }

            if (!values.message) {
                errors.message = "Message is Required";
            }

            if (values.message.length >= 4096) {
                errors.message = "Message exceed maximum character limit.";
            }

            return errors;
        },
        onSubmit: (values) => {
            props.submitEmailFormCallback(values);
        },
    });

    const handleCloseClick = () => {
        formik.resetForm();

        props.handleClose();
    };

    useEffect(() => {
        if (props && props.subject) {
            formik.setFieldValue("subject", props.subject);
        }
    }, [props.subject]);

    useEffect(() => {
        if (props.recipients && props.recipients.length) {
            formik.setFieldValue("recipients", props.recipients);
        }
    }, [props.recipients]);

    return (
        <div>
            <Dialog maxWidth={"lg"} open={props.open} onClose={handleCloseClick}>
                <DialogTitle>Send Email</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            sx={{
                                "& .MuiTextField-root": {
                                    mt: 1,
                                    width: "100%",
                                },
                            }}
                        >
                            <Autocomplete
                                multiple
                                options={props.recipients}
                                defaultValue={props.recipients}
                                getOptionLabel={(option) => option}
                                onChange={(_event, value) => {
                                    formik.setFieldValue("recipients", value);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            variant='outlined'
                                            label={typeof option == "string" ? option : option.email}
                                            {...getTagProps({index})}
                                        />
                                    ))
                                }
                                renderInput={(params) => <TextField {...params} variant='outlined'
                                                                    label='Recipients*'/>}
                            />
                            {formik.touched.recipients && formik.errors.recipients && (
                                <Typography variant='body2' color='error'>
                                    {formik.errors.recipients}
                                </Typography>
                            )}
                            <Autocomplete
                                options={props?.templates}
                                getOptionLabel={(option) => option.template_name}
                                onChange={(_event, value) => {
                                    if (value) {
                                        formik.setFieldValue("template", value);
                                        formik.setFieldValue("subject", value.template_subject);
                                        let body = value.template_body;
                                        body = body.replaceAll('&lt;', '<');
                                        body = body.replaceAll('&gt;', '>');
                                        formik.setFieldValue("message", body);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} variant='outlined'
                                                                    label='Template'/>}
                            />
                            <TextField
                                label='Subject*'
                                style={{marginTop: "25px"}}
                                variant='outlined'
                                id='subject'
                                name='subject'
                                value={formik.values.subject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subject && Boolean(formik.errors.subject)}
                            />
                            {formik.touched.subject && formik.errors.subject && (
                                <Typography variant='body2' color='error'>
                                    {formik.errors.subject}
                                </Typography>
                            )}

                            <InputLabel
                                htmlFor='message'
                                sx={{
                                    color: formik.touched.message && formik.errors.message ? "red" : "inherit",
                                    marginTop: 2,
                                }}
                            >
                                Message*
                            </InputLabel>

                            <CKEditor
                                id='message'
                                name='message'
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={`${formik.values.message}`}
                                onReady={setEditor}
                                onChange={(_event, editor) => {
                                    formik.setFieldValue("message", editor.getData());
                                }}
                            />
                            {formik.touched.message && formik.errors.message && (
                                <Typography variant='body2' color='error'>
                                    {formik.errors.message}
                                </Typography>
                            )}
                        </Box>
                        {props.attachment && (
                            <Tooltip title='Send Document' arrow placement='top'>
                                <IconButton
                                    aria-label='send document'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={() => {
                                        const newTab = window.open(props.attachment, "_blank", "noopener,noreferrer");
                                        if (newTab) {
                                            newTab.onload = () => {
                                                window.URL.revokeObjectURL(props.attachment);
                                            };
                                        }
                                    }}
                                    style={{
                                        color: "#000",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                    }}
                                >
                                    <Attachment color='primary'/> {props.attachmentName}
                                </IconButton>
                            </Tooltip>
                        )}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "12px",
                            }}
                        >
                            <LoadingButton
                                variant='contained'
                                color='secondary'
                                type='submit'
                                style={{marginRight: "12px"}}
                                disabled={props.isLoading}
                                loading={props.isSubmitting}
                            >
                                Send
                            </LoadingButton>
                            <Button variant='contained' color='error' onClick={handleCloseClick}
                                    disabled={props.isSubmitting}>
                                {"Cancel"}
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EmailModal;
