import {Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField} from "@mui/material";
import {dateToString} from "../../Utils/dateUtils";
import {cancelPurchase} from "../../../API/changePersonJob";
import {toast} from "react-toastify";
import {Form, Formik} from "formik";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LoadingButton} from "@mui/lab";
import * as React from "react";
import {useRef} from "react";
import {Close} from "@mui/icons-material";

const CancelPurchaseModal = (props) => {
    const {
        open,
        handleClose,
        personId
    } = props;
    const formRef = useRef(null);

    return (
        <div>
            <Dialog open={open} onClose={(_event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }} scroll="paper" fullWidth maxWidth={"md"}>
                <DialogTitle>Cancel Purchase</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <Close/>
                </IconButton>
                <DialogContent>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            canceled_from: null
                        }}
                        validate={(values) => {
                            const errors = {};

                            if (!values.canceled_from) {
                                errors.canceled_from = 'Required';
                            }

                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            let postData = {};
                            postData.canceled_from = dateToString(new Date(values.canceled_from), 'yyyy-MM-dd');

                            cancelPurchase(personId, postData).then((res) => {
                                if (res) {
                                    toast.success(res.message);
                                    handleClose(true);
                                }

                                setSubmitting(false);
                            }).catch((error) => {
                                setSubmitting(false);
                                toast.error(error.message);
                            });
                        }}>
                        {({isSubmitting, values, setFieldValue, errors}) => (
                            <Form>
                                <Grid container
                                      sx={{
                                          padding: "20px",
                                          textAlign: "left",
                                          display: "flex",
                                          justifyContent: "center"
                                      }}
                                      spacing={3}>
                                    <Grid item xs={5}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name="canceled_from"
                                                label='Canceled From*'
                                                inputFormat='dd/MM/yyyy'
                                                className='custom-datepicker'
                                                value={values.canceled_from} // Set the initial value
                                                onChange={(newValue) => {
                                                    setFieldValue("canceled_from", newValue);
                                                }}
                                                renderInput={(startProps) => (
                                                    <>
                                                        <TextField
                                                            {...startProps}
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            padding={{
                                                                top: "1px",
                                                            }}
                                                            error={
                                                                errors.canceled_from
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "0.75rem",
                                                    margin: "4px 14px 0px",
                                                }}
                                            >
                                                    <span>
                                                        {errors.canceled_from}
                                                    </span>
                                            </div>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button type='submit'
                                                variant='contained'
                                                color='secondary'
                                                onClick={() => handleClose(false)}
                                                style={{marginTop: "10px", marginRight: "6px"}}
                                                disabled={isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                        <LoadingButton type='submit'
                                                       variant='contained'
                                                       color='primary'
                                                       loading={isSubmitting}
                                                       style={{marginTop: "10px", marginRight: "5px"}}
                                        >
                                            Process
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CancelPurchaseModal