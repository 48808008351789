import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { selectors as accountSelectors } from '../../../../Ducks/account';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LostModalV2 from '../Modals/LostModalV2';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import UnsuccessfulModal from '../Modals/UnsuccessfulModal';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import MakeNoteModal from './MakeNoteModal';
import CompletedCallNoteModal from './CompletedCallNoteModal';
import SpellCheck from '../../../Common/TextEditorPlugins/SpellCheck';
import RichTextEditorForTemplates from '../../../Common/RichTextEditorForTemplates';
import AiContentSuggestionDialog from '../../Dialog/AiContentSuggestionDialog';

const editorConfiguration = {
  plugins: [List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote, SpellCheck],
  toolbar: ['heading', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo', 'spellCheck'],
  alignment: {
    options: ['left', 'right']
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  placeholder: "Insert text here ..."
};

const useStyles = () => ({
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px !important'
  },
  missedCallBtn: {
    marginRight: '12px !important'
  },
  modal: {
    marginTop: '12px !important'
  },
  nextPhoneCallBtn: {
    marginTop: '4px !important',
    marginRight: '12px !important'
  }
});

let ckeditor5;

function ActiveCall(props) {
  const { classes, opportunity, user, getCalendar_loading, postOpportunityAction, postOpportunityActionRes, handleTabChange, getCalendar } = props;
  const [content, setContent] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const [openLost, setOpenLost] = React.useState(false);
  const [openUnsuccessful, setOpenUnsuccessful] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(null);
  const [logCode, setLogCode] = React.useState(null);
  const [showMakeNoteModal, setShowMakeNoteModal] = React.useState(false);
  const [showCompletedCallNoteModal, setShowCompletedCallNoteModal] = React.useState(false);
  const [openAiContentSuggestionPopup, setOpenAiContentSuggestionPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [editorData, setEditorData] = useState('');

  const capability = user && user.user_details.capabilities.filter((item) => item.capability_title === "can_close_lost_with_unsuccessful_campaign");

  /* "AiContentSuggestionDialog" component block begins*/
  const acceptSuggestions = () => {
    setEditorData(localStorage.getItem('ai_content'));
    toggleAiContentSuggestionPopup(false);
  }

  const toggleAiContentSuggestionPopup = (toggle) => {
    setOpenAiContentSuggestionPopup(toggle);
  }

  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === 'ai_content') {
        setPopupContent(localStorage.getItem('ai_content'));
        toggleAiContentSuggestionPopup(true);
      }
    };
    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);
  /* "AiContentSuggestionDialog" component block ends*/

  useEffect(() => {
    if (opportunity && ckeditor5) {
      let logContent = localStorage.getItem('active_call_information');
      if (logContent !== null) {
        const log_content = JSON.parse(logContent);
        if (log_content && log_content.opportunity_id !== props.params.id) {
          localStorage.removeItem('active_call_information');
          ckeditor5.setData("");
        }
      }
    }
  }, [opportunity]);

  useEffect(() => {
    if (postOpportunityActionRes !== null && selectedTab !== null)
      getCalendar_loading !== false ? getCalendar() : handleTabChange('actions');
  }, [postOpportunityActionRes, getCalendar_loading, getCalendar]);

  if (!opportunity) {
    return <div></div>
  }

  const handleModal = (modal) => {
    callOpportunityActionV2();
    if (modal === "openLost") {
      setOpenLost(true);
    } else if (modal === "openUnsuccessful") {
      setOpenUnsuccessful(true);
    }
  };

  const handleClick = (log_code) => {
    setLogCode(log_code);
    setModal(true);
  }

  const handleClose = () => {
    setOpenLost(false);
    setOpenUnsuccessful(false);
  };

  const switchTabs = (tab) => {
    let queryparams = `?action=${tab}`;
    if (logCode == 'missed_call')
      queryparams += `&type=add_update_event&opportunity_id=${opportunity.main.opportunity_id}`;

    window.history.replaceState(null, null, queryparams);
    setSelectedTab(tab);
    callOpportunityAction();
  }

  const storeValue = (content) => {
    const data = {
      opportunity_id: props.params.id,
      log_content: content
    }

    localStorage.setItem('active_call_information', JSON.stringify(data));
    setContent(content);
  }

  const clearActiveCallContent = () => {
    localStorage.removeItem('active_call_information');
    ckeditor5 && ckeditor5.setData("");
    setContent('');
  }

  const callOpportunityAction = (values = {}) => {
    let date = new Date();
    const obj = {
      action_type: "follow_up",
      due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
      due_time_precise: 1
    };
    const postData = {
      opportunityId: props.params.id,
      obj: {
        "log_content": content,
        "log_title": logCode === "completed_call" ? "Completed Call" : "Missed Call",
        "log_code": logCode,
        ...values
      }
    }
    postOpportunityAction({ id: opportunity.main.opportunity_id, obj: obj, postData: postData, handleTabChange });
    clearActiveCallContent();
  }

  const callOpportunityActionV2 = (values = {}) => {
    let date = new Date();
    const obj = {
      action_type: "follow_up",
      due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
      due_time_precise: 1
    };
    const postData = {
      opportunityId: props.params.id,
      obj: {
        "log_content": content,
        "log_title": logCode === "completed_call" ? "Completed Call" : "Missed Call",
        "log_code": logCode,
        ...values
      }
    }
    postOpportunityAction({ id: opportunity.main.opportunity_id, obj: obj, postData: postData });
    clearActiveCallContent();
  }

  const handleCompletedCallNoteSubmit = (values) => {
    callOpportunityAction(values);
    props.handleTabChange('deal')
    setShowCompletedCallNoteModal(false);
  };

  const handleMissedCallNoteSubmit = (values) => {
    let date = new Date();
    const obj = {
      action_type: "follow_up",
      due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
      due_time_precise: 1,
      ...values
    };
    const postData = {
      opportunityId: props.params.id,
      obj: {
        "log_content": content,
        "log_title": logCode === "completed_call" ? "Completed Call" : "Missed Call",
        "log_code": logCode,
        ...values
      }
    }

    postOpportunityAction({ id: opportunity.main.opportunity_id, obj: obj, postData: postData });
    clearActiveCallContent();
  };

  return (
    <div className="active-call-tab">
      <MakeNoteModal open={showMakeNoteModal} onSubmit={handleMissedCallNoteSubmit} onClose={() => setShowMakeNoteModal(false)} />
      <CompletedCallNoteModal open={showCompletedCallNoteModal} onSubmit={handleCompletedCallNoteSubmit} onClose={() => setShowCompletedCallNoteModal(false)} />
      <RichTextEditorForTemplates
        editorConfigurationByUser={editorConfiguration}
        data={editorData}
        onReadyProp={(editor) => {
          ckeditor5 = editor;
          let logContent = localStorage.getItem('active_call_information');
          if (logContent !== null) {
            const log_content = JSON.parse(logContent);
            if (log_content && log_content.opportunity_id === props.params.id) {
              setContent(log_content.log_content);
            }
          }
        }}
        onChangeProp={(_event, editor) => {
          storeValue(editor.getData())
        }}
        onBlurProp={(_event, editor) => {
          storeValue(editor.getData());
        }}
      />
      <AiContentSuggestionDialog
        openPopup={openAiContentSuggestionPopup}
        setOpenPopup={toggleAiContentSuggestionPopup}
        popupContent={popupContent}
        acceptSuggestions={acceptSuggestions}
      />
      {
        opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" && (
          <div className={classes.actionContainer}>
            <Button
              variant="contained"
              color="error"
              onClick={(_event) => handleClick('missed_call')}
              className={classes.missedCallBtn}
            >
              Missed Call
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={(_event) => handleClick('completed_call')}
            >
              Completed Call
            </Button>
          </div>
        )
      }
      {
        modal &&
        <Grid className={classes.modal} container spacing={4}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="error"
              onClick={(_event) => handleModal('openLost')}
              className={classes.missedCallBtn}
              size="large"
              fullWidth
            >
              Closed:lost
            </Button>
            {
              opportunity && capability.length > 0 ? (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={(_event) => handleModal('openUnsuccessful')}
                  fullWidth
                  style={{ marginTop: '3px' }}
                >
                  Unsuccessful campaign
                </Button>
              ) : (
                <></>
              )
            }
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="primary"
              onClick={(_event) => setShowMakeNoteModal(true)}
              fullWidth
              className={classes.missedCallBtn}
              size="small"
            >
              {logCode == 'missed_call' ? 'Missed call' : 'Completed Call'} - Note
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={(_event) => switchTabs('call')}
              fullWidth
              className={classes.nextPhoneCallBtn}
              size="small"
            >
              Plan next call
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={(_event) => switchTabs('meeting')}
              fullWidth
              size="small"
              className={classes.nextPhoneCallBtn}
            >
              plan next phone meeting
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                if (opportunity.main.opportunity_type == 'resale') {
                  callOpportunityActionV2();
                  props.handleTabChange('deal')
                } else {
                  setShowCompletedCallNoteModal(true);
                }
              }}
              size={opportunity && capability.length > 0 ? "large" : "medium"}
            >
              Ready for closed:won
            </Button>
          </Grid>
        </Grid>
      }
      <LostModalV2
        open={openLost}
        onClose={handleClose}
        opportunity={opportunity}
      />

      <UnsuccessfulModal
        open={openUnsuccessful}
        onClose={handleClose}
        opportunity={opportunity}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  errorMessage: opportunitySelectors.errorMessage(state),
  postOpportunityActionRes: opportunitySelectors.postOpportunityActionRes(state),
  opportunityActiveCallActionResponse: opportunitySelectors.opportunityActiveCallActionResponse(state),
  user: accountSelectors.user(state),
  getCalendar_loading: opportunitySelectors.getCalendar_loading(state)
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    opportunityActiveCallAction: opportunityActions.opportunityActiveCallAction,
    postOpportunityAction: opportunityActions.postOpportunityAction,
    getCalendar: opportunityActions.getCalendar,
  },
  dispatch);

function WithNavigate(props) {
  let params = useParams();
  return <ActiveCall {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));