import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import Swal from "sweetalert2";
import { types as UsersTypes, actions as usersActions } from '../Ducks/users';

import { callApi } from './utils';
import * as api from '../API/users';


function* getUsersList(action) {
  const { success, failure } = usersActions.saga.getUsersList;

  yield callApi(
    call(api.getUsersList, action.data),
    success,
    failure
  )
}

function* getUserDetails(action) {
  const { success, failure } = usersActions.saga.getUserDetails;

  yield callApi(
    call(api.getUserDetails, action.data),
    success,
    failure
  )
}

function* getTeams(action) {
  const { success, failure } = usersActions.saga.getTeams;

  yield callApi(
    call(api.getTeams, action.data),
    success,
    failure
  )
}

function* addTeam(action) {
  const { success, failure } = usersActions.saga.addTeam;

  function* postApiSuccessCallEffect(res) {
    yield call(getTeams, action);
  }

  yield callApi(
    call(api.addTeam, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* deleteTeam(action) {
  const { success, failure } = usersActions.saga.deleteTeam;

  function* postApiSuccessCallEffect(res) {
    action.data.setTeamID(null);
    yield call(getTeams, action);
  }

  yield callApi(
    call(api.deleteTeam, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updateTeam(action) {
  const { success, failure } = usersActions.saga.updateTeam;

  function* postApiSuccessCallEffect(res) {
    yield call(getTeams, action);
  }

  yield callApi(
    call(api.updateTeam, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updateUser(action) {
  const { success, failure } = usersActions.saga.updateUser;

  function* postApiSuccessCallEffect(res) {
    if (res === "Success") {
      Swal.fire({
        icon: 'success',
        title: 'Saved successfully!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: res.error[1] || 'Something went wrong'
      })
    }
  }

  yield callApi(
    call(api.updateUser, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* createUser(action) {
  const { success, failure } = usersActions.saga.createUser;

  function* postApiSuccessCallEffect(res) {
    if (res.error) {
      Swal.fire({
        icon: 'error',
        title: res.message,
      })
    } else {
      Swal.fire({
        icon: res.error ? 'warning' : 'success',
        title: res.message,
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        action.data.navigateTo();
      })
    }
  }

  yield callApi(
    call(api.createUser, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getCaseTeams(action) {
  const { success, failure } = usersActions.saga.getCaseTeams;
  yield callApi(
    call(api.getCaseTeams,action.data),
    success,
    failure,
  )
}

function* getTeamDetails(action) {
  console.log("ACTIONSAGA....",action);
  const { success, failure } = usersActions.saga.getTeamDetails;

  yield callApi(
    call(api.getTeamDetails,action.data),
    success,
    failure,
  )
}

function* getActiveTeamUsers(action) {
  const { success, failure } = usersActions.saga.getActiveTeamUsers;

  yield callApi(
    call(api.getActiveTeamUsers,action.data),
    success,
    failure,
  )
}

function* getActiveUsers(action) {
  const { success, failure } = usersActions.saga.getActiveUsers;

  yield callApi(
    call(api.getActiveUsers,action.data),
    success,
    failure,
  )
}


export default function* rootCustomerSaga() {
  yield takeLatest(UsersTypes.getUsersList, getUsersList);
  yield takeLatest(UsersTypes.getUserDetails, getUserDetails);
  yield takeLatest(UsersTypes.getTeams, getTeams);
  yield takeLatest(UsersTypes.addTeam, addTeam);
  yield takeLatest(UsersTypes.updateTeam, updateTeam);
  yield takeLatest(UsersTypes.deleteTeam, deleteTeam);
  yield takeLatest(UsersTypes.updateUser, updateUser);
  yield takeLatest(UsersTypes.createUser, createUser);
  yield takeLatest(UsersTypes.getCaseTeams,getCaseTeams);
  yield takeLatest(UsersTypes.getTeamDetails,getTeamDetails);
  yield takeLatest(UsersTypes.getActiveTeamUsers,getActiveTeamUsers);
  yield takeLatest(UsersTypes.getActiveUsers,getActiveUsers);
}