import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import {
    Button,
    InputLabel,
    Box,
    CircularProgress,
    Typography,
    DialogTitle,
    TextField,
    RadioGroup,
    Radio,
    FormControl,
    FormLabel,
    FormControlLabel
} from '@mui/material';

import { selectors as accountSelectors } from '../../../../Ducks/account';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { createMissedCallNote } from '../../../../API/opportunity';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Placeholder from "ckeditor5-placeholder";
import { useParams } from 'react-router-dom';
import Image from '@ckeditor/ckeditor5-image/src/image';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';


const editorConfiguration = {
    plugins: [List, Placeholder, Essentials, Bold, Italic, Paragraph, SimpleUploadAdapter, ImageUpload, Image, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
    toolbar: ['heading', '|', 'Placeholder', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', 'uploadImage', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    placeholderProps: {
        types: ['FROM_FULLNAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'EMAIL_SUBJECT', 'FROM_EMAIL', 'TO_FULLNAME', 'TO_FIRSTNAME', 'FIRST_MEETING_DATE', 'FIRST_MEETING_START_TIME', 'FIRST_MEETING_END_TIME', 'FIRST_MEETING_DATE_AND_TIME', 'PRODUCT_NAME', 'PRODUCT_LOCATION', 'PRODUCT_ADDRESS', 'PRODUCT_ZIP_CODE', 'PRODUCT_CITY', 'NETWORK_DIRECTOR_1_FIRSTNAME', 'NETWORK_DIRECTOR_1_FULLNAME', 'NETWORK_DIRECTOR_2_FIRSTNAME', 'NETWORK_DIRECTOR_2_FULLNAME'],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: 'https://erp2.f5.dk/api/events/uploadimage',
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem('token')
        }
    },
};

const useStyles = theme => ({
    select: {
        "& ul": {
            display: "grid",
        }
    },
});

const ShowProgress = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
    </div>
)

const noteTitles = [ 'Medlemscase', 'Virksomhedsbesøg' ];

function CompletedCallNoteModal(props) {
    const [editor, setEditor] = React.useState(null);
    let { id: opportunityId } = useParams();
    const { createMissedCallNote, onClose, open } = props;

    const validationSchema = yup.object({
      title: yup.string().oneOf(noteTitles),
      subject: yup.string().required(),
      note: yup.string().required(),
    });

    const handleSubmit = (values) => {
      props.onSubmit(values);
      onClose();
    };
  
    const formik = useFormik({
      validationSchema,
      onSubmit: handleSubmit,
      initialValues: {
        title: noteTitles[0],
        subject: '',
        note: '',
      }
    });

    React.useEffect(() => {
        return () => {
            formik.resetForm();
            editor?.setData('');
        };
    }, [editor]);


    return (
      <Dialog maxWidth={'lg'} fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle>Make Note</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <>
            <Box
              sx={{
                '& .MuiTextField-root': { mt: 1, width: '100%' },
              }}>
                <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Title</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.subject && Boolean(formik.errors.subject)}
                  name="title"
                >
                  { noteTitles.map(t => <FormControlLabel value={t} control={<Radio />} label={t} />) }
                </RadioGroup>
              </FormControl>

                <TextField
                    label="Subject"
                    variant="outlined"
                    id="subject"
                    name="subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                />
              <InputLabel
                htmlFor="note"
                sx={{
                  color: formik.touched.note && formik.errors.note ? 'red' : 'inherit',
                  marginTop: 2,
                }}>
                Note
              </InputLabel>
              <CKEditor
                id="note"
                name="note"
                editor={ClassicEditor}
                config={editorConfiguration}
                data={formik.values.note}
                onReady={setEditor}
                onChange={(event, editor) => {
                  formik.setFieldValue('note', editor.getData());
                }}
              />
              {formik.touched.note && formik.errors.note && (
                <Typography variant="body2" color="error">
                  {formik.errors.note}
                </Typography>
              )}
            </Box>
          </>
      
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
      
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{ marginRight: '12px' }}
            >
              Create
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
      </Dialog>
    );
}
const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
      createMissedCallNote: opportunityActions.createMissedCallNote
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(CompletedCallNoteModal));