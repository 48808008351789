import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Button, Checkbox, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, FormLabel, Grid, IconButton, List, ListItem, ListItemText, Radio, RadioGroup } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowRightAlt, Bloodtype } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const useStyles = theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(4),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    },
    '.MuiTypography-h6': {
        paddingTop: '0px',
        paddingBottom: 0,
        textAlign: 'center'
    },
    'hr': {
        borderBottom: '1px solid #d7d7d7',
        width: '90%'
    },
    '.MuiGrid-container': {
        borderBottom: '1px solid #d7d7d7'
    },
    'h5': {
        fontSize: '14px',
        textTransform: 'capitalize'
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const FormItem = ({ title, value }) => {
    const formatTitle = () => {
        if (title === 'company_name') {
            let arr = title.split('_');
            return arr.join(" ");
        }
        else {
            let arr = title.split('_');
            arr.splice(0, 1);
            if (arr.length > 1) {
                return arr.join(" ");
            }
            else {
                return arr
            }
        }

    }

    return (
        <Grid container sx={{ marginBottom: '15px' }} spacing={1}>
            <Grid sx={{ textAlign: 'center' }} item xs={4}>
                <Typography component="h5" variant="h5">
                    <b>{formatTitle()}</b>
                </Typography>
            </Grid>
            <Grid sx={{ textAlign: 'center' }} item xs={4}>
                <ArrowRightAlt />
            </Grid>
            <Grid sx={{ textAlign: 'center' }} item xs={4}>
                <Typography component="span">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    )
}

const BorderElement = ({ gap }) => {
    return (
        <div className='border-element' style={{ margin: '0 auto', width: '90%', paddingBottom: gap, borderBottom: '1px solid #d7d7d7' }} />
    )
}


const StageChangeModal = (props) => {
    const { isLoading, onClose, selectedValue, open, classes, currentStage, userStage } = props;
    const [currStage, setCurrStage] = React.useState({});
    const [userSelectedStage, setUserSelectedStage] = React.useState({});

    useEffect(() => {
        setCurrStage(currentStage);
    }, [currentStage])

    useEffect(() => {
        setUserSelectedStage(userStage)
    }, [userStage])

    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )

    // if (!lostReasons || isLoading) {
    //     // return showProgress()
    // }

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        // onClose(value);
    };

    const handleConfirm = () => {
        const data = {
            opportunityId: props.params.id,
            stageId: currentStage.pipeline_stage_id,
            userStageId: userStage.pipeline_stage_id
        }
        props.updateSelectedStage(currentStage.pipeline_stage_id);
        props.updateStage(data);
        handleClose();
    }

    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BorderElement gap="50px" />
            <BootstrapDialogTitle>You are going to change the stage of the opportunity</BootstrapDialogTitle>
            <BorderElement gap="10px" />
            <DialogContent>
                <Grid container sx={{ marginBottom: '15px' }} spacing={1}>
                    <Grid sx={{ textAlign: 'center' }} item xs={4}>
                        <Typography component="h5" variant="h5">
                            <b>{userSelectedStage.pipeline_stage_title}</b>
                        </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: 'center' }} item xs={4}>
                        <ArrowRightAlt />
                    </Grid>
                    <Grid sx={{ textAlign: 'center' }} item xs={4}>
                        <Typography component="span">
                            {currStage.pipeline_stage_title}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color='inherit' onClick={handleConfirm}>Confirm</Button>
                <Button className={classes.button} variant="contained" color="error" onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.isLoading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        postClosedWon: opportunityActions.postClosedWon,
        updateStage: opportunityActions.updateStage,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <StageChangeModal {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));