import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Build, AccessTime, PersonOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  selectors as opportunitySelectors,
  actions as oppertunitiesActions
} from '../../../../Ducks/opportunity';
import { connect } from "react-redux";
import moment from 'moment';

const useStyles = theme => ({
  card: {
    backgroundColor: '#fff',
    marginBottom: '12px !important',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cardBody: {
    clear: 'both',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '13px !important'
  },
  title: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    marginBottom: '5px !important'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    alignItems: 'center'
  },
  headline: {
    fontSize: '12px',
    margin: '0 0 5px'
  },
  paragraph: {
    fontSize: '11px',
    margin: '0 0 10px'
  },
  timeline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const STATUS_COLORS = {
  'Accepted': 'green',
  'Pending': 'gray',
  'Rejected': '#8B0000',
  'Tentative': '#FFD700'
};

function PipelineOpportunityCard(props) {
  const { classes, opportunityId, stages, navigate, events } = props;
  const { job_id, opportunity_source, product_id, next_planned_action_time } = stages.related_objects.opportunities[opportunityId];
  const job = stages.related_objects.jobs[job_id];
  const { product_abbreviation } = stages.related_objects.products[product_id];
  const { person_id } = job;
  const person = stages.related_objects.persons[person_id];
  const [event, setEvent] = React.useState();

  const getTime = (date) => {
    return moment(date).format('HH:mm');
  }

  React.useEffect(() => {
    const event = events?.filter(e => {
      return e.event_id?.split('__').length === 2 && e.event_id?.split('__')[1] == opportunityId;
    }).sort((a, b) => new Date(b.created) - new Date(a.created)).at(0);

    if (event) {
      const all_accepted = event.all_accepted;
      const status_rejected = event.attendees.some(e => e.status == 'declined');
      const status_pending = event.attendees.some(e => e.status == 'needs_action');
      const status_tentative = event.attendees.some(e => e.status == 'tentative');

      if (all_accepted)
        event['event_status_overall'] = 'Accepted';
      else if (status_rejected)
        event['event_status_overall'] = 'Rejected';
      else if (status_tentative)
        event['event_status_overall'] = 'Tentative'
      else
        event['event_status_overall'] = 'Pending';
    }
    setEvent(event);
  }, [events, opportunityId]);

  const ParticipationStatus = styled(Box)`
    height: 15px;
    width: 15px;
    background-color: ${(props) => STATUS_COLORS[props.status]};
    border-radius: 50%;
    margin-left: .4rem;
    display: inline-block;
  `;

  // const isUpcomingEvent = React.useMemo(() => e.find(e => e.opportunity_id == ));

  return (
    <>
      <Card id= {`card-${opportunityId}`} className={classes.card} onClick={() => navigate("/opportunities/id/" + opportunityId)}>
        <CardContent className={classes.cardContent} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '185px' }}>
          <Typography className={classes.title} align="left" variant="h6" component="div">
            {person.person_firstname} {person.person_lastname}
          </Typography>
          <Typography className={classes.cardBody} align="left" variant="body2" color="site.main">
            {job.job_title}
          </Typography>
          <Typography className={classes.cardBody} align="left" variant="body2" color="site.main">
            {stages && stages.related_objects.companies[stages.related_objects.divisions[job.division_id].company_id] ? stages.related_objects.companies[stages.related_objects.divisions[job.division_id].company_id].company_name : ''}
          </Typography>
          <div className={classes.footer}>
            <Typography className={classes.timeline} align="right" variant="body2" color="site.main">
              {next_planned_action_time && <AccessTime sx={{ fontSize: '17px', marginRight: '5px' }} />}
              {next_planned_action_time && getTime(next_planned_action_time)}
              {event && <ParticipationStatus status={event.event_status_overall} />}
            </Typography>
            <Typography align="right" variant="body2" color="site.main">
              <span style={{ color: '#696969', marginRight: '15px' }}>{product_abbreviation}</span>

            </Typography>
            <Typography align="right" variant="body2" color="site.main">
              {
                opportunity_source && opportunity_source.includes("SalesView") ? (
                  <Tooltip title={opportunity_source}>
                    <PersonOutline sx={{ color: next_planned_action_time ? 'green' : 'red', fontSize: "16px" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title={opportunity_source}>
                    <Build sx={{ color: next_planned_action_time ? 'green' : 'red', fontSize: "13px" }} />
                  </Tooltip>
                )
              }

            </Typography>
          </div>
          {
            event &&
            <div>
              <Typography sx={{ fontSize: '.7rem' }} color="site.main" gutterBottom>
                <b>Event Last Updated:</b> <br />
                {new Date(event.updated).toLocaleString()}
              </Typography>
            </div>
          }
        </CardContent>
      </Card >
    </>
  );
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <PipelineOpportunityCard {...props} navigate={navigate} />
}


const mapStateToProps = (state) => ({
  events: opportunitySelectors.events(state),
  getAllEvents_loading: opportunitySelectors.getAllEvents_loading(state)
});



export default connect(mapStateToProps)(withStyles(useStyles)(WithNavigate));