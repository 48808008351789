import {
    Timeline, TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {
    Call,
    Email,
    ExpandMore as ExpandMoreIcon,
    PriorityHigh,
    StickyNote2
} from "@mui/icons-material";
import React from "react";
import {Card, CardActions, CardContent, IconButton, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import moment from "moment/moment";

const PersonHistoryTimeLine = (props) => {
    const {history, user, person} = props;

    const getUsername = (log) => {
        if (log && log.user_id === null) return "Someone";
        if (log.user_id === user.user_details.user_id) return "You";
        return log.user_firstname + " " + log.user_lastname;
    };

    const makeHeader = (key) => {
        key = key.replaceAll("_", " ");
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0) + splitStr[i].substring(1);
            key = splitStr.join(" ");
        }

        return key;
    };

    const getCard = (log) => {
        switch (log.log_code) {
            case "missed_call":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a missed call
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: "30px"}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                );
            case "welcome_call":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a welcome call
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: "30px"}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                );
            case "sent_sms":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a sent sms
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'>outgoing sms 1 test template</span>
                    </Typography>
                );
            case "sent_email":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} sent an email
                        to <b>{person}</b>
                        <br/>
                        <span>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: "30px"}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                );
            case "inbound_email_note":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a inbound email note
                        for{" "}
                        <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                );
            case "outbound_email_note":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a outbound email note
                        for{" "}
                        <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                );
            case "inbound_call_note":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a inbound call note
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                );
            case "lead_sheet_note":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a lead sheet note
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                );
            case "lead_sheet_call":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a lead sheet call
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                );
            case "completed_call":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a completed call
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: "30px"}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                );
            case "closed:lost":
            case "closed:won":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} changed the
                        opportunity <b>status</b> to{" "}
                        <b>{log.new_status}</b> <br/>{" "}
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                );
            case "person_erp_note":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} person erp note
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                );
            case "outbound_email":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} an outbound email
                        for <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className='card-text mt-2 mb-2'
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                );
            case "status_change":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "Have" : "has"} changed
                        the <b>status</b> to{" "}
                        <b>{log.pipeline_stage_title === undefined ? log.new_status : log.pipeline_stage_title}</b>
                        <br/>{" "}
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                );
            case "opportunity_transferred":
            case "pipeline_stage_change":
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "Have" : "has"} {log.log_content}
                        <br/>{" "}
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                );

            default:
                return (
                    <Typography variant='body2' color='site.main'>
                        <b>{getUsername(log)}</b> {getUsername(log) === "You" ? "have" : "has"} a {getLogTitle(log)} for{" "}
                        <b>{person}</b>
                        <br/>
                        <span className='card-text mt-2 mb-2'>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: "30px"}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                );
        }
    };

    const getLogTitle = (log) => {
        const {log_code} = log;
        return makeHeader(log_code);
    };

    const getIcon = (log) => {

        switch (log.person_log_code) {
            case 'missed_call':
                return <Call style={{color: 'red'}}/>
            case 'completed_call':
                return <Call style={{color: 'green'}}/>
            case 'sent_email':
                return <Email/>
            case 'inbound_email_note':
                return <StickyNote2/>
            default: {
                return <PriorityHigh/>
            }
        }
    }

    const getBgColor = (log) => {
        switch (log.log_code) {
            case "missed_call":
                return "red";
            case "completed_call":
                return "green";
            default: {
                return "";
            }
        }
    };

    return (
        <Timeline>
            {
                history?.map((log) => (
                    <TimelineItem>
                        <TimelineOppositeContent style={{flex: 0}}></TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot sx={{background: getBgColor(log)}}>{getIcon(log)}</TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <GetCardContent log={log} getCard={getCard}/>
                        </TimelineContent>
                    </TimelineItem>
                ))
            }
        </Timeline>
    );
}

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

function GetCardContent({log, getCard}) {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Card>
            <CardContent>
                <div
                    style={{
                        height:
                            log.log_code === "sent_email" || log.log_code === "inbound_email_note" || log.log_code === "outbound_email_note"
                                ? expanded
                                    ? "auto"
                                    : "144px"
                                : "auto",
                        overflow: "hidden",
                        display: "flex",
                    }}
                >
                    <div style={{marginRight: "8px"}}>
                        <img
                            style={{borderRadius: "50%"}}
                            width={35}
                            height={35}
                            src={`https://erp.f5.dk/uploads/scraped_source_images/${log.user_id}.jpg`}
                        />
                    </div>
                    {getCard(log)}
                </div>
            </CardContent>
            {(log.log_code === "sent_email" || log.log_code === "inbound_email_note" || log.log_code === "outbound_email_note") && (
                <CardActions style={{height: "20px", display: "flex", justifyContent: "center"}}>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label='show more'
                        style={{marginTop: "-14px"}}
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>
            )}
        </Card>
    );
}

export default PersonHistoryTimeLine;