import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

export default function TagHook({tags,setValue,selectedTags,edit}) {
  const [options, setOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  React.useEffect(() => {
    const newOptions = tags.map((item) => item.tag_name);
    setOptions(newOptions);
    setSelectedOptions(selectedTags);
  },[tags,selectedTags]);

  return (
    <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        value={selectedOptions}
        freeSolo
        readOnly={edit}
        onChange={(_event, value) => {
          setSelectedOptions(value);
          setValue('tags',value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
          />
        )}
      />
  );
}

