import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';
import Swal from 'sweetalert2'

export const {types, actions} = createActions({
    asyncs: {
        login: (data) => (data),
        logout: () => null,
        loginAs: (data) => (data),
        loginAlias: (data) => (data),
        setParentUser: (data) => (data),
        azureLogin: (data) => (data),
        forgotPassword: (data) => (data),
        createNewPassword: (data) => (data),
    }
}, 'account');

const isAdmin = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'admin').length > 0;
}

const superUser = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'view_all_stats').length > 0;
}

const manageTemplate = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_manage_template').length > 0;
}

const manageReleaseNotes = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_manage_release_notes').length > 0;
}

const manageDraftInvoice = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_draft_invoice').length > 0;
}

const manageDeleteInvoice = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_delete_invoice').length > 0;
}

const manageGroupChange = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_manage_group_change').length > 0;
}

const reviewGroupChange = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_review_group_change').length > 0;
}

const manageCancelPurchase = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_cancel_purchase').length > 0;
}

const manageTransferMembership = (user) => {
    if (!user) {
        return false;
    }
    return user.user_details.capabilities.filter(x => x.capability_title === 'can_transfer_membership').length > 0;
}

let initialState = asyncInitialState({
    isLoggedIn: !!localStorage.getItem('token'),
    cronofyAuthorized: localStorage.getItem('cronofy_authorized') === 'true',
    user: JSON.parse(localStorage.getItem('user')),
    resetPassword: '',
    errorMessage: '',
    success: null,
    loginAPIData: null,
    parentUser: null,
    isAdmin: isAdmin(JSON.parse(localStorage.getItem('user'))),
    canViewTeamStates: superUser(JSON.parse(localStorage.getItem('user'))),
    canManageTemplates: manageTemplate(JSON.parse(localStorage.getItem('user'))),
    canManageReleaseNotes: manageReleaseNotes(JSON.parse(localStorage.getItem('user'))),
    canDraftInvoice: manageDraftInvoice(JSON.parse(localStorage.getItem('user'))),
    canDeleteInvoice: manageDeleteInvoice(JSON.parse(localStorage.getItem('user'))),
    canManageGroupChange: manageGroupChange(JSON.parse(localStorage.getItem('user'))),
    canReviewGroupChange: reviewGroupChange(JSON.parse(localStorage.getItem('user'))),
    canCancelPurchase: manageCancelPurchase(JSON.parse(localStorage.getItem('user'))),
    canTransferMembership: manageTransferMembership(JSON.parse(localStorage.getItem('user')))
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.logout:
        case types.login:
        case types.loginAs:
        case types.loginAlias:
        case types.setParentUser:
        case types.azureLogin:
        case types.createNewPassword:
            return asyncOnRequest(state, action);
        case types.saga.logout.success:
            return asyncOnSuccess(state, action, (_data, _action) => {
                // We are handling clear timeout in logout success callback.
                // Only clearing localStorage.
                localStorage.clear();
                return {
                    ...state,
                    data: {
                        ...state.data,
                        isLoggedIn: false
                    }
                }
            })
        case types.saga.createNewPassword.success:
            return asyncOnSuccess(state, action, (data, action) => {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: action.payload.message,
                }).then(() => {
                    window.location.assign('/')
                });
                return {
                    ...data,
                    success: action.payload.message,
                    errorMessage: ''
                };
            })
        case types.saga.login.success:
            return asyncOnSuccess(state, action, (data, action) => {
                const loginError = action.payload.error;
                if (Array.isArray(loginError) && loginError.length)
                    return {loading: false, errorMessage: loginError[1] || loginError[0]}

                const user = action.payload.user;
                const userMeta = action.payload.meta;
                const filterObj = {
                    companyFilter: -1,
                    phoneFilter: -1,
                    pipelineFilter: "all",
                    productFilter: -1
                }
                const {token, cronofy_authorized} = action.payload;
                if (token) {
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('userMeta', JSON.stringify(userMeta))
                    localStorage.setItem('user_login_timestamp', Date.now())
                    localStorage.setItem('cronofy_authorized', cronofy_authorized)
                    localStorage.setItem('sales_overview_filters', JSON.stringify(filterObj))
                }

                return {
                    ...data,
                    user,
                    loginAPIData: action.payload,
                    isLoggedIn: true,
                    cronofyAuthorized: cronofy_authorized,
                    errorMessage: action.payload.message,
                    isAdmin: isAdmin(user),
                    canViewTeamStates: superUser(user),
                    canManageTemplates: manageTemplate(user),
                    canManageReleaseNotes: manageReleaseNotes(user),
                    canDraftInvoice: manageDraftInvoice(user),
                    canDeleteInvoice: manageDeleteInvoice(user),
                    canManageGroupChange: manageGroupChange(user),
                    canReviewGroupChange: reviewGroupChange(user),
                    canCancelPurchase: manageCancelPurchase(user),
                    canTransferMembership: manageTransferMembership(user)
                };
            })

        case types.saga.loginAs.success:
            return asyncOnSuccess(state, action, (data, action) => {
                const user = action.payload.user;
                const userMeta = action.payload.meta;
                const filterObj = {
                    companyFilter: -1,
                    phoneFilter: -1,
                    pipelineFilter: "all",
                    productFilter: -1
                }
                const {token, cronofy_authorized} = action.payload;
                if (token) {
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('userMeta', JSON.stringify(userMeta))
                    localStorage.setItem('cronofy_authorized', cronofy_authorized)
                    localStorage.setItem('sales_overview_filters', JSON.stringify(filterObj))
                }
                return {
                    ...data,
                    user,
                    loginAPIData: action.payload,
                    isLoggedIn: true,
                    cronofyAuthorized: cronofy_authorized,
                    errorMessage: action.payload.message,
                    isAdmin: isAdmin(user),
                    canViewTeamStates: superUser(user),
                    canManageTemplates: manageTemplate(user),
                    canManageReleaseNotes: manageReleaseNotes(user),
                    canDraftInvoice: manageDraftInvoice(user),
                    canDeleteInvoice: manageDeleteInvoice(user),
                    canManageGroupChange: manageGroupChange(user),
                    canReviewGroupChange: reviewGroupChange(user),
                    canCancelPurchase: manageCancelPurchase(user),
                    canTransferMembership: manageTransferMembership(user)
                };
            })
        case types.saga.loginAlias.success:
            return asyncOnSuccess(state, action, (data, action) => {
                const user = action.payload.user;
                const userMeta = action.payload.meta;
                const filterObj = {
                    companyFilter: -1,
                    phoneFilter: -1,
                    pipelineFilter: "all",
                    productFilter: -1
                }
                const {token, cronofy_authorized} = action.payload;
                if (token) {
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('userMeta', JSON.stringify(userMeta))
                    localStorage.setItem('cronofy_authorized', cronofy_authorized)
                    localStorage.setItem('sales_overview_filters', JSON.stringify(filterObj))
                }
                return {
                    ...data,
                    user,
                    loginAPIData: action.payload,
                    isLoggedIn: true,
                    cronofyAuthorized: cronofy_authorized,
                    errorMessage: action.payload.message,
                    isAdmin: isAdmin(user),
                    canViewTeamStates: superUser(user),
                    canManageTemplates: manageTemplate(user),
                    canManageReleaseNotes: manageReleaseNotes(user),
                    canDraftInvoice: manageDraftInvoice(user),
                    canDeleteInvoice: manageDeleteInvoice(user),
                    canManageGroupChange: manageGroupChange(user),
                    canReviewGroupChange: reviewGroupChange(user),
                    canCancelPurchase: manageCancelPurchase(user),
                    canTransferMembership: manageTransferMembership(user),
                };
            })
        case types.saga.setParentUser.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    parentUser: action.payload,
                    errorMessage: "",
                };
            })
        case types.saga.azureLogin.success:
            return asyncOnSuccess(state, action, (data, action) => {
                if (action.payload.user && action.payload.token) {
                    const user = action.payload.user;
                    const userMeta = action.payload.meta;
                    const {token} = action.payload;
                    if (token) {
                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(user))
                        localStorage.setItem('userMeta', JSON.stringify(userMeta))
                    }
                    return {
                        ...data,
                        user,
                        loginAPIData: action.payload,
                        isLoggedIn: true,
                        errorMessage: action.payload.message,
                        isAdmin: isAdmin(user),
                        canViewTeamStates: superUser(user),
                        canManageTemplates: manageTemplate(user),
                        canManageReleaseNotes: manageReleaseNotes(user),
                        canCancelPurchase: manageCancelPurchase(user),
                        canTransferMembership: manageTransferMembership(user)
                    };
                } else {
                    return {
                        ...data,
                        loginAPIData: action.payload,
                        isLoggedIn: false,
                        errorMessage: action.payload.error_msg
                    };
                }
            })
        case types.saga.logout.failure:
        case types.saga.login.failure:
        case types.saga.loginAs.failure:
        case types.saga.loginAlias.failure:
        case types.saga.setParentUser.failure:
        case types.saga.azureLogin.failure:
        case types.saga.createNewPassword.failure:
            Swal.fire({
                position: "center",
                icon: "error",
                title: action.error.message,
            })
            return asyncOnError(state, action)
        case types.forgotPassword:
            return asyncOnRequest(state, action);
        case types.saga.forgotPassword.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    resetPassword: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.forgotPassword.failure:
            return asyncOnError(state, action)
        default:
            return state
    }
}

const asyncSelector = asyncSelectors(
    (state) => state.account,
    {
        errorMessage: (data) => data.errorMessage,
        success: (data) => data.success,
    }
)

const syncSelector = {
    user: (state) => state.account.data.user,
    parentUser: (state) => state.account.data.parentUser,
    loading: (state) => state.account.loading,
    isLoggedIn: (state) => state.account.data.isLoggedIn,
    cronofyAuthorized: (state) => state.account.data.cronofyAuthorized,
    canViewTeamStates: (state) => state.account.data.canViewTeamStates,
    canManageTemplates: (state) => state.account.data.canManageTemplates,
    resetPassword: (state) => state.account.data.resetPassword,
    error: (state) => state.account.error,
    canManageReleaseNotes: (state) => state.account.data.canManageReleaseNotes,
    canDraftInvoice: (state) => state.account.data.canDraftInvoice,
    canDeleteInvoice: (state) => state.account.data.canDeleteInvoice,
    canManageGroupChange: (state) => state.account.data.canManageGroupChange,
    canReviewGroupChange: (state) => state.account.data.canReviewGroupChange,
    canCancelPurchase: (state) => state.account.data.canCancelPurchase,
    canTransferMembership: (state) => state.account.data.canTransferMembership,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)