import * as React from "react";
import { Formik, Form } from "formik";
import { Card, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import InputElement from "../../Common/InputElement";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: "16px",
    marginTop: 0,
  },
});

const fieldList = [
  {
    title: "Product Description",
    name: "purchase_description",
    type: "textfield",
  },
  {
    title: "EAN",
    name: "purchase_ean",
    type: "textfield",
  },
  {
    title: "Bookkeeper Email",
    name: "purchase_bookkeeper_email",
    type: "textfield",
  },
  {
    title: "PO Number",
    name: "purchase_po_number",
    type: "textfield",
  },
  {
    title: "Payment Terms",
    name: "purchase_payment_terms",
    type: "textfield",
  },
  {
    title: "Delayed Invoice",
    name: "purchase_delayed_invoice",
    type: "textfield",
  },
  {
    title: "Split Payment",
    name: "purchase_split_payment",
    type: "checkbox",
  },
  {
    title: "Start Date",
    name: "purchase_start",
    type: "textfield",
  },
  {
    title: "End Date",
    name: "purchase_stop",
    type: "textfield",
  },
  {
    title: "Price",
    name: "purchase_price",
    type: "textfield",
  },
  {
    title: "Currency",
    name: "purchase_price_currency",
    type: "textfield",
  },
  {
    title: "Special Considerations",
    name: "purchase_special_conditions",
    type: "textfield",
  },
  {
    title: "Food Considerations",
    name: "purchase_food_considerations",
    type: "textfield",
  },
];

class AgreementForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values;
    this.props.onClick({ [name]: formData[name] });
  };
  render() {
    const { classes, saleDetails } = this.props;
    var initialFormData = {...saleDetails};
    initialFormData.purchase_split_payment = !!initialFormData.purchase_split_payment;

    return (
      <React.Fragment>
        <Card className={classes.paper}>
          <Typography
            style={{ marginBottom: "30px" }}
            component="h6"
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            Agreement
          </Typography>
          <Formik
            innerRef={this.formRef}
            initialValues={initialFormData}
            validate={(values) => {
              const errors = {};
              if (!values.Title) {
                errors.Title = "Required";
              } else if (!values.Message) {
                errors.Message = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.props.saveNews(values);
              }, 500);
            }}
            render={({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form>
                {fieldList.map((item, index) => {
                  return (
                    <InputElement
                      {...item}
                      key={index}
                      onSaveClick={this.handleSaveClick}
                    />
                  );
                })}
              </Form>
            )}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(AgreementForm);
