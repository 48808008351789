import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { GoogleGenerativeAI } from "@google/generative-ai";
 
export default class SpellCheck extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('spellCheck', () => {
            const button = new ButtonView();
            button.set({
                label: 'Check Spell & Grammar',
                withText: true
            });
            button.on('execute', async () => {
                const data = editor.getData();
                let text = await this.getSugessions(data);
                localStorage.setItem('ai_content', text);
                const event = new StorageEvent('storage', {
                    key: 'ai_content',
                    oldValue: "",
                    newValue: text
                });
                window.dispatchEvent(event);
            });
            return button;
        });
    }

    getSugessions = async (prompt) => {
        prompt += ` From the above text, fix only spelling and grammar mistakes and return the result in html`;
        const API_KEY = "AIzaSyA-_KzHE-ajsPi2tfTK_XAg4AmtI2TaFNI";
        const genAI = new GoogleGenerativeAI(API_KEY);
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
        const result = await model.generateContent(prompt);
        const response = await result.response;
        return response.text();
    }
}