import * as React from 'react';
import { withStyles } from '@mui/styles';
import { selectors as companiesSelectors, actions as companiesActions } from '../../../Ducks/companies';
import { selectors as usersSelectors, actions as usersActions } from '../../../Ducks/users';
import { selectors as adminSelectors, actions as adminActions } from '../../../Ducks/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import NetworkDirectorForm from './NetworkDirectorForm';

const useStyles = theme => ({
    container: {
        textAlign: 'left',
    }
});

class NetworkDirector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userDetailsObj: null
        }
    }

    componentDidMount() {
        const { getCountries, getTeams } = this.props;
        getCountries();
        getTeams();
    }

    render() {
        const { classes, countries, teams } = this.props;
        const { userDetailsObj } = this.state;

        if (!teams || !countries) {
            return <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        }
        return (
            <div className={classes.container}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={6}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h6" component="div">
                                    Add Network Director
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NetworkDirectorForm countries={countries} teams={teams} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: usersSelectors.isLoading(state),
    countries: companiesSelectors.countries(state),
    teams: usersSelectors.teams(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getTeams: usersActions.getTeams,
        getCountries: companiesActions.getCountries
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <NetworkDirector {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
