import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {bindActionCreators} from 'redux';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    IconButton,
} from '@mui/material';
import {withStyles} from '@mui/styles';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {useParams} from "react-router-dom";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {BASE_URL, fetchGet} from '../../API/util';
import Swal from 'sweetalert2';
import Autocomplete from '@mui/material/Autocomplete';


const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'end'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    },
    list: {
        listStyle: 'none',
        padding: 0
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        cursor: 'pointer',
        '&:hover': {
            background: '#ebebeb'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-container': {
        '& > .MuiPaper-root': {
            minWidth: '500px',
        }
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    }
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2,}} {...other}>
            {children}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
    );
};


let timer;

const CompaniesProfileUpdateModel = (props) => {
    const {classes, onClose, open, selectedCompanies} = props;
    const [companyId, setCompanyId] = React.useState('');
    const [companyOption, setCompanyOption] = React.useState();
    const [parentCompanies, setParentCompanies] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState('');
    const [settingParent, setSettingParent] = React.useState(false);


    const fetchParentCompanies = async (filter = null, showLoader = true) => {
        showLoader && setLoading(true);

        const searchParams = new URLSearchParams();
        // searchParams.append('company_ids', JSON.stringify(selectedCompanies));
        filter === null && searchParams.append('page_size', 50);
        filter !== null && searchParams.append('company_name', filter);

        const {parent_companies} = await fetchGet(`/companies/mini-companies/parent-companies?${searchParams.toString()}`);
        setParentCompanies(parent_companies);
        setLoading(false);
    };

    useEffect(() => {
        if (!open) {
            setCompanyOption(undefined);
            setCompanyId('');
            return;
        }

        fetchParentCompanies();
    }, [open]);


    const handleCompanyOptionChange = (_e, newVal) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => fetchParentCompanies(newVal === '' ? null : newVal, false), 500);
    };

    const setParent = async () => {
        const parent_company_id = companyId || companyOption;
        if (settingParent || !props.selectedCompanies.length || !parent_company_id)
            return;

        setSettingParent(true);

        let res = await fetch(`${BASE_URL}/persons/mini-profiles/set-parent`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                parent_company_id,
                company_ids: selectedCompanies
            })
        });
        const data = await res.json();
        setSettingParent(false);

        if (res.status !== 200) {
            setError(data.errorMsg);
            return;
        }


        setCompanyOption('');
        setCompanyId('');
        onClose();
        props.onSetParent();
        Swal.fire({
            icon: 'success',
            title: 'Parent updated successfully!',
            showConfirmButton: false,
            timer: 1500
        });
    }


    const submitOnEnter = e => {
        if (e.key === 'Enter')
            setParent();
    }

    return (
        <BootstrapDialog onClose={onClose} open={open} onKeyDown={submitOnEnter}>
            <BootstrapDialogTitle onClose={onClose} sx={{textAlign: 'center'}}><b>Update
                Records</b></BootstrapDialogTitle>
            <DialogContent>
                {loading ? "LOADING" : <>
                    <div>
                        <Typography component="h2" variant="h6" sx={{paddingBottom: '.7rem'}}>
                            <b>Select Parent Company</b>
                        </Typography>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                getOptionLabel={option => `${option.label} (${option.value})`}
                                options={parentCompanies.map(c => ({label: c.company_name, value: c.company_id}))}
                                fullWidth
                                value={companyOption}
                                onInputChange={handleCompanyOptionChange}
                                onChange={(e, selectedOption) => setCompanyOption(selectedOption?.value || '')}
                                renderInput={(params) => <TextField {...params} label="Company Name"/>}
                            />
                        </FormControl>
                    </div>
                    <div>
                        <Typography component="h2" variant="h6" sx={{margin: '.5rem 0', paddingBottom: '.7rem'}}>
                            <b>OR<br/>Enter Parent ID</b>
                        </Typography>
                        <FormControl fullWidth>
                            <TextField
                                id="company_id_label"
                                label="Company ID"
                                value={companyId}
                                onChange={e => setCompanyId(e.target.value)}
                                error={!!error}
                                helperText={error ? error : ''}/>
                        </FormControl>
                    </div>
                </>
                }
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color="error" onClick={onClose}>Cancel</Button>
                <Button className={classes.button} variant="contained" color='success' onClick={setParent}
                        disabled={settingParent || !(props.selectedCompanies.length) || !(companyId || companyOption)}>Set</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {},
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <CompaniesProfileUpdateModel {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));