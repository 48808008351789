import * as React from "react";
import { withStyles } from "@mui/styles";
import { selectors as adminSelectors, actions as adminActions } from "../../../Ducks/admin";
import { selectors as usersSelectors, actions as usersActions } from "../../../Ducks/users";
import { selectors as companiesSelectors, actions as companiesActions } from '../../../Ducks/companies';
import { selectors as accountSelectors } from "../../../Ducks/account";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button,
    Grid,
    CircularProgress,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";

import { Check, RemoveCircle } from "@mui/icons-material";
import Swal from "sweetalert2";
import TeamUsersGrid from "./Grid/TeamUsersGrid";
import AddTeam from "./Modal/AddTeam";
import editIcon from '../../../Assets/images/editIcon.png'

const useStyles = (_theme) => ({
    container: {
        textAlign: "left",
        maxWidth: "90%",
        margin: "0 auto"
    },
    roles: {
        display: 'flex',
        flexDirection: "column !important",
        '& > div': {
            display: 'flex',
            '& > button': {
                width: '100%',
                marginBottom: "10px",
                fontWeight: "bold",
                "&.selected": {
                    background: "#002ea7",
                    color: "#fff",
                    "& + div > .deleteRole": {
                        display: 'none'
                    },
                    "& + div > .updateTeam": {
                        display: 'block',
                        "& > img": {
                            background: '#fff'
                        }
                    }
                }
            }
        }
    },
    box: {
        '& > label': {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            margin: 0,
        },
        '& .MuiTypography-root': {
            width: '100%',
            textTransform: "capitalize"
        }
    },
    accordion: {
        '& .MuiTypography-root': {
            width: '100%',
            textTransform: "capitalize"
        },
        '& .MuiAccordionDetails-root': {
            display: 'flex',
            flexWrap: 'wrap',
            '& > label': {
                display: 'flex',
                margin: 0,
                width: '33%'
            }
        }
    },
    submitForm: {
        width: '100%',
        marginTop: "10px",
        display: "flex",
        justifyContent: "flex-end"
    },
    deleteRole: {
        cursor: 'pointer',
        // position: 'absolute',
        // top: '-5px',
        // right: '-10px',
        fontSize: '18px',
        color: "red",
        // zIndex: 9999,
    },
    updateRole: {
        cursor: 'pointer',
        // position: 'absolute',
        // top: '-5px',
        // right: '10px',
        fontSize: '18px',
        // zIndex: 9999,
    },
    editButtons: {
        position: 'absolute',
        top: '-5px',
        right: '-10px',
        zIndex: 9,
    }
});

const Teams = (props) => {
    const { classes, isLoading, teams, countries,getActiveTeamUsers } = props;
    const [selectedteam, setSelectedteam] = React.useState(1);
    const [teamID, setTeamID] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [updateModal, setUpdateModal] = React.useState(false);
    const [usersState, setUsersState] = React.useState('&equals[user_deactivated]=null');
    const [edit, setEdit] = React.useState(false)

    React.useEffect(() => {
        // Get Teams
        props.getTeams();

        // Get Countries
        props.getCountries();

        //GET USERS
        props.getUsers();
    }, []);

    const teamSelection = (TeamID) => {
        setSelectedteam(TeamID);
    }

    const getTeamName = (teamID) => {
        return teams && teams.rows.filter((item) => item.team_id === teamID)[0].team_name;
    }

    const deleteTeam = (teamID) => {
        const { deleteTeam } = props;
        Swal.fire({
            title: "Confirm Delete",
            text: "Are you sure you want to delete this team?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                const obj = {
                    id: teamID,
                    setTeamID
                }
                deleteTeam(obj);
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    const handleClose = () => {
        setOpen(false);
        setUpdateModal(false);
    };

    const handleUpdateModal = (team_id) => {
        getActiveTeamUsers(`equals[team_id]=${team_id}`)
        setUpdateModal(true)
        setTeamID(team_id);
    }

    if (!countries || !teams) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            <div className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                component="h5"
                                variant="h5"
                                style={{
                                    fontSize: "18px",
                                    color: "#9e9c9c",
                                    borderBottom: '1px solid #d0d0d0',
                                    marginBottom: "40px",
                                    paddingBottom: "10px",
                                }}
                            >
                                Teams
                            </Typography>
                            {
                                !edit && <div><img onClick={() => setEdit(true)} src={editIcon} style={{ width: '18px', cursor: 'pointer' }} /></div>
                            }

                            {
                                edit && <Check sx={{ fontSize: '22px' }} onClick={() => setEdit(false)} />
                            }

                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.roles}>
                                {
                                    teams && teams.rows.map((team) => {
                                        return (
                                            <div style={{ position: 'relative' }}>
                                                <Button
                                                    key={team.team_id}
                                                    variant="contained"
                                                    color="inherit"
                                                    className={selectedteam === team.team_id ? "selected" : ""}
                                                    onClick={(e) => teamSelection(team.team_id, e)}
                                                    sx={{ fontSize: '13px', textTransform: 'capitalize' }}
                                                >
                                                    {team.team_name}
                                                </Button>
                                                <div className={classes.editButtons}>
                                                    {
                                                        edit && (
                                                            <span className="deleteRole updateTeam">
                                                                <img className={classes.updateRole} onClick={(e) => handleUpdateModal(team.team_id, e)} src={editIcon} style={{ width: '18px', cursor: 'pointer' }} />
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        team.users === 0 ? (
                                                            <span className="deleteRole"><RemoveCircle onClick={(e) => deleteTeam(team.team_id, e)} className={classes.deleteRole} /></span>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={(e) => { setOpen(true) }}
                                >
                                    Add New Team
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography
                                    component="h5"
                                    variant="h5"
                                    style={{
                                        fontSize: "18px",
                                        color: "#9e9c9c",
                                        marginBottom: "20px",
                                        borderBottom: '1px solid #d0d0d0',
                                        paddingBottom: "10px",
                                    }}
                                >
                                    Users
                                </Typography>
                                <FormControl>
                                    <InputLabel id="user-state">Users</InputLabel>
                                    <Select
                                        labelId="user-state"
                                        id="user-state-options"
                                        value={usersState}
                                        label="Users"
                                        size="small"
                                        onChange={(e) => setUsersState(e.target.value)}
                                    >
                                        <MenuItem value={'&equals[user_deactivated]=null'}>Active</MenuItem>
                                        <MenuItem value={'&notequals[user_deactivated]=null'}>Not Active</MenuItem>
                                        <MenuItem value={'&users=all'}>Show All</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TeamUsersGrid team={selectedteam} usersState={usersState} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <AddTeam
                open={open}
                onClose={handleClose}
                countries={countries}
            />
            <AddTeam
                open={updateModal}
                onClose={handleClose}
                countries={countries}
                teams={teams}
                teamID={teamID}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    isLoading: adminSelectors.isLoading(state),
    user: accountSelectors.user(state),
    teams: usersSelectors.teams(state),
    countries: companiesSelectors.countries(state),

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getTeams: usersActions.getTeams,
        deleteTeam: usersActions.deleteTeam,
        getCountries: companiesActions.getCountries,
        getUsers: usersActions.getUsersList,
        getActiveTeamUsers: usersActions.getActiveTeamUsers,
    },
    dispatch
);

function WithNavigate(props) {
    let params = useParams();
    return <Teams {...props} params={params} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
