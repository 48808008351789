import * as React from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Button,
  LinearProgress,
  MenuItem,
  Typography,
  Container,
  Grid,
  ButtonGroup,
  Tabs,
  Tab
} from '@mui/material';
import {
  TextField
} from 'formik-material-ui';
import { withStyles } from '@mui/styles';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionsTabList } from '../data';
import Overview from './Overview';
import Email from './ActionTabs/Email';
import Sms from './ActionTabs/Sms';
import Meeting from './ActionTabs/Meeting';
import Call from './ActionTabs/Call';

const useStyles = theme => ({
  noPadding: {
    padding: 0
  },
  container: {
    textAlign: 'left',
  }
});



class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'email',
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('action');
    if(tab) {
      this.setState({ selectedTab: tab });
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoading, success, history } = this.props;
    if (prevProps.isLoading && !isLoading && success) {
      history.push('/');
    }
  }

  componentWillUnmount() {
    window.history.replaceState(null, null,  window.location.origin + window.location.pathname);
  }

  handleChange = (e, newValue) => {
    this.setState({ selectedTab: newValue });
  }

  renderSelectedComponent = () => {
    const { selectedTab } = this.state;
    const { opportunity, isLoading } = this.props;
    switch (selectedTab) {
      case 'email': return <Email opportunity={opportunity} isLoading={isLoading} />;
      case 'sms': return <Sms opportunity={opportunity} isLoading={isLoading} />;
      case 'meeting': return <Meeting opportunity={opportunity} isLoading={isLoading} tab="phone_meeting" handleTabChange={this.props.handleTabChange}/>;
      case 'call': return <Call opportunity={opportunity} isLoading={isLoading} tab="phone_call" handleTabChange={this.props.handleTabChange}/>;
      default: return <Email opportunity={opportunity} />;
    }
  }

  render() {
    const { classes, opportunity, isLoading } = this.props;
    const { selectedTab } = this.state;
    return (
      <div className={classes.container}>
        <Tabs value={selectedTab} variant={'fullWidth'} onChange={this.handleChange} indicatorColor="primary">
          {
            actionsTabList.map((item, index) => <Tab key={index} style={{background: '#d8d9da', padding: 0}} label={item} value={item} />)
          }
        </Tabs>
        <div
          role="tabpanel"
          id={`full-width-tabpanel`}
          aria-labelledby={`full-width-tab`}
          style={{marginTop: '12px'}}
        >
          {
            this.renderSelectedComponent()
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorMessage: opportunitySelectors.errorMessage(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Actions));
