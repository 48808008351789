import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
    asyncs: {
        getResaleOverview: (data) => data,
    }
}, 'resaleOverview');

let initialState = asyncInitialState({
    resaleOverview: null,
    errorMessage: '',
    success: false
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.getResaleOverview:
            return asyncOnRequest(state, action);
        case types.saga.getResaleOverview.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    resaleOverview: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getResaleOverview.failure:
            return asyncOnError(state, action)
        default:
            return state
    }
}

const asyncSelector = asyncSelectors(
    (state) => state.resaleOverview,
    {
        errorMessage: (data) => data.errorMessage,
        getResaleOverview_loading: (data) => data.getResaleOverview_loading,
    }
)

const syncSelector = {
    isLoading: (state) => state.resaleOverview.loading,
    resaleOverview: (state) => state.resaleOverview.data.resaleOverview,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)