import * as React from 'react';
import { withStyles } from '@mui/styles';
import { selectors as companiesSelectors, actions as companiesActions } from '../../../Ducks/companies';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import { selectors as accountSelectors } from '../../../Ducks/account';
import ContactDetailsForm from './CompanyDetailsForm';
// import AgreementForm from './AgreementForm';
// import InvoiceForm from './InvoiceForm';
import CircularProgress from '@mui/material/CircularProgress';
import CompanyCard from './CompanyCard';
import DivisionsList from './Grids/Division'

const useStyles = theme => ({
  container: {
    textAlign: 'left',
  }
});

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: props.params.id,
      // companyColumns: props.user.tableSettings.purchases.columns,
      companyColumns: [
        'company_id','erp_client_id','country_id','company_cvr','company_name','company_ean','company_corporate_form','company_phone','company_bookkeeper_email','company_co_name','company_address_1','company_address_2','company_zip_code','company_city','company_address_country','company_is_public_sector','company_year_started','company_sales_euros','company_employees_total','dnb_duns_unique_id'
      ],
      companyDetails: null
    }
  }

  componentDidMount() {
    this.getCompanyDetail();
    this.getCountryList();
  }

  getCountryList = () => {
    const { getCountries } = this.props;
    getCountries();

  }

  getCompanyDetail = () => {
    const { getCompanyDetails, getStages } = this.props;
    const { selectedCompany, companyColumns } = this.state;
    getCompanyDetails({ id: selectedCompany, columns: companyColumns });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.companyDetails !== this.props.companyDetails) {
      this.setState({ companyDetails: this.props.companyDetails && this.props.companyDetails.main[0]})
    }
  }

  handleSaveClick = (obj) => {
    this.props.updateCompany({ id: this.state.selectedCompany, obj: obj, key: Object.keys(obj)[0]});
  }

  render() {
    const { classes, countries } = this.props;
    const { companyDetails, selectedCompany } = this.state;

    if(!companyDetails || !countries) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
    }
    return (
      <>
        {(companyDetails && countries) &&
          (<div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CompanyCard company={companyDetails} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ContactDetailsForm companyDetails={companyDetails} countries={countries} onClick={this.handleSaveClick}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DivisionsList />
              </Grid>
            </Grid>
          </div>)
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: companiesSelectors.isLoading(state),
  user: accountSelectors.user(state),
  companyDetails: companiesSelectors.companyDetails(state),
  countries: companiesSelectors.countries(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCompanyDetails: companiesActions.getCompanyDetails,
    updateCompany: companiesActions.updateCompany,
    getCountries: companiesActions.getCountries
  },
  dispatch);

function WithNavigate(props) {
  let params = useParams();
  return <CompanyDetails {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
