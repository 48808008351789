import { createActions, asyncInitialState, asyncOnRequest, asyncOnSuccess, asyncOnError, asyncSelectors } from "./utils";

export const { types, actions } = createActions(
	{
		asyncs: {
			getUsersList: (data) => data,
			getUserDetails: (data) => data,
			getTeams: (data) => data,
			getTeamDetails: (data) => data,
			updateUser: (data) => data,
			createUser: (data) => data,
			addTeam: (data) => data,
			updateTeam: (data) => data,
			deleteTeam: (data) => data,
			resetTeamDetails: (data) => data,
			getCaseTeams: (data) => data,
			getActiveTeamUsers: (data) => data,
			getActiveUsers: (data) => data,
		},
	},
	"users"
);

let initialState = asyncInitialState({
	usersList: null,
	userDetails: null,
	teams: null,
	caseTeams: null,
	teamsDetails: [],
	activeTeamUsers: [],
	activeUsers: [],
	errorMessage: "",
	success: false,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case types.getUsersList:
		case types.getUserDetails:
		case types.getTeams:
		case types.addTeam:
		case types.updateTeam:
		case types.deleteTeam:
		case types.updateUser:
		case types.createUser:
		case types.getTeamDetails:
		case types.getCaseTeams:
		case types.getActiveTeamUsers:
		case types.getActiveUsers:
			return asyncOnRequest(state, action);
		case types.resetTeamDetails:
			return {
				...asyncOnRequest(state, action),
				data: {
					...state.data,
					teamsDetails: action.data != null ? state.data.teamsDetails.filter((item, index) => index <= action.data) : [],
				},
			};
		case types.saga.getUsersList.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					usersList: action.payload.rows,
					errorMessage: "",
				};
			});
		case types.saga.getUserDetails.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					userDetails: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.updateUser.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					updatedUser: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.createUser.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					createdUser: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.getTeams.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					teams: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.addTeam.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					addedTeam: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.deleteTeam.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					deletedTeam: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.updateTeam.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					updatedTeam: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.getTeamDetails.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					teamsDetails: [...state.data.teamsDetails, action.payload],
					errorMessage: "",
				};
			});
		case types.saga.getCaseTeams.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					caseTeams: action.payload,
					errorMessage: "",
				};
			});
		case types.saga.getActiveTeamUsers.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					activeTeamUsers: action.payload.users,
					errorMessage: "",
				};
			});
		case types.saga.getActiveUsers.success:
			return asyncOnSuccess(state, action, (data, action) => {
				return {
					...data,
					activeUsers: action.payload.rows,
					errorMessage: "",
				};
			});
		case types.saga.getUsersList.failure:
		case types.saga.getUserDetails.failure:
		case types.saga.getTeams.failure:
		case types.saga.addTeam.failure:
		case types.saga.updateTeam.failure:
		case types.saga.deleteTeam.failure:
		case types.saga.updateUser.failure:
		case types.saga.createUser.failure:
		case types.saga.getTeamDetails.failure:
		case types.saga.getCaseTeams.failure:
		case types.saga.getActiveTeamUsers:
		case types.saga.activeUsers:
			return asyncOnError(state, action);
		default:
			return state;
	}
};

const asyncSelector = asyncSelectors((state) => state.users, {
	errorMessage: (data) => data.errorMessage,
});

const syncSelector = {
	isLoading: (state) => state.users.loading,
	usersList: (state) => state.users.data.usersList,
	userDetails: (state) => state.users.data.userDetails,
	teams: (state) => state.users.data.teams,
	teamDetails: (state) => state.users.data.teamsDetails,
	caseTeams: (state) => state.users.data.caseTeams,
	activeTeamUsers: (state) => state.users.data.activeTeamUsers,
	activeUsers: (state) => state.users.data.activeUsers,
};

export const selectors = Object.assign({}, asyncSelector, syncSelector);
