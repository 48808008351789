import {Autocomplete, Card, FormControlLabel, Grid, TextField, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import EventGrid from "../ChangePersonGroup/EventGrid";
import {checkFutureEvent} from "../../Utils/dateUtils";
import {DoubleArrow} from "@mui/icons-material";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import moment from "moment/moment";
import {getStatusClass} from "../../Utils/gridUtils";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

const TransferMembershipOverview = (props) => {
    const {
        products,
        person,
        newPerson,
        product,
        processing,
        handleProcessClick,
        setProductCallback,
        newEvents,
        eventOverview,
        classes
    } = props;
    const [newProduct, setNewProduct] = useState(null);
    const [meetingsCount, setMeetingsCount] = useState(0);
    const [newProductUpcomingEvents, setNewProductUpcomingEvents] = useState([]);
    const [doProceed, setDoProceed] = useState(false);

    useEffect(() => {
        if (product && products) {
            const selectedProduct = products.find((productItem) => productItem.product_id === product.product_id);

            setNewProduct(selectedProduct);
        }
    }, [product, products]);

    useEffect(() => {
        if (newEvents) {
            setNewProductUpcomingEvents(newEvents);
            setMeetingsCount(newEvents.length)
        }
    }, [newEvents]);

    const fetchNewProductUpcomingEvents = (events, meetingsLeft) => {
        let upcomingEvents = [];

        events.some((event) => {
            if (checkFutureEvent(event)
                && upcomingEvents.length < meetingsLeft
            ) {
                upcomingEvents.push(event);
            }
            return false;
        });

        return upcomingEvents
    }

    const disableProcessButton = () => {
        return !newPerson || !newProduct || !newEvents || !doProceed;
    }

    return (
        <>
            <Grid container spacing={2} sx={{padding: "10px"}}>
                <Grid item xs={5}>
                    <Card className='rounded-box'>
                        <Typography variant="span" component="span" sx={{paddingBottom: "10px", fontWeight: 'bold'}}>
                            Existing Member
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Person Name:</TableCell>
                                        <TableCell>{person?.person_firstname} {person?.person_lastname}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Mobile Number:</TableCell>
                                        <TableCell>{person?.person_mobile_number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Company Name:</TableCell>
                                        <TableCell>{person?.company_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Title:</TableCell>
                                        <TableCell>{person?.job_title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Email:</TableCell>
                                        <TableCell>{person?.job_email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Phone:</TableCell>
                                        <TableCell>{person?.job_phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LinkedIn Profile:</TableCell>
                                        <TableCell><a href={person?.person_linkedin_url}
                                                      target="_blank">{person?.person_linkedin_member_id}</a></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Region:</TableCell>
                                        <TableCell>{person?.person_region}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Country:</TableCell>
                                        <TableCell>{person?.person_country}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes?.flex} style={{marginTop: '20px'}}>
                            {
                                eventOverview?.map((item, index) => {
                                    const color = getStatusClass(item);
                                    return (
                                        <Tooltip
                                            key={index}
                                            title={
                                                <React.Fragment>
                                                    <div>{item.event_name}</div>
                                                    <div>Date: {moment(item.event_start).format("DD/MM/YYYY HH:mm")}</div>
                                                    <div>
                                                        {item.event_has_happened ? (
                                                            <>
                                                                {item.rating_nps ? <div>NPS
                                                                    Rating: {item.rating_nps}</div> : <></>}{" "}
                                                                {item.rating_overall ? <div>Overall
                                                                    Rating: {item.rating_overall}</div> : <></>}{" "}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <div className={classes[color]}></div>
                                        </Tooltip>
                                    );
                                })
                            }
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={2} sx={{margin: 'auto', textAlign: 'center'}}>
                    <DoubleArrow sx={{fontSize: '50px'}}/>
                </Grid>
                <Grid item xs={5}>
                    <Card className='rounded-box'>
                        <Typography variant="span" component="span" sx={{paddingBottom: "10px", fontWeight: 'bold'}}>
                            New Member
                        </Typography>

                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Person Name:</TableCell>
                                        <TableCell>{newPerson?.person_firstname} {newPerson?.person_lastname}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Mobile Number:</TableCell>
                                        <TableCell>{newPerson?.person_mobile_number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Company Name:</TableCell>
                                        <TableCell>{newPerson?.company_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Title:</TableCell>
                                        <TableCell>{newPerson?.job_title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Email:</TableCell>
                                        <TableCell>{newPerson?.job_email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Phone:</TableCell>
                                        <TableCell>{newPerson?.job_phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LinkedIn Profile:</TableCell>
                                        <TableCell><a href={newPerson?.person_linkedin_url}
                                                      target="_blank">{newPerson?.person_linkedin_member_id}</a></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Region:</TableCell>
                                        <TableCell>{newPerson?.person_region}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Country:</TableCell>
                                        <TableCell>{newPerson?.person_country}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{padding: "10px"}}>
                <Grid item xs={5}>
                    <Card className='rounded-box'>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        name='newProduct'
                                        margin='normal'
                                        size='small'
                                        id='products'
                                        disablePortal
                                        disableClearable
                                        options={products}
                                        value={newProduct}
                                        getOptionLabel={(option) => option.product_name}
                                        onChange={(_event, newValue) => {
                                            setNewProduct(newValue);

                                            const _newProductUpcomingEvents = fetchNewProductUpcomingEvents(newValue.events, meetingsCount);

                                            setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                            setProductCallback(newValue, _newProductUpcomingEvents);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Products'
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type='number'
                                        label='Meetings Count'
                                        variant='outlined'
                                        margin='normal'
                                        size='small'
                                        required
                                        fullWidth
                                        inputProps={{min: 1, max: 5}}
                                        value={meetingsCount}
                                        onInput={(event) => {
                                            const {value} = event.target;

                                            setMeetingsCount(value.replace(/[^0-9]/g, ""));// Remove any character that is not a digit

                                            if (newProduct?.events) {
                                                const _newProductUpcomingEvents = fetchNewProductUpcomingEvents(newProduct.events, value);

                                                setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                                setProductCallback(newProduct, _newProductUpcomingEvents);
                                            }
                                        }}
                                        onKeyDown={(event) => {
                                            const {key, target} = event;
                                            const {value} = target;

                                            if (key === "Backspace" || key === "Delete") {
                                                event.preventDefault();
                                            } else {
                                                // Prevent entering values greater than 5
                                                if (parseFloat(value + key) > 5) {
                                                    event.preventDefault();
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            newProductUpcomingEvents?.length > 0 &&
                            <Grid item xs={12}>
                                <Box style={{padding: "12px"}}>
                                    <Typography variant='h6' component='h6'>
                                        Upcoming events ({newProduct?.product_name})
                                    </Typography>
                                    <Card className='rounded-box'>
                                        <EventGrid events={newProductUpcomingEvents}/>
                                    </Card>
                                </Box>
                            </Grid>
                        }
                    </Card>

                </Grid>
                <Grid item xs={2} sx={{margin: 'auto'}}>
                </Grid>
                <Grid item xs={5}>
                    <Card className='rounded-box'>
                        <Box display='flex' justifyContent='center'>
                            <FormControlLabel
                                control={<Checkbox checked={doProceed}
                                                   onChange={(e) => setDoProceed(e.target.checked)}/>}
                                label='Are you sure want to continue?'
                                sx={{'& .MuiFormControlLabel-label': {fontSize: '0.875rem'}}}/>
                        </Box>
                        <Box display='flex' justifyContent='center'>
                            <LoadingButton type='submit'
                                           variant='contained'
                                           color='primary'
                                           onClick={handleProcessClick}
                                           loading={processing}
                                           style={{marginTop: "10px", marginRight: "5px"}}
                                           disabled={disableProcessButton()}
                            >
                                Process
                            </LoadingButton>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
};

export default TransferMembershipOverview;