import * as React from 'react';
import { TextField } from "formik-material-ui";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form } from 'formik';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Placeholder from "ckeditor5-placeholder";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Image from '@ckeditor/ckeditor5-image/src/image';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import {
    Button,
    LinearProgress,
    MenuItem,
    Typography,
    Container,
    Grid,
    ButtonGroup,
    Tabs,
    Tab,
    CircularProgress,
    Chip
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../../API/util';


const editorConfiguration = {
    plugins: [List, Placeholder, Essentials, Bold, Italic, Paragraph, Heading, SimpleUploadAdapter, Image, ImageUpload, ImageResize, ImageToolbar, ImageStyle, ImageCaption, LinkImage, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote, SpecialCharacters, SpecialCharactersEssentials],
    toolbar: ['heading', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', 'specialCharacters', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', 'uploadImage', 'Placeholder', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    placeholderProps: {
        types: ["EMAIL_SUBJECT", "FROM_EMAIL", "FROM_FULLNAME", "FROM_FIRSTNAME", "FROM_LASTNAME", "TO_EMAIL", "TO_FULLNAME", "TO_FIRSTNAME", "EVENT_DATE", "EVENT_TIME_FROM", "EVENT_TIME_TO", "EVENT_ADDRESS", "EVENT_PARKING_INFO"],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: `${BASE_URL}/events/uploadimage`, // 'https://stag2.f5.dk/api/events/uploadimage',

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem('token')
        }
    },
    image: {
        resizeOptions: [
            {
                name: 'resizeImage:original',
                value: null,
                label: 'Original'
            },
            {
                name: 'resizeImage:20',
                value: '20',
                label: '20%'
            },
            {
                name: 'resizeImage:30',
                value: '30',
                label: '30%'
            },
            {
                name: 'resizeImage:40',
                value: '40',
                label: '40%'
            },
            {
                name: 'resizeImage:50',
                value: '50',
                label: '50%'
            },
            {
                name: 'resizeImage:60',
                value: '60',
                label: '60%'
            },
            {
                name: 'resizeImage:70',
                value: '70',
                label: '70%'
            },
            {
                name: 'resizeImage:80',
                value: '80',
                label: '80%'
            }
        ],
        toolbar: [
            'imageStyle:alignBlockLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignBlockRight',
            '|',
            'imageStyle:alignLeft',
            'imageStyle:alignRight',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'resizeImage',
            '|',
            'linkImage'
        ]
    }
};

function EditEmailModal(props) {
    const formRef = React.createRef();
    const { fields, content, name, uploads, eventID, type } = props;
    const [files, setFiles] = React.useState([]);

    const handleClose = () => {
        setFiles([]);
        props.handleClose()
    };

    const deleteFile = (item, type, event) => {
        const { deleteAttachedFile } = props;
        const data = {
            event_id: eventID,
            upload_id: item.upload_id,
            type
        }

        Swal.fire({
            title: "Confirm Delete",
            text: `Are you sure you want to delete ${item.upload_name}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                deleteAttachedFile(data);
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    const downloadFile = (item, type, event) => {
        window.open(`https://stag1.f5.dk/uploads/${item.upload_token}.${item.upload_extension}`, '_blank')
    }

    const deleteFromFiles = (file) => {
        const fileListArr = Array.from(files)
        const index = fileListArr.indexOf(file);
        if (index !== -1) {
            fileListArr.splice(index, 1);
        }
        formRef.current.setFieldValue("event_file", fileListArr);
        setFiles(fileListArr);
    }

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} className="edit-modal" disableEnforceFocus>
                <DialogTitle style={{ paddingBottom: '0px', paddingTop: '4px' }}>Edit {name}</DialogTitle>
                <DialogContent>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            [fields.subject]: content[fields.subject],
                            [fields.introduction]: content[fields.introduction],
                            [fields.status]: content[fields.status],
                            // event_file_description: ''
                        }}
                        validate={values => {
                            const errors = {};
                            // if (values.event_file && !values.event_file_description) {
                            //     errors.event_file_description = 'Required';
                            // }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            let data = {
                                event_id: props.eventID,
                                [fields.subject]: values[fields.subject],
                                [fields.introduction]: values[fields.introduction],
                                [fields.status]: values[fields.status]
                            }
                            let formData = new FormData();
                            formData.append("event_id", props.eventID);
                            formData.append(fields.subject, values[fields.subject]);
                            formData.append(fields.introduction, values[fields.introduction]);
                            formData.append(fields.status, values[fields.status]);

                            // handle attachment if any
                            if (values.event_file) {
                                // formData.append("event_file_title", values.event_file_title);
                                Array.from(values.event_file).forEach(file => formData.append("event_file[]", file));
                                formData.append("event_file_description", values.event_file_description);
                                formData.append("event_file_type", content.event_file_type);
                            }

                            props.updateEmail({
                                event_id: props.eventID,
                                obj: formData,
                                setSubmitting
                            });
                            setFiles([]);
                            // handleClose();
                        }}
                        render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                            <Form>
                                <Grid container sx={{ marginBottom: '15px', marginTop: '0px' }} spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            name={fields.subject}
                                            type="text"
                                            label="Subject"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            size={'small'}
                                        />
                                        <Field
                                            style={{ minWidth: '400px' }}
                                            name={fields.introduction}
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={editorConfiguration}
                                                            data={field.value}
                                                            onChange={(event, editor) => {
                                                                form.setFieldValue(field.name, editor.getData());
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }}
                                        />
                                        <Grid item xs={12}>
                                            <div>
                                                <h3 style={{ marginBottom: '10px', fontWeight: 600 }}>Attachments</h3>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    {/* <Field
                                                        style={{ width: '33%', margin: 0 }}
                                                        name="event_file_title"
                                                        type="text"
                                                        label="Title"
                                                        variant="outlined"
                                                        margin="normal"
                                                        component={TextField}
                                                        size={'small'}
                                                        fullWidth
                                                    /> */}
                                                    {/* <Field
                                                        style={{ width: '49%', margin: 0 }}
                                                        name="event_file_description"
                                                        type="text"
                                                        label="Description"
                                                        variant="outlined"
                                                        margin="normal"
                                                        component={TextField}
                                                        size={'small'}
                                                        fullWidth
                                                    /> */}
                                                    <Field
                                                        name="event_file"
                                                        render={({ field, form }) => {
                                                            return (
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', margin: '10px 0', border: '3px dashed #c4c4c4', padding: '10px' }}>
                                                                    <div className='file' style={{ marginRight: '5px' }}>
                                                                        <label htmlFor="file-input">Select Files</label>
                                                                        <input
                                                                            multiple
                                                                            id="file-input"
                                                                            type="file"
                                                                            onChange={(event) => {
                                                                                console.log(field.name, "event.currentTarget.files...................", event.currentTarget.files);
                                                                                const _files = Array.from(files);
                                                                                Array.from(event.currentTarget.files).map((file) => _files.push(file))
                                                                                // form.setFieldValue(field.name, event.currentTarget.files);
                                                                                // setFiles(event.currentTarget.files)
                                                                                form.setFieldValue(field.name, _files);
                                                                                setFiles(_files)
                                                                            }}
                                                                            style={{ width: '100%', margin: 0, border: '1px solid rgba(0, 0, 0, 0.23)', padding: '8.5px 14px', borderRadius: '5px' }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            Array.from(files).map((file, index) => <Chip sx={{ marginRight: '5px' }} key={index} label={file.name} onDelete={(e) => deleteFromFiles(file, e)} />)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                {/* <input
                                                    multiple
                                                    id="file"
                                                    name="file"
                                                    type="file"
                                                    onChange={(event) => {
                                                        console.log("event.currentTarget.files...................", event.currentTarget.files)
                                                        setFieldValue("file", event.currentTarget.files);
                                                    }}
                                                /> */}
                                            </div>
                                            <div style={{ margin: "10px 0" }}>
                                                {
                                                    uploads.map((item, index) => {
                                                        return (
                                                            <Chip sx={{ marginRight: '5px' }} key={index} label={item.upload_name} onDelete={(e) => deleteFile(item, type, e)} onClick={(e) => downloadFile(item, type, e)} />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {isSubmitting && <LinearProgress />}
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        disabled={isSubmitting}
                                        onClick={handleClose}
                                        size="small"
                                        sx={{ marginRight: '5px' }}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            formRef.current.setFieldValue(fields.status, "Draft");
                                            submitForm();
                                        }}
                                        size="small"
                                        sx={{ marginRight: '5px' }}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            formRef.current.setFieldValue(fields.status, "Pending");
                                            submitForm();
                                        }}
                                        size="small"
                                    >
                                        Submit for Approval
                                    </Button>
                                </div>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: eventsOverviewSelectors.updateEmail_loading(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        updateEmail: eventsOverviewActions.updateEmail,
        deleteAttachedFile: eventsOverviewActions.deleteAttachedFile
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailModal);