import React, { useCallback, useMemo, useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../../API/util';
import { TextField } from '@mui/material';
import GridElement from '../../Common/GidElement';
import { selectors as adminSelectors, actions as adminsActions } from '../../../Ducks/admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { withStyles } from "@mui/styles";

const useStyles = () => ({

});

const ManageBouncedEmails = (props) => {
    const {postBouncedEmails, bouncedEmailFiles, BouncedFiles, loading,postBouncedEmails_loading} = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [filters, setFilters] = useState([]);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [emails,setEmails] = useState('');
    const [inputKey, setInputKey] = useState(Date.now());
    const [btnloading, setBtnLoading] = useState(false);

    const cols = useMemo(() => [
      {
        Header: 'File',
        accessor: 'import_file_name',
        column: 'import_file_name',
        minWidth: '160',
        filter: 'fuzzyText',
        Cell: ({row}) => (
          <a href={`${BASE_URL}/bounced-emails/file/${row.original.import_file_name}/download`} target='_blank'>
            {row.original.import_file_name}
          </a>
        ),
      },
      {
        Header: 'Upload By',
        accessor: 'import_user_id',
        column: 'import_type',
        minWidth: '150',
        filter: 'fuzzyText',
        Cell: ({row}) => (
          `${row.original.user_firstname} ${row.original.user_lastname}`
        ),
      },
      {
        Header: 'Status',
        accessor: 'import_status',
        column: 'import_status',
        minWidth: '150',
        filter: 'fuzzyText'
      },  
      {
        Header: 'Created At',
        accessor: 'created_at',
        column: 'created_at',
        minWidth: '150',
        filter: 'fuzzyText'
      },
      {
        Header: 'Result',
        accessor: 'result',
        column: 'result',
        minWidth: '150',
        filter: 'fuzzyText',
        Cell: ({row}) => (
          row.original.import_status === 'completed' ? <a href={`${BASE_URL}/bounced-emails/report/${row.original.import_id}/download`} target='_blank'>
            Download
          </a> : ''
        ),
      },
    ],[]);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setBtnLoading(true);

        if (!selectedFile) {
          alert('Please select a file first!');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        try {
          await axios.post(`${BASE_URL}/bounced-emails/import`, formData,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': undefined,
            }
          })
        } catch (error) {
          return error;
        } finally {
          setBtnLoading(false);

          Swal.fire({
            icon: 'success',
            title: 'Uploaded successfully!',
            showConfirmButton: false,
            timer: 1500
          })

          setInputKey(Date.now()); 
        }
    };

    const processEmails = () => {
      if(emails != '')
      {
        postBouncedEmails({'emails': emails});
        setEmails('');
      }
    }

    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {

      const _columns = cols.filter(item => item.column != 'result').map((item) => `bounced_email_imports.${item.column}`)
      _columns.push('user_firstname','user_lastname');

      let query = "", api = "", sort = "", filterPrefix = "";
  
      filters.forEach((item, _index) => {
          const _filterColumn = columns.filter((column) => column.accessor === item.id);
          if(_filterColumn[0].column.includes(".")) {
              filterPrefix = _filterColumn[0].column.split(".")[0]
          } else {
              filterPrefix = "bounced_email_imports"
          }
          query = query + `&contains[${filterPrefix}.${item.id}]=${item.value}`
      });
      //Check sorting
      if (sortBy.length) {
          let _sortByPrefix = "", _sortByColumn = columns?.filter((column) => column.accessor === sortBy[0].id);
          if (_sortByColumn[0]?.column.includes(".")) {
              _sortByPrefix = _sortByColumn[0].column.split(".")[0]
              _sortByPrefix = `${_sortByPrefix}.`
          }
          sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${_sortByPrefix}${sortBy[0].id}`;
      }
      api = `results=${pageSize}&columns=${_columns}&offset=${pageIndex + 1}${query}${sort}`
      
      bouncedEmailFiles(api);
    },[inputKey]);

    return (
        <>
            <div>
            <h2 className="page-title">Manage Bounced Emails</h2>
            </div>
            <div style={{marginTop: '30px', display:'flex'}}>
                <h2 className="page-title" style={{fontSize: '20px'}}>Upload CSV or Add Record</h2>
                <h2 className="page-title" style={{fontSize: '20px', marginLeft:'370px'}}>Add a single record:</h2>
            </div>
            <div style={{display:'flex'}}>
                <form onSubmit={handleSubmit}>
                    <input type="file" onChange={handleFileChange} key={inputKey} style={{border: '1px solid #b8b0b0', padding: '5px', borderRadius: '2px'}}/>
                    <LoadingButton variant="contained" type='submit' style={{margin: '10px'}} loading={btnloading} size='small'>Upload</LoadingButton>
                </form>
                <form style={{marginLeft: '240px'}}>
                  <TextField label="Bounced Emails" placeholder="Emails" multiline value={emails} onChange={(e)=> setEmails(e.target.value)} size='small' style={{margin: '10px'}}/>
                  <LoadingButton variant="contained" onClick={processEmails} loading={postBouncedEmails_loading} style={{marginTop: '10px'}}>Add</LoadingButton>
                </form>
            </div>

            <GridElement
              loading={loading}
              columns={cols}
              data={BouncedFiles?.main?.length ? BouncedFiles?.main : []}
              fetchData={fetchData}
              pageCount={BouncedFiles?.offset?.highest_offset}
              rowsCount={BouncedFiles?.offset?.rows_count}
              filters={filters}
              setFilters={setFilters}
              controlledPageIndex={controlledPageIndex}
              setControlledPageIndex={setControlledPageIndex}
              initialSortBy="import_id"
              handleRowClick={() => {}}
            /> 
        </>
    );  
}

const mapStateToProps = (state) => ({
  BouncedFiles: adminSelectors.bouncedFiles(state),
  loading: adminSelectors.isLoading(state),
  postBouncedEmails_loading: adminSelectors.postBouncedEmails_loading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postBouncedEmails: adminsActions.postBouncedEmails,
    bouncedEmailFiles: adminsActions.getBouncedEmailFiles
  },
  dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(useStyles)(ManageBouncedEmails));