import React from "react";

let BlankPage = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <p style={{marginTop: '50px', fontSize: '20px'}}>No capability found against this user, please contact your administrator.</p>
        </div>
    );
};

export default BlankPage;
