import React, { useEffect, useCallback,useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectors as adminSelectors, actions as adminsActions } from '../../../Ducks/admin';
import GridElement from '../../Common/GidElement';
import { withStyles } from "@mui/styles";
import { CSVLink } from "react-csv";


const useStyles = () => ({
    downloadbtn: {
    textDecoration: "none",
    border: "1px solid #1976d2",
    borderRadius: "10px",
    padding: "15px 10px 10px 10px",
    fontSize: "14px",
    color: "#1976d2",
    }
})

const GmailDataGrid = (props) => {
    const { getGmailData,loading,gmailData,gmaildataOffset,classes } = props;
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const [filters, setFilters] = React.useState([]);

    const cols = useMemo(() => [
        {
            Header: 'Person ID',
            accessor: 'person_id',
            column: 'person_id',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'Subject',
            accessor: 'subject',
            column: 'subject',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'From Name',
            accessor: 'name',
            column: 'name',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'From Email',
            accessor: 'from',
            column: 'from',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'Person Email',
            accessor: 'email',
            column: 'email',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'Person Telephone No',
            accessor: 'telephone_no',
            column: 'telephone_no',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'Person Mobile Number',
            accessor: 'mobile_no',
            column: 'mobile_no',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'Person Received At',
            accessor: 'email_receive_date',
            column: 'email_receive_date',
            minWidth: '150',
            filter: 'fuzzyText'
        },
        {
            Header: 'Processed',
            accessor: 'processed',
            column: 'processed',
            minWidth: '150',
            filter: 'fuzzyText'
        },
    ],[]);

    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {

        const _columns = cols.map((item) => item.column)

        let query = "", api = "", sort = "", filterPrefix = "";

        filters.forEach((item, index) => {
            const _filterColumn = columns.filter((column) => column.accessor === item.id);
            console.log(_filterColumn);
            if(_filterColumn[0].column.includes(".")) {
                filterPrefix = _filterColumn[0].column.split(".")[0]
            } else {
                filterPrefix = "email_extraction_data"
            }
            query = query + `&contains[${filterPrefix}.${item.id}]=${item.value}`
        });

        //Check sorting
        if (sortBy.length) {
            let _sortByPrefix = "", _sortByColumn = columns?.filter((column) => column.accessor === sortBy[0].id);
            if (_sortByColumn[0]?.column.includes(".")) {
                _sortByPrefix = _sortByColumn[0].column.split(".")[0]
                _sortByPrefix = `${_sortByPrefix}.`
            }
            sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${_sortByPrefix}${sortBy[0].id}`;
        }
        api = `results=${pageSize}&columns=${_columns}&offset=${pageIndex + 1}${query}${sort}`
        
        getGmailData(api);
    },[]);

    const csvData = [
        ["Subject", "From", "Person ID", "Email", "Telephone", "Mobile"],
        ...gmailData.map(({ subject, from, person_id, email, telephone_no, mobile_no }) => [
          subject,
          from,
          person_id,
          email,
          telephone_no,
          mobile_no,
        ]),
      ];

    return (
        <>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <h2 className="page-title">Emails Data Import</h2>
                <CSVLink className={classes.downloadbtn} filename='Person Details' data={csvData}>
                    Export to CSV
                </CSVLink>
            </div>
            
            <GridElement
                loading={loading}
                columns={cols}
                data={gmailData}
                fetchData={fetchData}
                pageCount={gmaildataOffset.highest_offset}
                rowsCount={gmaildataOffset.rows_count}
                filters={filters}
                setFilters={setFilters}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                initialSortBy="person_id"
                handleRowClick={() => {}}
            />   
        </>
    )
}

const mapStateToProps = (state) => ({
    loading: adminSelectors.isLoading(state),
    gmailData: adminSelectors.gmailData(state),
    gmaildataOffset: adminSelectors.gmaildataOffset(state),
});
  
  const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getGmailData: adminsActions.getGmailData,
    },
    dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(useStyles)(GmailDataGrid));