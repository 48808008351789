import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {bindActionCreators} from 'redux';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import {withStyles} from '@mui/styles';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {useParams} from "react-router-dom";
import {CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';
import {cloneDeep} from 'lodash';
import Swal from 'sweetalert2';
import {fetchPut} from '../../API/util';

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'end'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    },
    list: {
        listStyle: 'none',
        padding: 0
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        cursor: 'pointer',
        '&:hover': {
            background: '#ebebeb'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-container': {
        '& > .MuiPaper-root': {
            minWidth: '500px',
        }
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    }
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2,}} {...other}>
            {children}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
    );
};

const EditTable = (props) => {
    const {classes, onClose, open, table, columns, setColumns} = props;
    const [_userMeta, setUserMeta] = React.useState(null);
    const [_currentSettings, setCurrentSettings] = React.useState(null);
    const [visibleColumns, setVisibleColumns] = React.useState(null);
    const [invisibleColumns, setInvisibleColumns] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState(table);

    useEffect(() => {
        const _userMeta = JSON.parse(localStorage.getItem("userMeta"));
        const _currentSettings = JSON.parse(localStorage.getItem("user"));
        const _user_meta = cloneDeep(_userMeta);

        setUserMeta(_userMeta);
        setCurrentSettings(_currentSettings);

        setVisibleColumns(_currentSettings.tableSettings[table].columns)
        setInvisibleColumns(_user_meta)
    }, []);

    const sendToVisible = (item, parent) => {
        const _invisible_columns = cloneDeep(invisibleColumns);
        const _visible_columns = cloneDeep(visibleColumns);
        let _item = item;

        if (parent !== table) {
            _item = `${parent}.${item}`
        }

        if (!_visible_columns.includes(_item)) {
            const filtered_columns = _invisible_columns.table_columns[selectedValue].filter((column) => column !== item);
            _invisible_columns.table_columns[selectedValue] = filtered_columns
            setInvisibleColumns(_invisible_columns);

            if (selectedValue === table) {
                _visible_columns.push(item);
            } else {
                _visible_columns.push(`${selectedValue}.${item}`);
            }
            setVisibleColumns(_visible_columns);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Column already added',
                showConfirmButton: false,
                timer: 1500
            });
        }
    }

    const sendToInvisible = (item, parent) => {
        const _invisible_columns = cloneDeep(invisibleColumns);
        const _visible_columns = cloneDeep(visibleColumns);
        let _item = item;

        const filtered_columns = _visible_columns.filter((column) => column !== item);
        setVisibleColumns(filtered_columns);

        // Remove dot notation before sending to invisible array if exists
        if (item.includes(".")) {
            _item = item.split(".")[1];
        }

        // Remove duplicates
        _invisible_columns.table_columns[selectedValue] = invisibleColumns.table_columns[selectedValue].filter(val => !visibleColumns.includes(selectedValue !== table ? `${selectedValue}.${val}` : val))

        _invisible_columns.table_columns[parent].push(_item);
        setInvisibleColumns(_invisible_columns);
    }

    const makeHeader = (title) => {
        let _title = "";
        if (title.includes(".")) {
            _title = title.split(".")[1].split("_");
        } else {
            _title = title.split("_");
        }
        return _title.join(" ");
    }

    const getParentName = (key) => {
        if (key.includes(".")) {
            return key.split(".")[0];
        } else {
            return table
        }
    }

    const makeAccessor = (key) => {
        if (key.includes(".")) {
            return key.split(".")[1];
        } else {
            return key
        }
    }

    const saveColumns = () => {
        // Update local storage
        const _user = JSON.parse(localStorage.getItem("user"));
        _user.tableSettings[table].columns = visibleColumns;
        localStorage.setItem("user", JSON.stringify(_user));

        // Update user settings
        fetchPut(`/users/settings`, _user).then((res) => {
        }).catch(_error => {
        })

        // Update columns for the table
        const _columns = visibleColumns.map(key => ({
            column: key,
            accessor: makeAccessor(key),
            minWidth: 150,
            Header: makeHeader(key),
            filter: 'fuzzyText'
        }));
        setColumns(_columns);
        onClose();
        Swal.fire({
            icon: 'success',
            title: 'Table Updated!',
            showConfirmButton: false,
            timer: 1500
        });
    }

    return (
        <BootstrapDialog onClose={onClose} open={open}>
            <BootstrapDialogTitle onClose={onClose} sx={{textAlign: 'center'}}><b>Edit Table</b></BootstrapDialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <ul className={classes.list}>
                            {
                                visibleColumns && visibleColumns.map((item, index) => {
                                    return (
                                        <li key={index} className={classes.listItem}
                                            onClick={() => sendToInvisible(item, getParentName(item))}>
                                            <CheckBox sx={{color: '#0075ff', fontSize: '18px', marginRight: '5px'}}/>
                                            <div className={classes.header}>
                                                <span>{makeHeader(item)}</span>
                                                <span>{getParentName(item)}</span>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="select-column">Columns</InputLabel>
                            <Select
                                id="select-column"
                                value={selectedValue}
                                label="Columns"
                                sx={{textTransform: 'capitalize'}}
                                onChange={(event) => setSelectedValue(event.target.value)}
                            >
                                {
                                    columns.map((item, index) => {
                                        return (
                                            <MenuItem sx={{textTransform: 'capitalize'}} key={index}
                                                      value={item}>{item}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <ul className={classes.list}>
                            {
                                invisibleColumns && invisibleColumns.table_columns[selectedValue].filter(val => !visibleColumns.includes(selectedValue !== table ? `${selectedValue}.${val}` : val)).map((item, index) => {
                                    return (
                                        <li key={index} className={classes.listItem}
                                            onClick={() => sendToVisible(item, selectedValue)}>
                                            <CheckBoxOutlineBlank
                                                sx={{color: '#000', fontSize: '18px', marginRight: '5px'}}/>
                                            <span>{makeHeader(item)}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color="error" onClick={onClose}>Cancel</Button>
                <Button className={classes.button} variant="contained" color='success'
                        onClick={saveColumns}>Save</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {},
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <EditTable {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));