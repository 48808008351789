import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff'
  },
  cardItem: {
    marginTop: '16px'
  },
  typography: {
    marginLeft: '20px'
  },
});
function Leads(props) {
  const { classes } = props;
  // const openOpportunities = (props.employees && props.employees.main &&props.employees.main.open_opportunities) || {};
  // const persons = (props.employees && props.employees.related_objects && props.employees.related_objects.persons) || {};
  // const products = (props.employees && props.employees.related_objects && props.employees.related_objects.products) || {};

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div" style={{borderBottom: "1px solid #ddd", marginBottom: '10px', paddingBottom: '10px'}} >
        Leads (0)
        </Typography>
        <Typography gutterBottom variant="body2" component="text.secondary">
        No Leads
        </Typography>
      </CardContent>
    </Card>
  );
}

const EventItem = ({ openOppurtunity, person, product, classes }) => {
  return (
  <MemberCard 
    name={person.person_firstname + ' ' + person.person_lastname} 
    title={person.title} 
    date={`${product.product_name} (${openOppurtunity.stage_name}) - ${openOppurtunity.sales_rep}`}
    classes={classes} 
    />
    )
}

const MemberCard = ({ name, title, date, classes }) => {
  return (
    <>
      <Typography gutterBottom variant="p" component="p" style={{fontSize:'14px'}} >
        <strong> {name} </strong>
      </Typography>
      <Typography gutterBottom variant="span" component="text.secondary">
        {title}
      </Typography>
      <Typography gutterBottom className={classes.typography} variant="span" component="text.secondary">
        {date}
      </Typography>
    </>
  )
}

export default withStyles(useStyles)(Leads);