import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as adminSelectors, actions as adminActions } from "../../../../Ducks/admin";
import { selectors as usersSelectors, actions as usersActions } from "../../../../Ducks/users";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { bindActionCreators } from 'redux';

import { Button, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select,TextField as MTextField,Autocomplete } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import { TextField } from "formik-material-ui";

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        paddingTop: "10px !important"
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    },
    '.MuiTypography-root': {
        paddingBottom: 0
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
    const label = name.split('_')[0].charAt(0).toUpperCase() + name.split('_')[0].slice(1);
    return (
        <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="country_id">{label}</InputLabel>
            <Select
                required
                labelId={name}
                id={name.split('_')[0]}
                fullWidth
                label={label}
                name={name}
                value={value}
                onChange={e => {
                    setFieldValue(name, e.target.value);
                }}
            >
                {children}
            </Select>
        </FormControl>
    );
};


const AddTeam = (props) => {
    const { onClose, selectedValue, open, classes, countries, teams, teamID, crmTeams ,activeTeamUsers } = props;
    const [countryList, setCountryList] = React.useState([]);
    const [initialValues, setInitialValues] = React.useState({});
    const [selectedUser,setSelectedUser] = React.useState([]);
    const formRef = React.createRef();
    const _users = activeTeamUsers?.map((user) => {
        user.key = user.user_id;
        user.label = `${user.user_firstname} ${user.user_lastname}`;
        return user;
    });

    useEffect(() => {
        if (countries && countries.main) {
            setCountryList(countries.main)
        }
    }, [countries]);

    useEffect(() => {
        if (teams && teamID) {
            const _initialValues = {
                team_name: teams.rows.filter((item) => item.team_id === teamID)[0].team_name,
                country_id: teams.rows.filter((item) => item.team_id === teamID)[0].country_id,
                parent_team_id: teams.rows.filter((item) => item.team_id === teamID)[0].parent_id,
                team_lead_id: teams.rows.filter((item) => item.team_id === teamID)[0].team_lead_user_id,

            }
            setInitialValues(_initialValues);
        }
    }, [teams, teamID])

    useEffect(() =>{
        let activeUsers = activeTeamUsers?.filter((user) => user.user_id === initialValues.team_lead_id);
        setSelectedUser(activeUsers);
    },[initialValues,activeTeamUsers?.length])

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BootstrapDialogTitle>{teams ? "Update" : "Add"} Team</BootstrapDialogTitle>
            <DialogContent>
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validate={values => {
                        const errors = {};
                        if (!values.team_name) {
                            errors.team_name = 'Required';
                        }
                        if (!values.country_id) {
                            errors.country_id = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        const data = {
                            "team_name": values.team_name,
                            "country_id": values.country_id,
                            "parent_id": values.parent_team_id,
                            "team_lead_user_id": values.team_lead_id,
                        }
                        if (teams && teamID) {
                            const _data = {
                                id: teamID,
                                obj: {
                                    "team_name": values.team_name,
                                    "country_id": values.country_id,
                                    "parent_id": values.parent_team_id,
                                    "team_lead_user_id": values.team_lead_id,
                                }
                            }
                            props.updateTeam(_data)
                        } else {
                            props.addTeam(data);
                        }
                        handleClose();
                    }}
                    render={({ submitForm, setFieldValue }) => (
                        <Form>
                            <Grid container sx={{ marginBottom: '15px' }}>
                                <Grid item xs={12}>
                                    <Field
                                        required
                                        sx={{ display: 'flex', width: '100%', minWidth: '400px' }}
                                        classes={{ input: classes.noPadding }}
                                        name="team_name"
                                        type="text"
                                        label="Team Name"
                                        variant="outlined"
                                        margin="normal"
                                        component={TextField}
                                    />
                                    <Field
                                        required
                                        component={CustomizedSelectForFormik}
                                        fullWidth
                                        name="parent_team_id"
                                        label="Parent Team"
                                        variant="outlined"
                                    >
                                        {crmTeams?.rows.filter((item) => item.team_id !== teamID)
                                        .map((item) => (
                                            <MenuItem
                                                key={item.team_id}
                                                value={item.team_id}
                                            >
                                                {item.team_name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <Autocomplete
                                        id="team_lead_id"
                                        name="team_lead_id"
                                        options={ _users }
                                        clearOnBlur={false}
                                        value={selectedUser?.[0] || ''}
                                        onChange={(_e,value) => setFieldValue('team_lead_id',value.user_id)}
                                        renderInput={params => (
                                        <MTextField
                                            { ...params }
                                            margin="normal"
                                            label="Team Lead"
                                            fullWidth
                                        />
                                        )}
                                    />
                                    <Field
                                        required
                                        component={CustomizedSelectForFormik}
                                        fullWidth
                                        name="country_id"
                                        label="Country"
                                        variant="outlined"
                                    >
                                        {countryList.map((country) => (
                                            <MenuItem
                                                key={country.country_id}
                                                value={country.country_id}
                                            >
                                                {country.country_name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button className={classes.button} variant="contained" color='error' onClick={handleClose}>Cancel</Button>
                                    <Button className={classes.button} variant="contained" color="inherit" onClick={submitForm}>{teams ? "Update" : "Add"}</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                />

            </DialogContent>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    isLoading: adminSelectors.isLoading(state),
    errorMessage: adminSelectors.errorMessage(state),
    crmTeams: usersSelectors.teams(state),
    users: usersSelectors.usersList(state),
    activeTeamUsers: usersSelectors.activeTeamUsers(state),

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        addTeam: usersActions.addTeam,
        updateTeam: usersActions.updateTeam,

    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <AddTeam {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));