import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel
} from '@mui/material';
import DateTimePicker from '../../../Common/CalendarWidget/DateTimePicker';
// import { toDateTime } from '../../Utils/dateUtils';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@mui/styles';

const types = [
    'phone_meeting',
    'follow_up'
]

const useStyles = theme => ({
    select: {
        "& ul": {
            display: "grid",
        }
    },
});

function EventModal(props) {
    const { classes, isUpcomingEvent, startDate, editEvent, opportunity, person, addPlannedAction, editPlannedAction, action } = props;
    const [date, setDate] = React.useState(new Date());
    const [type, setType] = React.useState(types[0]);
    const handleClose = () => {
        props.handleClose()
    };

    React.useEffect(() => {
        if (startDate) {
            setDate(new Date(startDate))
        } else {
            setDate(new Date())
        }
    }, [startDate])

    React.useEffect(() => {
        if (action && action.opportunity_action_type) {
            setType(action.opportunity_action_type)
        } else {
            setType(types[0])
        }
    }, [action])

    const handleSubmit = () => {
        if (editEvent) {
            const obj = {
                opportunity_action_type: type,
                opportunity_action_due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
                opportunity_action_due_time_precise: 1
            };
            let d = new Date();
            let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
            editPlannedAction({ id: opportunity.opportunity_id, person_id: person.person_id, obj: obj, date: YearMonth, actionId: action.opportunity_action_id });
        } else {
            const obj = {
                action_type: type,
                due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
                due_time_precise: 1
            };
            let d = new Date();
            let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
            addPlannedAction({ id: opportunity.opportunity_id, person_id: person.person_id, obj: obj, date: YearMonth });
        }
        handleClose();
    }

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    const getPersonName = () => {
        if (person) {
            return person.person_firstname + ' ' + person.person_lastname;
        }
        return '';
    }

    return (
        <div>
            <Dialog width={700} open={props.open} onClose={handleClose}>
                <DialogContent>
                    {isUpcomingEvent &&
                        'You already have a planned action for this opportunity so you cannot plan a new one until this has been carried out'
                    }
                    {
                        !isUpcomingEvent &&
                        <div style={{ textAlign: 'center' }}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px'}}>
                                <span style={{marginRight: '5px'}}>Plan a</span> <FormControl style={{ width: '180px' }}>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        name="type"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Age"
                                        onChange={(e) => setType(e.target.value)}
                                        size={'small'}
                                        MenuProps={{ classes: { paper: classes.select } }}
                                    >
                                        {
                                            types.map(item => <MenuItem value={item}>{makeHeader(item)}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                on <b>{moment(date).format('MMMM D, YYYY, HH:mm')}</b> with <b>{getPersonName()}</b>
                            </div>

                            <DateTimePicker date={date} setDate={setDate} />

                            {'Do you confirm the creation of event?'}
                        </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        {
                            !isUpcomingEvent &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                                style={{ marginRight: '12px' }}
                            >
                                {'Confirm'}
                            </Button>
                        }
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        addPlannedAction: eventsOverviewActions.addPlannedAction,
        editPlannedAction: eventsOverviewActions.editPlannedAction,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(EventModal));