import React, { useEffect } from "react";
import { Autocomplete, CardMedia, Grid, TextField, Input, Accordion, AccordionSummary, AccordionDetails, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as resaleOverviewSelectors, actions as resaleOverviewActions } from '../../../../Ducks/resaleOverview';
import { selectors as ContactsSelectors, actions as contactsActions } from "../../../../Ducks/contacts";
import { selectors as opportunitySelectors, actions as opportunityActions } from "../../../../Ducks/opportunity";
import { bindActionCreators } from "redux";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Radio } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LanguageIcon from '@mui/icons-material/Language';
import { Phone, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt } from '@mui/icons-material';
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import moment from "moment";
import { fetchPatch } from "../../../../API/util";
import Swal from "sweetalert2";
// import PersonHistory from "../Widgets/PersonHistory";
import { cloneDeep } from "lodash-es";
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import NextPlannedAction from "../Widgets/NextPlannedAction";
import { isEmpty } from 'lodash';
import ParticipantPersonalDetails from "../../../Common/Overview/ParticipantPersonalDetails";
import ParticipantCard from "../../../Common/Overview/ParticipantCard";
import BookMeetingModal from "../../EventsOverview/Modals/BookMeetingModal";
import ParticipantEventsOverview from "../../../Common/Overview/ParticipantEventsOverview";
import ParticipantSendoutOverview from "../../../Common/Overview/ParticipantSendoutOverview";
import PersonHistory from "../../EventsOverview/Widgets/PersonHistory";
import ParticipantJobs from "../../../Common/Overview/ParticipantJobs";
import ParticipantPurchases from "../../../Common/Overview/ParticipantPurchases";
import ParticipantOpportunities from "../../../Common/Overview/ParticipantOpportunities";
import ParticipantMeetings from "../../../Common/Overview/ParticipantMeetings";
import NextPlannedAction from "../../EventsOverview/Widgets/NextPlannedAction";

const useStyles = (theme) => ({
    title: {
        display: 'flex !important',
        alignItems: 'center !important'
    },
    card: {
        display: "flex !important",
        backgroundColor: "#fff !important",
        // height: 200
    },
    cardMedia: {
        flex: 1,
        width: "85px !important",
        backgroundColor: "#F1F1F1 !important",
    },
    cardContent: {
        flex: 4,
        padding: '16px !important'
    },
    flexVertical: {
        display: "flex",
        alignItems: "center",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputField: {
        // maxWidth: '125px',
        flex: 1
    },
    fieldSet: {
        display: 'flex',
        alignItems: 'end'
    },
    flex: {
        display: 'flex'
    },
    sendout: {
        display: 'flex',
        alignItems: 'center'
    },
    send: {
        background: 'green',
        width: '14px',
        height: '14px',
        marginRight: '5px',
    },
    sent: {
        background: '#2785e6',
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'dashed'
    },
    notsent: {
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'solid'
    },
    noBgDotted: {
        background: 'transparent',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        // borderStyle: 'dashed'
        background: 'repeating-linear-gradient(45deg, red, red 2px, transparent 2px, transparent 8px)',
        border: 'red',
        borderStyle: 'solid'
    },
    lightBlueBgDotted: {
        // background: '#6cb1ee',
        // background: '#5cd7ff',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        // borderStyle: 'dashed'
        // borderStyle: 'solid',
        // borderColor: 'red'
        background: 'repeating-linear-gradient(45deg, red, red 2px, #5cd7ff 2px, #5cd7ff 8px)',
        borderStyle: 'solid',
        borderColor: 'red'
    },
    lightBlueBgSolid: {
        // background: '#6cb1ee',
        background: '#5cd7ff',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        // borderStyle: 'solid'
        // background: 'repeating-linear-gradient(45deg, #59b559, #59b559 2px, #5cd7ff 2px, #5cd7ff 8px)',
        borderStyle: 'solid',
        borderColor: '#42d142'

    },
    lightBlueBg: {
        // background: '#6cb1ee',
        background: '#5cd7ff',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    darkBlueBgSolid: {
        // background: '#246eaf',
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        // borderStyle: 'solid'
        // background: 'repeating-linear-gradient(45deg, #b4ffb4, #b4ffb4 2px, #0047ab 2px, #0047ab 8px)',
        borderStyle: 'solid',
        borderColor: '#42d142'
    },
    darkBlueBg: {
        // background: '#246eaf',
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px'
    },
    darkBlueBgDotted: {
        // background: '#246eaf',
        background: '#0047ab',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        // borderStyle: 'dashed'
        borderStyle: 'solid',
        borderColor: 'red',
        background: 'repeating-linear-gradient(45deg, red, red 2px, #0047ab 2px, #0047ab 8px)'
    },
    noBgSolid: {
        background: 'transparent',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid'
        // background: 'repeating-linear-gradient(45deg, green, green 2px, transparent 2px, transparent 8px)'
    },
    cyanBgSolid: {
        // background: '#52d3da',
        background: '#FDDA0D',
        width: '19px',
        height: '19px',
        marginRight: '5px',
        // borderStyle: 'solid',
        // borderColor: '#cacaca'
        // background: 'repeating-linear-gradient(45deg, green, green 2px, yellow 2px, yellow 8px)'
    },
    cyanBg: {
        // background: '#52d3da',
        background: '#FDDA0D',
        width: '19px',
        height: '19px',
        marginRight: '5px',
    },
    empty: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: '#cacaca'
    },
    completed: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'green',
        background: 'green'
    },
    changed: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'red',
        background: 'green'
    },
    failed: {
        width: '19px',
        height: '19px',
        marginRight: '5px',
        borderStyle: 'solid',
        borderColor: 'red',
        background: 'red'
    },
    attending: {
        background: '#68a54d !important',
        color: '#fff !important',
        '&:hover': {
            background: '#68a54d !important',
        }
    },
    not_attending: {
        background: '#db6669 !important',
        color: '#fff !important',
        '&:hover': {
            background: '#db6669 !important',
        }
    },
    unknown: {
        background: '#7f7f7f !important',
        color: '#fff !important',
        '&:hover': {
            background: '#7f7f7f !important',
        }
    },
    tentative: {
        background: '#f9fd01 !important',
        color: '#000 !important',
        '&:hover': {
            background: '#f9fd01 !important',
        }
    },
    appeared: {
        background: '#2785e6',
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'solid'
    },
    nothing: {
        background: '#2785e6',
        width: '14px',
        height: '14px',
        marginRight: '5px',
    },
    happened: {
        background: 'green',
        width: '14px',
        height: '14px',
        marginRight: '5px',
    },
    notHappened: {
        width: '14px',
        height: '14px',
        marginRight: '5px',
        borderStyle: 'solid'
    },
    answer: {
        background: '#1976d2',
        color: '#fff',
        '&:hover': {
            color: '#000'
        }
    },
    accordionSummary: {
        '& > div': {
            flexDirection: 'column'
        }
    },
    historyCount: {
        background: 'red',
        borderRadius: '50%',
        color: '#fff',
        fontSize: '12px',
        marginLeft: '5px',
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    panel: {
        backgroundColor: '#fff',
        color: "rgba(0, 0, 0, 0.87)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "4px",
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        position: "relative",
        transition: " margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        overflowAnchor: "none",
        borderRadius: 0,
        padding: "15px"
    },
    checkbox: {
        width: "15px",
        height: "15px",
        cursor: "pointer"
    },
    personTable: {
        '& > tbody > tr > td': {
            padding: '5px',
            minWidth: '160px'
        }
    }
});

const personColumns = [
    "person_id",
    "erp_client_id",
    "region_id",
    "person_token",
    "person_firstname",
    "person_lastname",
    "person_never_contact",
    "person_mobile_number",
    "person_preferences_never_text",
    "person_preferences_limit_calls",
    "person_linkedin_url",
    "person_linkedin_member_id",
    "person_challenges",
    "person_food_considerations",
    "person_special_conditions",
    "person_industry",
    "person_country",
    "person_region",
    "person_language",
    "person_gender",
    "lead_source",
    "lead_target_id",
    "sf_person_id",
    "person_created_at",
    "person_last_update",
    "person_awaiting_sync",
    "person_last_sync",
    "person_flagged_from_salesview",
];

const sendoutOverview = [
    {
        name: 'send',
        status: 'notsent'
    },
    {
        name: 'send',
        status: 'send'
    },
    {
        name: 'send',
        status: 'send'
    },
    {
        name: 'send',
        status: 'notsent'
    },
    {
        name: 'send',
        status: 'send'
    },
    {
        name: 'send',
        status: 'notsent'
    }
]

const attendingStatus = [
    {
        title: 'Attending',
        value: 'attending'
    },
    {
        title: 'Not Attending',
        value: 'not_attending'
    },
    {
        title: 'Tentative',
        value: 'tentative'
    },
    {
        title: 'Unknown',
        value: 'unknown'
    }
];

const sendOutEmails = [
    {
        title: 'Invitation',
        value: 'invitation'
    },
    {
        title: 'Calendar',
        value: 'calendar'
    },
    {
        title: 'Placeholder',
        value: 'placeholder'
    },
    {
        title: 'Reminder email',
        value: 'reminder_email'
    },
    {
        title: 'Summary email',
        value: 'summary_email'
    },
    {
        title: 'Reminder sms',
        value: 'reminder_sms'
    }
];

const OutboundOverview = (props) => {
    const { classes, item, name } = props;
    var _class = "", _changed = false, _text = [];
    if (item === undefined) {
        _class = "empty"
    } else if (item[0].mail_queue_status === "complete" && !item.changed) {
        _class = "completed"
    } else if (item[0].mail_queue_status === "completed" && item.changed) {
        _class = "changed"
    } else if (item[0].mail_queue_status.includes("failed")) {
        _class = "failed"
    }

    // Get content and check for status change
    if (item) {
        item.forEach((element) => {
            if (element.changed) {
                _changed = true;
                _text.push(element.created_at + ": " + element.changed_text);
            }
            _text.push(element.mail_queue_attempted_send + ": " + element.mail_queue_status);
        });
    }

    if (item === undefined) {
        return (
            <Tooltip title={name}>
                <div className={classes[_class]}></div>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={<React.Fragment><div>{name}</div><div>{_text.map((text) => <div>{text}</div>)}</div></React.Fragment>}>
                <div style={_changed ? { border: '1px solid red' } : { border: 'none' }} className={classes[_class]}></div>
            </Tooltip>
        );
    }
}

const PersonResaleOverviewTab = (props) => {
    const { classes, isLoading, person, eventInfo, updateAttendanceStatus, updateSendoutEmail, selectedMonth, selectedYear, getContactDetails, contactDetails, getJobs, jobs, getPersonPurchases, personPurchases, getPersonOpportunities, personOpportunities, getParticipantDetails, participantDetails, personHistory, foodConsiderations, isNetworkDirector, getPersonOpenOpportunity, personOpenOpportunity, getPersonMeetings, personMeetings, updateBookedMeeting } = props;
    const [personObj, setPersonObj] = React.useState({});
    const [eventDetails, setEventDetails] = React.useState({});
    const [attendeeDetails, setAttendeeDetails] = React.useState(null);
    const [eventOverview, setEventOverview] = React.useState([]);
    const [outboundOverview, setOutboundOverview] = React.useState(null);
    const [personDetails, setpersonDetails] = React.useState({});
    const [expanded, setExpanded] = React.useState('panel4');
    const [isOpen, setIsOpen] = React.useState(false);
    const [neverContact, setNeverContact] = React.useState(false);
    const [neverSms, setNeverSms] = React.useState(false);
    const [limitCalls, setLimitCalls] = React.useState(false);
    const [limitEmails, setLimitEmails] = React.useState(false);
    const [personChallenges, setPersonChallenges] = React.useState("");
    const [personFoodConsiderations1, setPersonFoodConsiderations1] = React.useState("");
    const [personFoodConsiderations2, setPersonFoodConsiderations2] = React.useState([]);
    const [personFoodConsiderationsOther, setPersonFoodConsiderationsOther] = React.useState("");
    const [personPielineStage, setPersonPielineStage] = React.useState("");
    const [personPielineStages, setPersonPielineStages] = React.useState([]);
    const [bookMeetingModal, setBookMeetingModal] = React.useState(false);
    const [createMeetingModal, setCreateMeetingModal] = React.useState(false);
    const [updateMeetingModal, setUpdateMeetingModal] = React.useState(false);
    const [overviewQuery, setOverviewQuery] = React.useState(null);
    const [row, setRow] = React.useState(null);

    useEffect(() => {
        props.getFoodConsiderations();
        // set query
        const _overviewQuery = getQuery();
        setOverviewQuery(_overviewQuery)
    }, [])

    useEffect(() => {
        if (person) {
            console.log("Resale Overview: Person", person);
            setPersonObj(person);
            getContactDetails({ id: person.person_id, columns: personColumns });
            getJobs(person.person_id);
            getPersonMeetings(person.purchase_id);
            getPersonOpenOpportunity(person.person_id);
            getPersonPurchases(person.person_id);
            getPersonOpportunities(person.person_id);
        }

        if (eventInfo !== null && eventInfo !== undefined) {
            setEventDetails(eventInfo);
        }

        if ((person !== null && person !== undefined) && (eventInfo !== null && eventInfo !== undefined)) {
            getParticipantDetails({ event_id: eventInfo.event_id, participant_id: person.potential_participant_id });
        }

    }, [person, eventInfo]);

    useEffect(() => {
        if (contactDetails !== null && contactDetails !== undefined) {
            setpersonDetails(contactDetails.main[0]);

            // set checkboxes
            setNeverContact(contactDetails.main[0].person_never_contact);
            setNeverSms(contactDetails.main[0].person_preferences_never_text);
            setLimitCalls(contactDetails.main[0].person_preferences_limit_calls);
            setLimitCalls(contactDetails.main[0].person_preferences_limit_emails);

            // set fields
            setPersonChallenges(contactDetails.main[0].person_challenges);
            setPersonFoodConsiderations1(contactDetails.main[0].person_food_considerations_1 ? contactDetails.main[0].person_food_considerations_1 : "");
            setPersonFoodConsiderations2(contactDetails.main[0].person_food_considerations_2 ? contactDetails.main[0].person_food_considerations_2 : []);
            setPersonFoodConsiderationsOther(contactDetails.main[0].person_food_considerations_other ? contactDetails.main[0].person_food_considerations_other : "");
        }
    }, [contactDetails]);

    useEffect(() => {
        if (participantDetails) {
            const _eventOverview = cloneDeep(participantDetails.event_overview);
            if (_eventOverview) {
                // Sort
                _eventOverview.sort(function (a, b) {
                    return new Date(a.event_start) - new Date(b.event_start);
                });
                setEventOverview(_eventOverview);
                // Sort
                setOutboundOverview(participantDetails.outbound_overview);
                setAttendeeDetails(participantDetails);
            }
        }
    }, [participantDetails]);

    useEffect(() => {
        if (personOpenOpportunity) {
            setPersonPielineStage(personOpenOpportunity.pipeline_stage_id ? personOpenOpportunity.pipeline_stage_id : "")
            setPersonPielineStages(personOpenOpportunity.pipeline_stages ? personOpenOpportunity.pipeline_stages : []);
        }
    }, [personOpenOpportunity])

    const showProgress = () => (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
        </div>
    );

    const attendanceStatusUpdate = (item) => {
        const { query } = props;
        const start_date = moment(query.range[0]).format('YYYY-MM-DD');
        const end_date = moment(query.range[1]).format('YYYY-MM-DD');;
        let _query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (query.productType !== "" && query.productType !== null) {
            _query = `${_query}&equals[product_type_id]=${query.productType.product_type_id}`
        }
        if (query.product !== "" && query.product !== null && query.product !== undefined) {
            _query = `${_query}&equals[product_id]=${query.product.product_id}`
        }
        if (query.user !== "" && query.user !== null && query.user !== undefined) {
            _query = `${_query}&equals[user_id]=${query.user.user_id}&equals[user_type]=${query.user.user_type}`
        }
        if (query.country !== "" && query.country !== null && query.country !== undefined) {
            _query = `${_query}&equals[country_id]=${query.country.country_id}`
        }
        if (query.networkDirector !== "" && query.networkDirector !== null && query.networkDirector !== undefined) {
            _query = `${_query}&equals[network_directors]=${query.networkDirector.id}`
        }

        const data = {
            participantDetails: { event_id: eventInfo.event_id, participant_id: person.potential_participant_id },
            query: _query,
            obj: {
                event_id: eventDetails.event_id,
                job_id: personObj.job_id,
                attending: item.value
            }
        }
        updateAttendanceStatus(data);
        setAttendeeDetails(null);
    }

    const getQuery = () => {
        const { query } = props;
        const start_date = moment(query.range[0]).format('YYYY-MM-DD');
        const end_date = moment(query.range[1]).format('YYYY-MM-DD');;
        let _query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (query.productType !== "" && query.productType !== null) {
            _query = `${_query}&equals[product_type_id]=${query.productType.product_type_id}`
        }
        if (query.product !== "" && query.product !== null && query.product !== undefined) {
            _query = `${_query}&equals[product_id]=${query.product.product_id}`
        }
        if (query.user !== "" && query.user !== null && query.user !== undefined) {
            _query = `${_query}&equals[user_id]=${query.user.user_id}&equals[user_type]=${query.user.user_type}`
        }
        if (query.country !== "" && query.country !== null && query.country !== undefined) {
            _query = `${_query}&equals[country_id]=${query.country.country_id}`
        }
        if (query.networkDirector !== "" && query.networkDirector !== null && query.networkDirector !== undefined) {
            _query = `${_query}&equals[network_directors]=${query.networkDirector.id}`
        }

        return _query;
    }

    const handleSendoutEmail = (e) => {
        const { query } = props;
        const start_date = moment(query.range[0]).format('YYYY-MM-DD');
        const end_date = moment(query.range[1]).format('YYYY-MM-DD');;
        let _query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (query.productType !== "" && query.productType !== null) {
            _query = `${_query}&equals[product_type_id]=${query.productType.product_type_id}`
        }
        if (query.product !== "" && query.product !== null && query.product !== undefined) {
            _query = `${_query}&equals[product_id]=${query.product.product_id}`
        }
        if (query.user !== "" && query.user !== null && query.user !== undefined) {
            _query = `${_query}&equals[user_id]=${query.user.user_id}&equals[user_type]=${query.user.user_type}`
        }
        if (query.country !== "" && query.country !== null && query.country !== undefined) {
            _query = `${_query}&country_id=${query.country.country_id}`
        }
        const data = {
            query: _query,
            obj: {
                event_id: eventDetails.event_id,
                job_id: personObj.job_id,
                invitation_type: e.target.value
            }
        }
        fetchPatch(`/email/event/jobs`, data.obj).then((res) => {
            Swal.fire({
                title: "",
                text: res.message,
                icon: res.result ? "success" : "warning",
                showCancelButton: false,
                confirmButtonText: "Ok",
            }).then(result => {
                // something
            });
        })
        // updateSendoutEmail(data);
    }

    const getStatusClass = (item) => {
        const { event_has_happened, showed, color, has_open_opportunity, purchase_id, event_id } = item;
        if (event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
            return 'noBgDotted';
        } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
            return 'lightBlueBgDotted';
        } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && showed) {
            return 'lightBlueBgSolid';
        } else if (!event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
            return 'lightBlueBg';
        } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && showed) {
            return 'darkBlueBgSolid';
        } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
            return 'darkBlueBgDotted';
        } else if (!event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
            return 'darkBlueBg';
        } else if (!event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
            return 'noBgSolid';
        } else if (!event_has_happened && color === "purchase_a" && has_open_opportunity && !showed) {
            return 'cyanBgSolid'
        } else if (!event_has_happened && color === "purchase_b" && has_open_opportunity && !showed) {
            return 'cyanBg'
        } else {
            return 'noBgSolid';
        }
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const getCompanyName = (companyID) => {
        return jobs.related_objects.companies[companyID].company_name;
    };

    const getYears = (date, end_date) => {
        let d = new Date(date);
        let start = d.getFullYear();

        if (end_date === null) {
            let c = new Date();
            let current = c.getFullYear();
            return Math.ceil(parseInt(current) - parseInt(start));
        }
        else {
            let e = new Date(end_date);
            let end = e.getFullYear();
            return Math.ceil(parseInt(end) - parseInt(start));
        }
    };

    if (!person) {
        return showProgress();
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "person_never_contact") {
            setNeverContact(value);
        }

        if (name === "person_preferences_never_text") {
            setNeverSms(value);
        }

        if (name === "person_preferences_limit_calls") {
            setLimitCalls(value);
        }

        if (name === "person_preferences_limit_emails") {
            setLimitEmails(value);
        }

        props.updateContact({
            id: person.person_id,
            obj: { [name]: value }
        });
    }

    const saveInfo = (field) => {
        if (field === "person_challenges") {
            props.updateContact({
                id: person.person_id,
                obj: { person_challenges: personChallenges }
            });
        }
        if (field === "person_food_considerations") {
            if (personFoodConsiderationsOther === "") {
                props.updateContact({
                    id: person.person_id,
                    obj: {
                        person_food_considerations_1: personFoodConsiderations1,
                        person_food_considerations_2: personFoodConsiderations2
                    }
                });
            } else {
                props.updateContact({
                    id: person.person_id,
                    obj: {
                        person_food_considerations_1: personFoodConsiderations1,
                        person_food_considerations_2: personFoodConsiderations2,
                        person_food_considerations_other: personFoodConsiderationsOther
                    }
                });
            }
        }
    }

    const cancelMeeting = (row) => {
        console.log("delete...", row);
        const data = {
            person_id: person.person_id,
            purchase_id: person.purchase_id,
            meeting_status: "CANCELLED"
        }
        Swal.fire({
            title: "Confirm Cancel",
            text: "Are you sure you want to cancel this booking?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                updateBookedMeeting({ person_id: person.person_id, booked_meeting_id: row.booked_meeting_id, data, query: overviewQuery, purchase_id: person.purchase_id });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                    <ParticipantCard
                        classes={classes}
                        person={person}
                        personDetails={personDetails}
                        setBookMeetingModal={setBookMeetingModal}
                        status={person.tile_color}
                        booking={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ParticipantPersonalDetails
                        classes={classes}
                        jobs={jobs}
                        personDetails={personDetails}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        isNetworkDirector={isNetworkDirector}
                        handleInputChange={handleInputChange}
                        limitCalls={limitCalls}
                        limitEmails={limitEmails}
                        neverSms={neverSms}
                        neverContact={neverContact}
                        personChallenges={personChallenges}
                        setPersonChallenges={setPersonChallenges}
                        saveInfo={saveInfo}
                        setPersonFoodConsiderations1={setPersonFoodConsiderations1}
                        setPersonFoodConsiderations2={setPersonFoodConsiderations2}
                        setPersonFoodConsiderationsOther={setPersonFoodConsiderationsOther}
                        personFoodConsiderations1={personFoodConsiderations1}
                        personFoodConsiderations2={personFoodConsiderations2}
                        personFoodConsiderationsOther={personFoodConsiderationsOther}
                        foodConsiderations={foodConsiderations}
                        noAction={true}
                    />
                </Grid>
                {
                    !attendeeDetails ? (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <ParticipantEventsOverview
                                    classes={classes}
                                    attendeeDetails={attendeeDetails}
                                    eventOverview={eventOverview}
                                    getStatusClass={getStatusClass}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ParticipantSendoutOverview
                                    classes={classes}
                                    attendeeDetails={attendeeDetails}
                                    eventOverview={eventOverview}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.sendout}>
                                    <Typography component="span" style={{ marginRight: '10px' }}>
                                        <b>Attending Status</b>
                                    </Typography>
                                    <div className={classes.flexCenter}>
                                        {
                                            attendingStatus.map((item, index) => {
                                                const selected = participantDetails && participantDetails.answer && participantDetails.answer.value === item.value ? item.value : '';
                                                return (
                                                    // <Button disabled={eventInfo.event_attendance_done !== false || isNetworkDirector} className={classes[selected]} color="inherit" variant="contained" key={index + 200} sx={{ marginRight: '10px' }} onClick={(e) => attendanceStatusUpdate(item)}>{item.title}</Button>
                                                    <Button disabled={true} className={classes[selected]} color="inherit" variant="contained" key={index + 200} sx={{ marginRight: '10px' }} onClick={(e) => attendanceStatusUpdate(item)}>{item.title}</Button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </>
                    )
                }

                {/* <Grid xs={12} item>
                    <ParticipantMeetings
                        classes={classes}
                        personMeetings={personMeetings}
                        setUpdateMeetingModal={setUpdateMeetingModal}
                        setCreateMeetingModal={setCreateMeetingModal}
                        setRow={setRow}
                        cancelMeeting={cancelMeeting}
                    />
                </Grid> */}

                {
                    personOpenOpportunity && !isNetworkDirector && !isEmpty(personOpenOpportunity) && (
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <NextPlannedAction opportunity={personOpenOpportunity} person={person} />

                                    <div style={{ borderTop: '1px solid #c8c8c8', padding: '10px 0', margin: '10px 0 0' }}></div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ marginRight: '10px' }}>
                                            <b>Current Open Opportunity:</b> {personOpenOpportunity && personOpenOpportunity.opportunity_id ? <a href={`${window.location.origin}/opportunities/id/${personOpenOpportunity ? personOpenOpportunity.opportunity_id : undefined}`} target="_blank">{personOpenOpportunity && personOpenOpportunity.opportunity_id}</a> : "None"}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography component="span" style={{ marginRight: '10px' }}>
                                                <b>Pipeline Stage:</b>
                                            </Typography>
                                            <FormControl size="small" sx={{ minWidth: '200px' }}>
                                                <InputLabel id="person_pipeline_stage">Pipeline Stage</InputLabel>
                                                <Select
                                                    disabled={true}
                                                    fullWidth
                                                    labelId="person_pipeline_stage"
                                                    id="person_pipeline_stage"
                                                    value={personPielineStage}
                                                    onChange={(e) => {
                                                        const selected_stage = personOpenOpportunity.pipeline_stages.filter(item => item.pipeline_stage_id === e.target.value)[0]
                                                        const current_stage = personOpenOpportunity.pipeline_stages.filter(item => item.pipeline_stage_id === personPielineStage)[0]
                                                        Swal.fire({
                                                            customClass: {
                                                                title: 'custom-swal-title',
                                                                confirmButton: 'custom-swal-confirm',
                                                            },
                                                            title: 'You are going to change the stage of the opportunity',
                                                            html: `${current_stage.pipeline_stage_title} &#8594 ${selected_stage.pipeline_stage_title}`,
                                                            width: '600px',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#e0e0e0',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: "Cancel"
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                setPersonPielineStage(e.target.value);
                                                                props.updatePersonStage({
                                                                    person_id: person.person_id,
                                                                    opportunityId: personOpenOpportunity.opportunity_id,
                                                                    stageId: e.target.value
                                                                });
                                                            }
                                                        })
                                                    }}
                                                    input={<OutlinedInput label="Pipeline Stage" />}
                                                >
                                                    {personPielineStages.map((item) => (
                                                        <MenuItem
                                                            key={item.pipeline_stage_id}
                                                            value={item.pipeline_stage_id}
                                                        >
                                                            {item.pipeline_stage_title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }

                <Grid item xs={12}>
                    <ParticipantJobs
                        classes={classes}
                        jobs={jobs}
                        personObj={personObj}
                        getCompanyName={getCompanyName}
                        getYears={getYears}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ParticipantPurchases
                        classes={classes}
                        personPurchases={personPurchases}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ParticipantOpportunities
                        classes={classes}
                        personOpportunities={personOpportunities}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Accordion expanded={expanded === "panel4"} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography variant="h1" component="h5" sx={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: '500' }}>
                                History <span className={classes.historyCount}>{personHistory && personHistory.length}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ background: "#efefef" }}>
                            <PersonHistory person={personObj} isNetworkDirector={isNetworkDirector} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <BookMeetingModal
                open={bookMeetingModal}
                handleClose={() => setBookMeetingModal(false)}
                person={person}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                reset={Math.random()}
            />
            <BookMeetingModal
                open={createMeetingModal}
                handleClose={() => setCreateMeetingModal(false)}
                person={person}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                reset={Math.random()}
            />
            <BookMeetingModal
                open={updateMeetingModal}
                handleClose={() => setUpdateMeetingModal(false)}
                person={person}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                row={row}
                edit={true}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    personPurchases: eventsOverviewSelectors.personPurchases(state),
    personOpportunities: eventsOverviewSelectors.personOpportunities(state),
    participantDetails: eventsOverviewSelectors.participantDetails(state),
    personMeetings: eventsOverviewSelectors.personMeetings(state),
    contactDetails: ContactsSelectors.contactDetails(state),
    foodConsiderations: ContactsSelectors.foodConsiderations(state),
    jobs: opportunitySelectors.jobs(state),
    personHistory: eventsOverviewSelectors.personHistory(state),
    personOpenOpportunity: eventsOverviewSelectors.personOpenOpportunity(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateAttendanceStatus: eventsOverviewActions.updateAttendanceStatus,
            updateBookedMeeting: eventsOverviewActions.updateBookedMeeting,
            updateSendoutEmail: eventsOverviewActions.updateSendoutEmail,
            updatePersonStage: eventsOverviewActions.updatePersonStage,
            getPersonPurchases: eventsOverviewActions.getPersonPurchases,
            getPersonOpenOpportunity: eventsOverviewActions.getPersonOpenOpportunity,
            getPersonOpportunities: eventsOverviewActions.getPersonOpportunities,
            getParticipantDetails: eventsOverviewActions.getParticipantDetails,
            getPersonMeetings: eventsOverviewActions.getPersonMeetings,
            getContactDetails: contactsActions.getContactDetails,
            getFoodConsiderations: contactsActions.getFoodConsiderations,
            getJobs: opportunityActions.getJobs,
            updateContact: contactsActions.updateContact
        },
        dispatch
    );

function WithNavigate(props) {
    let params = useParams();
    return <PersonResaleOverviewTab {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
