import * as React from 'react';
import { TextField } from "formik-material-ui";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form } from 'formik';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import {
    Button,
    LinearProgress,
    MenuItem,
    Typography,
    Container,
    Grid,
    ButtonGroup,
    Tabs,
    Tab,
    CircularProgress,
    TextareaAutosize
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';

const editorConfiguration = {
    plugins: [ List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote ],
    toolbar: ['heading', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    }
};

const types = [
    'select_event_type',
    'sent_email',
    'missed_call',
    'completed_call',
    'inbound_email_note',
    'inbound_call_note',
    'lead_sheet_call',
    'lead_sheet_note',
    'outbound_email_note',
    'person_erp_note',
]

function AddEventModal(props) {
    const formRef = React.createRef();
    const { getHistoryNoteTypes, historyNoteTypes } = props;

    React.useEffect(() => {
        getHistoryNoteTypes();
    }, []);

    const handleClose = () => {
        props.handleClose()
    };

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} className="edit-modal" disableEnforceFocus>
                <DialogTitle style={{ paddingBottom: '0px', paddingTop: '4px' }}>Add Event</DialogTitle>
                <DialogContent>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            type: 'person_erp_note',
                            subject: '',
                            note: ''
                        }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            let data = {
                                person_log_content: values.note,
                                person_log_title: values.subject == "" ? historyNoteTypes?.filter(item => item.type_code === values.type)[0].type_title : values.subject,
                                person_log_code: values.type,
                                person_log_object_type: 'opportunity_action',
                                person_log_type: 'sales',
                                person_log_object_id: 6
                            }
                            props.addEventLog({ id: props.personId, obj: data, opportunityId: props.opportunityId });
                            handleClose();
                        }}
                        render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                            <Form>
                                <Grid container sx={{ marginBottom: '15px', marginTop: '0px' }} spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            select
                                            name="type"
                                            label="Event type"
                                            variant="outlined"
                                            margin="normal"
                                            component={TextField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            size={'small'}
                                            style={{ marginTop: '0px' }}
                                        >
                                            {
                                                historyNoteTypes && historyNoteTypes.map(item => <MenuItem value={item.type_code}>{item.type_title}</MenuItem>)
                                            }
                                        </Field>

                                        {
                                            values.type !== 'missed_call' && values.type !== 'completed_call' &&
                                            <Field
                                                name="subject"
                                                type="text"
                                                label="subject"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                size={'small'}
                                                style={{ marginTop: '0px' }}
                                            />
                                        }

                                        <Field
                                            name="note"
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={ editorConfiguration }
                                                            data={field.value}
                                                            onChange={(event, editor) => {
                                                                form.setFieldValue(field.name, editor.getData());
                                                            }}
                                                        // onSaveKeystroke={this.props.onSaveKeystroke}
                                                        />
                                                    </>
                                                )
                                            }}
                                        />

                                        {/* New Change: Replacing Rich Text Editor with Textarea */}
                                        {/* <Field
                                            name="note"
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <TextareaAutosize
                                                            name="note"
                                                            label="Note"
                                                            onChange={(event) => {
                                                                form.setFieldValue(field.name, event.target.value);
                                                            }}
                                                            minRows={8}
                                                            placeholder="Add a note"
                                                            style={{ padding: '10px', width: '100%', minWidth: '400px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '5px' }}
                                                        />
                                                    </>
                                                )
                                            }}
                                        /> */}

                                    </Grid>
                                </Grid>

                                {isSubmitting && <LinearProgress />}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        style={{ marginRight: '12px' }}
                                    >
                                        {'Save'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        disabled={isSubmitting}
                                        onClick={handleClose}
                                    >
                                        {'Cancel'}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.getHistoryNoteTypes_loading(state),
    historyNoteTypes: opportunitySelectors.historyNoteTypes(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getHistoryNoteTypes: opportunityActions.getHistoryNoteTypes,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEventModal);