import React from "react";

let NotFound = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <h1 style={{marginBottom: 0}}>404</h1>
            <h2 style={{marginTop: 0}}>Page not found</h2>
        </div>
    );
};

export default NotFound;
