import React from "react";
import Button from '@mui/material/Button';
import CalendarWidget from "./Widgets/CalendarWidget/Calendar";

import { selectors as opportunitySelectors, actions as oppertunitiesActions } from '../../../Ducks/opportunity';
import { selectors as accountSelectors } from '../../../Ducks/account';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function Calendar(props) {
  const { cronofyAuthorized, getAllEvents, getAllEvenets_inProgress } = props;

    return (
        <>
            <div style={{textAlign: 'right', marginBottom: '1rem'}}>
                <Button onClick={() => getAllEvents()} variant="contained" disabled={getAllEvenets_inProgress}>Refresh
                    Events</Button>
            </div>
            <CalendarWidget {...props} events={cronofyAuthorized ? props.events : []}
                            legacy_events={props.calendar?.data?.main}/>
        </>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.isLoading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    calendar: opportunitySelectors.calendar(state),
    events: opportunitySelectors.events(state),
    getAllEvents_loading: opportunitySelectors.getAllEvents_loading(state),
    getCalendar_loading: opportunitySelectors.getCalendar_loading(state),
    getAllEvenets_inProgress: opportunitySelectors.getAllEvenets_inProgress(state),
    cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
    calendarIsLoading: opportunitySelectors.calendarIsLoading(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getCalendar: oppertunitiesActions.getCalendar,
        getAllEvents: oppertunitiesActions.getAllEvents,
        resetCalendarLoader: oppertunitiesActions.resetCalendarLoader
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
