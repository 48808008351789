import { call } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

import {
  types as salesListTypes,
  actions as salesListActions,
} from "../Ducks/salesList";

import { callApi } from "./utils";
import * as api from "../API/contacts";

function* getSalesList(action) {
  const { success, failure } = salesListActions.saga.getSalesList;

  yield callApi(call(api.getSalesList, action.data), success, failure);
}

export default function* rootCustomerSaga() {
  yield takeLatest(salesListTypes.getSalesList, getSalesList);
}
