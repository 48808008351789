import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
  asyncs: {
    getProducts: (data) => data,
    getProductDetails: (data) => data,
    updateProduct: (data) => data,
    getCountries: (data) => data,
    getProductTypes: (data) => data,
    getEvents: (data) => data
  }
}, 'products');

let initialState = asyncInitialState({
  products: null,
  productDetails: null,
  updatedProduct: null,
  productTypes: null,
  countries: null,
  events: null,
  errorMessage: '',
  success: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getProducts:
    case types.getProductDetails:
    case types.getCountries:
    case types.updateProduct:
    case types.getProductTypes:
    case types.getEvents:
      return asyncOnRequest(state, action);
    case types.saga.getProducts.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          products: action.payload.data,
          errorMessage: ''
        };
      })

    case types.saga.getProductDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          productDetails: action.payload.data,
          errorMessage: ''
        };
      })

    case types.saga.getProductTypes.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          productTypes: action.payload.data,
          errorMessage: ''
        };
      })

    case types.saga.getCountries.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          countries: action.payload.data,
          errorMessage: ''
        };
      })

    case types.saga.updateProduct.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          updatedProduct: action.payload.data,
          errorMessage: ''
        };
      })

    case types.saga.getEvents.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          events: action.payload.data.main,
          errorMessage: ''
        };
      })

    case types.saga.getProducts.failure:
    case types.saga.getProductDetails.failure:
    case types.saga.getProductTypes.failure:
    case types.saga.getCountries.failure:
    case types.saga.updateProduct.failure:
    case types.saga.getEvents.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.products,
  {
    errorMessage: (data) => data.errorMessage,
    getEvents_loading: (data) => data.getEvents_loading
  }
)

const syncSelector = {
  isLoading: (state) => state.products.loading,
  products: (state) => state.products.data.products,
  productDetails: (state) => state.products.data.productDetails,
  productTypes: (state) => state.products.data.productTypes,
  countries: (state) => state.products.data.countries,
  events: (state) => state.products.data.events
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)