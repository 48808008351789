import {closestCenter, DndContext, DragOverlay, useDraggable, useDroppable} from "@dnd-kit/core";
import {
    Collapse, FormControl,
    FormControlLabel,
    Grid,
    IconButton, InputAdornment,
    List,
    ListItem,
    ListItemText,
    Paper, TextField, Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DragIndicator, ExpandLess, ExpandMore, Search, SyncAlt} from "@mui/icons-material";
import {assignTodosToKpis, getAllTodos, getKpis} from "../../../API/clientRetention";
import {toast} from "react-toastify";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import {LoadingButton} from "@mui/lab";

function DraggableItem({id, children}) {
    const {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable({
        id,
    });

    const style = {
        transform: `translate3d(${transform ? transform.x : 0}px, ${transform ? transform.y : 0}px, 0)`,
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
            {children}
        </div>
    );
}

// Droppable Area Component
function DroppableArea({id, children}) {
    const {setNodeRef} = useDroppable({
        id,
    });

    return (
        <div ref={setNodeRef}>
            {children}
        </div>
    );
}

const AssignTodoToKpi = (props) => {
    const {handleClose} = props;
    const [kpis, setKpis] = useState([]);
    const [todos, setTodos] = useState([]);
    const [toggle, setToggle] = useState({});
    const [activeId, setActiveId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);


    useEffect(() => {
        fetchKpis();
        fetchTodos();
    }, []);

    const fetchKpis = () => {
        getKpis().then((res) => {
            setKpis(res.data.main);
            handleToggle(res.data.main[0].id);
        })
    }

    const fetchTodos = (search = '') => {

        getAllTodos(search).then((res) => {
            setTodos(res.data.main);
        }).catch((error) => {
            toast.error(error.error);
        })
    }

    const updateKpis = (activeId, kpiId, criterionId, remove = false) => {
        let prevKpis = [...kpis];

        prevKpis.forEach((kpi) => {
            if (kpi.id == kpiId) {
                kpi.criteria.forEach((criterion) => {
                    if (criterion.id == criterionId) {
                        if (!remove) {
                            const todoExists = criterion.todos?.filter((todo) => todo.id == activeId);

                            if (!todoExists?.length) {
                                const todo = todos.find((todo) => todo.id == activeId);

                                criterion.todos.push(todo);
                            }
                        } else {
                            const filteredTodos = criterion.todos?.filter((todo) => todo.id != activeId);
                            criterion.todos = filteredTodos;
                        }
                    }
                })
            }
        });

        setKpis(prevKpis);
    }

    const handleToggle = (id) => {
        setToggle((prevToggle) => ({
            ...prevToggle,
            [id]: !prevToggle[id],
        }));
    };

    const handleDragEnd = (event) => {
        const {active, over} = event;
        const splittedActiveItem = active.id.split('_');

        if (over && active.id !== over.id) {
            if (splittedActiveItem[0] === 'todo') {
                //dragging is from todos to kpis.
                const activeId = splittedActiveItem[1];
                const droppableItem = over.id.split('_');
                const kpiId = droppableItem[1];
                const criterionId = droppableItem[3];

                updateKpis(activeId, kpiId, criterionId);
            } else {
                //dragging from kpi list to todos
                const activeId = splittedActiveItem[5];
                const kpiId = splittedActiveItem[1];
                const criterionId = splittedActiveItem[3];

                updateKpis(activeId, kpiId, criterionId, true);
            }
        }
    };

    const handleDragStart = (event) => {
        setActiveId(event.active.id);
    }

    const handleSaveClick = () => {
        setIsSubmitting(true);
        let postData = {"kpi_todo_items": []};

        kpis.forEach((kpi) => {
            let kpiTodoItem = {"kpi_id": kpi.id, "kpi_criteria": []};

            kpi.criteria?.forEach(criterion => {
                let kpiCriterion = {"criteria_id": criterion.id, "todo_ids": []};

                let criterionTodos = criterion.todos?.map(todo => todo.id);
                kpiCriterion.todo_ids = criterionTodos;

                kpiTodoItem.kpi_criteria.push(kpiCriterion);
            });

            postData.kpi_todo_items.push(kpiTodoItem);
        });

        assignTodosToKpis(postData).then(res => {
            if (res) {
                toast.success(res.message);

                if (handleClose) {
                    handleClose();
                }
            }

            setIsSubmitting(false);
        }).catch((error) => {
            toast.error(error.message);
            setIsSubmitting(false);
        });
    }

    return (
        <div>
            <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <Typography
                    variant='h5'
                    component='h5'
                    sx={{fontWeight: 'bold', textAlign: 'left', padding: '10px'}}>
                    Assign Todos To KPIs
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Paper sx={{padding: '20px'}}>
                            <Box display='flex' justifyContent='space-between'>
                                <Typography
                                    variant='h5'
                                    component='h5'
                                    sx={{fontWeight: 'bold', textAlign: 'left', padding: '10px'}}>
                                    KPIs
                                </Typography>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    style={{
                                        margin: "10px",
                                    }}
                                    loading={isSubmitting}
                                    onClick={() => handleSaveClick()}
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                            <List>
                                {
                                    kpis.map((kpi) => (
                                        <React.Fragment key={kpi.id}>
                                            <ListItem button onClick={() => handleToggle(kpi.id)}
                                                      sx={{
                                                          backgroundColor: '#1565c0',
                                                          color: 'white',
                                                          '&:hover': {
                                                              backgroundColor: '#1565c0',
                                                              color: 'white',
                                                              textDecoration: 'underline'
                                                          }
                                                      }}>
                                                <ListItemText primary={kpi.title}/>
                                                <IconButton size="small" sx={{color: "white"}}>
                                                    {toggle[kpi.id] ? <ExpandLess/> : <ExpandMore/>}
                                                </IconButton>
                                            </ListItem>
                                            <Collapse in={toggle[kpi.id]} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {kpi.criteria.map((criterion) => (
                                                        <>
                                                            <DroppableArea
                                                                id={`kpi_${kpi.id}_criteria_${criterion.id}`}
                                                                key="kpi-list">
                                                                <ListItem button xs={2} sx={{
                                                                    pl: 4, backgroundColor: '#F7F7F7',
                                                                    color: 'black'
                                                                }}>
                                                                    <ListItemText
                                                                        primary={`Criteria: ${criterion.title}`}
                                                                        sx={{width: '50px'}}/>
                                                                    <br/>


                                                                </ListItem>

                                                                <FormControl sx={{display: 'inline'}}>
                                                                    <Box display="flex" alignItems="left">
                                                                        <FormLabel component="legend"
                                                                                   sx={{
                                                                                       marginTop: '8px',
                                                                                       marginLeft: 1,
                                                                                       marginRight: 2,
                                                                                       fontWeight: 'bold'
                                                                                   }}>
                                                                            Severity:
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            row
                                                                            size="small"
                                                                            aria-labelledby='kpi-criteria-severity-radio-buttons-group-label'
                                                                            name='severity'
                                                                            value={criterion.severity}
                                                                            onChange={() => {
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value='1'
                                                                                              control={<Radio/>}
                                                                                              label='1'
                                                                                              disabled={true}/>
                                                                            <FormControlLabel value='2'
                                                                                              control={<Radio/>}
                                                                                              label='2'
                                                                                              disabled={true}/>
                                                                            <FormControlLabel value='3'
                                                                                              control={<Radio/>}
                                                                                              label='3'
                                                                                              disabled={true}/>
                                                                            <FormControlLabel value='4'
                                                                                              control={<Radio/>}
                                                                                              label='4'
                                                                                              disabled={true}/>
                                                                            <FormControlLabel value='5'
                                                                                              control={<Radio/>}
                                                                                              label='5'
                                                                                              disabled={true}/>
                                                                            <FormControlLabel value='6'
                                                                                              control={<Radio/>}
                                                                                              label='6'
                                                                                              disabled={true}/>
                                                                        </RadioGroup>
                                                                    </Box>
                                                                </FormControl>
                                                                <List id={`kpi_criteria_${criterion.id}_todos`}
                                                                      component="div" disablePadding>
                                                                    {
                                                                        criterion.todos?.map((todo) => (
                                                                            <>
                                                                                <DraggableItem
                                                                                    id={`kpi_${kpi.id}_criterion_${criterion.id}_todo_${todo.id}`}
                                                                                    key={`criterion_todo_${todo.id}`}
                                                                                >
                                                                                    <ListItem button sx={{
                                                                                        pl: 4,
                                                                                        mb: '5px',
                                                                                        border: '1px solid #F0F0F0',
                                                                                        borderRadius: '7px'
                                                                                    }}>
                                                                                        <Tooltip
                                                                                            title='Drag right to remove todo from kpi criteria'
                                                                                            placement='top'>
                                                                                            <DragIndicator/>
                                                                                        </Tooltip>

                                                                                        <ListItemText
                                                                                            primary={todo.title}/>
                                                                                    </ListItem>
                                                                                </DraggableItem>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </List>
                                                            </DroppableArea>
                                                        </>

                                                    ))}
                                                </List>
                                            </Collapse>
                                        </React.Fragment>
                                    ))
                                }
                            </List>

                        </Paper>
                    </Grid>
                    <Grid item xs={1} sx={{marginTop: '250px'}}>
                        <SyncAlt sx={{fontWeight: 'bold', fontSize: '50px'}}/>
                    </Grid>
                    <Grid item xs={5}>
                        <Paper sx={{padding: '20px'}}>
                            <Box display='flex' justifyContent='space-between'>
                                <Typography
                                    variant='h5'
                                    component='h5'
                                    sx={{fontWeight: 'bold', textAlign: 'left', padding: '10px'}}>
                                    Todo's
                                </Typography>
                                <TextField
                                    name='search_todo'
                                    size='small'
                                    sx={{padding: 0}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                    onInput={(event) => {
                                        let query = `?contains[title]=${event.target.value}`

                                        fetchTodos(query);
                                    }}/>
                            </Box>
                            <DroppableArea id="todo-list" key="todo-list">
                                <List
                                    sx={{
                                        maxHeight: 600,
                                        overflowY: 'auto',
                                    }}>
                                    {todos.map((todo) => (
                                        <DraggableItem id={`todo_${todo.id}`} key={`todo_${todo.id}`}>
                                            <ListItem button
                                                      sx={{
                                                          pl: 4,
                                                          mb: '5px',
                                                          border: '1px solid #F0F0F0',
                                                          borderRadius: '7px'
                                                      }}>
                                                <Tooltip
                                                    title='Drag left to assign todo to kpi criteria'
                                                    placement='top'>
                                                    <DragIndicator/>
                                                </Tooltip>
                                                <ListItemText primary={todo.title}/>
                                            </ListItem>
                                        </DraggableItem>
                                    ))}
                                </List>
                            </DroppableArea>
                        </Paper>
                    </Grid>
                </Grid>
                <DragOverlay>
                    {activeId ? (
                        <Paper xs={{padding: "50px"}}>
                            <Typography>
                                {kpis.concat(todos).find((item) => item.id === activeId)?.title || ''}
                            </Typography>
                        </Paper>
                    ) : null}
                </DragOverlay>
            </DndContext>
        </div>
    );
}

export default AssignTodoToKpi;