import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';

import { enGB } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

setDefaultOptions({
    locale: enGB,
})

export default function SubComponentsPickers(props) {
    const { date, setDate } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', zIndex: 100, alignItems: 'center' }}>
                        <CustomTimeInput min={0} max={23} handleChange={(n) => (setDate(moment(date).set({ hours: n })))} number={moment(date).hours()} />
                        <h1 style={{ padding: "0px 8px" }}>:</h1>
                        <CustomTimeInput min={0} max={59} handleChange={(n) => (setDate(moment(date).set({ minute: n })))} number={moment(date).minutes()} />
                    </div>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}


function CustomTimeInput(props) {
    const [number, setNumber] = React.useState(props.number || 0);

    const handleChange = (n) => {
        if(parseInt(n) > props.max) {
            n = props.max;
        }
        if(parseInt(n) < props.min) {
            n = props.min;
        }
        setNumber(n);
        props.handleChange(n);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <IconButton disabled={props.max <= number} style={{ width: '40px', height: '40px', marginLeft: "6px" }} onClick={() => props.max > number && handleChange(number + 1)}>
                <KeyboardArrowUpIcon style={{ width: '40px', height: '40px' }} />
            </IconButton>
            <TextField
                type="number"
                className="time-input"
                InputProps={{
                    inputProps: {
                        max: props.max, min: props.min
                    }
                }}
                value={number}
                onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (!value || (value >= props.min && value <= props.max)) {
                      setNumber(value);
                      handleChange(value);
                    }
                  }}
                size={'small'}
                style={{ width: '50px' }}
            />
            <IconButton disabled={props.min >= number} style={{ width: '40px', height: '40px', marginLeft: "6px" }} onClick={() => props.min < number && handleChange(number - 1)}>
                <KeyboardArrowDownIcon style={{ width: '40px', height: '40px' }} />
            </IconButton>

        </div>
    )
}
