import {fetchGet, fetchPatch, fetchPost, fetchDelete, fetchPut} from './util';


export const getOpportunityDetails = ({id}) => {
    const filters = localStorage.getItem("sales_overview_filters")
    let query = "";
    if (filters !== null) {
        const _filters = JSON.parse(filters);
        query = `?pipelineFilter=${_filters.pipelineFilter}&productFilter=${_filters.productFilter}&phoneFilter=${_filters.phoneFilter}`;
    }
    return fetchGet(`/opportunities/${id}/overview${query}`).then(res => res)
}

export const getEmployees = ({id}) => {
    return fetchGet(`/companies/${id}/employees`).then(res => res)
}

export const getStages = ({id}) => {
    return fetchGet(`/pipeline_stages?equals[pipeline_id]=${id}&sortBy=pipeline_stage_order,pipeline_stage_title`).then(res => res)
}

export const getUserPipelineStages = ({id}) => {
    return fetchGet(`/pipeline_stages?equals[pipeline_id]=${id}&sortBy=pipeline_stage_order,pipeline_stage_title`).then(res => res)
}

export const getHistory = ({id}) => {
    return fetchGet(`/opportunities/${id}/history`).then(res => res)
}

export const getJobs = (personID) => {
    return fetchGet(`/jobs?columns=persons.person_id,jobs.job_title,jobs.override_company_name,jobs.override_job_title,jobs.job_start,jobs.job_stop,jobs.job_email,jobs.job_phone,divisions.division_id,divisions.division_country,divisions.division_city,companies.company_id,companies.company_name,jobs.job_is_primary&equals[jobs.job_is_deleted]=0&equals[persons.person_id]=${personID}`).then(res => res)
}

export const getOpportunities = ({results, columns, offset}) => {
    return fetchGet(`/opportunities?results=${results}&columns=${columns}&offset=${offset}`).then(res => res)
}

export const getProducts = ({countryID, productShowInSales, results}) => {
    return fetchGet(`/products?results=${results}&equals[country_id]=${countryID}&equals[product_show_in_sales]=${productShowInSales}`).then(res => res)
}

export const getFilteredProducts = (queryString) => {
    return fetchGet(`/products?${queryString}`).then(res => res)
}

export const applyEmailTemplate = ({templateId, opportunityId}) => {
    return fetchGet(`/templates/${templateId}/apply?opportunity_id=${opportunityId}`).then(res => res)
}

export const applySmsTemplate = ({templateId, opportunityId}) => {
    return fetchGet(`/templates/${templateId}/apply?opportunity_id=${opportunityId}`).then(res => res)
}

export const getProductOverview = (productID) => {
    return fetchGet(`/products/${productID}/overview`).then(res => res)
}

export const getMails = (data) => {
    return fetchGet(`/templates/${data.type ? data.type : 'mail'}/list?product_id=${data.productId}`).then(res => res)
}

export const getSmsTemplates = (productID) => {
    return fetchGet(`/templates/sms/list?product_id=${productID}`).then(res => res)
}

export const getLostReasons = (data) => {
    let url = '/opportunities/reasons/closed-lost';

    if(data?.opportunity?.opportunity_id) {
        url = `${url}?opportunity_id=${data?.opportunity?.opportunity_id}`;
    }

  return fetchGet(url).then(res => res)
}

export const getSalesReps = () => {
  return fetchGet(`/capabilities/salesrep`).then(res => res)
}

export const getPipelineId = (userID) => {
    return fetchGet(`/pipelines?equals[user_id]=${userID}`).then(res => res)
}

export const patchOpportunityData = (obj) => {
    if (obj.key !== undefined) {
        return fetchPatch(`/opportunities/${obj.id}`, {[obj.key]: obj[obj.key]}).then(res => res)
    } else {

        return fetchPatch(`/opportunities/${obj.id}`, obj.formData).then(res => res)
    }
}

export const postOpportunityData = (obj) => {
    return fetchPatch(`/opportunities/${obj.id}`, obj.formData).then(res => res)
}

export const searchCvr = (id) => {
    return fetchGet(`/cvr-search/${id}`).then(res => res)
}

export const postClosedLostReasons = (obj) => {
    return fetchPatch(`/opportunities/${obj.id}/status/closed:lost`, obj).then(res => res)
}

export const postUnsuccessfulCampaign = (obj) => {
    return fetchPatch(`/opportunities/${obj.id}/status/closed:unsuccessful_campaign`, obj).then(res => res)
}

export const postClosedWon = (obj) => {
    return fetchPatch(`/opportunities/${obj.id}/status/closed:won`, obj.formData).then(res => res)
}

export const checkEvent = (data) => {
    return fetchPost(`/opportunities/${data.opportunityId}/events/${data.eventId}`, {}).then(res => res)
}

export const unCheckEvent = (data) => {
    return fetchDelete(`/opportunities/${data.opportunityId}/events/${data.eventId}`, {}).then(res => res)
}

export const switchProduct = (data) => {
    return fetchPatch(`/opportunities/${data.opportunityId}/products/${data.productId}`, {}).then(res => res)
}

export const sendEmail = (data) => {
    return fetchPost(`/opportunities/${data.id}/email`, data.obj).then(res => res)
}

export const sendActionEmail = (data) => {
    return fetchPost(`/opportunities/${data.id}/action/send-email`, data.obj).then(res => res)
};

export const sendSms = (data) => {
    return fetchPost(`/opportunities/${data.id}/sms`, data.obj).then(res => res)
}
export const postNewJob = (data) => {
    return fetchPost(`/persons/${data.personId}/jobs`, data.newJob).then(res => res)
}
export const addEventLog = (data) => {
    return fetchPost(`/persons/${data.id}/log`, data.obj).then(res => res)
}

export const addCalendarEvent = (data) => {
    return fetchPost(`/opportunities/${data.id}/action`, data.obj).then(res => res)
}

export const editCalendarEvent = (data) => {
  return fetchPatch(`/opportunities/${data.opportunityId}/action/${data.actionId}/date-and-type`, data.obj).then(res => res)
}

export const updateExistingJob = ({personId, jobs}) => {
    return fetchPut(`/persons/${personId}/jobs?update_opportunities`, {jobs}).then(res => res)
}

export const updateStage = (data) => {
    return fetchPatch(`/opportunities/${data.opportunityId}/stages/${data.stageId}`, {}).then(res => res)
}

export const updateDivisionPhone = ({divisionID, division_phone}) => {
    return fetchPatch(`/divisions/${divisionID}`, {division_phone}).then(res => res)
}

export const updateJobPhone = (data) => {
    return fetchPatch(`/jobs/${data.jobID}`, data.obj).then(res => res)
}

export const searchCompanies = ({string, countryID}) => {
    return fetchGet(`/companies/search?contains[company_name]=${string}&equals[country_id]=${countryID}`).then(res => res)
}

export const getCalendar = (YearMonth) => {
    return fetchGet(`/calendar?date=${YearMonth}`).then(res => res)
}

export const getAllEvents = (from = null, to = null) => {
    const searchParams = new URLSearchParams();


  // Will do 3 attemps, so if cronofy credentials are being set e.g. the cronofy webhook is being processed by the backend, it dont throw 401 error
  return fetchGet('/cronofy/calendars/events?' + searchParams.toString(), 3);
};

export const updateEvent = (data) => {
    return fetchPut('/cronofy/events', data);
};

export const getAllCalendars = () => {
    return fetchGet('/cronofy/calendars');
};

export const opportunityActiveCallAction = (data) => {
    return fetchPatch(`/opportunities/${data.opportunityId}/action`, data.obj).then(res => res)
}

export const postOpportunityAction = (data) => {
    return fetchPost(`/opportunities/${data.id}/action`, data.obj).then(res => res)
}

export const getPersons = ({string}) => {
    return fetchGet(`/persons/search/${string}`).then(res => res)
}

export const getUserPipelineContent = (pipelineID) => {
    return fetchGet(`/pipelines/${pipelineID}/content`).then(res => res);
}

export const getHistoryNoteTypes = () => {
  return fetchGet(`/note-types`).then(res => res);
}

export const postPermissions = (data) => {
  return fetchPost(`/persons/${data.personId}/permission`,data).then(res => res);
}

export const sendTestMail = (data) => {
    return fetchPost(`/opportunities/${data.id}/email/test`, data).then(res => res);
}

export const togglePin = (data) => {
  return fetchPatch(`/opportunities/notes/${data}/pin`).then(res => res);
}

export const latestPlannedAction = (id) => {
    return fetchGet(`/opportunities/${id}/action/latest`).then(res => res);
}