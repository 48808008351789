import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { AccessAlarm, LinkedIn, Map, MobileFriendly } from '@mui/icons-material';

const useStyles = theme => ({
  card: {
    display: 'flex',
    backgroundColor: '#fff',
    marginBottom: "20px"
  },
  cardMedia: {
    flex: 1,
    width: '85px',
    backgroundColor: '#F1F1F1'
  },
  cardContent: {
    flex: 4
  },
  flexVertical: {
    display: "flex",
    // alignItems: "center"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

function CompanyCard(props) {
  const { classes, company } = props;
  const [companyObj, setCompanyObj] = React.useState([]);

  useEffect(() => {
    if (company && company.length !== 0) {
      setCompanyObj(company);
    }
  }, [company]);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div">
          {companyObj.company_name}
        </Typography>
        <Typography variant="body2" color="site.main">
          {companyObj.company_address_1}, {companyObj.company_city}
        </Typography>
      </CardContent>
    </Card>
  );
}


export default withStyles(useStyles)(CompanyCard);