import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { tryParseDate } from '../../../Utils/dateUtils';

const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff'
  },
  cardItem: {
    marginTop: '16px'
  },
  typography: {
    marginLeft: '20px'
  },
});
function OpenOpportunities(props) {
  const { classes } = props;
  const openOpportunities = (props.employees && props.employees.main &&props.employees.main.open_opportunities) || {};
  const persons = (props.employees && props.employees.related_objects && props.employees.related_objects.persons) || {};
  const products = (props.employees && props.employees.related_objects && props.employees.related_objects.products) || {};

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div" style={{borderBottom: "1px solid #ddd", marginBottom: '10px', paddingBottom: '10px'}} >
        Open Oppurtunities ({Object.keys(openOpportunities).length})
        </Typography>
          {
            Object.keys(openOpportunities).map((key, index) => {
              const openOppurtunity = openOpportunities[key];
            const person = persons[openOppurtunity.person_id];
            const product = products[openOppurtunity.products[0]]
              return <EventItem openOppurtunity={openOppurtunity} key={index} person={person} product={product} classes={classes} />
            })
          }
      </CardContent>
    </Card>
  );
}

const EventItem = ({ openOppurtunity, person, product, classes }) => {
  return (
  <MemberCard 
    name={person.person_firstname + ' ' + person.person_lastname} 
    title={person.title} 
    date={`${product.product_name} (${tryParseDate(openOppurtunity.stage_name)}) - ${tryParseDate(openOppurtunity.sales_rep)}`}
    classes={classes} 
    />
    )
}

const MemberCard = ({ name, title, date, classes }) => {
  return (
    <>
      <Typography gutterBottom variant="p" component="p" style={{fontSize:'14px'}} >
        <strong> {name} </strong>
      </Typography>
      <div>
        <Typography variant="body2" color="site.main">
          <div style={{color: 'inherit', display: 'flex', alignItems: 'center'}}>
            <span>
              {title}
            </span>
          </div>
        </Typography>
          <div style={{display: 'flex', alignItems: 'center', color: "#3a3641", fontSize: "12px" }}>
            <span>{date}
            </span>
          </div>
      </div>
    </>
  )
}

export default withStyles(useStyles)(OpenOpportunities);