import React from 'react';
import Select from "react-select";

const styles = {
    container: base => ({
        ...base,
        minWidth: '200px'
    })
};

const ValueEditor = ({
    operator,
    value,
    handleOnChange,
    title,
    className,
    type,
    inputType,
    values,
    fieldData
}) => {
    const [options, setOptions] = React.useState([]);
    const placeHolderText = (fieldData && fieldData.placeholder) || "";

    React.useEffect(() => {
        console.log("fieldData.... did update:", fieldData)
        if (fieldData && fieldData.multiple) {
            setOptions([
                { value: "male", label: Math.random() },
                { value: "female", label: "Female" },
                { value: "other", label: "Other" }
            ])
        }
    }, [fieldData])

    if (operator === "null" || operator === "notNull") {
        return null;
    }

    if (fieldData && fieldData.multiple) {
        return (
            <Select
                onChange={handleOnChange}
                value={value || null}
                options={options}
                isMulti
                isDisabled={fieldData?.disabled}
                styles={styles}
            />
        );
    }

    switch (type) {
        case "select":
            return (
                <select
                    className={className}
                    title={title}
                    onChange={(e) => handleOnChange(e.target.value)}
                    value={value}
                >
                    {values &&
                        values.map((v) => (
                            <option key={v.name} value={v.name}>
                                {v.label}
                            </option>
                        ))}
                </select>
            );

        case "checkbox":
            return (
                <input
                    type="checkbox"
                    className={className}
                    title={title}
                    onChange={(e) => handleOnChange(e.target.checked)}
                    checked={!!value}
                    disabled={fieldData?.disabled}
                />
            );

        case "radio":
            return (
                <span className={className} title={title}>
                    {values &&
                        values.map((v) => (
                            <label key={v.name}>
                                <input
                                    type="radio"
                                    value={v.name}
                                    checked={value === v.name}
                                    onChange={(e) => handleOnChange(e.target.value)}
                                />
                                {v.label}
                            </label>
                        ))}
                </span>
            );

        default:
            return (
                <input
                    type={inputType || "text"}
                    placeholder={placeHolderText}
                    value={value}
                    title={title}
                    className={className}
                    onChange={(e) => handleOnChange(e.target.value)}
                    disabled={fieldData?.disabled}
                />
            );
    }
};

ValueEditor.displayName = "ValueEditor";

export default ValueEditor;
