import React, {useEffect} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import {connect} from 'react-redux';
import {selectors as eventsOverviewSelectors, actions as eventsOverviewActions} from '../../../../Ducks/eventsOverview';
import {bindActionCreators} from 'redux';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent
} from '@mui/lab';
import {
    Email,
    Call,
    StickyNote2,
    PriorityHigh,
    ExpandMore as ExpandMoreIcon,
    Add,
} from '@mui/icons-material';
import {selectors as accountSelectors} from '../../../../Ducks/account';
import moment from 'moment';
import AddCommentModal from '../Modals/AddCommentModal';

const useStyles = _theme => ({});

function PersonHistory(props) {
    const {getPersonHistory, personHistory, classes, isLoading, user, person, isNetworkDirector} = props;
    const [selectedFilter, setSelectedFilter] = React.useState('all');
    const [selectedType, setSelectedType] = React.useState("all");
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [history, setHistory] = React.useState(null);

    useEffect(() => {
        setSelectedFilter(props.type ? 'special_case' : 'all')
    }, [])

    useEffect(() => {
        if (person && Object.keys(person).length !== 0) {
            const query = "all";
            getPersonHistory({person_id: person.person_id, query, isNetworkDirector});
        }
    }, [person]);

    useEffect(() => {
        if (personHistory !== null && personHistory !== undefined) {
            setHistory(personHistory);
        }
    }, [personHistory]);

    const showProgress = () => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </div>
    )

    if (isLoading || !history) {
        return showProgress()
    }

    let logs = history;

    const getUsername = (log) => {
        if (log && log.user_id === null)
            return 'Someone';
        if (log.user_id === user.user_details.user_id)
            return 'You';
        return log.user_firstname + " " + log.user_lastname;
    }
    const getPersonName = (person) => {
        return person.person_firstname + " " + person.person_lastname;
    }

    const handleChange = (e) => {
        setSelectedFilter(e.target.value);
    }

    const handleType = (e) => {
        setHistory(null);
        setSelectedType(e.target.value);
        getPersonHistory({person_id: person.person_id, query: e.target.value, isNetworkDirector});
    }

    let result = logs.reduce(function (r, a) {
        let key = a.log_code;
        if (a.new_stage_id) {
            key = 'stage_change';
        }
        if (key !== undefined) {
            r[key] = r[key] || [];
            r[key].push(a);
        }
        return r;
    }, Object.create(null));

    result['all'] = logs;

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0) + splitStr[i].substring(1);
            key = splitStr.join(" ");
        }

        return key;
    }

    const getCard = (log) => {
        switch (log.log_code) {
            case 'person_food_considerations':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} updated
                        food considerations for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                )
            case 'person_tagged':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> updated tag(s) for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/><br/>
                        <div style={{marginRight: '30px'}}
                             dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                )
            case 'missed_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a missed call
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                )
            case 'welcome_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a welcome call
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                )
            case 'sent_sms':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a sent sms
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2">outgoing sms 1 test template</span>
                    </Typography>
                )
            case 'sent_email':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} sent an email
                        to <b>{getPersonName(person)}</b>
                        <br/>
                        <span>{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                )
            case 'inbound_email_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a inbound email note
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                )
            case 'outbound_email_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a outbound email note
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                )
            case 'inbound_call_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a inbound call note
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                )
            case 'lead_sheet_note':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a lead sheet note
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                )
            case 'lead_sheet_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a lead sheet call
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                )
            case 'completed_call':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a completed call
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                )
            case 'closed:lost':
            case 'closed:won':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} changed the
                        opportunity <b>status</b> to <b>{log.new_status}</b> <br/> <span
                        className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                )
            case "person_erp_note":
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} person erp note
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                )
            case 'outbound_email':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} an outbound email
                        for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <span className="card-text mt-2 mb-2"
                              dangerouslySetInnerHTML={{__html: log.log_content}}></span>
                    </Typography>
                )
            case 'status_change':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'Have' : 'has'} changed
                        the <b>status</b> to <b>{log.pipeline_stage_title === undefined ? log.new_status : log.pipeline_stage_title}</b>
                        <br/> <span
                        className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                )
            case 'opportunity_transferred':
            case 'pipeline_stage_change':
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'Have' : 'has'} {log.log_content} <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                    </Typography>
                )
            default:
                return (
                    <Typography className={classes.typography} variant="body2" color="site.main">
                        <b>{getUsername(log)}</b> {getUsername(log) === 'You' ? 'have' : 'has'} a {getLogTitle(log)} for <b>{getPersonName(person)}</b>
                        <br/>
                        <span className="card-text mt-2 mb-2">{moment(log.created_at).format("DD/MM/YYYY HH:mm")}</span>
                        <br/>
                        <div style={{marginRight: '30px'}} dangerouslySetInnerHTML={{__html: log.log_content}}></div>
                    </Typography>
                )
        }
    }

    const getIcon = (log) => {
        switch (log.log_code) {
            case 'completed_call':
            case 'missed_call':
            case 'welcome_call':
                return <Call/>
            case 'sent_email':
            case 'outbound_email':
                return <Email/>
            case 'inbound_email_note':
            case 'person_erp_note':
                return <StickyNote2/>
            default: {
                return <PriorityHigh/>
            }
        }
    }

    const getBgColor = (log) => {
        switch (log.log_code) {
            case 'missed_call':
                return 'red'
            case 'completed_call':
                return 'green'
            default: {
                return ''
            }
        }
    }

    const getLogTitle = (log) => {
        const {log_code} = log;
        return makeHeader(log_code);
    }

    return (
        <>
            <div style={{
                display: 'flex',
                paddingRight: '30px',
                marginLeft: '50px',
                marginTop: '16px',
                alignItems: 'center'
            }}>
                <div>
                    {
                        <Button disabled={isNetworkDirector} variant="outlined" startIcon={<Add/>}
                                onClick={e => setOpenAddModal(!openAddModal)}>
                            {'Add Entry'}
                        </Button>
                    }
                </div>
                <div style={{flexGrow: 1}}>

                </div>
                <FormControl style={{width: '200px', marginRight: '10px'}}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedType}
                        label="Filter"
                        onChange={handleType}
                        style={{}}
                    >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"person"}>Person</MenuItem>
                        <MenuItem value={"opportunity"}>Opportunity</MenuItem>
                    </Select>
                </FormControl>

                <FormControl style={{width: '200px'}}>
                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFilter}
                        label="Filter"
                        onChange={handleChange}
                        style={{}}
                    >
                        <MenuItem value={"all"}>Filter History</MenuItem>
                        {
                            Object.keys(result).filter(k => k !== 'all').map((x, index) => <MenuItem key={index}
                                                                                                     value={x}>{makeHeader(x) + ' (' + result[x].length + ')'}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>
            <Timeline>
                {
                    result[selectedFilter] && result[selectedFilter].filter(x => (x.log_code !== undefined || !!x.new_stage_id)).map((log, index) => {
                        return (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent style={{flex: 0}}>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot sx={{background: getBgColor(log)}}>
                                        {getIcon(log)}
                                    </TimelineDot>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <GetCardContent log={log} getCard={getCard}/>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })
                }
            </Timeline>
            <AddCommentModal
                open={openAddModal}
                handleClose={() => setOpenAddModal(!openAddModal)}
                addEventLog={props.addEventLog}
                personId={person.person_id}
                type={selectedType}
                specialCase={!!props.type}
            />
        </>
    );
}

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function GetCardContent({log, getCard}) {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Card>
            <CardContent>
                <div style={{
                    height: (log.log_code === 'sent_email' || log.log_code === 'inbound_email_note' || log.log_code === 'outbound_email_note') ? expanded ? 'auto' : '144px' : 'auto',
                    overflow: 'hidden',
                    display: 'flex'
                }}>
                    <div style={{marginRight: '8px'}}>
                        <img style={{borderRadius: '50%'}} width={35} height={35}
                             src={`https://erp.f5.dk/uploads/scraped_source_images/${log.user_id}.jpg`}/>
                    </div>
                    {
                        getCard(log)
                    }
                </div>
            </CardContent>
            {
                (log.log_code === 'sent_email' || log.log_code === 'inbound_email_note' || log.log_code === 'outbound_email_note') &&
                <CardActions style={{height: '20px', display: 'flex', justifyContent: 'center'}}>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        style={{marginTop: '-14px'}}
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>
            }
        </Card>
    )
}

const mapStateToProps = (state) => ({
    isLoading: eventsOverviewSelectors.getPersonHistory_loading(state),
    personHistory: eventsOverviewSelectors.personHistory(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getPersonHistory: eventsOverviewActions.getPersonHistory
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(PersonHistory));