import * as React from "react";
import { Formik, Form } from "formik";
import { Button, Card, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import FieldElement from "../../Common/FieldElement";
import { selectors as contactsSelectors, actions as contactsActions } from "../../../Ducks/contacts";
import { actions as usersActions } from "../../../Ducks/users";
import { selectors as accountSelectors } from "../../../Ducks/account";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams, useNavigate } from "react-router-dom";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: "16px",
    marginTop: 0
  },
});
class NetworkDirectorForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      fieldListData: null,
      openCvr: false,
      cvrData: [],
      contactColumns: [
        "person_id",
        "erp_client_id",
        "region_id",
        "person_token",
        "person_firstname",
        "person_lastname",
        "person_never_contact",
        "person_mobile_number",
        "person_preferences_never_text",
        "person_preferences_limit_calls",
        "person_linkedin_url",
        "person_linkedin_member_id",
        "person_challenges",
        "person_food_considerations",
        "person_special_conditions",
        "person_industry",
        "person_country",
        "person_region",
        "person_language",
        "person_gender",
        "lead_source",
        "lead_target_id",
        "sf_person_id",
        "person_created_at",
        "person_last_update",
        "person_awaiting_sync",
        "person_last_sync",
        "person_flagged_from_salesview",
      ],
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps, prevState) {
    const { contactDetails } = this.props
    const { setFieldValue } = this.formRef.current;
    if (prevProps.contactDetails !== contactDetails) {
      const person = this.props.contactDetails.main[0];
      const jobs = this.props.contactDetails.main[0].jobs;
      const primary_job = jobs.filter((item) => item.job_is_primary === 1)
      setFieldValue('user_firstname', person.person_firstname);
      setFieldValue('user_lastname', person.person_lastname);
      setFieldValue('user_linkedin_member_id', person.person_linkedin_member_id);
      if (primary_job.length > 0) {
        setFieldValue('user_email', primary_job[0].job_email);
        setFieldValue('user_phone', primary_job[0].job_phone);
        setFieldValue('user_title', primary_job[0].job_title);
      }
    }
  }

  initialize = () => {
    const { countries, userRoles, teams } = this.props;
    let objCountry = {};
    let objRoles = {};
    let objTeams = {};

    let list = [];
    let rolesList = [];
    let teamList = [];

    countries && countries.main.map((item, key) => {
      return list.push({ id: item.country_id, name: item.country_name });
    });

    userRoles && userRoles.map((item, key) => {
      return rolesList.push({ id: item.role_id, name: item.role_title });
    });

    teams && teams.rows.map((item, key) => {
      return teamList.push({ id: item.team_id, name: item.team_name });
    });

    objCountry.list = list;
    objRoles.list = rolesList;
    objTeams.list = teamList;

    const fieldList = [
      {
        title: "Person ID",
        name: "person_id",
        type: "text",
        required: true,
        onBlur: this.handleBlur
      },
      {
        title: "First Name",
        name: "user_firstname",
        type: "text",
        required: true,
      },
      {
        title: "Last Name",
        name: "user_lastname",
        type: "text",
        required: true,
      },
      {
        title: "Email",
        name: "user_email",
        type: "text",
        required: true,
      },
      {
        title: "Title",
        name: "user_title",
        type: "text",
        required: true,
      },
      {
        title: "Phone",
        name: "user_phone",
        type: "text",
        required: true,
      },
      {
        title: "LinkedIn Link",
        name: "user_linkedin",
        type: "text",
      },
      {
        title: "Phone IP Address",
        name: "user_phone_ip",
        type: "text",
      },
      {
        title: 'Send Welcome Email',
        name: 'user_send_welcome_email',
        type: 'checkbox'
      },
      // {
      //   title: "PM Description",
      //   name: "user_pm_description",
      //   type: "textarea",
      //   required: true,
      // },
      {
        title: "Team",
        name: "team_id",
        type: "dropdown",
        list: objTeams.list,
      },
      {
        title: "LinkedIn ID",
        name: "user_linkedin_member_id",
        type: "text",
        required: true,
      },
      {
        title: "Country",
        name: "country_id",
        type: "dropdown",
        list: objCountry.list,
        required: true,
      }
    ];
    this.setState({ fieldListData: fieldList });
  };

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values;
    this.props.onClick({ [name]: formData[name] });
  };

  getCountryByCode = (code) => {
    if (code === "DK") {
      return "Denmark"
    }
    return code;
  }

  handleBlur = () => {
    if (this.formRef.current.values && this.formRef.current.values.person_id) {
      this.props.getContactDetails({ id: this.formRef.current.values.person_id, columns: this.state.contactColumns });
    }
  }

  navigateTo = () => {
    this.props.navigate("/admin/users")
  }

  render() {
    const { classes } = this.props;
    const { fieldListData } = this.state;

    return (
      <React.Fragment>
        {fieldListData && (
          <Card className={classes.paper}>
            <Formik
              innerRef={this.formRef}
              initialValues={{
                person_id: "",
                user_firstname: "",
                user_lastname: "",
                user_email: "",
                user_title: "",
                user_phone: "",
                user_linkedin: "",
                user_linkedin_member_id: "",
                team_id: 1,
                user_send_welcome_email: true,
                country_id: "",
                user_phone_ip: ""
              }}
              validate={(values) => {
                const errors = {};
                if (!values.person_id) {
                  errors.person_id = 'Please enter person ID';
                }
                if (!values.user_firstname) {
                  errors.user_firstname = 'Please enter firstname';
                }
                if (!values.user_lastname) {
                  errors.user_lastname = 'Please enter lastname';
                }
                if (!values.user_email) {
                  errors.user_email = 'Please enter email';
                }
                if (!values.user_title) {
                  errors.user_title = 'Please enter title';
                }
                if (!values.user_phone) {
                  errors.user_phone = 'Please enter phone';
                }
                if (!values.user_linkedin_member_id) {
                  errors.user_linkedin_member_id = 'Please enter linkedin ID';
                }
                if (!values.country_id) {
                  errors.country_id = 'Please select country';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  console.log('form values', values)
                  const data = {
                    formData: values,
                    navigateTo: this.navigateTo
                  }
                  this.props.createUser(data);
              }}
              render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form>
                  {fieldListData.map((item, index) => {
                    return (
                      <FieldElement
                        {...item}
                        key={index}
                      />
                    );
                  })}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{ float: 'right' }}
                  >
                    Create
                  </Button>
                </Form>
              )}
            />
          </Card>
        )}

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: accountSelectors.user(state),
  contactDetails: contactsSelectors.contactDetails(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContactDetails: contactsActions.getContactDetails,
      createUser: usersActions.createUser,
    },
    dispatch
  );

function WithNavigate(props) {
  let params = useParams();
  let navigate = useNavigate();
  return <NetworkDirectorForm {...props} params={params} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));