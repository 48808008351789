import React, { useEffect,useState } from 'react'
import { Autocomplete,TextField as MaterialTextField } from '@mui/material'
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as accountSelectors, actions as accountActions } from '../../../../Ducks/account';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


const UserList = (props) => {
    const {getCaseUsersList,caseUsersList,caseName,capabilityId,setUserForParent,assignee,width} = props
    const [user, setUser] = useState("");
    const [userList, setUserList] = useState([]);
    const [assignedUser, setAssignedUser] = useState("");


    useEffect(() => {
        getCaseUsersList({caseName,capabilityId});
    },[])

    useEffect(() => {
        if (caseUsersList) {
            const _users = caseUsersList.map((item) => {
                item.label = item.user_name;
                item.id = item.user_id
                item.key = item.user_id
                return item;
            });
            setUserList(_users);  
        }   
    }, [caseUsersList])

    useEffect(() => {
        if(userList)
        {
            let assigned = userList?.filter((item) => item.id == assignee);
            setAssignedUser(assigned);
        }
    },[userList,assignee])

  return (
    <Autocomplete
        disablePortal
        fullWidth
        value={assignedUser?.[0] || user}
        id="combo-box-demo"
        options={userList}
        groupBy={(option) => option.type}
        sx={{ width: width, margin: '10px 0' }}
        onChange={(_, value) => {
            setUser(value)
            setUserForParent(value)
        }}
        renderOption={(props, option, _) => {
            return (
                <div {...props}>
                    <div style={{ textAlign: 'left', padding: '0', fontSize: '13px', borderBottom: '1px solid #eaeaea' }}>
                        <span>{`${option.label}`}</span>
                    </div>
                </div>
            );
        }}
        renderInput={(params) => <MaterialTextField {...params} label="User" />}
    />
  )
}

const mapStateToProps = (state) => ({
    caseUsersList: eventsOverviewSelectors.caseUsersList(state),
    loggedin_user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getCaseUsersList: eventsOverviewActions.getCaseUsersList,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserList)