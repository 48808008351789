import { FormControl, Grid, InputLabel, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Email, Phone } from '@mui/icons-material';
import { Select } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import {
    TextField
} from 'formik-material-ui';
import { useParams } from 'react-router-dom';

const useStyles = theme => ({
    card: {
        display: 'flex',
        backgroundColor: '#F1F1F1'
        // height: 200
    },
    cardMedia: {
        flex: 1,
        width: '85px',
        backgroundColor: '#F1F1F1'
    },
    cardContent: {
        flex: 4
    },
    flexVertical: {
        display: "flex",
        alignItems: "center"
    },
    flexCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    form: {
        marginBottom: "20px"
    }
});

const Products = (props) => {
    const { classes, products, productOverview, opportunity, isLoading, switchProduct } = props;
    const [opportunityObj, setOpportunityObj] = React.useState([]);
    const [productList, setProductList] = React.useState([]);
    const [opportunityProduct, setOpportunityProduct] = React.useState(0);

    useEffect(() => {
        if (opportunity !== null) {
            let countryID = opportunity.related_objects.products[opportunity.main.product_id].country_id;
            let productShowInSales = opportunity.related_objects.products[opportunity.main.product_id].product_show_in_sales;
            let results = 500;
            console.log(countryID, productShowInSales, results)
            props.getProducts({ countryID, productShowInSales, results });
            props.getProductOverview(opportunity.main.product_id);
        }
    }, [opportunity]);

    useEffect(() => {
        if (products !== null) {
            let sorted = products.sort(function (a, b) {
                if (a.product_name < b.product_name) { return -1; }
                if (a.product_name > b.product_name) { return 1; }
                return 0;
            });
            setProductList(sorted)
        }
    }, [products])

    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )

    const handleChange = (event) => {
        console.log('handleChange................................', event)
        setOpportunityProduct(event.target.value);
        switchProduct({ opportunityId: props.params.id, productId: event.target.value });
    }

    if (!products || isLoading) {
        return showProgress()
    }

    return (
        <>
            <Formik
                initialValues={{
                    opportunityProduct: ''
                }}

                onSubmit={(values, { setSubmitting }) => {
                    console.log(values)
                }}
                render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                    <Form className={classes.form}>
                        <Field
                            type="text"
                            name="opportunityProduct"
                            label="Change the product of this opportunity"
                            select
                            variant="outlined"
                            helperText=""
                            margin="normal"
                            component={TextField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '400px', fontSize: '16px' }}
                            value={opportunityProduct}
                            onChange={handleChange}
                            disabled={opportunity && opportunity.main.self_opportunity === true ? false : true}
                        >
                            {productList && productList.map((option, index) => (
                                <MenuItem key={index} value={option.product_id}>
                                    {option.product_name}
                                </MenuItem>
                            ))}
                        </Field>
                    </Form>
                )}
            />
            {
                productList && productList.filter((item) => item.product_id === opportunity.main.product_id).
                    map((product, index) => {
                        return (
                            <Grid key={index} container spacing={2} style={{ marginBottom: "10px" }}>
                                <Grid item xs={12}>
                                    <Card className={classes.cardItem}>
                                        <CardContent style={{ paddingTop: '4', paddingBottom: '4' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {product.product_name}{" "}({product.product_price} {product.product_price_currency})
                                            </Typography>

                                            <Typography style={{ flexGrow: '1' }} gutterBottom variant="span" component="span">
                                                {productOverview === null ? "" : productOverview.main.main.product_region}, {product.product_country}
                                            </Typography>
                                            {/* <Typography style={{ padding: '0 20px 0 0' }} gutterBottom className={classes.typography} variant="span" component="span">
                                                {""}
                                            </Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        )
                    })
            }
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', paddingBottom: '4' }}>
                            <Typography gutterBottom variant="h6" component="div">
                                Network Coordinator
                            </Typography>

                            <Typography style={{ flexGrow: '1' }} gutterBottom variant="span" component="span">
                                The network director currently has no description set
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', paddingBottom: '4' }}>
                            <Typography gutterBottom variant="h6" component="div">
                                Target Group Definition
                            </Typography>

                            <Typography style={{ flexGrow: '1' }} gutterBottom variant="div" component="div">
                                {
                                    productOverview && productOverview.main.main.target_group_definition ? (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: productOverview.main.main.target_group_definition }}></div>
                                        </>
                                    ) : "There is no information regarding the target group definition of the product"
                                }

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', paddingBottom: '4' }}>
                            <Typography gutterBottom variant="h6" component="div">
                                Pains Gains
                            </Typography>

                            <Typography style={{ flexGrow: '1' }} gutterBottom variant="div" component="div">
                                {
                                    productOverview && productOverview.main.main.pains_gains ? (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: productOverview.main.main.pains_gains }}></div>
                                        </>
                                    ) : "There is no information regarding the target group definition of the product"
                                }

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', paddingBottom: '4' }}>
                            <Typography gutterBottom variant="h6" component="div">
                                Thematics
                            </Typography>

                            <Typography style={{ flexGrow: '1' }} gutterBottom variant="div" component="div">
                                {
                                    productOverview && productOverview.main.main.thematics ? (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: productOverview.main.main.thematics }}></div>
                                        </>
                                    ) : "There is no information regarding the target group definition of the product"
                                }

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', paddingBottom: '4' }}>
                            <Typography gutterBottom variant="h6" component="div">
                                Remarks
                            </Typography>

                            <Typography style={{ flexGrow: '1' }} gutterBottom variant="div" component="div">
                                {
                                    productOverview && productOverview.main.main.remarks ? (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: productOverview.main.main.remarks }}></div>
                                        </>
                                    ) : "There is no information regarding the target group definition of the product"
                                }

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', paddingBottom: '4' }}>
                            <Typography gutterBottom variant="h6" component="div">
                                Previous Events
                            </Typography>

                            {/* <Typography style={{ flexGrow: '1' }} gutterBottom variant="span" component="span">
                                {
                                    productOverview && productOverview.main.main.remarks ? (
                                        <Typography gutterBottom component="span">
                                            {productOverview.main.main.remarks}
                                        </Typography>
                                    ) : "There is no information regarding the remarks of the product"
                                }

                            </Typography> */}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.getProducts_loading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    jobs: opportunitySelectors.jobs(state),
    products: opportunitySelectors.products(state),
    productOverview: opportunitySelectors.productOverview(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getProducts: opportunityActions.getProducts,
        getProductOverview: opportunityActions.getProductOverview,
        switchProduct: opportunityActions.switchProduct,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <Products {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));