import { fork } from 'redux-saga/effects';
import account from './account';
import opportunity from './opportunity';
import sales from './sales';
import contacts from './contacts';
import companies from './companies';
import products from './products';
import dashboard from './dashboard';
import jobsList from './jobsList';
import opportunitiesList from './opportunitiesList';
import salesList from './salesList';
import plannedCalls from './plannedCalls';
import divisions from './divisions';
import admin from './admin';
import eventsOverview from './eventsOverview';
import resaleOverview from './resaleOverview';
import users from './users';

export default function* rootSaga(){
  yield fork(account);
  yield fork(opportunity);
  yield fork(sales);
  yield fork(contacts);
  yield fork(companies);
  yield fork(products);
  yield fork(dashboard);
  yield fork(jobsList);
  yield fork(opportunitiesList);
  yield fork(salesList);
  yield fork(plannedCalls);
  yield fork(divisions);
  yield fork(admin);
  yield fork(eventsOverview);
  yield fork(users);
  yield fork(resaleOverview);
}