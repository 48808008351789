import { combineReducers } from 'redux';
import account from './account';
import opportunity from './opportunity';
import sales from './sales';
import contacts from './contacts';
import companies from './companies';
import products from './products'
import dashboard from './dashboard';
import jobs from './JobsList';
import opportunitiesList from './opportunitiesList';
import salesList from './salesList';
import plannedCalls from './plannedCalls';
import divisions from './divisions';
import admin from './admin';
import eventsOverview from './eventsOverview'
import resaleOverview from './resaleOverview'
import users from './users'

const rootReducer = combineReducers({
  account,
  opportunity,
  sales,
  contacts,
  companies,
  products,
  dashboard,
  jobs,
  opportunitiesList,
  salesList,
  plannedCalls,
  divisions,
  admin,
  eventsOverview,
  users,
  resaleOverview
});

export default rootReducer;