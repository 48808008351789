import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import {Close} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import TransferMembershipStepper from "./TransferMembershipStepper";
import {getPersonInfo} from "../../../API/changePersonJob";

const TransferMembershipModal = (props) => {
    const {open, personId, transferLater, personChangeJobId, eventOverview, classes} = props;
    const [products, setProducts] = useState([]);
    const [personInfo, setPersonInfo] = useState({});
    const [product, setProduct] = useState({});
    const [purchase, setPurchase] = useState({});
    const [_loading, setLoading] = useState(false);

    useEffect(() => {
        if (personId && open) {
            setLoading(true);
            let searchQuery = '?columns=persons.person_id,persons.person_firstname,persons.person_lastname,jobs.job_id,jobs.job_title,jobs.job_email,companies.company_name,persons.person_linkedin_member_id,persons.person_linkedin_url,persons.person_preferences_limit_calls,jobs.job_phone,persons.person_mobile_number';

            if (transferLater) {
                searchQuery += '&transfer_later=true';
            }

            getPersonInfo(personId, searchQuery).then((res) => {
                if (res.person) {
                    setPersonInfo(res.person);
                }

                if (res.purchase) {
                    setPurchase(res.purchase);
                }

                if (res.product) {
                    setProduct(res.product);
                }

                if (res.products) {
                    setProducts(res.products);
                }
                setLoading(false);
            }).catch((error) => {
                toast.error(error.message);
                setLoading(false);
            });
        }
    }, [personId, open]);

    const handleCloseClick = (fetchPendingRequest = false) => {
        if (!transferLater) {
            props.handleClose(fetchPendingRequest);
        } else {
            props.handleClose();
        }
    };

    return (
        <div>
            <Dialog open={props.open} onClose={(_event, reason) => {
                if (reason !== "backdropClick") {
                    handleCloseClick();
                }
            }} scroll="paper" fullWidth maxWidth={"xl"}>
                <DialogTitle
                    sx={{backgroundColor: '#1565c0', color: 'white'}}>Transfer Memberships</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseClick}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white'
                    })}
                >
                    <Close/>
                </IconButton>
                <DialogContent style={{paddingTop: "60px"}} dividers="true">
                    <TransferMembershipStepper products={products} personId={personId} person={personInfo}
                                               product={product}
                                               purchase={purchase}
                                               handleCancelClick={handleCloseClick}
                                               transferLater={transferLater}
                                               personChangeJobId={personChangeJobId}
                                               eventOverview={eventOverview}
                                               classes={classes}/>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TransferMembershipModal;