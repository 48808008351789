import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { selectors as accountSelectors, actions as accountActions } from '../../Ducks/account';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import logo from "../../Assets/icons/f5-logo.svg"
import { AzureSignInButton } from '../../Azure/AzureSignInButton';

const useStyles = theme => ({
  '@global': {
    body: {
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px'
  },
  avatar: {
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginBottom: '5px !important'
  },
  link: {
    cursor: 'pointer'
  },
  errorMessage: {
    margin: '1rem',
    width: 'calc(100% - 28px)',
  },
});


class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      Password: '',
      errorMessage: '',
      isLoggingIn: false
    }
  }

  handleLogin = () => {
    const { UserName, Password } = this.state;
    if (UserName && Password) {
      this.setState({ 
        isLoggingIn: true,
        errorMessage: '' 
       });
      this.props.login({ username: UserName, password: Password });
    }
    else {
      this.setState({ errorMessage: 'Please fill in the fields' })
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  }

  enterKey = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin();
    }
  };


  componentDidUpdate(prevProps) {
    const { isLoggedIn, errorMessage } = this.props;
    if (isLoggedIn && !prevProps.isLoggedIn) {
      this.props.history.push('/');
    }
  }

  render() {
    const { classes, errorMessage, loading } = this.props;
    const { UserName, Password, isLoggingIn } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <img alt="" src={logo} className="logo-block"></img>
          <Typography component="h1" variant="h5">
            ERP
          </Typography>
          <TextField
            value={UserName}
            onChange={this.handleChange}
            onKeyDown={this.enterKey}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="UserName"
            autoComplete="email"
          />
          <TextField
            value={Password}
            onChange={this.handleChange}
            onKeyDown={this.enterKey}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          {
            (this.state.errorMessage || errorMessage) &&
            <Alert
              severity="error"
              className={classes.errorMessage} >{this.state.errorMessage || errorMessage}</Alert>
          }
          <LoadingButton
            fullWidth
            loading={loading}
            loadingIndicator="Logging in..."
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.handleLogin}
          >
            Log In
          </LoadingButton>

          <AzureSignInButton disabled={loading} />
          <Grid container>
            <Grid item>
              <Link className={classes.link} to="/reset-password" variant="body2">
                {"Forgot password?"}
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}


const mapStateToProps = (state) => ({
  loading: accountSelectors.loading(state),
  errorMessage: accountSelectors.errorMessage(state),
  isLoggedIn: accountSelectors.isLoggedIn(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    login: accountActions.login,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(SignIn));