import React, { useState } from 'react';
import { Alert, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const AlertElement = (props) => {
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <div>
            {
                show && <Alert severity={props.severity}
                    action={
                        <IconButton  
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}>
                                <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }>
                    {props.message}
                </Alert>
            }
        </div>
    );
}

export default AlertElement;