import { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import moment from "moment";
import EventModal from "../Modals/EventModal";
import BookMeetingModal from "../Modals/BookMeetingModal";
import CancelMeetingModal from "../Modals/CancelMeetingModal";
import { Button, Tooltip, Typography } from "@mui/material";
import AddEventModal from "../../Calendar/Widgets/CalendarWidget/AddEventModal";
import { LoadingButton } from "@mui/lab";

const useStyles = (_theme) => ({
	card: {
		backgroundColor: "#fff",
	},
	cardActions: {
		display: "flex",
		flexDirection: "row-reverse",
		marginRight: "-8px",
		marginBottom: "-8px",
	},
	box: {
		width: "19px",
		height: "19px",
		borderColor: "#cacaca",
		borderStyle: "solid",
		marginRight: "5px",
	},
	none: {
		background: "transparent",
	},
	unknown: {
		background: "yellow",
	},
	attending: {
		background: "green",
	},
	not_attending: {
		background: "red",
	},
	tentative: {
		background: "yellow",
	},
});

function NextPlannedAction(props) {
	const { classes, opportunity, person, opportunityDetails, events, plannedAction, opportunityLoading, getCalendarLoading } = props;
	const [action, setAction] = useState({});
	const [eventModalOpen, setEventModalOpen] = useState(false);
	const [plannedActionModal, setPlannedActionModal] = useState(false);
	const [cancelMeetingModal, setCancelMeetingModal] = useState(false);
	const [openEventModal, setOpenEventModal] = useState(false);
	const [eventToUpdate, setEventToUpdate] = useState({});
	const [jobEmail, setJobEmail] = useState([]);

	useEffect(() => {

		if (opportunity) {
			if (opportunity.opportunity_actions) {
				setAction(opportunity.opportunity_actions);
			} else if (plannedAction && plannedAction.opportunity_id == opportunity.opportunity_id) {
				setAction(plannedAction);
			} else {
				setAction({});
			}
		} else {
			setAction({});
		}
	}, [opportunity, plannedAction]);

	const makeHeader = (key) => {
		key = key.replaceAll("_", " ");
		let splitStr = key.toLowerCase().split(" ");

		for (let i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

			key = splitStr.join(" ");
		}

		return key;
	};

	const getStatus = () => {
		if (!!opportunity.meeting) {
			const obj = opportunity?.meeting?.participant_details?.filter((item) => item.recipient_type === "PERSON");
			return obj[0].attendance_status === "1"
				? "attending"
				: obj[0].attendance_status === "-1"
				? "not_attending"
				: obj[0].attendance_status === "2"
				? "tentative"
				: "unknown";
		} else {
			return "none";
		}
	};

	const handleAddOrUpdateEvent = () => {
		const opportunityId = opportunityDetails ?? opportunityDetails.opportunity_id;

		if (opportunityId) {
			const _eventToUpdate = events
				?.filter((e) => e.event_id?.split("__").length === 2 && e.event_id?.split("__")[1] == opportunityId)
				.sort((a, b) => new Date(b.created) - new Date(a.created))
				.at(0);

			const _jobEmail = Object.values(opportunityDetails.related_objects.jobs).filter((item) => item.job_is_primary == 1);

			setJobEmail(_jobEmail);
			setEventToUpdate(_eventToUpdate);
			setOpenEventModal(true);
		}
	};

	const handleAddEventModalClose = () => {
		setOpenEventModal(false);
		setEventToUpdate(null);
	};

	return (
		<>
			<div>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<Typography variant='h6' component='div' gutterBottom>
						Planned Action: {action && action.opportunity_action_type ? makeHeader(action.opportunity_action_type) : "None"}
					</Typography>
					<div style={{ display: "flex" }}>
						{action && action.opportunity_action_due_time && !!opportunity.meeting && (
							<Button
								color='error'
								variant='contained'
								size='small'
								style={{ textTransform: "capitalize", marginRight: "5px" }}
								onClick={() => setCancelMeetingModal(true)}
							>
								Cancel
							</Button>
						)}
						{action && !action.opportunity_action_due_time && (
							<LoadingButton
								color='primary'
								variant='contained'
								size='small'
								style={{ textTransform: "capitalize" }}
								disabled={opportunityLoading}
								onClick={handleAddOrUpdateEvent}
								loading={getCalendarLoading}
							>
								Create
							</LoadingButton>
						)}
					</div>
				</div>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					{action && action.opportunity_action_due_time ? (
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<Typography variant='body2' color='site.main' gutterBottom style={{ margin: " 0 5px 0 0" }}>
								<b>{moment(action.opportunity_action_due_time).format("DD/MM/YYYY HH:mm")}</b>
							</Typography>
							{!!opportunity.meeting && (
								<Tooltip title={getStatus()}>
									<div className={`${classes.box} ${classes[getStatus()]}`}></div>
								</Tooltip>
							)}
						</div>
					) : (
						""
					)}
				</div>
				{!action.opportunity_action_type && (
					<Typography variant='body2' color='site.main'>
						There is no planned action for this opportunity
					</Typography>
				)}
			</div>
			<EventModal
				open={eventModalOpen}
				handleClose={() => setEventModalOpen(false)}
				startDate={action.opportunity_action_due_time}
				opportunity={opportunity}
				action={action}
				person={person}
				editEvent={action.opportunity_action_due_time ? true : false}
			/>
			<BookMeetingModal
				open={plannedActionModal}
				handleClose={() => setPlannedActionModal(false)}
				startDate={action.opportunity_action_due_time}
				opportunity={opportunity}
				action={action}
				person={person}
				edit={action.opportunity_action_due_time ? true : false}
			/>
			<CancelMeetingModal
				open={cancelMeetingModal}
				handleClose={() => setCancelMeetingModal(false)}
				startDate={action.opportunity_action_due_time}
				opportunity={opportunity}
				action={action}
				person={person}
				editEvent={action.opportunity_action_due_time ? true : false}
			/>
			<AddEventModal
				open={openEventModal}
				currentEvent={eventToUpdate}
				tab='phone_meeting'
				jobEmail={jobEmail}
				handleClose={handleAddEventModalClose}
				startDate={null}
				endDate={null}
				opportunity={opportunityDetails}
			/>
		</>
	);
}

export default withStyles(useStyles)(NextPlannedAction);
