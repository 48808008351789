import * as React from 'react';
import { TextField } from "formik-material-ui";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form } from 'formik';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import {
    Button,
    LinearProgress,
    MenuItem,
    Typography,
    Container,
    Grid,
    ButtonGroup,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';

const editorConfiguration = {
    plugins: [ List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote ],
    toolbar: ['heading', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    }
};

function EditNotesModal(props) {
    const formRef = React.createRef();
    const { note } = props

    const handleClose = () => {
        props.handleClose()
    };

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} className="edit-modal" disableEnforceFocus>
                <DialogTitle style={{ paddingBottom: '0px', paddingTop: '4px' }}>Edit Note</DialogTitle>
                <DialogContent>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            note: note
                        }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            let data = {
                                event_id: props.eventID,
                                type: props.type,
                                note: values.note
                            }
                            props.updateNotes({
                                event_id: props.eventID,
                                obj: data
                            });
                            handleClose();
                        }}
                        render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                            <Form>
                                <Grid container sx={{ marginBottom: '15px', marginTop: '0px' }} spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="note"
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={ editorConfiguration }
                                                            data={field.value}
                                                            onChange={(event, editor) => {
                                                                form.setFieldValue(field.name, editor.getData());
                                                            }}
                                                        // onSaveKeystroke={this.props.onSaveKeystroke}
                                                        />
                                                    </>
                                                )
                                            }}
                                        />

                                    </Grid>
                                </Grid>

                                {isSubmitting && <LinearProgress />}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        style={{ marginRight: '12px' }}
                                    >
                                        {'Save'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        disabled={isSubmitting}
                                        onClick={handleClose}
                                    >
                                        {'Cancel'}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: eventsOverviewSelectors.updateNotes_loading(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        updateNotes: eventsOverviewActions.updateNotes,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditNotesModal);