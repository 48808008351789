import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { MenuItem, Button } from "@mui/material";
import { Select, TextField } from "formik-material-ui";
import { Field } from "formik";
import RichTextEditor from "../Common/RichTextEditor";
import SelectBox from "./SelectBox";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    margin: "16px 0",
    '&:hover button': {
      display: 'block !important'
    }
  },
  typography: {
    flex: 2,
    marginRight: '5px'
  },
  field: {
    flex: 7,
    marginRight: "12px",
  },
  actions: {
    flex: 2,
  },
  formBtn: {
    display: 'none !important'
  }
});

function InputElement(props) {
  const { classes, title } = props;

  return (
    <div className={classes.container}>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="span"
        component="span"
      >
        {title}
      </Typography>
      <div className={classes.field}>{getInputComponent(props)}</div>
      <div className={classes.actions}>
        {!props.disableButton && (
          <Button
            className={classes.formBtn}
            variant="contained"
            color="primary"
            onClick={(e) => props.onSaveClick(e, props.name)}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
}

const getInputComponent = (props) => {
  switch (props.type) {
    case "textfield":
      return (
        <Field
          component={TextField}
          fullWidth
          size="small"
          {...props}
          type={"text"}
        />
      );
    case "textarea":
      return (
        <Field
          component={TextField}
          fullWidth
          size="large"
          {...props}
          type={"textarea"}
        />
      );
    case "richText":
      return <RichTextEditor {...props} />;
    case "datetime-local":
      return (
        <Field
          {...props}
          component={TextField}
          type="time"
          sx={{ width: "100%" }}
        />
      );
    case "checkbox":
      return (
        <Field style={{ width: '30px', height: '30px' }} name={props.name} type="checkbox" />
      )
    case "dropdown":
      return (
        <Field component={TextField} select fullWidth size="small" {...props}>
          {props.list &&
            props.list.map((option) => (
              <MenuItem
                key={option.Id}
                value={option.id}
                disabled={option.disabled}
              >
                {option.name}
              </MenuItem>
            ))}
        </Field>
      );
    case "foodconsideration":
      return (
        <>
          <div className="food-consideration-section">
            <SelectBox 
              multiple={false} 
              name="person_food_considerations_1" 
              type="type1_food" 
              label="Select Type"
              labelId="select-type"
              options={props.options}
            />
            <SelectBox 
              multiple={true} 
              name="person_food_considerations_2" 
              type="type2_food" 
              label="Select Allergy"
              labelId="select-allergy"
              options={props.options}
              onChange={props.onChange}
            />
          </div>
          {
            props.formData && props.formData.person_food_considerations_2.filter((item) => item === 16).length ? (
              <Field
                component="textarea"
                fullWidth
                rows="5"
                size="large"
                name="person_food_considerations_other"
                style={{width: '100%', padding: '10px', fontSize: '16px', borderColor: "#c4c4c4", borderRadius: '5px'}}
                placeholder="Please write other allergies"
              />
            ) : (
              <></>
            )
          }

        </>
      );
    default:
      return <Field component={TextField} fullWidth size="small" {...props} />;
  }
};

export default withStyles(useStyles)(InputElement);
