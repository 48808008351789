import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import React from "react";
import {Close} from "@mui/icons-material";
import AssignTodoToKpi from "./AssignTodoToKpi";


const AssignTodoToKpiModal = (props) => {
    const {open, handleClose} = props;

    return (
        <Dialog open={open} scroll="paper" fullWidth maxWidth={'lg'}>
            <DialogTitle sx={{backgroundColor: '#1565c0', color: 'white'}}>Assign Todos To Kpis</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500, 100],
                })}
            >
                <Close/>
            </IconButton>
            <DialogContent sx={{padding: '14px', backgroundColor: '#F7F7F7'}}>
                <AssignTodoToKpi handleClose={handleClose}/>
            </DialogContent>
        </Dialog>
    )
}

export default AssignTodoToKpiModal;