import * as React from 'react';
import { withStyles } from '@mui/styles';
import { selectors as companiesSelectors, actions as companiesActions } from '../../../Ducks/companies';
import { selectors as usersSelectors, actions as usersActions } from '../../../Ducks/users';
import { selectors as adminSelectors, actions as adminActions } from '../../../Ducks/admin';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../Ducks/opportunity';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import UserDetailsForm from './UserDetailsForm';
import CircularProgress from '@mui/material/CircularProgress';
import UserCard from './UserCard';

const useStyles = theme => ({
    container: {
        textAlign: 'left',
    }
});

class UserDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userDetailsObj: null
        }
    }

    componentDidMount() {
        const { getCountries, getUserDetails, getUserRoles, getTeams, getSalesReps } = this.props;
        getCountries();
        getUserRoles();
        getTeams();
        getSalesReps();
        getUserDetails(this.props.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userDetails !== this.props.userDetails) {
            this.setState({ userDetailsObj: this.props.userDetails && this.props.userDetails })
        }
    }

    handleSaveClick = (obj) => {
        this.props.updateUser({ id: this.props.params.id, obj: obj, key: Object.keys(obj)[0] });
    }

    render() {
        const { classes, countries, userDetails, userRoles, teams, salesReps } = this.props;
        const { userDetailsObj } = this.state;

        if (!userDetailsObj || !countries) {
            return <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        }
        return (
            <>
                {(userDetails && countries) &&
                    (<div className={classes.container}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <UserCard person={userDetailsObj} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {
                                    userDetailsObj && countries && userRoles && salesReps && teams && <UserDetailsForm userDetails={userDetailsObj} countries={countries} userRoles={userRoles} teams={teams} salesReps={salesReps} onClick={this.handleSaveClick} />
                                }
                            </Grid>
                        </Grid>
                    </div>)
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: usersSelectors.isLoading(state),
    userDetails: usersSelectors.userDetails(state),
    countries: companiesSelectors.countries(state),
    userRoles: adminSelectors.userRoles(state),
    teams: usersSelectors.teams(state),
    salesReps: opportunitySelectors.salesReps(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getUserDetails: usersActions.getUserDetails,
        getTeams: usersActions.getTeams,
        updateUser: usersActions.updateUser,
        getCountries: companiesActions.getCountries,
        getUserRoles: adminActions.getUserRoles,
        getSalesReps: opportunityActions.getSalesReps,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <UserDetails {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
