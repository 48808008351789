import React, { useEffect } from "react";
import Calendar from "../../Common/CalendarWidget/Calendar";

import { selectors as opportunitySelectors, actions as oppertunitiesActions } from '../../../Ducks/opportunity';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


function SaleCalendar(props) {
  const { calendar, getCalendar } = props;

  useEffect(() => {
    renderCalendarData()
  },[]);

  const renderCalendarData = () => {
    let d = new Date();
    let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth()+1)).slice(-2);
    getCalendar(YearMonth);
  }

  return (
    <>
      {
        props.calendar !== null &&
        (
          <Calendar {...props} calendar={props.calendar} />
        )
      }
    </>
    );
}


const mapStateToProps = (state) => ({
  isLoading: opportunitySelectors.isLoading(state),
  errorMessage: opportunitySelectors.errorMessage(state),
  calendar: opportunitySelectors.calendar(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCalendar: oppertunitiesActions.getCalendar,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleCalendar);
