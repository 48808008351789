import { call, put } from 'redux-saga/effects';



export function callApi(apiCallEffect, successAction, failureAction, options = null) {
  return call(handleApiCall, apiCallEffect, successAction, failureAction, options);
}

function* handleApiCall(apiCallEffect, successAction, failureAction, options) {
  const { postApiSuccessCallEffect = null } = options || {};

  try {
    let response;
    response = yield apiCallEffect;

    yield put(successAction(response));

    if (postApiSuccessCallEffect) {
      yield call(postApiSuccessCallEffect, response);
    }
  } catch (error) {
    yield put(failureAction(error));
  }
}

