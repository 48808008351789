import * as React from "react";
import { withStyles } from "@mui/styles";
import {
    selectors as companiesSelectors,
    actions as companiesActions,
} from "../../../Ducks/companies";
import {
    selectors as adminSelectors,
    actions as adminActions,
} from "../../../Ducks/admin";
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../Ducks/opportunity';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { selectors as accountSelectors } from "../../../Ducks/account";
import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import {
    Button,
    LinearProgress,
    MenuItem,
    Grid,
    CircularProgress,
    Typography,
} from "@mui/material";
import moment from "moment";
import Swal from "sweetalert2";
import AiContentSuggestionDialog from "../Dialog/AiContentSuggestionDialog";
import RichTextEditorForTemplates from "../../Common/RichTextEditorForTemplates";

const useStyles = () => ({
    container: {
        textAlign: "left",
        maxWidth: "90%",
        margin: "0 auto"
    },
});

class Templates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplateData: {},
            editMode: false,
            openPopup: false,
            popupContent: false,
        };
        this.formRef = React.createRef();
    }

    togglePopup = () => {
        this.setState({ openPopup: true })
    }

    componentDidMount() {
        window.addEventListener('storage', (event) => {
            if (event.key === 'ai_content') {
                this.setState({ popupContent: localStorage.getItem('ai_content') })
                this.setState({ openPopup: true })
            }
        })
        this.getData();
    }

    acceptSuggestions = () => {
        let text = localStorage.getItem('ai_content');
        this.formRef.current.setFieldValue('template_body', text);
        this.setState({ openPopup: false })
    }

    getData = () => {
        const { getCountries } = this.props;
        const { getAdminTemplates } = this.props;
        const { getAdminTemplateTags, getMyTemplates } = this.props;
        getCountries();
        getAdminTemplates();
        getAdminTemplateTags();
        getMyTemplates();
    };

    editTemplate = (item, _event, action) => {
        action === 'edit' ? this.setState({ selectedTemplateData: item, editMode: true }) : this.setState({ selectedTemplateData: item, editMode: false });
        this.formRef.current.resetForm();
        this.formRef.current.setFieldValue('template_country_id', item.template_country_id);
        this.formRef.current.setFieldValue('template_type', item.template_type);
        this.formRef.current.setFieldValue('template_name', item.template_name);
        this.formRef.current.setFieldValue('template_subject', item.template_subject);
        this.formRef.current.setFieldValue('template_body', item.template_body);
    }

    deleteTemplate = () => {
        const { deleteAdminTemplate } = this.props;
        const { selectedTemplateData } = this.state;
        Swal.fire({
            title: "Confirm Delete",
            text: "Are you sure you want to delete this template?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                this.formRef.current.resetForm();
                this.setState({ editMode: false });
                deleteAdminTemplate(selectedTemplateData.template_id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    addTag = (item) => {
        // CKEDITOR.instances['editor1'].setData(item.template_tag_name);
        let content = this.formRef.current.values.template_body;
        if (content === "") {
            content = `<p>[${item.template_tag_name}]</p>`;
            this.formRef.current.setFieldValue('template_body', content);
        }
    }

    closePopup = (toggle) => {
        this.setState({ openPopup: toggle })
    }

    sendTestMail = (values) => {
        const { sendTestEmail } = this.props;
        
        let data = {
            message: values.template_body,
            subject: values.template_subject
        }
        if ((values.template_type === 'mail' && (values.template_subject === null
            || values.template_subject === ''
            || values.template_subject.trim().length === 0
            || values.template_subject === "-"
            || values.template_subject?.trim()?.length === 1))
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Subject Required',
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            sendTestEmail({ id: 0, obj: data });
        }
    }

    render() {
        const {
            classes,
            countries,
            canManageTemplates,
            user,
            myTemplates,
            getMyTemplatesLoading,
            adminTemplates,
            getAdminTemplatesLoading,
            adminTemplateTags,
            saveAdminTemplate,
            saveLoader,
            createAdminTemplate,
            createLoader,
            isLoading,
        } = this.props;
        const { selectedTemplateData, editMode, openPopup, popupContent } = this.state;

        if (!countries && !adminTemplates && !adminTemplateTags) {
            return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <>
                <div className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography
                                component="h5"
                                variant="h5"
                                style={{
                                    fontSize: "18px",
                                    color: "#9e9c9c",
                                    borderBottom: "1px solid #d0d0d0",
                                    marginBottom: "20px",
                                    paddingBottom: "10px",
                                }}
                            >
                                Templates
                            </Typography>
                            {
                                getAdminTemplatesLoading ? (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className="template-box">
                                            {adminTemplates && adminTemplates.map((item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className="template-line"
                                                            style={{ padding: "3px", margin: '5px 0' }}
                                                            key={index}
                                                        >
                                                            <span
                                                                onClick={(e) => this.editTemplate(item, e, '')}
                                                                style={{
                                                                    color: "#000",
                                                                }}
                                                            >
                                                                {item?.template_name} ({item?.template_type})
                                                            </span>
                                                            {canManageTemplates ?
                                                                <Button
                                                                    className="show-btn"
                                                                    variant="contained"
                                                                    color="success"
                                                                    size="small"
                                                                    style={{
                                                                        float: "right",
                                                                        height: "21px",
                                                                        padding: "0px",
                                                                    }}
                                                                    onClick={(e) => this.editTemplate(item, e, 'edit')}
                                                                >
                                                                    Edit
                                                                </Button> : <></>
                                                            }
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                )
                            }
                            <Typography
                                component="h5"
                                variant="h5"
                                style={{
                                    fontSize: "18px",
                                    color: "#9e9c9c",
                                    borderBottom: "1px solid #d0d0d0",
                                    marginBottom: "20px",
                                    paddingBottom: "10px",
                                    marginTop: "15px",
                                }}
                            >
                                My Templates
                            </Typography>
                            {
                                getMyTemplatesLoading ? (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className="template-box">
                                            {myTemplates && myTemplates?.map((item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className="template-line"
                                                            style={{ padding: "3px", margin: '5px 0' }}
                                                            key={index}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#000",
                                                                }}
                                                            >

                                                                {item?.template_name} {item?.template_type ? `(${item?.template_type})` : <></>}
                                                            </span>

                                                            <Button
                                                                className="show-btn"
                                                                variant="contained"
                                                                color="success"
                                                                size="small"
                                                                style={{
                                                                    float: "right",
                                                                    height: "21px",
                                                                    padding: "0px",
                                                                }}
                                                                onClick={(e) => this.editTemplate(item, e, 'edit')}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography
                                    component="h5"
                                    variant="h5"
                                    style={{
                                        fontSize: "18px",
                                        color: "#9e9c9c",
                                        borderBottom: "1px solid #d0d0d0",
                                        marginBottom: "20px",
                                        paddingBottom: "10px",
                                        flexGrow: "0.5",
                                    }}
                                >
                                    {editMode ? "Update" : "Create"} Template
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            this.formRef.current.resetForm();
                                            this.setState({ editMode: false })
                                        }
                                    }
                                    style={{ marginRight: "12px", float: "right", height: "fit-content" }}
                                >
                                    Create New Template
                                </Button>
                            </Grid>
                            <Formik
                                innerRef={this.formRef}
                                initialValues={{
                                    template_country_id: "",
                                    template_type: "",
                                    template_name: "",
                                    template_subject: "",
                                    template_body: ""
                                }}
                                validate={values => {
                                    const errors = {};
                                    if (values.template_type == 'mail' && !values.template_subject) {
                                        errors.template_subject = 'Subject Required';
                                    }
                                    if (!values.template_name) {
                                        errors.template_name = 'Template Name Required';
                                    }
                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    let date = new Date();
                                    let data = {
                                        template_country_id: values.template_country_id,
                                        template_type: values.template_type,
                                        template_name: values.template_name,
                                        template_subject: values.template_subject,
                                        template_body: values.template_body,
                                        template_id: selectedTemplateData.template_id,
                                        country: selectedTemplateData.country,
                                        created_at: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                                        deleted_at: null,
                                        updated_at: moment(date).format("YYYY-MM-DD HH:mm:ss")
                                    };
                                    if (editMode) {
                                        saveAdminTemplate(data);
                                    } else {
                                        let payload = {
                                            template_country_id: values.template_country_id,
                                            template_type: values.template_type,
                                            template_name: values.template_name,
                                            template_subject: values.template_subject,
                                            template_body: values.template_body,
                                            created_by: user?.user_details?.user_id
                                        };
                                        createAdminTemplate(payload);
                                        this.formRef.current.resetForm();
                                    }
                                }}
                                render={({
                                    submitForm,
                                    isSubmitting,
                                    values,
                                }) => (
                                    <Form>
                                        <Grid
                                            container
                                            sx={{ marginBottom: "15px", marginTop: "0px" }}
                                            spacing={1}
                                        >
                                            <Grid item xs={6}>
                                                <Field
                                                    select
                                                    name="template_country_id"
                                                    label="Select Country"
                                                    variant="outlined"
                                                    margin="normal"
                                                    component={TextField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    fullWidth
                                                    size={"small"}
                                                    style={{ marginTop: "0px" }}
                                                >
                                                    {this.props.countries &&
                                                        this.props.countries.main
                                                            .filter(
                                                                (item) =>
                                                                    !item.country_name.includes("Unknown") &&
                                                                    !item.country_name.includes("UNKNOWN") &&
                                                                    (item.country_name.includes('Denmark') || item.country_name.includes('Sweden'))
                                                            )
                                                            .map((item) => (
                                                                <MenuItem value={item.country_id}>
                                                                    {item.country_name}
                                                                </MenuItem>
                                                            ))}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    select
                                                    name="template_type"
                                                    label="Template Type"
                                                    variant="outlined"
                                                    margin="normal"
                                                    component={TextField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    fullWidth
                                                    size={"small"}
                                                    style={{ marginTop: "0px" }}
                                                >
                                                    <MenuItem value="calender">Calender</MenuItem>
                                                    <MenuItem value="mail">Mail</MenuItem>
                                                    <MenuItem value="sms">SMS</MenuItem>
                                                    <MenuItem value="linkedin_connect">
                                                        LinkedIn Connect
                                                    </MenuItem>
                                                    <MenuItem value="linkedin_message">
                                                        LinkedIn Message
                                                    </MenuItem>
                                                    <MenuItem value="invoice">Invoice</MenuItem>
                                                    <MenuItem value="order">Order</MenuItem>
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="template_name"
                                                    type="text"
                                                    label="Template Name"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    component={TextField}
                                                    size={"small"}
                                                    style={{ marginTop: "0px" }}
                                                />
                                            </Grid>
                                            {
                                                values.template_type === "mail" || values.template_type === "invoice"
                                                    || values.template_type === "order" ? (
                                                    <Grid item xs={12}>
                                                        <Field
                                                            name="template_subject"
                                                            type="text"
                                                            label="Template Subject"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            component={TextField}
                                                            size={"small"}
                                                            style={{ marginTop: "0px" }}
                                                        />
                                                    </Grid>

                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <Grid item xs={12}>
                                                <Field
                                                    name="template_body"
                                                    render={({ field, form }) => {
                                                        const handleChange = (_event, editor) => {
                                                            /* Update the form field with the editor data */
                                                            form.setFieldValue(
                                                                field.name,
                                                                editor.getData()
                                                            );
                                                        };

                                                        return (
                                                            <>
                                                                <RichTextEditorForTemplates
                                                                    data={field.value}
                                                                    onChangeProp={handleChange}
                                                                />
                                                            </>
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        {isSubmitting && <LinearProgress />}
                                        <div
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            {
                                                isLoading || saveLoader || createLoader ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                            onClick={submitForm}
                                                            style={{ marginRight: "12px" }}
                                                        >
                                                            {editMode ? "Update" : "Save"}
                                                        </Button>
                                                        {
                                                            editMode &&
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                disabled={isSubmitting}
                                                                onClick={this.deleteTemplate}
                                                                style={{ marginRight: "12px" }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        }

                                                        <Button
                                                            variant="contained"
                                                            style={{ backgroundColor: 'orange' }}
                                                            disabled={isSubmitting}
                                                            onClick={() => {
                                                                this.sendTestMail(values)
                                                            }}>
                                                            Test Mail
                                                        </Button>
                                                    </>
                                                )

                                            }
                                        </div>
                                    </Form>
                                )}
                            />
                            <AiContentSuggestionDialog
                                openPopup={openPopup}
                                setOpenPopup={this.closePopup}
                                popupContent={popupContent}
                                acceptSuggestions={this.acceptSuggestions}
                            />
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: adminSelectors.isLoading(state),
    saveLoader: adminSelectors.saveAdminTemplate_loading(state),
    createLoader: adminSelectors.createAdminTemplate_loading(state),
    getAdminTemplatesLoading: adminSelectors.getAdminTemplates_loading(state),
    user: accountSelectors.user(state),
    canManageTemplates: accountSelectors.canManageTemplates(state),
    countries: companiesSelectors.countries(state),
    adminTemplates: adminSelectors.adminTemplates(state),
    adminTemplateTags: adminSelectors.adminTemplateTags(state),
    getMyTemplatesLoading: adminSelectors.getMyTemplates_loading(state),
    myTemplates: adminSelectors.myTemplates(state),
    sendTestEmailResp: opportunitySelectors.sendTestEmail(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCountries: companiesActions.getCountries,
            getAdminTemplates: adminActions.getAdminTemplates,
            getAdminTemplateTags: adminActions.getAdminTemplateTags,
            saveAdminTemplate: adminActions.saveAdminTemplate,
            createAdminTemplate: adminActions.createAdminTemplate,
            deleteAdminTemplate: adminActions.deleteAdminTemplate,
            getMyTemplates: adminActions.getMyTemplates,
            sendTestEmail: opportunityActions.sendTestEmail,

        },
        dispatch
    );

function WithNavigate(props) {
    let params = useParams();
    return <Templates {...props} params={params} />;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(WithNavigate));
