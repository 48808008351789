import * as React from "react";
import {withStyles} from "@mui/styles";
import {actions as contactsActions, selectors as contactsSelectors} from "../../../Ducks/contacts";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import JobsList from "./Grids/JobsList";
import ContactForm from "./ContactForm";
import PlannedCalls from "./Grids/PlannedCalls";
import OpportunitiesList from "./Grids/OpportunitiesList";
import SalesList from "./Grids/SalesList";

const useStyles = (_theme) => ({
    container: {
        textAlign: "left",
    },
});

class ContactDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedContact: props.params.id,
            contactColumns: [
                "person_id",
                "erp_client_id",
                "region_id",
                "person_token",
                "person_firstname",
                "person_lastname",
                "person_never_contact",
                "person_mobile_number",
                "person_preferences_never_text",
                "person_preferences_limit_calls",
                "person_linkedin_url",
                "person_linkedin_member_id",
                "person_challenges",
                "person_food_considerations",
                "person_special_conditions",
                "person_industry",
                "person_country",
                "person_region",
                "person_language",
                "person_gender",
                "lead_source",
                "lead_target_id",
                "sf_person_id",
                "person_created_at",
                "person_last_update",
                "person_awaiting_sync",
                "person_last_sync",
                "person_flagged_from_salesview",
            ],
            contactDetails: null,
        };
    }

    componentDidMount() {
        this.getContactDetail();
        this.getFoodConsiderationOptions();
    }

    getContactDetail = () => {
        const {getContactDetails} = this.props;
        const {selectedContact, contactColumns} = this.state;
        getContactDetails({id: selectedContact, columns: contactColumns});
    };

    getFoodConsiderationOptions = () => {
        const {getFoodConsiderations} = this.props;
        getFoodConsiderations();
    };

    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.contactDetails !== this.props.contactDetails) {
            if (this.props.contactDetails !== null && this.props.contactDetails !== undefined) {
                this.setState({
                    contactDetails: this.props.contactDetails && this.props.contactDetails.main[0],
                });
            }
        }
    }

    handleSaveClick = (obj) => {
        this.props.updateContact({
            id: this.state.selectedContact,
            obj: obj,
            key: Object.keys(obj)[0],
            columns: this.state.contactColumns,
        });
    };

    render() {
        const {classes, foodConsiderations} = this.props;
        const {contactDetails} = this.state;

        if (!contactDetails || !foodConsiderations) {
            return (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <CircularProgress/>
                </div>
            );
        }
        return (
            <div className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ContactForm contactDetails={contactDetails} saveData={this.handleSaveClick}
                                     foodConsiderations={foodConsiderations}/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <JobsList/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpportunitiesList/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SalesList/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <PlannedCalls/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    contactDetails: contactsSelectors.contactDetails(state),
    foodConsiderations: contactsSelectors.foodConsiderations(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContactDetails: contactsActions.getContactDetails,
            updateContact: contactsActions.updateContact,
            getFoodConsiderations: contactsActions.getFoodConsiderations,
        },
        dispatch
    );

function WithNavigate(props) {
    let params = useParams();
    return <ContactDetails {...props} params={params}/>;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
