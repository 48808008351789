import { call } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

import {
  types as plannedCallsTypes,
  actions as plannedCallsActions,
} from "../Ducks/plannedCalls";

import { callApi } from "./utils";
import * as api from "../API/contacts";

function* getPlannedCalls(action) {
  const { success, failure } = plannedCallsActions.saga.getPlannedCalls;

  yield callApi(call(api.getPlannedCalls, action.data), success, failure);
}

export default function* rootCustomerSaga() {
  yield takeLatest(plannedCallsTypes.getPlannedCalls, getPlannedCalls);
}
