import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
  asyncs: {
    getContacts: (data) => data,
    getContactDetails: (data) => data,
    getFoodConsiderations: (data) => data,
    getPersonMiniProfile: (data) => data,
    updateContact: (data) => data,
    getTags: (data) => data,
    postBouncedEmails: (data) => data,
  }
}, 'contacts');

let initialState = asyncInitialState({
  contacts: null,
  contactDetails: null,
  updatedContact: null,
  foodConsiderations: null,
  personMiniProfile: null,
  profile_loading: false,
  errorMessage: '',
  success: false,
  tags: [],
  response: null,
  updateContactLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getContacts:
    case types.getContactDetails:
    case types.getFoodConsiderations:
    case types.getPersonMiniProfile:
      return { ...asyncOnRequest(state, action), data: { ...state.data, profile_loading: true } }
    case types.updateContact:
      return { ...asyncOnRequest(state, action), data: { ...state.data, updateContactLoading: true } }
    case types.getTags:
      return asyncOnRequest(state, action);
    case types.saga.getContacts.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          contacts: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getContactDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          contactDetails: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getFoodConsiderations.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          foodConsiderations: action.payload,
          errorMessage: ''
        };
      })
    case types.saga.updateContact.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          updatedContact: action.payload.data,
          updateContactLoading: false,
          errorMessage: ''
        };
      })
    case types.saga.getPersonMiniProfile.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          personMiniProfile: action.payload,
          profile_loading: false,
          errorMessage: ''
        };
      })
    case types.saga.getTags.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          tags: action.payload.data.tags,
          profile_loading: false,
          errorMessage: ''
        };
      })
    case types.saga.getPersonMiniProfile.failure:
      return { ...asyncOnRequest(state, action), data: { ...state.data, profile_loading: false } }
    case types.saga.updateContact.failure:      
      return { ...asyncOnRequest(state, action), data: { ...state.data, updateContactLoading: false } }
    case types.saga.getContacts.failure:
    case types.saga.getContactDetails.failure:
    case types.saga.getFoodConsiderations.failure:
      return asyncOnError(state, action)

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.contacts,
  {
    errorMessage: (data) => data.errorMessage,
  }
)

const syncSelector = {
  isLoading: (state) => state.contacts.loading,
  contacts: (state) => state.contacts.data.contacts,
  contactDetails: (state) => state.contacts.data.contactDetails,
  updatedContact: (state) => state.contacts.data.updatedContact,
  foodConsiderations: (state) => state.contacts.data.foodConsiderations,
  personMiniProfile: (state) => state.contacts.data.personMiniProfile,
  profile_loading: (state) => state.contacts.data.profile_loading,
  Tags: (state) => state.contacts.data.tags,
  updateContactLoading: (state) => state.contacts.data.updateContactLoading
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)