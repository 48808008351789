import { call } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import { types as resaleOverviewTypes, actions as resaleOverviewActions } from "../Ducks/resaleOverview";

import { callApi } from "./utils";
import * as api from "../API/resaleOverview";

function* getResaleOverview(action) {
	const { success, failure } = resaleOverviewActions.saga.getResaleOverview;

	yield callApi(call(api.getResaleOverview, action.data), success, failure);
}

export default function* rootCustomerSaga() {
	yield takeLatest(resaleOverviewTypes.getResaleOverview, getResaleOverview);
}
