import { call } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

import {
  types as opportunitiesListTypes,
  actions as opportunitiesListActions,
} from "../Ducks/opportunitiesList";

import { callApi } from "./utils";
import * as api from "../API/contacts";

function* getOpportunitiesList(action) {
  const { success, failure } =
    opportunitiesListActions.saga.getOpportunitiesList;

  yield callApi(call(api.getOpportunitiesList, action.data), success, failure);
}

export default function* rootCustomerSaga() {
  yield takeLatest(
    opportunitiesListTypes.getOpportunitiesList,
    getOpportunitiesList
  );
}
