import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import pencilEditIcon from '../../Assets/images/pencilEditIcon.PNG'
import { CancelPresentation } from '@mui/icons-material';

export default function SimpleTable(props) {
    const { rows, columnHeader, columnKeys, copyOption, beautify, editing, openModal, row, removeRow, statusKey, status } = props;
    const [copyMessage, setCopyMessage] = React.useState("");
    const [data, setData] = React.useState([]);
    const tableRef = React.useRef(null);

    React.useEffect(() => {
        if (rows) {
            if (editing) {
                const _rows = rows.map(function (el) {
                    var o = Object.assign({}, el);
                    if (statusKey && status && el[statusKey] === status) {
                        o.actions = (
                            <div className="actions"></div>
                        )
                    } else {

                        o.actions = (
                            <div className="actions" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                {
                                    editing.update && (
                                        <img onClick={() => handleRecordClick(el, 'update')} src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} />
                                    )
                                }
                                {
                                    editing.remove && (
                                        <CancelPresentation sx={{ color: 'red', fontSize: '18px' }} onClick={() => handleRecordClick(el, 'remove')} />
                                    )
                                }
                            </div>
                        );
                    }
                    return o;
                });
                setData(_rows)
            } else {
                setData(rows)
            }
        }
    }, [rows]);

    const handleRecordClick = (el, action) => {
        if (action === "update") {
            row(el);
            openModal(true)
        } else if(action === "remove") {
            removeRow(el);
        }
    }

    const copyToClipboard = (e) => {

        // This is old implementation
        // const code = tableRef.current.innerHtml;
        // navigator.clipboard.writeText(code);
        // setCopyMessage("Copied!");

        // New implementation to copy the formatting for emails
        var body = document.body, range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(tableRef.current);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(tableRef.current);
                sel.addRange(range);
            }
        } else if (body.createTextRange) {
            range = body.createTextRange();
            range.moveToElementText(tableRef.current);
            range.select();
        }
        document.execCommand("Copy");
        setCopyMessage("Copied!");
    };

    return (
        <div style={{ display: 'flex', alignItems: 'start' }}>
            <TableContainer ref={tableRef} component={Paper}>
                <table size="small" style={{ width: '100%', borderCollapse: beautify ? 'collapse' : 'separate' }}>
                    {data.length > 0 && columnHeader && <thead>
                        <tr>
                            {
                                columnHeader.map((item) => <th style={{ border: !beautify ? "1px solid #8c8c8c" : "none", borderBottom: beautify ? "0.5px solid #c2c2c2" : "1px solid #8c8c8c", padding: !beautify ? "5px 10px" : "5px", fontSize: "14px", textAlign: 'left' }}>{item}</th>)
                            }
                        </tr>
                    </thead>
                    }
                    <tbody>
                        {data.map((row) => (
                            <tr
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {
                                    columnKeys.map(key => <td style={{ border: !beautify ? "1px solid #8c8c8c" : "none", padding: beautify ? "5px" : "5px 10px", fontSize: beautify ? "13px" : "14px" }}>{row[key] ? row[key] : "-"}</td>)
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableContainer>
            {data && data.length > 0 && copyOption && (
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <div style={{ margin: '0 0 0 10px' }}>
                        <Button color="inherit" variant="contained" onClick={copyToClipboard}>Copy</Button>
                    </div>
                    <div style={{ color: "green", fontSize: '13px', textAlign: 'center', marginTop: "10px" }}>
                        {copyMessage}
                    </div>
                </div>
            )}
        </div>
    );
}
