import * as React from 'react';
import moment from 'moment';
import { TextField } from "formik-material-ui";
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateRangePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import addWeeks from 'date-fns/addWeeks';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, TextField as MaterialTextField,Grid,Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form } from 'formik';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';


const useStyles = theme => ({
    contextText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    paddingTop: '10px',
    }
});

function PermissionModal(props) {
    const { oppOverview,postPermission,classes,closePermissionModal,permissionModal,postPermissionLoading } = props
    let personId = oppOverview ? Object.keys(oppOverview.related_objects.persons) : null;
    let perimssion  = oppOverview ? Object.values(oppOverview.related_objects.persons) : null;
    let personEmail = oppOverview ? Object.values(oppOverview.related_objects.persons) : null;

    const handleClose = (e,reason) => {
        if (reason && reason == "backdropClick") 
            return;
        props.handleClose();
        closePermissionModal();
    };
    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} disableEnforceFocus disableEscapeKeyDown={permissionModal ? true : false}>
                <DialogTitle style={{ paddingBottom: '10px', paddingTop: '10px', boxShadow: '0px 0px 20px 2px #ebebeb' }}>Customer Consent</DialogTitle>
                <DialogContent style={{width: '600px'}}>
                    <DialogContentText className={classes.contextText}>
                        Email Permission
                    </DialogContentText>
                    <Formik
                        initialValues={{
                            permissions: oppOverview ? oppOverview.related_objects.permissions.permissions : [],
                            email: personEmail[0].email != null || "" ? personEmail[0].email : null,
                            picked: perimssion[0].permissions == 2 ? 'no' : oppOverview.related_objects.permissions.permissions.length > 0 ? 'yes' : 'no'
                        }}
                        onSubmit={values => {
                            const data = {
                                permissions: values.picked == 'no' ? [] : values.permissions,
                                email: values.email,
                                personId: personId ? personId[0] : null,
                                opportunityId: oppOverview ? oppOverview.main.opportunity_id : null,
                                consent: values.picked
                            }
                            postPermission(data);
                            handleClose();

                        }}
                        validate = {
                           values => {
                            console.log(values)
                            let errors = {}
                            if (!values.email && values.picked != 'no') {
                                errors.email = 'Required';
                                if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                      values.email
                                    )
                                  ) {
                                    errors.email = 'Invalid email address';
                                  }

                                  return errors;
                                }  
                           }     
                        }
                    >
                    {({ isSubmitting, getFieldProps, handleChange, handleBlur, values }) => (
                        <Form>
                            <Grid container sx={{ marginBottom: '15px', marginTop: '0px' }} spacing={1}>
                                <Grid item xs={12}>

                                    <label style={{ marginRight: '10px', display: 'inline-flex'}}>
                                        <Field 
                                            style={{ width: '20px', height: '20px', margin: '0 5px 0 0' }} 
                                            type="radio" 
                                            name="picked" 
                                            value="yes" 
                                        />
                                        Yes
                                        </label>
                                        <label style={{marginRight: '10px', display: 'inline-flex'}}>
                                        <Field 
                                            style={{ width: '20px', height: '20px', margin: '0 5px 0 0' }} 
                                            type="radio" 
                                            name="picked" 
                                            value="no" 
                                        />
                                        No
                                    </label>
                                    { values.picked == 'yes' ?
                                    <>
                                    <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                        <Field
                                            name="permissions"
                                            style={{ width: '20px', height: '20px', margin: '0 5px 0 0' }}
                                            type="checkbox"
                                            value="F5"
                                            onChange={handleChange}
                                        
                                        />
                                        Person informed that newsletter/campaign will be send from F5 ApS.
                                    </label>
                                    <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <Field
                                            name="permissions"
                                            style={{ width: '24px', height: '20px', margin: '0 5px 0 0' }}
                                            type="checkbox"  
                                            value="BI"
                                            onChange={handleChange} 
                                               
                                        />
                                        Person Informed that newsletter/campaign will be send from Business Institute ApS.
                                    </label>
                                    <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <Field
                                            name="permissions"
                                            style={{ width: '20px', height: '20px', margin: '0 5px 0 0' }}
                                            type="checkbox"
                                            value="DRAWN"
                                            onChange={handleChange}
                                        />
                                        Person Informed that the given permission can be withdrawn.
                                    </label>
                                    <Field
                                        name="email"
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        component={TextField}
                                        size={'small'}
                                        onChange={handleChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                     </> : <></>}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={(values.picked == 'yes' ? values.permissions.length > 0 ? false : true : false) || postPermissionLoading}
                                                // onClick={submitForm}
                                                type='submit'
                                                style={{ marginRight: '12px' }}
                                            >
                                                {'Save'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                // disabled={isSubmitting}
                                                onClick={permissionModal ? ()=>void(0) : handleClose}
                                            >
                                                {'Cancel'}
                                            </Button>
                                        </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    permissionModal: opportunitySelectors.permissionModalStatus(state),
    postPermissionLoading: opportunitySelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        postPermission: opportunityActions.postPermissions,
        closePermissionModal: opportunityActions.closePermissionModal,  
    },
    dispatch);



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(PermissionModal));