import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { toDateOnly } from '../../../Utils/dateUtils';
import moment from 'moment';

const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff',
  },
  cardItem: {
    marginTop: '16px'
  },
  typography: {
    marginLeft: '20px'
  },
});
function MembersList(props) {
  const { classes } = props;
  const members = (props.employees && props.employees.main && props.employees.main.members) || {};
  const persons = (props.employees && props.employees.related_objects && props.employees.related_objects.persons) || {};

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div" style={{borderBottom: "1px solid #ddd", marginBottom: '10px', paddingBottom: '10px'}} >
          Members ({Object.keys(members).length})
        </Typography>
        {
          Object.keys(members).map((key, index) => {
            const member = members[key];
            const person = persons[member.person_id];
            return <EventItem member={member} key={index} person={person} classes={classes} />
          })
        }
      </CardContent>
    </Card>
  );
}

const EventItem = ({ member, person, classes }) => {
  return (<MemberCard name={person.person_firstname + ' ' + person.person_lastname} title={person.title} date={moment(member.from).format("DD/MM/YYYY") + ' - ' + moment(member.to).format("DD/MM/YYYY")} status={member.status} classes={classes} />)
}

const MemberCard = ({ name, title, date,status, classes }) => {
  return (
    <>
      <Typography gutterBottom variant="p" component="p" style={{fontSize:'14px'}} >
        <strong> {name} </strong>  - <span>{status}</span>
      </Typography>
      <div>
        <Typography variant="body2" color="site.main">
          <div style={{color: 'inherit', display: 'flex', alignItems: 'center'}}>
            <span>
              {title}
            </span>
          </div>
        </Typography>
          <div style={{display: 'flex', alignItems: 'center', color: "#3a3641", fontSize: "12px" }}>
            <span>{date}</span>
          </div>
      </div>
    </>
  )
}

export default withStyles(useStyles)(MembersList);