import Swal from 'sweetalert2';
import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';
import {MODULE_CHANGE_MEMBER_GROUP, MODULE_FINANCE, MODULE_TRANSFER_MEMBERSHIP} from "../Components/Utils/constants";

export const {types, actions} = createActions({
    asyncs: {
        getAdminTemplates: (data) => data,
        getAdminTemplateTags: (data) => data,
        saveAdminTemplate: (data) => data,
        createAdminTemplate: (data) => data,
        deleteAdminTemplate: (data) => data,
        getUserRoles: (data) => data,
        getCapabilitiesList: (data) => data,
        getRoleCapabilities: (data) => data,
        addNewRole: (data) => data,
        saveRoleCapabilities: (data) => data,
        deleteUserRole: (data) => data,
        getGmailData: (data) => data,
        getMyTemplates: (data) => data,
        getSiteSettings: (data) => data,
        addSiteSettings: (data) => data,
        updateSiteSettings: (data) => data,
        getReleaseNotes: (data) => data,
        saveReleaseNotes: (data) => data,
        toggleBar: (data) => data,
        getBouncedEmails: (data) => data,
        postBouncedEmails: (data) => data,
        getBouncedEmailFiles: (data) => data,
    }
}, 'admin');

const manageModules = (settings, module) => {
    if (!settings) {
        return false;
    }

    return settings.filter((setting) => setting.meta_name === module && JSON.parse(setting.meta_value).module_enabled == 1).length > 0;
}

let initialState = asyncInitialState({
    adminTemplates: null,
    adminTemplateTags: null,
    savedAdminTemplate: null,
    createdAdminTemplate: null,
    userRoles: null,
    capabilitiesList: null,
    roleCapabilities: null,
    saveAdminTemplate_loading: false,
    errorMessage: '',
    success: false,
    gmailData: [],
    gmaildataOffset: [],
    myTemplates: [],
    siteSettings: [],
    releaseNotes: [],
    toggleReleaseNoteBar: false,
    bouncedEmails: [],
    postBouncedEmailResponse: null,
    bouncedEmailFiles: [],
    financeEnabled: false,
    changePersonGroupEnabled: false,
    transferMembershipEnabled: false
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.getAdminTemplates:
        case types.getAdminTemplateTags:
        case types.createAdminTemplate:
        case types.saveAdminTemplate:
        case types.deleteAdminTemplate:
        case types.getUserRoles:
        case types.getCapabilitiesList:
        case types.getRoleCapabilities:
        case types.addNewRole:
        case types.saveRoleCapabilities:
        case types.getGmailData:
        case types.getMyTemplates:
        case types.getSiteSettings:
        case types.addSiteSettings:
        case types.updateSiteSettings:
        case types.getReleaseNotes:
        case types.saveReleaseNotes:
        case types.getbouncedEmails:
        case types.postBouncedEmails:
        case types.getBouncedEmailFiles:
            return asyncOnRequest(state, action);
        case types.toggleBar:
            return {
                ...asyncOnRequest(state, action),
                data: {...state.data, toggleReleaseNoteBar: !state.data.toggleReleaseNoteBar}
            }
        case types.saga.getAdminTemplates.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    adminTemplates: action.payload.data.main,
                    errorMessage: ''
                };
            })
        case types.saga.getAdminTemplateTags.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    adminTemplateTags: action.payload.main,
                    errorMessage: ''
                };
            })
        case types.saga.saveAdminTemplate.success:
            return asyncOnSuccess(state, action, (data, action) => {
                let tempIndex = state.data.myTemplates.findIndex(obj => obj.template_id === action.payload.main.template_id);
                let adminTempIndex = state.data.adminTemplates.findIndex(obj => obj.template_id === action.payload.main.template_id);
                state.data.myTemplates[tempIndex] = action.payload.main
                state.data.adminTemplates[adminTempIndex] = action.payload.main
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Template updated!",
                    showConfirmButton: false,
                    timer: 1500
                })
                return {
                    ...data,
                    myTemplates: state.data.myTemplates,
                    adminTemplates: state.data.adminTemplates,
                    errorMessage: ''
                };
            })
        case types.saga.createAdminTemplate.success:
            return asyncOnSuccess(state, action, (data, action) => {
                state.data.myTemplates.push(action.payload.main)
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Template created!",
                    showConfirmButton: false,
                    timer: 1500
                })
                return {
                    ...data,
                    myTemplates: state.data.myTemplates,
                    errorMessage: ''
                };
            })
        case types.saga.deleteAdminTemplate.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    deletedAdminTemplate: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getUserRoles.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    userRoles: action.payload.rows,
                    errorMessage: ''
                };
            })
        case types.saga.getCapabilitiesList.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    capabilitiesList: action.payload.rows,
                    errorMessage: ''
                };
            })
        case types.saga.getRoleCapabilities.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    roleCapabilities: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.addNewRole.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    newRole: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.saveRoleCapabilities.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    savedroleCapabilities: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.deleteUserRole.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    deletedUserRole: action.payload.success,
                    errorMessage: ''
                };
            })
        case types.saga.getGmailData.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    gmailData: action.payload.data.main,
                    gmaildataOffset: action.payload.data.offset,
                    errorMessage: ''
                };
            })
        case types.saga.getMyTemplates.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    myTemplates: Array.isArray(action.payload.data.main) ? action.payload.data.main : [action.payload.data.main],
                    errorMessage: '',
                }
            })
        case types.saga.getSiteSettings.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    siteSettings: action.payload,
                    financeEnabled: manageModules(action.payload, MODULE_FINANCE),
                    changePersonGroupEnabled: manageModules(action.payload, MODULE_CHANGE_MEMBER_GROUP),
                    transferMembershipEnabled: manageModules(action.payload, MODULE_TRANSFER_MEMBERSHIP),
                    errorMessage: ''
                }
            })
        case types.saga.addSiteSettings.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    siteSettings: [...state.data.siteSettings, action.payload],
                    financeEnabled: manageModules(action.payload, MODULE_FINANCE),
                    changePersonGroupEnabled: manageModules(action.payload, MODULE_CHANGE_MEMBER_GROUP),
                    transferMembershipEnabled: manageModules(action.payload, MODULE_TRANSFER_MEMBERSHIP),
                    errorMessage: ''
                }
            })
        case types.saga.updateSiteSettings.success:
            return asyncOnSuccess(state, action, (data, action) => {
                let index = state.data.siteSettings.findIndex(obj => obj.id === action.payload.id);
                const updatedSiteSettings = [...state.data.siteSettings];
                updatedSiteSettings[index] = action.payload;
                return {
                    ...data,
                    siteSettings: updatedSiteSettings,
                    financeEnabled: manageModules(action.payload, MODULE_FINANCE),
                    changePersonGroupEnabled: manageModules(action.payload, MODULE_CHANGE_MEMBER_GROUP),
                    transferMembershipEnabled: manageModules(action.payload, MODULE_TRANSFER_MEMBERSHIP),
                    errorMessage: ''
                }
            })
        case types.saga.getReleaseNotes.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    releaseNotes: action.payload,
                    errorMessage: ''
                }
            })
        case types.saga.saveReleaseNotes.success:
            return asyncOnSuccess(state, action, (data, action) => {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Release note created!",
                    showConfirmButton: false,
                    timer: 1500
                })
                return {
                    ...data,
                    releaseNotes: [action.payload, ...state.data.releaseNotes,],
                    errorMessage: ''
                }
            })
        case types.saga.getBouncedEmails.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    bouncedEmails: action.payload.data,
                    errorMessage: ''
                }
            })
        case types.saga.postBouncedEmails.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    postBouncedEmailResponse: action.payload,
                    errorMessage: ''
                }
            })
        case types.saga.getBouncedEmailFiles.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    bouncedEmailFiles: action.payload.data,
                    errorMessage: ''
                }
            })
        case types.saga.getAdminTemplates.failure:
        case types.saga.getAdminTemplateTags.failure:
        case types.saga.saveAdminTemplate.failure:
        case types.saga.createAdminTemplate.failure:
        case types.saga.deleteAdminTemplate.failure:
        case types.saga.getUserRoles.failure:
        case types.saga.getCapabilitiesList.failure:
        case types.saga.getRoleCapabilities.failure:
        case types.saga.addNewRole.failure:
        case types.saga.saveRoleCapabilities.failure:
        case types.saga.deleteUserRole.failure:
        case types.saga.getGmailData.failure:
        case types.saga.getMyTemplates.failure:
        case types.saga.getSiteSettings.failure:
        case types.saga.getReleaseNotes.failure:
        case types.saga.saveReleaseNotes.failure:
        case types.toggleBar.failure:
        case types.getBouncedEmails.failure:
        case types.postBouncedEmails.failure:
        case types.getBouncedEmailFiles.failure:
            Swal.fire({
                position: "center",
                icon: "error",
                title: action.error.message,
            })
            return asyncOnError(state, action)
        default:
            return state
    }
}

const asyncSelector = asyncSelectors(
    (state) => state.admin,
    {
        errorMessage: (data) => data.errorMessage,
        getAdminTemplates_loading: (data) => data.getAdminTemplates_loading,
        getAdminTemplateTags_loading: (data) => data.getAdminTemplateTags_loading,
        createAdminTemplate_loading: (data) => data.createAdminTemplate_loading,
        saveAdminTemplate_loading: (data) => data.saveAdminTemplate_loading,
        deleteAdminTemplate_loading: (data) => data.deleteAdminTemplate_loading,
        getUserRoles_loading: (data) => data.getUserRoles_loading,
        getCapabilitiesList_loading: (data) => data.getCapabilitiesList_loading,
        getRoleCapabilities_loading: (data) => data.getRoleCapabilities_loading,
        addNewRole_loading: (data) => data.addNewRole_loading,
        saveRoleCapabilities_loading: (data) => data.saveRoleCapabilities_loading,
        deleteUserRole_loading: (data) => data.deleteUserRole_loading,
        getMyTemplates_loading: (data) => data.getMyTemplates_loading,
    }
)

const syncSelector = {
    isLoading: (state) => state.admin.loading,
    adminTemplates: (state) => state.admin.data.adminTemplates,
    adminTemplateTags: (state) => state.admin.data.adminTemplateTags,
    savedAdminTemplate: (state) => state.admin.data.savedAdminTemplate,
    createdAdminTemplate: (state) => state.admin.data.createdAdminTemplate,
    userRoles: (state) => state.admin.data.userRoles,
    capabilitiesList: (state) => state.admin.data.capabilitiesList,
    roleCapabilities: (state) => state.admin.data.roleCapabilities,
    gmailData: (state) => state.admin.data.gmailData,
    gmaildataOffset: (state) => state.admin.data.gmaildataOffset,
    myTemplates: (state) => state.admin.data.myTemplates,
    getMyTemplates_loading: (state) => state.admin.getMyTemplates_loading,
    siteSettings: (state) => state.admin.data.siteSettings,
    siteSettingsLoader: (state) => state.admin.getSiteSettings_loading,
    updateSiteSettingsLoader: (state) => state.admin.updateSiteSettings_loading,
    addSiteSettingsLoader: (state) => state.admin.addSiteSettings_loading,
    releaseNotes: (state) => state.admin.data.releaseNotes,
    releaseNoteLoader: (state) => state.admin.saveReleaseNotes_loading,
    barState: (state) => state.admin.data.toggleReleaseNoteBar,
    bouncedEmails: (state) => state.admin.data.bouncedEmails,
    bouncedFiles: (state) => state.admin.data.bouncedEmailFiles,
    postBouncedEmails_loading: (state) => state.admin.postBouncedEmails_loading,
    financeEnabled: (state) => state.admin.data.financeEnabled,
    transferMembershipEnabled: (state) => state.admin.data.transferMembershipEnabled
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)