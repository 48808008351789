import {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography} from "@mui/material";
import {styled, withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {bindActionCreators} from "redux";

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    }
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(4),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    },
    '.MuiTypography-h6': {
        paddingTop: '0px',
        paddingBottom: 0,
        textAlign: 'center'
    },
    'hr': {
        borderBottom: '1px solid #d7d7d7',
        width: '90%'
    },
    '.MuiGrid-container': {
        borderBottom: '1px solid #d7d7d7'
    },
    'h5': {
        fontSize: '14px',
        textTransform: 'capitalize'
    }
}));

const SwitchToInvoicesConfirmationModal = (props) => {
    const {classes, onClose, navigate} = props;
    const [timeLeft, setTimeLeft] = useState(5);

    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        navigate("/finance");
        handleClose();
    }

    useEffect(() => {
        let timer;

        if (props.open && timeLeft > 0) {

            timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else if (timeLeft == 0) {
            handleClose();
        }

        return () => clearTimeout(timer);
    }, [props.open, timeLeft])

    return (

        <BootstrapDialog onClose={handleClose} open={props.open}>
            <DialogContent>
                <Typography component="h1" variant="h6" align="center" color="textPrimary" gutterBottom>
                    Do you want to switch to Finance Tab?
                </Typography>
                <DialogContentText id="alert-dialog-description">
                    <Typography component="h1" variant="h6" align="center" color="textPrimary"
                                gutterBottom>{timeLeft}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color='inherit'
                        onClick={handleConfirm}>Confirm</Button>
                <Button className={classes.button} variant="contained" color="error"
                        onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (_) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {},
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <SwitchToInvoicesConfirmationModal {...props} params={params} navigate={navigate}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));