import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Placeholder from "ckeditor5-placeholder";
import Image from '@ckeditor/ckeditor5-image/src/image';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import SpellCheck from "./TextEditorPlugins/SpellCheck";

const editorConfigurationDefault = {
    plugins: [List, Placeholder, Essentials, Bold, Italic, Paragraph, SimpleUploadAdapter, ImageUpload, Image, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote, SpellCheck],
    toolbar: ['heading', '|', 'Placeholder', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote', 'uploadImage', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo', 'spellCheck'],
    alignment: {
        options: ['left', 'right']
    },
    placeholderProps: {
        types: ['FROM_FULLNAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'EMAIL_SUBJECT', 'FROM_EMAIL', 'TO_FULLNAME', 'TO_FIRSTNAME', 'FIRST_MEETING_DATE', 'FIRST_MEETING_START_TIME', 'FIRST_MEETING_END_TIME', 'FIRST_MEETING_DATE_AND_TIME', 'PRODUCT_NAME', 'PRODUCT_LOCATION', 'PRODUCT_ADDRESS', 'PRODUCT_ZIP_CODE', 'PRODUCT_CITY', 'NETWORK_DIRECTOR_1_FIRSTNAME', 'NETWORK_DIRECTOR_1_FULLNAME', 'NETWORK_DIRECTOR_2_FIRSTNAME', 'NETWORK_DIRECTOR_2_FULLNAME'],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: 'https://erp2.f5.dk/api/events/uploadimage',
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem('token')
        }
    },
};

export default function RichTextEditorForTemplates(props) {
    const {
        data,
        editorConfigurationByUser,
        onReadyProp,
        onChangeProp,
        onBlurProp,
    } = props;

    return (
        <>
            <CKEditor
                /* Props */
                editor={ClassicEditor}
                config={editorConfigurationByUser || editorConfigurationDefault}
                data={data}
                /* Events */
                onReady={onReadyProp}
                onChange={onChangeProp}
                onBlur={onBlurProp}
            />
        </>
    )
}