import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';


const useStyles = _ => ({
    card: {
        display: 'flex',
        backgroundColor: '#F1F1F1'
    },
    cardMedia: {
        flex: 1,
        width: '85px',
        backgroundColor: '#F1F1F1'
    },
    cardContent: {
        flex: 4
    },
    flexVertical: {
        display: "flex",
        alignItems: "center"
    },
    flexCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    specialCase: {
        '& p': {
            margin: 0
        }
    },
    cardItem: {
        marginBottom: '10px'
    }
});

const SpecialCaseTab = (props) => {
    const { classes, person, getSpecialCaseDetails, specialCaseDetails } = props;
    const [details, setDetails] = React.useState([])

    React.useEffect(() => {
        if (person) {
            getSpecialCaseDetails(person.purchase_id)
        }
    }, [person])

    React.useEffect(() => {
        if (specialCaseDetails) {
            setDetails(specialCaseDetails);
        }
    }, [specialCaseDetails])

    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )

    if (!person) {
        return showProgress()
    }

    return (
        <>
            <Grid container spacing={2} className={classes.specialCase}>
                <Grid item xs={12}>
                    { details.length > 0 ? details.reverse().map(item => 
                        <Card className={classes.cardItem}>
                            <CardContent>
                                <h3 style={{ marginTop: 0, paddingTop: 0 }}>Case {item.type === "pause" ? "Paused" : item.type === "ongoing" ? "Ongoing" : ""}</h3>
                                {item.type === "pause" ? (
                                    <ul style={{ padding: 0, margin: 0 }}>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Start Date:</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["start_date"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>End Date:</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["end_date"]}
                                            </div>
                                        </li>
                                        {
                                            item["due_date"] && (
                                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                                    <div style={{ textTransform: 'capitalize' }}>
                                                        <b>Due Date:</b>
                                                    </div>
                                                    <div style={{ marginLeft: '10px' }}>
                                                        {item["due_date"]}
                                                    </div>
                                                </li>
                                            )
                                        }
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Events Approved:</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["events_approved"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Receive summaries:</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["notifications"] ? "Yes" : "No"}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Notes</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: item["notes"] }}></div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Created at</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["created_at"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Created by</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["created_by"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Status:</b>
                                            </div>
                                            <div style={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                                                {item["status"]}
                                            </div>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul style={{ padding: 0, margin: 0 }}>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>User</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["user"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Follow up date</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["call_due_date_time"]}
                                            </div>
                                        </li>
                                        {
                                            item["due_date"] && (
                                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                                    <div style={{ textTransform: 'capitalize' }}>
                                                        <b>Due Date:</b>
                                                    </div>
                                                    <div style={{ marginLeft: '10px' }}>
                                                        {item["due_date"]}
                                                    </div>
                                                </li>
                                            )
                                        }
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Reason</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["reason"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Notes</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: item["notes"] }}></div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Created at</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["created_at"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Created by</b>
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {item["created_by"]}
                                            </div>
                                        </li>
                                        <li style={{ display: 'flex', marginBottom: '5px', fontSize: '15px' }}>
                                            <div style={{ textTransform: 'capitalize' }}>
                                                <b>Status</b>
                                            </div>
                                            <div style={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                                                {item["status"]}
                                            </div>
                                        </li>
                                    </ul>
                                )}

                            </CardContent>
                        </Card>
                    ) : <Typography>No Record</Typography>
                    }
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: eventsOverviewSelectors.getEventDetails_loading(state),
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    specialCaseDetails: eventsOverviewSelectors.specialCaseDetails(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getSpecialCaseDetails: eventsOverviewActions.getSpecialCaseDetails
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <SpecialCaseTab {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));