import React from 'react'
import { selectors as companiesSelectors, actions as companiesActions } from '../../../Ducks/companies';
import { selectors as accountSelectors } from '../../../Ducks/account';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchGet } from '../../../API/util';
import GridElement from '../../Common/GidElement';
import EditTable from '../../Common/EditTable';
import { cloneDeep, isEmpty } from 'lodash';
import { Button } from '@mui/material';

const makeHeader = (title) => {
    let _title = "";
    if (title.includes(".")) {
        _title = title.split(".")[1].split("_");
    } else {
        _title = title.split("_");
    }
    return _title.join(" ");
}

const makeAccessor = (key) => {
    if (key.includes(".")) {
        return key.split(".")[1];
    } else {
        return key
    }
}

const CompanyGrid = (props) => {
    const { user, navigate } = props;
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(false);
    const [cols, setCols] = React.useState(JSON.parse(localStorage.getItem("user")).tableSettings.companies.columns.map(key => ({ column: key, accessor: makeAccessor(key), minWidth: 150, Header: makeHeader(key), filter: 'fuzzyText' })));
    // const columns = user.tableSettings.companies.columns;
    // const _columns = columns.map(key => ({ accessor: key, minWidth: 150, Header: makeHeader(key), filter: 'fuzzyText' }))
    const fetchIdRef = React.useRef(0);

    const fetchData = React.useCallback(({ pageSize, pageIndex, sortBy, filters, columns }) => {
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.

        // Parse columns
        const _columns = columns.map((item) => item.column)

        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true)

        // Check filter data
        let query = "", api = "", sort = "", filterPrefix = "";
        filters.forEach((item, index) => {
            const _filterColumn = columns.filter((column) => column.accessor === item.id);
            if(_filterColumn[0].column.includes(".")) {
                filterPrefix = _filterColumn[0].column.split(".")[0]
            } else {
                filterPrefix = "companies"
            }
            query = query + `&contains[${filterPrefix}.${item.id}]=${item.value}`
        });

        // Check sorting
        if (sortBy.length) {
            let _sortByPrefix = "", _sortByColumn = columns.filter((column) => column.accessor === sortBy[0].id);
            if (_sortByColumn[0].column.includes(".")) {
                _sortByPrefix = _sortByColumn[0].column.split(".")[0]
                _sortByPrefix = `${_sortByPrefix}.`
            }
            sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${_sortByPrefix}${sortBy[0].id}`;
        }

        api = `/companies?results=${pageSize}&columns=${_columns}&offset=${pageIndex + 1}${query}${sort}`

        fetchGet(api).then(companies => {
            if (fetchId === fetchIdRef.current && companies) {
                setData(companies.data.main);
                setPageCount(companies.data.offset.highest_offset);
                setRowsCount(companies.data.offset.rows_count);
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error.....")
            setLoading(false);
        })
    }, []);

    const resetFilters = React.useCallback(() => setFilters([]), [setFilters]);

    const handleRowClick = (row) => {
        navigate("/company/id/" + row.original.company_id);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className="page-title">Companies</h2>
                <Button variant="outlined" color='primary' onClick={() => setIsOpen(true)}>Edit Table</Button>
            </div>

            <GridElement
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={handleRowClick}
                initialSortBy="company_id"
            />
            <EditTable
                open={isOpen}
                onClose={handleClose}
                columns={["companies"]}
                table="companies"
                setColumns={setCols}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: companiesSelectors.isLoading(state),
    errorMessage: companiesSelectors.errorMessage(state),
    companies: companiesSelectors.companies(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getCompanies: companiesActions.getCompanies,
    },
    dispatch);

function WithNavigate(props) {
    let navigate = useNavigate();
    return <CompanyGrid {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);