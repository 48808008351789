import {
  createActions,
  asyncInitialState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError,
  asyncSelectors,
} from "./utils";

export const { types, actions } = createActions(
  {
    asyncs: {
      getPlannedCalls: (data) => data,
    },
  },
  "plannedCalls"
);

let initialState = asyncInitialState({
  plannedCalls: null,
  errorMessage: "",
  success: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getPlannedCalls:
      return asyncOnRequest(state, action);
    case types.saga.getPlannedCalls.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          plannedCalls: action.payload.data,
          errorMessage: "",
        };
      });
    case types.saga.getPlannedCalls.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors((state) => state.plannedCalls, {
  errorMessage: (data) => data.errorMessage,
});

const syncSelector = {
  isLoading: (state) => state.plannedCalls.loading,
  plannedCalls: (state) => state.plannedCalls.data.plannedCalls,
};

export const selectors = Object.assign({}, asyncSelector, syncSelector);
