import { call, select } from 'redux-saga/effects';
import { takeLatest, put } from 'redux-saga/effects';

import { user_logout } from '../API/util';
import { types as accountTypes, actions as accountActions, selectors as accountSelectors } from '../Ducks/account';

import {
  types as opportunityTypes
} from '../Ducks/opportunity';

import { callApi } from './utils';
import * as api from '../API/account';


function* login(action) {
  const { success, failure } = accountActions.saga.login;

  function* postApiSuccessCallEffect() {
    console.log("login success..!")
    // Register timeout
    if (user_logout.timer) {
      window.clearTimeout(user_logout.timer);
      user_logout.timer = null;
    }
    
    user_logout.timer = setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user_login_timestamp');
      if (!localStorage.getItem('token')) {
        window.location.href = '/'
      }
    }, user_logout.time * 60 * 60 * 1000);
  }

  yield callApi(
    call(api.login, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* loginAs(action) {
  const { success, failure } = accountActions.saga.loginAs;

  function* postApiSuccessCallEffect() {
    let d = new Date();
    let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth()+1)).slice(-2);
    
    yield put({ 
      type: opportunityTypes.clearEvents
    });
    const cronofyAuthorized = yield select(accountSelectors.cronofyAuthorized);
    
    if (cronofyAuthorized)
      yield put({ 
        type: opportunityTypes.getAllEvents
      });

    yield put({
      type: opportunityTypes.getCalendar, 
      data: YearMonth
    });
    action.data.navigateTo();
  }

  yield callApi(
    call(api.loginAs, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* loginAlias(action) {
  const { success, failure } = accountActions.saga.loginAlias;

  function* postApiSuccessCallEffect() {
    let d = new Date();
    let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth()+1)).slice(-2);
    
    yield put({ 
      type: opportunityTypes.clearEvents
    });
    const cronofyAuthorized = yield select(accountSelectors.cronofyAuthorized);
    
    if (cronofyAuthorized)
      yield put({ 
        type: opportunityTypes.getAllEvents
      });

    yield put({
      type: opportunityTypes.getCalendar, 
      data: YearMonth
    });
    action.data.navigateTo();
  }

  yield callApi(
    call(api.loginAlias, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* setParentUser(action) {
  const { success, failure } = accountActions.saga.setParentUser;

  yield callApi(
    call(api.setParentUser, action.data),
    success,
    failure
  )
}

function* azureLogin(action) {
  const { success, failure } = accountActions.saga.azureLogin;

  yield callApi(
    call(api.azureLogin, action.data),
    success,
    failure
  )
}

function* forgotPassword(action) {
  const { success, failure } = accountActions.saga.forgotPassword;

  yield callApi(
    call(api.forgotPassword, action.data),
    success,
    failure
  )
}

function* logout(action) {
  const { success, failure } = accountActions.saga.logout;

  function* postApiSuccessCallEffect(res) {
    if (user_logout.timer) {
      window.clearTimeout(user_logout.timer);
      user_logout.timer = null;
    }
    console.log("logged out successfully....!", user_logout.timer);
  }

  yield callApi(
    call(api.logout, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* createNewPassword(action) 
{
  const {success,failure} = accountActions.saga.createNewPassword;
  yield callApi(call(api.createNewPassword,action.data),
  success,
  failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(accountTypes.login, login)
  yield takeLatest(accountTypes.loginAs, loginAs)
  yield takeLatest(accountTypes.loginAlias, loginAlias)
  yield takeLatest(accountTypes.azureLogin, azureLogin)
  yield takeLatest(accountTypes.forgotPassword, forgotPassword)
  yield takeLatest(accountTypes.setParentUser, setParentUser)
  yield takeLatest(accountTypes.logout, logout)
  yield takeLatest(accountTypes.createNewPassword,createNewPassword)
}