import {Card, Grid, Typography, TextField as MaterialTextField} from "@mui/material";
import {useEffect, useRef} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {TextField} from "formik-material-ui";
import {actions as eventsOverviewActions, selectors as eventsOverviewSelectors} from "../../../Ducks/eventsOverview";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {cloneDeep} from "lodash";
import {dateToString} from "../../Utils/dateUtils";
import PersonHistoryTimeLine from "./PersonHistoryTimeLine";
import {selectors as accountSelectors} from "../../../Ducks/account";

const PurchaseOverview = (props) => {
    const {data, personHistory, purchaseOverviewSubmit, newPurchaseStop, user} = props;
    const formRef = useRef(null);

    const populateForm = () => {
        formRef.current.setFieldValue('product_id', data?.new_product_id);
        formRef.current.setFieldValue('product_name', data?.new_product_name);
        formRef.current.setFieldValue('purchase_type', data?.purchase?.purchase_type);
        formRef.current.setFieldValue('purchase_start', dateToString(new Date(), 'yyyy-MM-dd'));
        formRef.current.setFieldValue('purchase_stop', dateToString(newPurchaseStop, 'yyyy-MM-dd'));
        formRef.current.setFieldValue('purchase_price', 0);
        formRef.current.setFieldValue('purchase_price_currency', data?.purchase?.purchase_price_currency);
        formRef.current.setFieldValue('purchase_bookkeeper_email', data?.purchase?.purchase_bookkeeper_email);
        formRef.current.setFieldValue('purchase_ean', data?.purchase?.purchase_ean);
        formRef.current.setFieldValue('purchase_po_number', data?.purchase?.purchase_po_number);
        formRef.current.setFieldValue('purchase_invoice_no', data?.purchase?.purchase_invoice_no);
        formRef.current.setFieldValue('purchase_invoice_date', data?.purchase?.purchase_invoice_date);
        formRef.current.setFieldValue('purchase_invoice_due_date', data?.purchase?.purchase_invoice_due_date);
        formRef.current.setFieldValue('purchase_payment_date', data?.purchase?.purchase_payment_date);
        formRef.current.setFieldValue('purchase_payment_terms', '8 Days');
        formRef.current.setFieldValue('purchase_delayed_invoice', '0 Days');
    };

    const updatePayload = (data, field, value) => {
        let _data = cloneDeep(data);
        _data[field] = value;

        purchaseOverviewSubmit(_data);
    }

    useEffect(() => {
        populateForm();

        if (data?.person_id) {
            const {getPersonHistory} = props;
            const query = "person_log";
            getPersonHistory({person_id: data.person_id, query, isNetworkDirector: false});
        }
    }, [data, newPurchaseStop]);

    useEffect(() => {
        if (formRef?.current?.values) {
            purchaseOverviewSubmit(formRef.current.values);
        }
    }, [formRef?.current?.values]);

    return (
        <div
            style={{
                paddingTop: "30px"
            }}>
            <Formik
                innerRef={formRef}
                initialValues={{
                    product_id: "",
                    product_name: "",
                    purchase_type: "",
                    purchase_start: "",
                    purchase_stop: "",
                    purchase_price: "",
                    purchase_price_currency: "",
                    purchase_bookkeeper_email: "",
                    purchase_ean: "",
                    purchase_po_number: "",
                    purchase_invoice_no: "",
                    purchase_invoice_date: null,
                    purchase_invoice_due_date: null,
                    purchase_payment_date: null,
                    purchase_payment_terms: "",
                    purchase_delayed_invoice: "",
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.product_id) {
                        errors.product_id = 'Required';
                    }

                    if (!values.purchase_start) {
                        errors.purchase_start = 'Required';
                    }

                    if (!values.purchase_stop) {
                        errors.purchase_stop = 'Required';
                    }

                    if (!values.purchase_bookkeeper_email) {
                        errors.purchase_bookkeeper_email = 'Required';
                    }

                    if (values.purchase_bookkeeper_email &&
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.purchase_bookkeeper_email)
                    ) {
                        errors.purchase_bookkeeper_email = "Invalid email address";
                    }

                    if (values.purchase_ean && values.purchase_ean.length < 13) {
                        errors.purchase_ean = "EAN should be at least 13 characters";
                    }

                    return errors;

                }}
                onSubmit={(values) => {
                    purchaseOverviewSubmit(values);
                }}
            >
                {({values, setFieldValue, errors, submitCount}) => (
                    <Form>
                        <Grid container sx={{padding: "20px 20px 20px", textAlign: "left"}}>
                            <Grid item>
                                <Typography variant='h5' component='div'>
                                    Purchase Overview
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{padding: "0px 0px 20px 20px", textAlign: "left"}} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Card style={{padding: "12px"}}>
                                            <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                        gutterBottom>
                                                Purchase Details
                                            </Typography>
                                            <Grid
                                                container
                                                sx={{
                                                    marginBottom: "15px",
                                                }}
                                                spacing={2}
                                            >
                                                <Grid item xs={6}>
                                                    <Field
                                                        name='product_name'
                                                        type='text'
                                                        label='New group*'
                                                        variant='outlined'
                                                        margin='normal'
                                                        component={TextField}
                                                        fullWidth={true}
                                                        size='small'
                                                        placeholder='New Group'
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name='purchase_type'
                                                        type='text'
                                                        label='Purchase Type*'
                                                        variant='outlined'
                                                        margin='normal'
                                                        fullWidth={true}
                                                        component={TextField}
                                                        size='small'
                                                        placeholder='Purchase Type'
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            name='purchase_start'
                                                            label='Purchase Start*'
                                                            inputFormat='dd/MM/yyyy'
                                                            className='custom-datepicker'
                                                            value={values?.purchase_start} // Set the initial value
                                                            onChange={(newValue) => {
                                                                setFieldValue('purchase_start',
                                                                    newValue
                                                                );
                                                            }}
                                                            renderInput={(startProps) => (
                                                                <MaterialTextField
                                                                    {...startProps}
                                                                    sx={{
                                                                        width: "100%",
                                                                        margin: "0",
                                                                        padding: "0px"
                                                                    }}
                                                                    padding={{
                                                                        top: "0px"
                                                                    }}
                                                                    error={submitCount > 0 && !!errors.purchase_start}
                                                                />
                                                            )}
                                                            disabled={true}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.75rem",
                                                                margin: "4px 14px 0px",
                                                            }}>
                                                            <ErrorMessage
                                                                name='purchase_start'
                                                            />
                                                        </div>
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            name='purchase_stop'
                                                            label='Purchase Stop*'
                                                            inputFormat='dd/MM/yyyy'
                                                            className='custom-datepicker'
                                                            value={values?.purchase_stop} // Set the initial value
                                                            onChange={(newValue) => {
                                                                setFieldValue('purchase_stop',
                                                                    newValue
                                                                );
                                                            }}
                                                            renderInput={(startProps) => (
                                                                <MaterialTextField
                                                                    {...startProps}
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    error={submitCount > 0 && !!errors.purchase_stop}
                                                                />
                                                            )}
                                                            disabled={true}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.75rem",
                                                                margin: "4px 14px 0px",
                                                            }}>
                                                            <ErrorMessage
                                                                name='purchase_start'
                                                            />
                                                        </div>
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name='purchase_price'
                                                        type='text'
                                                        label='Price*'
                                                        variant='outlined'
                                                        margin='normal'
                                                        fullWidth={true}
                                                        component={TextField}
                                                        size='small'
                                                        placeholder='Price'
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name='purchase_price_currency'
                                                        type='text'
                                                        label='Price Currency*'
                                                        variant='outlined'
                                                        margin='normal'
                                                        fullWidth={true}
                                                        component={TextField}
                                                        size='small'
                                                        placeholder='Price Currency'
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card style={{padding: "12px"}}>
                                            <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                                        gutterBottom>
                                                Invoice Details
                                            </Typography>
                                            <Grid
                                                container
                                                sx={{
                                                    marginBottom: "15px",
                                                }}
                                                spacing={2}
                                            >
                                                <Grid item xs={6}>
                                                    <Field
                                                        name='purchase_invoice_no'
                                                        type='text'
                                                        label='Invoice Number'
                                                        variant='outlined'
                                                        margin='normal'
                                                        fullWidth={true}
                                                        component={TextField}
                                                        size='small'
                                                        placeholder='Invoice Number'
                                                        disabled={true}
                                                        onChange={(e) => {
                                                            let {value} = e.target;
                                                            value = value.replace(/[^0-9,]/g, '');
                                                            setFieldValue("purchase_invoice_no", value);

                                                            let _values = cloneDeep(values);
                                                            _values.purchase_invoice_no = value;
                                                            purchaseOverviewSubmit(_values);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            name='purchase_invoice_date'
                                                            label='Invoice Date'
                                                            inputFormat='dd/MM/yyyy'
                                                            className='custom-datepicker'
                                                            value={values?.purchase_invoice_date} // Set the initial value
                                                            disabled={true}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    const formatDate = dateToString(newValue, 'yyyy-MM-dd');

                                                                    setFieldValue("purchase_invoice_date", formatDate);
                                                                    updatePayload(values, "purchase_invoice_date", formatDate);
                                                                } else {
                                                                    setFieldValue("purchase_invoice_date", null);
                                                                    updatePayload(values, "purchase_invoice_date", null);
                                                                }
                                                            }}
                                                            renderInput={(startProps) => (
                                                                <MaterialTextField
                                                                    {...startProps}
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginTop: "15px"
                                                                    }}
                                                                    error={submitCount > 0 && !!errors.purchase_invoice_date}
                                                                />
                                                            )}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.75rem",
                                                                margin: "4px 14px 0px",
                                                            }}></div>
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            name='purchase_invoice_due_date'
                                                            label='Invoice Due Date'
                                                            inputFormat='dd/MM/yyyy'
                                                            className='custom-datepicker'
                                                            value={values?.purchase_invoice_due_date} // Set the initial value
                                                            disabled={true}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    const formatDate = dateToString(newValue, 'yyyy-MM-dd');

                                                                    setFieldValue("purchase_invoice_due_date", formatDate);
                                                                    updatePayload(values, "purchase_invoice_due_date", formatDate);
                                                                } else {
                                                                    setFieldValue("purchase_invoice_due_date", null);
                                                                    updatePayload(values, "purchase_invoice_due_date", null);
                                                                }
                                                            }}
                                                            renderInput={(startProps) => (
                                                                <MaterialTextField
                                                                    {...startProps}
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    padding={{
                                                                        top: "1px"
                                                                    }}
                                                                    error={submitCount > 0 && !!errors.purchase_invoice_due_date}
                                                                />
                                                            )}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.75rem",
                                                                margin: "4px 14px 0px",
                                                            }}></div>
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            name='purchase_payment_date'
                                                            label='Invoice Payment Date'
                                                            inputFormat='dd/MM/yyyy'
                                                            className='custom-datepicker'
                                                            value={values?.purchase_payment_date} // Set the initial value
                                                            disabled={true}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    const formatDate = dateToString(newValue, 'yyyy-MM-dd');

                                                                    setFieldValue("purchase_payment_date", formatDate);
                                                                    updatePayload(values, "purchase_payment_date", formatDate);
                                                                } else {
                                                                    setFieldValue("purchase_payment_date", null);
                                                                    updatePayload(values, "purchase_payment_date", null);
                                                                }
                                                            }}
                                                            renderInput={(startProps) => (
                                                                <MaterialTextField
                                                                    {...startProps}
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    padding={{
                                                                        top: "1px"
                                                                    }}
                                                                    error={submitCount > 0 && !!errors.purchase_payment_date}
                                                                />
                                                            )}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.75rem",
                                                                margin: "4px 14px 0px",
                                                            }}></div>
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Card style={{padding: "12px"}}>
                                                    <Typography component='h1' variant='h6' align='left'
                                                                color='textPrimary'
                                                                gutterBottom>
                                                        Bookkeeping Details
                                                    </Typography>
                                                    <Grid container sx={{marginBottom: "15px"}}>
                                                        <Grid item xs={6} style={{paddingRight: "4px"}}>
                                                            <Field
                                                                name='purchase_bookkeeper_email'
                                                                type='text'
                                                                label='Bookkeeper Email*'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                placeholder='Bookkeeper Email'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} style={{paddingLeft: "4px"}}>
                                                            <Field
                                                                name='purchase_ean'
                                                                type='text'
                                                                label='EAN*'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                placeholder='EAN'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='purchase_po_number'
                                                                type='text'
                                                                label='PO Number'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                placeholder='PO Number'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Card style={{padding: "12px"}}>
                                                    <Typography component='h1' variant='h6' align='left'
                                                                color='textPrimary'
                                                                gutterBottom>
                                                        Payment Terms
                                                    </Typography>
                                                    <Grid container sx={{marginBottom: "15px"}}>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='purchase_payment_terms'
                                                                type='text'
                                                                label='Payment Terms*'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                placeholder='Payment Terms'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='purchase_delayed_invoice'
                                                                type='text'
                                                                label='Delayed Invoice*'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                placeholder='Delayed Invoice'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card style={{padding: "12px"}}>
                                            <Grid
                                                container
                                                sx={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                <Grid item xs={12} style={{overflowY: 'auto', height: '400px'}}>
                                                    <Typography component='h1' variant='h6' align='left'
                                                                color='textPrimary'
                                                                gutterBottom>
                                                        Person History
                                                    </Typography>
                                                    <PersonHistoryTimeLine person={data?.person_name}
                                                                           history={personHistory}
                                                                           user={user}
                                                                           />
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const mapStateToProps = (state) => ({
    personHistoryLoading: eventsOverviewSelectors.getPersonHistory_loading(state),
    personHistory: eventsOverviewSelectors.personHistory(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getPersonHistory: eventsOverviewActions.getPersonHistory
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOverview);