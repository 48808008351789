import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Chip from '@mui/material/Chip';
import StageChangeModal from '../Containers/Opportunity/Modals/StageChangeModal';
// import StepIcon from '@mui/material/StepIcon';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

export default function HorizontalLinearStepper(props) {
    const [activeStep, setActiveStep] = React.useState(20);
    const [openStageChangeModal, setOpenStageChangeModal] = React.useState(false);
    const [currentStage, setCurrentStage] = React.useState({});
    const [userStage, setUserStage] = React.useState({});
    const [selectedStageId, setSelectedStageId] = React.useState(null);
    const { stages, isLoading } = props;
    const [sortedArray, setSortedArray] = React.useState([]);
    const [selectedStage, setSelectedStage] = React.useState({});

    React.useEffect(() => {
        setSelectedStageId(props.opportunity?.main?.pipeline_stage_id);
    }, [props.opportunity])

    React.useEffect(() => {
        if (!props.stages || props.isLoading) {
            return;
        }

        const sortedArray = props.stages.main.sort(function (a, b) {
            return a.pipeline_stage_order - b.pipeline_stage_order;
        });
        setSortedArray(sortedArray);

        const selectedStage = sortedArray.find(item => item.pipeline_stage_id === selectedStageId) || {};
        setSelectedStage(selectedStage);
    }, [props.stages, props.isLoading, selectedStageId]);

    if (!stages || isLoading) {
        return <div></div>
    }

    // let sortedArray = stages.main.sort(function (a, b) {
    //     return a.pipeline_stage_order - b.pipeline_stage_order;
    // });

    // let selectedStage = sortedArray.filter(item => item.pipeline_stage_id === pipelineStageId)[0] || {};

    const handleClose = () => {
        setOpenStageChangeModal(false);
    };

    const openStageModal = (stage, selectedStage, e) => {
        const { opportunity } = props;
        if (opportunity.main.opportunity_status === "open") {
            setCurrentStage(stage);
            setUserStage(selectedStage);
            setOpenStageChangeModal(true);
        }
    }

    const handleUpdateSelectedStage = stageId => {
        setSelectedStageId(stageId);
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {sortedArray.map((stage, index) => {
                        return (
                            <Step active={true} expanded={true} key={stage.pipeline_stage_title}>
                                <StepButton
                                    style={{ cursor: 'default', zIndex: 1000 }}
                                    icon={<Chip onClick={(e) => { if (props.opportunity.main.self_opportunity) { openStageModal(stage, selectedStage, e) } }} label={stage.pipeline_stage_title} variant={'filled'} color={(stage.pipeline_stage_order <= selectedStage.pipeline_stage_order) ? 'success' : 'primary'} />}
                                />
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            <StageChangeModal
                open={openStageChangeModal}
                onClose={handleClose}
                currentStage={currentStage}
                userStage={userStage}
                updateSelectedStage={handleUpdateSelectedStage}
            />
        </>
    );
}
