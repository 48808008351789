import * as React from "react";
import { Formik, Form } from "formik";
import { Card, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import InputElement from "../../Common/InputElement";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: "16px",
    marginTop: 0,
  },
});

class ProductDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldListData: null
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.dataFormationCountries();
    this.dataFormationProductTypes();
  }

  dataFormationProductTypes = () => {

  }

  dataFormationCountries = () => {
    const { countries, productTypes } = this.props;
    let objCountry = {};
    let objProductTypes = {}
    let list = [];
    let listProdTypes = [];
    countries.main.map((item, key) => {
      return list.push({ id: item.country_id, name: item.country_name });
    });
    productTypes.main.map((item, key) => {
      return listProdTypes.push({ id: item.product_type_id, name: item.product_type_name });
    });
    objCountry.list = list;
    objProductTypes.list = listProdTypes;

    const fieldList = [
      {
        title: "Product",
        name: "product_name",
        type: "textfield",
      },
      {
        title: "Product External Name",
        name: "product_external_name",
        type: "textfield",
      },
      {
        title: "Product Type",
        name: "product_type_id",
        type: "dropdown",
        list: objProductTypes.list,
      },
      {
        title: "Product Description",
        name: "product_description",
        type: "textfield",
      },
      {
        title: "Price",
        name: "product_price",
        type: "textfield",
      },
      {
        title: "Currency",
        name: "product_price_currency",
        type: "dropdown",
        list: [
          {
            id: "DKK",
            name: "DKK",
          },
          {
            id: "EUR",
            name: "EUR",
          },
          {
            id: "SEK",
            name: "SEK",
          },
          {
            id: "HUF",
            name: "HUF",
          },
          {
            id: "CHF",
            name: "CHF",
          },
        ],
      },
      {
        title: "Country",
        name: "product_country",
        type: "dropdown",
        list: objCountry.list,
      },
      {
        title: "Region",
        name: "product_region",
        type: "textfield",
      },
      {
        title: "Parking Info",
        name: "product_parking_info",
        type: "textfield",
      },
      {
        title: "Show on website",
        name: "product_show_on_website",
        type: "checkbox",
      },
      {
        title: "Show in sales",
        name: "product_show_in_sales",
        type: "checkbox",
      },
    ];
    this.setState({ fieldListData: fieldList })
  };

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values;
    this.props.onClick({ [name]: formData[name] });
  };
  render() {
    const { classes, productDetails } = this.props;
    const { fieldListData } = this.state;
    var initialFormData = {...productDetails};
    initialFormData.product_show_in_sales = !!initialFormData.product_show_in_sales;
    initialFormData.product_show_on_website = !!initialFormData.product_show_on_website

    return (
      <React.Fragment>
        {productDetails && fieldListData && (
          <Card style={{ marginBottom: "20px" }} className={classes.paper}>
            <Typography
              style={{ marginBottom: "30px" }}
              component="h6"
              variant="h6"
              color="textPrimary"
              gutterBottom
            >
              {productDetails.product_external_name}
            </Typography>
            <Formik
              innerRef={this.formRef}
              initialValues={initialFormData}
              validate={(values) => {
                const errors = {};
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  this.props.saveNews(values);
                }, 500);
              }}
              render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form>
                  {fieldListData.map((item, index) => {
                    return (
                      <InputElement
                        {...item}
                        key={index}
                        onSaveClick={this.handleSaveClick}
                      />
                    );
                  })}
                </Form>
              )}
            />
          </Card>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ProductDetailsForm);
