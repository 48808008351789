import {fetchGet, fetchPatch} from './util';


export const getProducts = ({results, columns, offset}) => {
    return fetchGet(`/products?results=${results}&columns=${columns}&offset=${offset}`).then(res => res)
}

export const getProductDetails = ({id, columns}) => {
    return fetchGet(`/products/${id}?columns=${columns}`).then(res => res)
}

export const getProductTypes = () => {
    return fetchGet(`/product_types?columns=product_type_id,product_type_name`).then(res => res)
}

export const updateProduct = (data) => {
    const value = data.obj[data.key];
    if (value.constructor === Array) {
        data.obj[data.key] = parseInt(value[0])
    }
    return fetchPatch(`/products/${data.id}`, data.obj).then(res => res)
}

export const getEvents = (id) => {
    return fetchGet(`/events/${id}`).then(res => res);
}

export const getProductCountry = (id) => {
    return fetchGet(`/products/${id}/country`).then(res => res)
}