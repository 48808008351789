import React from 'react'
import {actions as plannedCallsActions, selectors as PlannedCallsSelectors} from "../../../../Ducks/plannedCalls";
import {selectors as accountSelectors} from '../../../../Ducks/account';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchGet} from '../../../../API/util';
import GridElement from '../../../Common/GidElement';

const makeHeader = (key) => {
    key = key.replaceAll('_', ' ');
    let splitStr = key.toLowerCase().split(" ");
    splitStr.shift();
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

        key = splitStr.join(" ");
    }

    return key;
}

const PlannedCallsList = (props) => {
    const {user} = props;
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const columns = user.tableSettings.planned_calls.columns.filter((item, index, self) => self.indexOf(item) === index);
    const _cols = [...columns]
    _cols.push("persons.person_id");
    const _columns = columns.map(key => ({accessor: key, minWidth: 150, Header: makeHeader(key), filter: 'fuzzyText'}));
    const fetchIdRef = React.useRef(0);

    const fetchData = React.useCallback(({pageSize, pageIndex, sortBy, filters}) => {
        if (fetchIdRef.current >= 1) {
            return;
        }

        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.

        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true)

        // Check filter data
        let query = "", api = "", sort = "";
        filters.forEach((item, index) => {
            query = query + `&contains[planned_calls.${item.id}]=${item.value}`
        });

        // Check sorting
        if (sortBy.length) {
            sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`;
        }

        api = `/planned_calls?results=${pageSize}&columns=${_cols}&equals[persons.person_id]=${props.params.id}&offset=${pageIndex + 1}${query}${sort}`

        fetchGet(api).then(contacts => {
            if (fetchId === fetchIdRef.current && contacts) {
                setData(contacts.data.main);
                setPageCount(contacts.data.offset.highest_offset);
                setRowsCount(contacts.data.offset.rows_count);
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error.....")
            setLoading(false);
        })
    }, []);

    const resetFilters = React.useCallback(() => setFilters([]), [setFilters]);

    const handleRowClick = (_row) => {

    }

    return (
        <div style={{marginTop: '20px'}}>
            <h2 className="page-title">
                Planned Calls
            </h2>

            <GridElement
                columns={React.useMemo(() => _columns, [])}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={handleRowClick}
                initialSortBy={user.tableSettings.planned_calls.filters.sort}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: PlannedCallsSelectors.isLoading(state),
    errorMessage: PlannedCallsSelectors.errorMessage(state),
    plannedCalls: PlannedCallsSelectors.plannedCalls(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getPlannedCalls: plannedCallsActions.getPlannedCalls,
        },
        dispatch
    );

function WithNavigate(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <PlannedCallsList {...props} params={params} navigate={navigate}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);
