import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Delete, Edit, FileDownload, Note, NoteAdd, StickyNote2 } from '@mui/icons-material';
import EditNotesModal from '../Modals/EditNotesModal';
import pencilEditIcon from '../../../../Assets/images/pencilEditIcon.PNG'
import EditEmailModal from '../Modals/EditEmailModal';
import Swal from 'sweetalert2';

const useStyles = theme => ({
    card: {
        display: 'flex',
        backgroundColor: '#F1F1F1'
    },
    cardMedia: {
        flex: 1,
        width: '85px',
        backgroundColor: '#F1F1F1'
    },
    cardContent: {
        flex: 4
    },
    flexVertical: {
        display: "flex",
        alignItems: "center"
    },
    flexCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
});

const EventsInfoTab = (props) => {
    const { classes, eventInfo, getEventDetails, eventDetails, isLoading, isNetworkDirector } = props;
    const [directors, setDirectors] = React.useState([]);
    const [coordinator, setCoordinator] = React.useState({});
    const [managerNotesModal, setManagerNotesModal] = React.useState(false);
    const [memberServiceNotesModal, setMemberServiceNotesModal] = React.useState(false);
    const [emailInvitationModal, setEmailInvitationModal] = React.useState(false);
    const [calendarInvitationModal, setCalendarInvitationModal] = React.useState(false);
    const [summaryModal, setSummaryModal] = React.useState(false);

    useEffect(() => {
        if (eventInfo !== null && eventInfo !== undefined) {
            getEventDetails(eventInfo.event_id)
        }
    }, [eventInfo])

    useEffect(() => {
        if (eventDetails !== null && eventDetails !== undefined) {
            setDirectors(eventDetails.network_directors)
            setCoordinator(eventDetails.network_coordinator)
        }
    }, [eventDetails]);

    const formatTitle = (title) => {
        return title.split("_").filter((item) => item !== "event").join(" ");
    }

    const salesReps = (sales_reps) => {
        const reps = sales_reps && sales_reps.map((item) => item.user_name);
        if (reps.length)
            return reps.join(",");
        return ''
    }

    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )

    const handleClose = () => {
        setManagerNotesModal(false);
        setMemberServiceNotesModal(false);
    };

    const deleteFile = (item, type, event) => {
        const { deleteAttachedFile } = props;
        const data = {
            event_id: eventInfo.event_id,
            upload_id: item.upload_id,
            type
        }

        Swal.fire({
            title: "Confirm Delete",
            text: `Are you sure you want to delete ${item.upload_name}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                deleteAttachedFile(data);
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    const isPastEvent = (event_stop_date) => {
        const stop_date = new Date(event_stop_date);
        const today_date = new Date();
        return stop_date.getTime() < today_date.getTime()
    }

    if (!eventDetails) {
        return showProgress()
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4' }}>
                            <ul style={{ padding: 0, margin: 0 }}>
                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                    <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                        <b>Name</b>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {eventDetails["event_name"]}
                                    </div>
                                </li>
                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                    <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                        <b>Start Time</b>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {moment(eventDetails["event_start"]).format("HH:mm")}
                                    </div>
                                </li>
                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                    <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                        <b>End Time</b>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {moment(eventDetails["event_stop"]).format("HH:mm")}
                                    </div>
                                </li>
                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                    <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                        <b>Date</b>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {moment(eventDetails["event_start"]).format("DD/MM/YYYY")}
                                    </div>
                                </li>
                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                    <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                        <b>Address</b>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {eventDetails["event_address"]}
                                    </div>
                                </li>
                                {
                                    eventDetails["network_directors"] && eventDetails["network_directors"].length > 0 && eventDetails["network_directors"].map((director, index) => {
                                        return (
                                            <li key={index} style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                                <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                                    <b>Network Director {index + 1}</b>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    {director.person_string}
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                    <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                        <b>Network Coordinator</b>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {eventDetails["network_coordinator"] && eventDetails["network_coordinator"].user_name}
                                    </div>
                                </li>
                                <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                    <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                        <b>Sales Rep</b>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {salesReps(eventDetails["sales_reps"])}
                                    </div>
                                </li>
                            </ul>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={6}>
                <Card className={classes.cardItem}>
                    <CardContent style={{ paddingTop: '4' }}>
                        <ul style={{ padding: 0, margin: 0 }}>
                            <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                    <b>Location</b>
                                </div>
                                <div style={{ flex: 1 }}>
                                    {eventDetails["event_location"]}
                                </div>
                            </li>
                            
                            <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                    <b>Zip Code</b>
                                </div>
                                <div style={{ flex: 1 }}>
                                    {eventDetails["event_zip_code"]}
                                </div>
                            </li>
                            <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                    <b>City</b>
                                </div>
                                <div style={{ flex: 1 }}>
                                    {eventDetails["event_city"]}
                                </div>
                            </li>
                            <li style={{ display: 'flex', marginBottom: '5px', fontSize: '13px' }}>
                                <div style={{ textTransform: 'capitalize', flex: 1 }}>
                                    <b>Country</b>
                                </div>
                                <div style={{ flex: 1 }}>
                                    {eventDetails["event_country"]}
                                </div>
                            </li>
                            
                        </ul>
                    </CardContent>
                </Card>
            </Grid> */}
                <Grid item xs={6}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', minHeight: '200px' }}>
                            <Typography component="h5" variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', borderBottom: '1px solid #cacaca' }}><span>Product Manager Notes</span><span>{!isNetworkDirector && <img onClick={() => setManagerNotesModal(true)} src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} />}</span></Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_notes_product_manager"] }}></div>

                            <Typography component="h5" variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', borderBottom: '1px solid #cacaca' }}><span>Network Director Notes</span><span><img onClick={() => setMemberServiceNotesModal(true)} src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} /></span></Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_notes_member_service"] }}></div>
                        </CardContent>
                    </Card>
                </Grid>
                {
                    eventDetails && eventDetails["is_calendar_invitation"] && (
                        <Grid item xs={12}>
                            <Card className={classes.cardItem}>
                                <CardContent className="invitation" style={{ paddingTop: '4' }}>
                                    <Typography component="h5" variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '22px', borderBottom: '1px solid #cacaca' }}><span>Calendar invitation</span><span>{isNetworkDirector && !isPastEvent(eventDetails["event_stop"]) && !eventDetails["event_calendar_ready"] && eventDetails["nd_editing_status_calendar_email"] !== "Pending" && <img onClick={() => setCalendarInvitationModal(true)} src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} />}</span></Typography>

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Email Subject</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_calendar_subject"] }}></div>

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Calendar Content</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_calendar_introduction"] }}></div>

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Status</Typography>
                                    <div>{eventDetails["event_calendar_sent"] && eventDetails["event_calendar_sent"]}</div>

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Send Time</Typography>
                                    <div>{eventDetails["event_calendar_sendtime"] && moment(eventDetails["event_calendar_sendtime"]).format("DD/MM/YYYY - HH:mm")}</div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }
                {
                    eventDetails && eventDetails["is_email_invitation"] && (
                        <Grid item xs={12}>
                            <Card className={classes.cardItem}>
                                <CardContent className="invitation" style={{ paddingTop: '4' }}>
                                    <Typography component="h5" variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '22px', borderBottom: '1px solid #cacaca' }}><span>Invitation</span><span>{isNetworkDirector && !isPastEvent(eventDetails["event_stop"]) && !eventDetails["event_invitation_ready"] && eventDetails["nd_editing_status_invitation_email"] !== "Pending" && <img onClick={() => setEmailInvitationModal(true)} src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} />}</span></Typography>
                                    {/* <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_email_compiled"] }}></div> */}

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Email Subject</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_subject"] }}></div>

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Introduction</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_introduction"] }}></div>

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Send Time</Typography>
                                    <div>{moment(eventDetails["event_invitation_sendtime"]).format("DD/MM/YYYY - HH:mm")}</div>

                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Status</Typography>
                                    <div>{eventDetails["event_invitation_sent"] && eventDetails["event_invitation_sent"]}</div>

                                    {/* <Typography component="h6" variant="h6" sx={{ fontSize: '16px' }}>Image 1</Typography>
                        
                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px' }}>Introduction</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_introduction"] }}></div>
                                    
                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px' }}>Introduction</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_introduction"] }}></div>
                                    
                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px' }}>Introduction</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_introduction"] }}></div>
                                    
                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px' }}>Introduction</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_introduction"] }}></div>
                                    
                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px' }}>Introduction</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_introduction"] }}></div>
                                    
                                    <Typography component="h6" variant="h6" sx={{ fontSize: '16px' }}>Introduction</Typography>
                                    <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_invitation_introduction"] }}></div> */}
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent className="invitation" style={{ paddingTop: '4' }}>
                            <Typography component="h5" variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '22px', borderBottom: '1px solid #cacaca' }}><span>Summary</span><span>{isNetworkDirector && !eventDetails["event_summary_ready"] && eventDetails["nd_editing_status_summary_email"] !== "Pending" && <img onClick={() => setSummaryModal(true)} src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} />}</span></Typography>


                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Email Subject</Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_summary_subject"] }}></div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Introduction</Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_summary_introduction"] }}></div>

                            {/* <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Image</Typography>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Image Description</Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_summary_image_title"] }}></div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Image Link</Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_summary_image_link"] }}></div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Block 1 Title</Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_summary_block_1_title"] }}></div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Block 1</Typography>
                            <div style={{ marginRight: '30px' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_summary_block_1"] }}></div> */}

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Send Time</Typography>
                            <div>{moment(eventDetails["event_summary_sendtime"]).format("DD/MM/YYYY - HH:mm")}</div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Status</Typography>
                            <div>{eventDetails["event_summary_sent"] && eventDetails["event_summary_sent"]}</div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4', minHeight: '200px' }}>
                            <Typography component="h5" variant="h5" sx={{ fontSize: '20px', borderBottom: '1px solid #cacaca', marginBottom: '20px' }}>Reminder Email</Typography>

                            <Typography component="h5" variant="h5" sx={{ fontSize: '16px', fontWeight: 600 }}>Email Subject</Typography>
                            <div style={{ margin: '10px 30px 10px 0' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_reminder_email_new_unknown_subject"] }}></div>

                            <Typography component="h5" variant="h5" sx={{ fontSize: '16px', fontWeight: 600 }}>Email Body</Typography>
                            <div style={{ margin: '10px 30px 10px 0' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_reminder_email_new_unknown_body"] }}></div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>Send Time</Typography>
                            <div>{moment(eventDetails["event_reminder_email_sendtime"]).format("DD/MM/YYYY - HH:mm")}</div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Status</Typography>
                            <div>{eventDetails["event_reminder_email_sent"] && eventDetails["event_reminder_email_sent"]}</div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent style={{ paddingTop: '4' }}>
                            <Typography component="h5" variant="h5" sx={{ fontSize: '20px', borderBottom: '1px solid #cacaca', marginBottom: '20px' }}>Reminder SMS</Typography>
                            <div style={{ margin: '0 30px 10px 0' }} dangerouslySetInnerHTML={{ __html: eventDetails["event_reminder_sms_new_unknown_body"] }}></div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>Send Time</Typography>
                            <div>{moment(eventDetails["event_reminder_sms_sendtime"]).format("DD/MM/YYYY - HH:mm")}</div>

                            <Typography component="h6" variant="h6" sx={{ fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>Status</Typography>
                            <div>{eventDetails["event_reminder_sms_sent"] && eventDetails["event_reminder_sms_sent"]}</div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.cardItem}>
                        <CardContent className='uploads' style={{ paddingTop: '4' }}>
                            <Typography component="h5" variant="h5" sx={{ fontSize: '20px', borderBottom: '1px solid #cacaca', marginBottom: '20px' }}>File Uploads</Typography>
                            <div className="react-table-container">
                                <div className='react-table-wrapper'>
                                    {
                                        eventDetails["invitation_uploads"].length || eventDetails["summary_uploads"].length || eventDetails["calendar_uploads"].length ? (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Download</th>
                                                        <th>Invitation</th>
                                                        <th>Summary</th>
                                                        <th>Calendar</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        eventDetails["invitation_uploads"].map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><a href={`https://stag1.f5.dk/uploads/${item.upload_token}.${item.upload_extension}`} target="_blank" download={item.upload_name}><span style={{ display: 'flex', alignItems: 'center' }}><FileDownload sx={{ fontSize: '18px' }} /> {item.upload_name}</span></a></td>
                                                                    <td><input type="checkbox" disabled checked /></td>
                                                                    <td><input type="checkbox" disabled /></td>
                                                                    <td><input type="checkbox" disabled /></td>
                                                                    <td><Delete onClick={(e) => deleteFile(item, "invitation", e)} sx={{ color: '#df4d4d', fontSize: '18px', cursor: 'pointer' }} /></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        eventDetails["summary_uploads"].map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><a href={`https://stag1.f5.dk/uploads/${item.upload_token}.${item.upload_extension}`} target="_blank" download={item.upload_name}><span style={{ display: 'flex', alignItems: 'center' }}><FileDownload sx={{ fontSize: '18px' }} /> {item.upload_name}</span></a></td>
                                                                    <td><input type="checkbox" disabled /></td>
                                                                    <td><input type="checkbox" disabled checked /></td>
                                                                    <td><input type="checkbox" disabled /></td>
                                                                    <td><Delete onClick={(e) => deleteFile(item, "summary", e)} sx={{ color: '#df4d4d', fontSize: '18px', cursor: 'pointer' }} /></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        eventDetails["calendar_uploads"].map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><a href={`https://stag1.f5.dk/uploads/${item.upload_token}.${item.upload_extension}`} target="_blank" download={item.upload_name}><span style={{ display: 'flex', alignItems: 'center' }}><FileDownload sx={{ fontSize: '18px' }} /> {item.upload_name}</span></a></td>
                                                                    <td><input type="checkbox" disabled /></td>
                                                                    <td><input type="checkbox" disabled /></td>
                                                                    <td><input type="checkbox" disabled checked /></td>
                                                                    <td><Delete onClick={(e) => deleteFile(item, "calendar", e)} sx={{ color: '#df4d4d', fontSize: '18px', cursor: 'pointer' }} /></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <p style={{ margin: 0 }}>No uploads found!</p>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <EditNotesModal
                open={managerNotesModal}
                handleClose={handleClose}
                note={eventDetails["event_notes_product_manager"]}
                eventID={eventInfo.event_id}
                type={1}
            />

            <EditNotesModal
                open={memberServiceNotesModal}
                handleClose={handleClose}
                note={eventDetails["event_notes_member_service"]}
                eventID={eventInfo.event_id}
                type={2}
            />

            <EditEmailModal
                open={emailInvitationModal}
                handleClose={() => setEmailInvitationModal(false)}
                name="Invitation Email"
                fields={
                    {
                        subject: "event_invitation_subject",
                        introduction: "event_invitation_introduction",
                        status: "nd_editing_status_invitation_email"
                    }
                }
                content={
                    {
                        event_invitation_subject: eventDetails["event_invitation_subject"],
                        event_invitation_introduction: eventDetails["event_invitation_introduction"],
                        event_file_type: "invitation"
                    }
                }
                eventID={eventInfo.event_id}
                uploads={eventDetails["invitation_uploads"]}
                type="invitation"
            />

            <EditEmailModal
                open={calendarInvitationModal}
                handleClose={() => setCalendarInvitationModal(false)}
                name="Calendar Invitation"
                fields={
                    {
                        subject: "event_calendar_subject",
                        introduction: "event_calendar_introduction",
                        status: "nd_editing_status_calendar_email"
                    }
                }
                content={
                    {
                        event_calendar_subject: eventDetails["event_calendar_subject"],
                        event_calendar_introduction: eventDetails["event_calendar_introduction"],
                        event_file_type: "calendar"
                    }
                }
                eventID={eventInfo.event_id}
                uploads={eventDetails["calendar_uploads"]}
                type="calendar"
            />

            <EditEmailModal
                open={summaryModal}
                handleClose={() => setSummaryModal(false)}
                name="Summary"
                fields={
                    {
                        subject: "event_summary_subject",
                        introduction: "event_summary_introduction",
                        status: "nd_editing_status_summary_email"
                    }
                }
                content={
                    {
                        event_summary_subject: eventDetails["event_summary_subject"],
                        event_summary_introduction: eventDetails["event_summary_introduction"],
                        event_file_type: "summary"
                    }
                }
                eventID={eventInfo.event_id}
                uploads={eventDetails["summary_uploads"]}
                type="summary"
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: eventsOverviewSelectors.getEventDetails_loading(state),
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    eventDetails: eventsOverviewSelectors.eventDetails(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getEventDetails: eventsOverviewActions.getEventDetails,
        deleteAttachedFile: eventsOverviewActions.deleteAttachedFile
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <EventsInfoTab {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));