import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = (theme) => ({
});

function MemberShips(props) {
  const { classes, dashboard } = props;
  const [dashboardObj, setDashboardObj] = React.useState({});
  const [tableHeader, setTableHeader] = React.useState(null);
  const [tableBodyData, setTableBodyData] = React.useState(null);

  useEffect(() => {
    if (dashboard !== null) {
      let memberShips = dashboard.memberships;
      setDashboardObj(memberShips || {});
      makeTableHeader(dashboard.memberships["cancellations"]);
      makeTableBody(dashboard.memberships);
    }
  }, [dashboard]);

  const reMoveUnderScore = (key) => {
    key = key.replaceAll("_", " ");
    let splitStr = key.toLowerCase().split(" ");

    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

      key = splitStr.join(" ");
    }

    return key;
  };

  const makeTableHeader = (headersData) => {
    return setTableHeader(Object.keys(headersData));
  };

  const makeTableBody = (dashboardData) => {
    let objectKeys = Object.keys(dashboardData);
    let objectValues = Object.values(dashboardData);
    let bodyData = [];
    for (let index = 0; index < objectValues.length; index++) {
      let arryValues = Object.values(objectValues[index]);
      let arrTemp = [];
      for (let i = 0; i < arryValues.length; i++) {
        if (i == 0) {
          arrTemp.push(reMoveUnderScore(objectKeys[index]));
          arrTemp.push(arryValues[i]);
        } else {
          arrTemp.push(arryValues[i]);
        }
      }
      bodyData.push(arrTemp);
      setTableBodyData(bodyData);
      console.log("arrTemp", arrTemp);
    }
  };

  return tableHeader && tableBodyData ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {tableHeader.map((currElement, index) => {
              return (
                <TableCell style={{textAlign: 'left', padding: '6px 12px'}} align="right">
                  {reMoveUnderScore(currElement)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyData.map((subArray, index) => {
            return (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {subArray.map((subitem, i) => {
                  return (
                    <TableCell style={{fontSize: '0.8rem', textAlign: 'left', padding: '6px 12px', borderBottom: 'none'}} align="right" component="td" scope="row">
                      {subitem}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    ""
  );
}

export default withStyles(useStyles)(MemberShips);
