import * as React from 'react';
import { Formik, Field, Form } from 'formik';
import {
    Button,
    LinearProgress,
    MenuItem,
    Typography,
    Container,
    Grid,
    ButtonGroup,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';
import {
    TextField
} from 'formik-material-ui';
import { withStyles } from '@mui/styles';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../../Ducks/opportunity';
import { selectors as accountSelectors } from "../../../../../Ducks/account";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Placeholder from "ckeditor5-placeholder";
import { useParams } from 'react-router-dom';
import Image from '@ckeditor/ckeditor5-image/src/image';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';

const editorConfiguration = {
    plugins: [List,Placeholder, Essentials, Bold, Italic, Paragraph,SimpleUploadAdapter,ImageUpload,Image, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
    toolbar: ['heading', '|','Placeholder','|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote','uploadImage', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    placeholderProps: {
        types: ['FROM_FULLNAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'EMAIL_SUBJECT', 'FROM_EMAIL','TO_FULLNAME', 'TO_FIRSTNAME', 'FIRST_MEETING_DATE', 'FIRST_MEETING_START_TIME', 'FIRST_MEETING_END_TIME', 'FIRST_MEETING_DATE_AND_TIME', 'PRODUCT_NAME', 'PRODUCT_LOCATION', 'PRODUCT_ADDRESS', 'PRODUCT_ZIP_CODE', 'PRODUCT_CITY', 'NETWORK_DIRECTOR_1_FIRSTNAME', 'NETWORK_DIRECTOR_1_FULLNAME', 'NETWORK_DIRECTOR_2_FIRSTNAME', 'NETWORK_DIRECTOR_2_FULLNAME'],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: 'https://erp2.f5.dk/api/events/uploadimage',
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem('token')
        }
    },
};

const useStyles = theme => ({
    noPadding: {
        padding: 0
    },
    container: {
        textAlign: 'left',
    }
});


const Email = (props) => {
    const { classes, opportunity, isLoading, mails,user, emailTemplate, sendEmail, sentEmail,sendTestEmail,sentTestEmail } = props;
    const [template, setTemplate] = React.useState(null);
    const [sent, setSent] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [testMessage, setTestMessage] = React.useState('');

    const formRef = React.createRef();
    console.log(sentTestEmail?.message);

    React.useEffect(() => {
        if (emailTemplate !== null && formRef.current && template !== null) {
            formRef.current.resetForm();
            formRef.current.setFieldValue('template', template);
            formRef.current.setFieldValue('subject', emailTemplate.main.subject);
            formRef.current.setFieldValue('body', emailTemplate.main.body);

        }
    }, [emailTemplate])

    React.useEffect(() => {
        if (opportunity !== undefined) {
            var payload = {
                productId: opportunity.main.product_id,
                type: 'mail'
            }
            props.getMails(payload);
        }
    }, [opportunity]);

    React.useEffect(() => {
        if (sentEmail !== null && sent) {
            // toast.success(sentEmail.message, {
            //     position: toast.POSITION.BOTTOM_LEFT,
            //     hideProgressBar: true,
            //     autoClose: 2000
            //   });
            setMessage(sentEmail.message);
            formRef.current.resetForm();
        }
        if (sentTestEmail !== null && sent) {
            // toast.success(sentEmail.message, {
            //     position: toast.POSITION.BOTTOM_LEFT,
            //     hideProgressBar: true,
            //     autoClose: 2000
            //   });
            setTestMessage(sentTestEmail.message);
            formRef.current.resetForm();
        }
    }, [sentEmail,sentTestEmail]);


    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )

    const getTemplate = e => {
        const templateId = e.target.value;
        const opportunityId = props.params.id;
        setTemplate(templateId);
        props.applyEmailTemplate({ templateId, opportunityId });
    }

    if (!mails || isLoading) {
        return showProgress()
    }

    let submitAction = undefined;

    const handleFormSubmit = (values, actions) => {
        if(submitAction === 'sendMail')
        {
            let data = {
                message: values.body,
                subject: values.subject
            }
            sendEmail({ id: props.params.id, obj: data });
            setSent(true);
            setTemplate(null)
            actions.setSubmitting(false);
        } else if (submitAction === 'sendTestMail') {
            // Logic for sending test mail
            let data = {
                message: values.body,
                subject: values.subject
            }
            sendTestEmail({ id: props.params.id, obj: data });
            setSent(true);
            setTemplate(null)
            actions.setSubmitting(false);
          }
    }

    return (
        <React.Fragment>
            <div>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        template: '', //emailTemplate && template,
                        subject: "", //emailTemplate && template && emailTemplate.main.subject,
                        body: "", //emailTemplate && template && emailTemplate.main.body
                    }}
                    validate={values => {
                        const errors = {};
                        if(!values.subject || values.subject.trim()?.length === 0 || (values.subject === "-" || values.subject.trim()?.length === 1 ))
                        {
                            errors.subject = 'Subject Required';
                        }
                        return errors;
                    }}
                    onSubmit={handleFormSubmit}
                    render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                        <Form>
                            <Grid container sx={{ marginBottom: '15px', marginTop: '15px' }} spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        select
                                        name="template"
                                        label="Please select a template"
                                        variant="outlined"
                                        helperText=""
                                        margin="normal"
                                        component={TextField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        onChange={getTemplate}
                                    >
                                        {/* <MenuItem disabled >
                                            UNIVERSAL TEMPLATES
                                        </MenuItem>
                                        {mails &&
                                            mails.main.templates
                                            .filter(option => option.created_by === null)
                                            // .map((opt,index) => console.log("OPTION",opt))
                                            .map((option, index) => (
                                                <MenuItem key={option.template_id} value={option.template_id}>
                                                {option.template_name}
                                                </MenuItem>
                                            ))
                                        } */}
                                        {/* <MenuItem disabled >
                                            SALESREP TEMPLATES
                                        </MenuItem>
                                        {mails &&
                                            mails.main.templates
                                            .filter(option => option.created_by !== null && option.created_by !== 500)
                                            .map((option, index) => (
                                                <MenuItem key={option.template_id} value={option.template_id}>
                                                {option.template_name}
                                                </MenuItem>
                                            ))
                                        } */}
                                        <MenuItem disabled >
                                            MY TEMPLATES
                                        </MenuItem>
                                        {mails &&
                                            mails.main.templates
                                            .filter(option => option.created_by !== null && option.created_by === user?.user_details?.user_id)
                                            .map((option, index) => (
                                                <MenuItem key={option.template_id} value={option.template_id}>
                                                {option.template_name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Field>

                                    <Field
                                        name="subject"
                                        type="text"
                                        label="subject"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        component={TextField}
                                        
                                    />

                                    {/* <Field
                                        type="text"
                                        label=""
                                        name="body"
                                        multiline
                                        rows="4"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        component={TextField}
                                    /> */}
                                    <Field
                                        name="body"
                                        render={({ field, form }) => {
                                            return (
                                                <>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={editorConfiguration}
                                                        data={field.value}
                                                        onChange={(event, editor) => {
                                                            form.setFieldValue(field.name, editor.getData());
                                                        }}
                                                    // onSaveKeystroke={this.props.onSaveKeystroke}
                                                    />
                                                </>
                                            )
                                        }}
                                    />

                                </Grid>
                            </Grid>

                            {isSubmitting && <LinearProgress />}
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Button
                                    variant="contained"
                                    color="primary"

                                    disabled={isSubmitting}
                                    onClick={() => {
                                        submitForm();
                                        submitAction = 'sendMail'
                                    }}
                                    style={{ marginRight: "12px" }}
                                >
                                    Send Email
                                </Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        submitForm();
                                        submitAction = 'sendTestMail'
                                    }}                                >
                                    Test Mail
                                </Button>
                            </div>
                        </Form>
                    )}
                />
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.applyEmailTemplate_loading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    mails: opportunitySelectors.mails(state),
    emailTemplate: opportunitySelectors.emailTemplate(state),
    sentEmail: opportunitySelectors.sentEmail(state),
    sentTestEmail: opportunitySelectors.sendTestEmail(state),
    user: accountSelectors.user(state),
});


const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getMails: opportunityActions.getMails,
        applyEmailTemplate: opportunityActions.applyEmailTemplate,
        sendEmail: opportunityActions.sendEmail,
        sendTestEmail: opportunityActions.sendTestEmail,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <Email {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
