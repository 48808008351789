import * as React from "react";
import { withStyles } from "@mui/styles";
import {
    selectors as adminSelectors,
    actions as adminActions,
} from "../../../Ducks/admin";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { selectors as accountSelectors } from "../../../Ducks/account";
import { Switch, TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import {
    Button,
    LinearProgress,
    MenuItem,
    Grid,
    CircularProgress,
    Typography,
    FormControlLabel,
    Box,
} from "@mui/material";
import moment from "moment";
import AddNewRole from "./Modals/AddNewRole";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { groupBy, isEmpty } from "lodash";
import UserGrid from "./Grids/UserGrid";
import { Cancel, RemoveCircle } from "@mui/icons-material";
import Swal from "sweetalert2";

const useStyles = (theme) => ({
    container: {
        textAlign: "left",
        maxWidth: "90%",
        margin: "0 auto"
    },
    roles: {
        display: 'flex',
        flexDirection: "column !important",
        '& div': {
            display: 'flex',
            '& > button': {
                width: '100%',
                marginBottom: "10px",
                fontWeight: "bold",
                "&.selected": {
                    background: "#002ea7",
                    color: "#fff",
                    "& + .deleteRole": {
                        display: 'none'
                    }
                }
            }
        }
    },
    box: {
        '& > label': {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            margin: 0,
        },
        '& .MuiTypography-root': {
            width: '100%',
            textTransform: "capitalize"
        }
    },
    accordion: {
        '& .MuiTypography-root': {
            width: '100%',
            textTransform: "capitalize"
        },
        '& .MuiAccordionDetails-root': {
            display: 'flex',
            flexWrap: 'wrap',
            '& > label' : {
                display: 'flex',
                margin: 0,
                width: '33%'
            }
        }
    },
    submitForm: {
        width: '100%',
        marginTop: "10px",
        display: "flex",
        justifyContent: "flex-end"
    },
    deleteRole: {
        cursor: 'pointer',
        position: 'absolute',
        top: '-5px',
        right: '-10px',
        fontSize: '18px',
        color: "red",
        zIndex: 9999,
    }
});

const Capabilities = (props) => {
    const { classes, isLoading, userRoles, capabilitiesList, roleCapabilities } = props;
    const [selectedRole, setSelectedRole] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [allCapabilities, setAllCapabilities] = React.useState({});
    const formRef = React.createRef();

    React.useEffect(() => {
        // Get Roles
        props.getUserRoles();

        // Get CapabilitiesList
        props.getCapabilitiesList();

        // Get Specific User Capability - initially for super admin
        props.getRoleCapabilities(1);
    }, []);

    React.useEffect(() => {
        if (capabilitiesList) {
            const capabilitiesObject = groupBy(capabilitiesList, "capability_group_title");
            setAllCapabilities(capabilitiesObject);
            console.log("capabilitiesObject", capabilitiesObject);
        }
    }, [capabilitiesList])

    React.useEffect(() => {
        if (roleCapabilities) {
            roleCapabilities.rows.map((capability) => formRef.current.setFieldValue(capability.capability_title, [capability.capability_id.toString()]));
        }
    }, [roleCapabilities])

    const roleSelection = (roleID) => {
        // Reset Form
        formRef.current.resetForm();
        setSelectedRole(roleID);
        props.getRoleCapabilities(roleID);
    }

    const deleteRole = (roleID) => {
        const { deleteUserRole } = props;
        Swal.fire({
            title: "Confirm Delete",
            text: "Are you sure you want to delete this role?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel"
        }).then(result => {
            if (result.value) {
                deleteUserRole(roleID);
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");
        key = splitStr.join(" ");
        return key;
    }

    return (
        <>
            <div className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography
                            component="h5"
                            variant="h5"
                            style={{
                                fontSize: "18px",
                                color: "#9e9c9c",
                                borderBottom: "1px solid #d0d0d0",
                                marginBottom: "20px",
                                paddingBottom: "10px",
                            }}
                        >
                            Roles
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.roles}>
                                {
                                    userRoles && userRoles.map((role) => {
                                        return (
                                            <div style={{ position: 'relative' }}>
                                                <Button
                                                    key={role.role_id}
                                                    variant="contained"
                                                    color="inherit"
                                                    className={selectedRole === role.role_id ? "selected" : ""}
                                                    onClick={(e) => roleSelection(role.role_id, e)}
                                                >
                                                    {role.role_title}
                                                </Button>
                                                {
                                                    !role.default_role && role.user_count === 0 ? (
                                                        <span className="deleteRole"><RemoveCircle onClick={(e) => deleteRole(role.role_id, e)} className={classes.deleteRole} /></span>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={(e) => { setOpen(true) }}
                                >
                                    Add New Role
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    component="h5"
                                    variant="h5"
                                    style={{
                                        fontSize: "18px",
                                        color: "#9e9c9c",
                                        borderBottom: "1px solid #d0d0d0",
                                        marginBottom: "20px",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    Capabilities
                                </Typography>
                            </Grid>
                        </Grid>
                        <Formik
                            innerRef={formRef}
                            initialValues={{

                            }}
                            validate={(values) => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);

                                const formValues = Object.keys(values).map((value) => {
                                    const item = values[value];
                                    return {
                                        capability_title: value,
                                        capability_id: item[0]
                                    }
                                });

                                const capabilities = formValues.filter(capability => capability.capability_id !== undefined).map(item => parseInt(item.capability_id))
                                // console.log("capabilities...", capabilities)
                                const data = {
                                    role_id: selectedRole,
                                    values: capabilities
                                }
                                props.saveRoleCapabilities(data);
                            }}
                            render={({
                                submitForm,
                                isSubmitting,
                                values,
                                setFieldValue,
                                handleChange,
                            }) => (
                                <Form>
                                    <Box className={classes.box}>
                                        {
                                            allCapabilities && !isEmpty(allCapabilities) && Object.keys(allCapabilities).map((capability, index) => {
                                                const collection = allCapabilities[capability];
                                                const title = collection[0].capability_group_title;
                                                return (
                                                    <Accordion key={index} className={classes.accordion}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${index + 1}a-content`}
                                                            id={`panel${index + 1}a-header`}
                                                        >
                                                            <Typography sx={{ fontWeight: 'bold' }}>{title ? title : "General"}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {
                                                                collection.map((item, index) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={item.capability_id}
                                                                            label={makeHeader(item.capability_title)}
                                                                            control={
                                                                                <Field
                                                                                    component={Switch}
                                                                                    name={item.capability_title}
                                                                                    value={item.capability_id.toString()}
                                                                                    type="checkbox"
                                                                                />
                                                                            }
                                                                        />
                                                                    )
                                                                })
                                                            }

                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })
                                        }
                                    </Box>
                                    {
                                        isSubmitting ? (
                                            <CircularProgress />
                                        ) : (
                                            <Box className={classes.submitForm}>
                                                <Button variant="contained" color="primary" onClick={submitForm}>
                                                    Save
                                                </Button>
                                            </Box>
                                        )
                                    }

                                </Form>
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UserGrid rows={roleCapabilities ? roleCapabilities.users : []} />
                    </Grid>
                </Grid>
            </div>
            <AddNewRole
                open={open}
                onClose={handleClose}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    isLoading: adminSelectors.isLoading(state),
    user: accountSelectors.user(state),
    userRoles: adminSelectors.userRoles(state),
    capabilitiesList: adminSelectors.capabilitiesList(state),
    roleCapabilities: adminSelectors.roleCapabilities(state)
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUserRoles: adminActions.getUserRoles,
            getCapabilitiesList: adminActions.getCapabilitiesList,
            getRoleCapabilities: adminActions.getRoleCapabilities,
            saveRoleCapabilities: adminActions.saveRoleCapabilities,
            deleteUserRole: adminActions.deleteUserRole
        },
        dispatch
    );

function WithNavigate(props) {
    let params = useParams();
    return <Capabilities {...props} params={params} />;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(WithNavigate));
