import * as React from 'react';
import { withStyles } from '@mui/styles';
import { selectors as productsSelectors, actions as productsActions } from '../../../Ducks/products';
import { selectors as companiesSelectors, actions as companiesActions } from '../../../Ducks/companies';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import { selectors as accountSelectors } from '../../../Ducks/account';
import ContactDetailsForm from './ProductDetailsForm';
// import AgreementForm from './AgreementForm';
// import InvoiceForm from './InvoiceForm';
import CircularProgress from '@mui/material/CircularProgress';
import ProductDetailsForm from './ProductDetailsForm';
import ProductInformationForm from './ProductInformationForm';
import ProductAdditionalForm from './ProductAdditionalForm';

const useStyles = theme => ({
  container: {
    textAlign: 'left',
  }
});

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: props.params.id,
      // productColumns: props.user.tableSettings.purchases.columns,
      productColumns: [
        'product_id','erp_client_division_id','product_type_id','country_id','product_name','product_external_name','product_abbreviation','product_description','product_price_currency','product_price','product_location','product_address','product_zip_code','product_city','product_country','product_region','product_parking_info','product_events_start','product_events_stop','product_show_on_website','product_show_in_sales','product_default_attendance_value','product_website_description_short','product_website_description_long','product_last_modified','product_group_id','target_group_definition','pains_gains','thematics','remarks','countries.country_name','product_types.product_type_name'
      ],
      productDetails: null
    }
  }

  componentDidMount() {
    this.getContactDetail();
    this.getProductTypesList();
    this.getCountryList();
    
  }

  getProductTypesList = () => {
    const { getProductTypes } = this.props;
    getProductTypes();
  }

  getCountryList = () => {
    const { getCountries } = this.props;
    getCountries();
  }

  getContactDetail = () => {
    const { getProductDetails, getStages } = this.props;
    const { selectedProduct, productColumns } = this.state;
    getProductDetails({ id: selectedProduct, columns: productColumns });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.productDetails !== this.props.productDetails) {
      this.setState({ productDetails: this.props.productDetails && this.props.productDetails.main[0]})
    }
  }

  handleSaveClick = (obj) => {
    this.props.updateProduct({ id: this.state.selectedProduct, obj: obj, key: Object.keys(obj)[0]});
  }

  render() {
    const { classes, countries, productTypes } = this.props;
    const { productDetails, selectedProduct } = this.state;

    if(!productDetails) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
    }
    return (
      <>

        {
          (productDetails && countries && productTypes) &&(
            <div className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ProductDetailsForm productDetails={productDetails} productTypes={productTypes} countries={countries} onClick={this.handleSaveClick}/>
              <ProductInformationForm productDetails={productDetails} onClick={this.handleSaveClick}/>
            </Grid>
            <Grid item xs={6}>
              <ProductAdditionalForm productDetails={productDetails} onClick={this.handleSaveClick}/>
            </Grid>
          </Grid>

        </div>
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: productsSelectors.isLoading(state),
  user: accountSelectors.user(state),
  productDetails: productsSelectors.productDetails(state),
  countries: companiesSelectors.countries(state),
  productTypes: productsSelectors.productTypes(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getProductDetails: productsActions.getProductDetails,
    updateProduct: productsActions.updateProduct,
    getCountries: companiesActions.getCountries,
    getProductTypes: productsActions.getProductTypes
  },
  dispatch);

function WithNavigate(props) {
  let params = useParams();
  return <ProductDetails {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
