import {Autocomplete, Grid, Typography, TextField as MaterialTextField,} from "@mui/material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {addTodoActionItem} from "../../../API/clientRetention";
import {toast} from "react-toastify";

const TodoActionItemForm = (props) => {
    const {todoActionOptionCallback} = props;
    const todoActionItemTypes = [
        {
            title: "Checkbox",
            value: "checkbox"
        },
        {
            title: "Date",
            value: "date",
        },
        {
            title: "Erp Action",
            value: "erp_action"
        },
        {
            title: "Input",
            value: "input"
        },
        {
            title: "Multi Radio",
            value: "multi_radio"
        },
        {
            title: "Single Radio",
            value: "single_radio"
        },
        {
            title: "Text Only",
            value: "text_only"
        }
    ]
    return (
        <>
            <Typography
                variant="span"
                component="span"
                sx={{fontWeight: 'bold', marginBottom: '10px'}}
            >
                Add Todo Action Item
            </Typography>

            <Formik
                initialValues={{
                    title: "",
                    description: "",
                    type: "checkbox"
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.title) {
                        errors.title = "Required"; // Check if array is empty
                    }

                    if (!values.type) {
                        errors.type = "Required";
                    }

                    return errors;
                }}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    addTodoActionItem(values).then((res) => {
                        if (res?.data && todoActionOptionCallback) {
                            todoActionOptionCallback(res.data);
                            resetForm();
                        }

                        setSubmitting(false);
                    }).catch((err) => {
                        toast.error(err.error);
                        setSubmitting(false);
                    });
                }}>
                {
                    ({values, submitCount, errors, isSubmitting, setFieldValue}) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={3} style={{margin: "auto"}}>
                                    <Typography
                                        variant="span"
                                        component="span"
                                    >
                                        Title*
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} style={{margin: "auto"}}>
                                    <Field
                                        id='todo-action-item-title'
                                        name='title'
                                        type='text'
                                        label='Todo Action Item Title*'
                                        variant='outlined'
                                        margin='normal'
                                        fullWidth={true}
                                        component={TextField}
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} style={{margin: "auto"}}>
                                    <Typography
                                        variant="span"
                                        component="span">
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} style={{margin: "auto", paddingTop: 25}}>
                                    <Field
                                        id='todo-action-item-description'
                                        name="description"
                                        component={TextField}
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        type={"textarea"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} style={{margin: "auto", paddingTop: 35}}>
                                    <Typography
                                        variant="span"
                                        component="span">
                                        Type*
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} style={{margin: "auto", paddingTop: 35}}>
                                    <Autocomplete
                                        id='todo-action-item-type'
                                        name='type'
                                        disablePortal
                                        margin='normal'
                                        size='small'
                                        disableClearable
                                        options={todoActionItemTypes}
                                        value={todoActionItemTypes.find((option) => option.value === values.type) || {}}
                                        getOptionLabel={(option) => option?.title || ''}
                                        renderInput={(params) => (
                                            <MaterialTextField
                                                {...params}
                                                label='Todo Action Item Type*'
                                                error={submitCount > 0 && errors.type}
                                            />
                                        )}
                                        onChange={(_event, newValue) => {
                                            setFieldValue('type', newValue.value);
                                        }}
                                    />
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "0.75rem",
                                            margin: "4px 14px 0px",
                                        }}
                                    >
                                        <ErrorMessage name={"type"}/>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{
                                    display: "flex",
                                    justifyContent: "right",
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        style={{
                                            margin: "10px",
                                        }}
                                        loading={isSubmitting}
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }
            </Formik>
        </>
    );
}

export default TodoActionItemForm