import {fetchGet, fetchPost, fetchPatch, fetchDelete} from "./util";

export const getEventsOverview = (query) => {
    return fetchGet(`/events/overview?${query}`).then((res) => res);
};

export const getCasesOverview = (query) => {
    if (query === undefined) {
        return fetchGet(`/events/cases-overview`).then((res) => res)
    }
    return fetchGet(`/events/cases-overview?${query}`).then((res) => res);
};

export const getResaleOverview = (query) => {
    return fetchGet(`/events/resale-overview?${query}`).then((res) => res);
};

export const updateAttendanceStatus = (data) => {
    return fetchPatch(`/events/person/attendance`, data.obj).then(res => res)
};

export const updateSendoutEmail = (data) => {
    return fetchPatch(`/notifications`, data.obj).then(res => res)
};

export const getPersonPurchases = (id) => {
    return fetchGet(`/persons/${id}/purchases`).then(res => res)
};

export const getPersonOpportunities = (id) => {
    return fetchGet(`/persons/${id}/opportunities`).then(res => res)
};

export const getPersonOpenOpportunity = (id) => {
    return fetchGet(`/persons/${id}/opportunities/open`).then(res => res)
};

export const updatePersonStage = (data) => {
    return fetchPatch(`/opportunities/${data.opportunityId}/stages/${data.stageId}`, {}).then(res => res)
}

export const getPersonHistory = ({person_id, query, isNetworkDirector}) => {
    return fetchGet(`/persons/${person_id}/history?type=${query}&nd=${isNetworkDirector ? 1 : 0}`).then(res => res);
};

export const getParticipantDetails = ({event_id, participant_id}) => {
    return fetchGet(`/events/${event_id}/participants/${participant_id}/details`).then(res => res);
};

export const getEventDetails = (event_id) => {
    return fetchGet(`/events/${event_id}/details`).then(res => res);
};

export const getNoteTypes = () => {
    return fetchGet(`/note-types`).then(res => res);
};

export const addHistoryLog = (data) => {
    return fetchPost(`/persons/${data.id}/log`, data.obj).then(res => res);
};

export const updateNotes = (data) => {
    return fetchPatch(`/events/notes`, data.obj).then(res => res);
};

export const updateEmail = (data) => {
    return fetchPost(`/events/email-invitations`, data.obj, true).then(res => res);
};

export const deleteAttachedFile = (data) => {
    return fetchDelete(`/events/file/${data.upload_id}/${data.event_id}/${data.type}`, {}).then(res => res);
};

export const getNetworkDirectors = (_data) => {
    return fetchGet(`/events/network-directors`).then(res => res);
};

export const getEventsOverviewUsers = (_data) => {
    return fetchGet(`/events/sales-reps`).then(res => res);
};

export const getPlannedCallTypes = () => {
    return fetchGet(`/planned_call_types`).then(res => res);
};

export const getPersonPlannedCalls = (person_id) => {
    return fetchGet(`/planned_calls?equals[person_id]=${person_id}`).then(res => res);
};

export const addPlannedAction = (data) => {
    return fetchPost(`/opportunities/${data.id}/action`, data.obj).then(res => res)
}

export const pauseCase = (data) => {
    return fetchPost(`/cases/pause`, data.data).then(res => res)
}

export const registerOnGoingCase = (data) => {
    return fetchPost(`/cases/ongoing`, data.data).then(res => res)
}

export const bookMeeting = (data) => {
    return fetchPost(`/events/booked-meeting`, data.data).then(res => res)
}

export const editPlannedAction = (data) => {
    return fetchPatch(`/opportunities/${data.id}/action/${data.actionId}/date-and-type`, data.obj).then(res => res)
}

export const createPlannedCall = (data) => {
    return fetchPost(`/planned_call`, data.data).then(res => res)
}

export const updatePlannedCall = (data) => {
    return fetchPatch(`/planned_call/${data.planned_call_id}`, data.data).then(res => res)
}

export const resolveCase = (data) => {
    return fetchPatch(`/cases/resolve/${data.case_id}`, data.data).then(res => res)
}

export const updateBookedMeeting = (data) => {
    return fetchPatch(`/booked-meeting/${data.booked_meeting_id}`, data.data).then(res => res)
}

export const getPlannedCall = (planned_call_id) => {
    return fetchGet(`/planned_calls/${planned_call_id}`).then(res => res)
}

export const getSpecialCaseDetails = (purchase_id) => {
    return fetchGet(`/cases/purchases/${purchase_id}`).then(res => res)
}

export const getBookedMeetingById = (meeting_id) => {
    return fetchGet(`/booked-meeting/${meeting_id}`).then(res => res)
}

export const getPersonMeetings = (purchase_id) => {
    return fetchGet(`/events/booked-meeting/${purchase_id}`).then((res) => res);
};

export const getPendingCases = () => {
    return fetchGet(`/cases/stats`).then((res) => res);
};

export const getCaseUsersList = (data) => {
    return fetchGet(`/cases/${data.caseName}/user-list?cap_id=${data.capabilityId}`).then((res) => res);
};

export const getEventMembers = (event_id) => {
    return fetchGet(`/events/participants/${event_id}`).then((res) => res);
};

export const caseDecline = (data) => {
    return fetchPatch(`/cases/decline/${data.case_id}`).then((res) => res);
}
export const getCasesEventsOverview = ({product_id, purchase_id}) => {
    return fetchGet(`/events/casesoverview/${product_id}/${purchase_id}/details`);
};

export const updateAssignee = (data) => {
    return fetchPatch(`/cases/reassign/${data.caseId}`, data).then((res) => res);
}

export const pendingCaseDetails = (purchase_id) => {
    return fetchGet(`/cases/purchase/${purchase_id}/details`).then(res => res)
}