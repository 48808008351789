import * as React from 'react';
import moment from 'moment';
import { TextField } from "formik-material-ui";
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateRangePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import addWeeks from 'date-fns/addWeeks';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, TextField as MaterialTextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form } from 'formik';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as accountSelectors, actions as accountActions } from '../../../../Ducks/account';
import { selectors as ContactsSelectors, actions as contactsActions } from "../../../../Ducks/contacts";
import {
    Button,
    LinearProgress,
    MenuItem,
    Typography,
    Container,
    Grid,
    ButtonGroup,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';
import { fetchGet } from '../../../../API/util';
import UserList from '../Widgets/UserList';

const editorConfiguration = {
    plugins: [List, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
    toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    placeholder: "Add comments ..."
};

const useStyles = theme => ({
});

function CasePauseModal(props) {
    const { person, event_id, participant_id, columns, query, pauseCase, pausedCase, getContactDetails, toggleSpecialCaseTab } = props;
    const [range, setRange] = React.useState([null, null]);
    const [user, setUser] = React.useState(null)
    const formRef = React.createRef();

    React.useEffect(() => {
        if (pausedCase) {
            getContactDetails({ id: person.person_id, columns: columns });
        }
    }, [pausedCase]);

    const handleClose = () => {
        setUser(null);
        props.handleClose()
    };

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} disableEnforceFocus>
                <DialogTitle style={{ paddingBottom: '10px', paddingTop: '10px', boxShadow: '0px 0px 20px 2px #ebebeb' }}>Pause Case</DialogTitle>
                <DialogContent>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            type: 'pause',
                            date_range: [null, null],
                            due_date: new Date(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0)),
                            no_of_events: 0,
                            events_approved: 0,
                            notifications: true,
                            notes: '',
                            user: user?.user_id
                        }}
                        validate={values => {
                            console.log(values);
                            const errors = {};
                            if(values.no_of_events == 0)
                            {
                                errors.no_of_events = 'Number of the events should be greater than 0'
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            const data = {
                                person_id: person.person_id,
                                start_date: values.date_range[0],
                                end_date: values.date_range[1],
                                due_date: moment(values.due_date).format('YYYY-MM-DD'),
                                type: values.type,
                                notifications: values.notifications,
                                events_approved: values.events_approved,
                                notes: values.notes,
                                purchase_id: person.purchase_id,
                                user_id: user.user_id
                            }
                            // console.log("submit data", data)
                            pauseCase({ id: person.person_id, event_id, data, participant_id, query, columns, toggleSpecialCaseTab });
                            handleClose();
                        }}
                        render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                            <Form>
                                <Grid container sx={{ marginBottom: '15px', marginTop: '0px' }} spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="date_range"
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DateRangePicker
                                                                disableOpenPicker={false}
                                                                value={field.value}
                                                                inputFormat="dd/MM/yyyy"
                                                                className="date-range-picker"
                                                                // maxDate={this.getWeeksAfter(range[0], 1)}
                                                                onChange={(newValue) => {
                                                                    if (newValue[0] && newValue[1] && range[0] && range[1] && newValue[0].getTime() === range[0].getTime() && newValue[1].getTime() === range[1].getTime()) {
                                                                        // Do nothing
                                                                    } else if ((newValue[0] !== null && newValue[1] !== null)) {
                                                                        // setRange(newValue);
                                                                        form.setFieldValue(field.name, [moment(newValue[0]).format("YYYY-MM-DD"), moment(newValue[1]).format("YYYY-MM-DD")])

                                                                        const start_date = moment(newValue[0]).format("YYYY-MM-DD");
                                                                        const end_date = moment(newValue[1]).format("YYYY-MM-DD");
                                                                        const api = `/events/special-case-events/${start_date}/${end_date}/${person.person_id}`
                                                                        fetchGet(api).then(res => {
                                                                            form.setFieldValue("no_of_events", res.length)
                                                                            form.setFieldValue("events_approved", res.length)
                                                                        }).catch((error) => {
                                                                            console.log("error.....", error)
                                                                            // setLoading(false);
                                                                        })
                                                                    }

                                                                }}
                                                                renderInput={(startProps, endProps) => (
                                                                    <React.Fragment>
                                                                        <MaterialTextField fullWidth size='small' {...startProps} />
                                                                        <Box sx={{ mx: 2 }}> to </Box>
                                                                        <MaterialTextField fullWidth size='small' disabled {...endProps} />
                                                                    </React.Fragment>
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </>
                                                )
                                            }}
                                        />
                                        <UserList 
                                            caseName="pause"
                                            capabilityId={47}
                                            setUserForParent={setUser}
                                            width={"100%"}
                                        />
                                        <Field
                                            name="no_of_events"
                                            type="text"
                                            label="No of events"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            size={'small'}
                                            style={{ marginTop: '10px' }}
                                            disabled
                                        />
                                        <Field
                                            name="events_approved"
                                            type="text"
                                            label="Future no of events"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            size={'small'}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <Field
                                            name="due_date"
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker
                                                                label="Due Date"
                                                                inputFormat="yyyy/MM/dd"
                                                                value={formRef.current ? formRef.current.values.due_date : new Date(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0))}
                                                                onChange={(value) => {
                                                                    console.log("due_date", value)
                                                                    form.setFieldValue(field.name, value);
                                                                }}
                                                                renderInput={(params) => <MaterialTextField sx={{ margin: '10px 0' }} fullWidth size='small' {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </>
                                                )
                                            }}
                                        />
                                        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <Field
                                                name="notifications"
                                                style={{ width: '20px', height: '20px', margin: '0 5px 0 0' }}
                                                type="checkbox"
                                            />
                                            Wish to receive summaries?
                                        </label>
                                        <Field
                                            name="notes"
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={editorConfiguration}
                                                            data={field.value}
                                                            onChange={(event, editor) => {
                                                                form.setFieldValue(field.name, editor.getData());
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }}
                                        />

                                    </Grid>
                                </Grid>

                                {isSubmitting && <LinearProgress />}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || user == null}
                                        onClick={submitForm}
                                        style={{ marginRight: '12px' }}
                                    >
                                        {'Save'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        disabled={isSubmitting}
                                        onClick={handleClose}
                                    >
                                        {'Cancel'}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: eventsOverviewSelectors.getNoteTypes_loading(state),
    pausedCase: eventsOverviewSelectors.pausedCase(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        pauseCase: eventsOverviewActions.pauseCase,
        getContactDetails: contactsActions.getContactDetails,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(CasePauseModal));