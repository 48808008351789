import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { List, ListItem, ListItemText, FormControlLabel, Checkbox, Grid, CardActions, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import SimpleTable from '../../../Common/SimpleTable';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { toDateTime } from '../../../Utils/dateUtils';
import { connect } from "react-redux";
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from "../../../../Ducks/eventsOverview";
import { bindActionCreators } from "redux"
import moment from 'moment';

const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff'
    // height: 200
  },
  typography: {
    width: '100%'
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '-8px',
    marginBottom: '-8px',
  }
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    border: '1px solid #dadde9',
  },
}));

function UpcomingEvents(props) {
  const { classes, overview, checkEvent_loading, checkEvent, unCheckEvent, assignedOpportunity } = props;
  const [opportunityEvents, setOpportunityEvents] = React.useState([]);
  const [overviewRelatedObjects, setOverviewRelatedObjects] = React.useState({})
  const [relatedProduct, setRelatedProduct] = React.useState({})

  React.useEffect(() => {
    if (overview) {
      const relatedObjects = overview.related_objects || {};
      const main = overview.main || {};
      const { products } = relatedObjects || {};
      const product = (main && products && products[main.product_id]) || {};

      // Filter by one previous month of now

      // const filteredEvents = product.events.filter(x => {
      //   const event = relatedObjects.events[x];
      //   const eventStartDate = new Date(event.event_start);
      //   const a = moment(new Date()).subtract(1, 'months');
      //   return a < eventStartDate;
      // });

      // Filter by opportunity start date

      const filteredEvents = product.events.filter(x => {
        const event = relatedObjects.events[x];
        const eventStartDate = new Date(event.event_start);
        const a = moment(new Date(overview.main.opportunity_start))
        return a < eventStartDate;
      });

      const _opportunityEvents = filteredEvents.map((x) => relatedObjects.events[x]).sort((a, b) => new Date(a.event_start) - new Date(b.event_start)).map((y) => y.event_id);
      setOverviewRelatedObjects(relatedObjects);
      setRelatedProduct(product);
      setOpportunityEvents(_opportunityEvents);
    }
  }, [overview])


  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div" sx={{ marginBottom: '10px' }}>
          {relatedProduct.product_name}
        </Typography>
        <Grid container spacing={0} style={{ paddingLeft: '8px' }}>
          {
            overview && opportunityEvents.map((eventID, index) => (
              <EventItem
                event={overviewRelatedObjects.events[eventID]}
                key={index}
                overview={overview}
                checkEvent_loading={checkEvent_loading}
                checkEvent={checkEvent}
                unCheckEvent={unCheckEvent}
                assignedOpportunity={assignedOpportunity}
              />
            ))
          }
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button variant="contained" color="secondary" endIcon={<ArrowRightAltIcon />} size="small" onClick={() => props.handleTabChange('product')}>Product</Button>
      </CardActions>
    </Card>
  );
}

const EventItem = ({ event, overview, checkEvent_loading, checkEvent, unCheckEvent, assignedOpportunity }) => {
  const { event_name, event_start, potential_participants, event_id } = event;
  const [isChecked, setIsChecked] = useState(false);
  const [checkTimer, setCheckTimer] = useState(null);
  const handleChange = (event) => {
    setIsChecked(!isChecked);
    const checkChangeHandler = event.target.checked ? checkEvent : unCheckEvent;

    clearTimeout(checkTimer);
    const timerId = setTimeout(checkChangeHandler.bind(null, {
      opportunityId: overview.main.opportunity_id,
      eventId: event_id
    }), 1000);
    setCheckTimer(timerId);
  }

  useEffect(() => {
    const selectedEvents = overview.related_objects.events_to_opportunities || {};
    setIsChecked(Object.keys(selectedEvents).some(x => selectedEvents[x].event_id === event_id));
  }, [event_id, overview]);

  return (
    <Grid item sx={{ width: '100%', marginBottom: '15px' }}>
      <FormControlLabel
        sx={{ display: 'flex', marginRight: 0 }}
        control={
          <Checkbox
            sx={{ padding: 0, margin: '5px 5px 5px 0 ' }}
            disabled={(!assignedOpportunity || overview.main.opportunity_status !== "open" || checkEvent_loading)}
            checked={isChecked}
            onChange={(event) => handleChange(event)}
          />
        }
        label={event_name}
      />
      <Typography gutterBottom variant="body2" component="text.secondary" sx={{ fontWeight: 600, marginLeft: '-8px' }}>
        {event_start && moment(event_start).format("DD/MM/YYYY HH:mm")}
        <HtmlTooltip
          title={
            <Popover opportunity={overview} eventID={event_id} targetID={'popover_' + event_id} participants={overview.related_objects.potential_participants} jobs={overview.related_objects.jobs} participantIds={potential_participants} />
          }
        >
          <Typography style={{ marginLeft: '32px', fontWeight: 600 }} gutterBottom variant="body2" component="text.secondary">
            {potential_participants.length} members
          </Typography>
        </HtmlTooltip>
      </Typography>
    </Grid>
  )
}

const Popover = connect(
  (state) => ({
    isLoading: eventsOverviewSelectors.getEventMembers_loading(state),
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    eventMembers: eventsOverviewSelectors.eventMembers(state)
  }), (dispatch) => bindActionCreators(
    {
      getEventMembers: eventsOverviewActions.getEventMembers
    },
    dispatch
  ))((props) => {
    const { getEventMembers, eventMembers, eventID } = props;
    const [rows, setRows] = React.useState(null)

    React.useEffect(() => {
      getEventMembers(eventID);
    }, []);

    React.useEffect(() => {
      if (eventMembers) {
        const sorted = eventMembers.sort(function (a, b) {
          if (a.scraped_company_name < b.scraped_company_name) { return -1; }
          if (a.scraped_company_name > b.scraped_company_name) { return 1; }
          return 0;
        });
        setRows(sorted)
      }
    }, [eventMembers])

    if (eventMembers) {
      return <SimpleTable copyOption={true} rows={eventMembers} columnHeader={["Company", "Job Title"]} columnKeys={['job_company', 'job_title']} />
    }

    return null
  })

export default withStyles(useStyles)(UpcomingEvents);