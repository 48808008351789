import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import Swal from "sweetalert2";
import { types as contactsTypes, actions as contactsActions } from '../Ducks/contacts';

import { callApi } from './utils';
import * as api from '../API/contacts';


function* getContacts(action) {
  const { success, failure } = contactsActions.saga.getContacts;

  yield callApi(
    call(api.getContacts, action.data),
    success,
    failure
  )
}

function* getContactDetails(action) {
  const { success, failure } = contactsActions.saga.getContactDetails;

  yield callApi(
    call(api.getContactDetails, action.data),
    success,
    failure
  )
}

function* getFoodConsiderations(action) {
  const { success, failure } = contactsActions.saga.getFoodConsiderations;

  yield callApi(
    call(api.getFoodConsiderations, action.data),
    success,
    failure
  )
}

function* getPersonMiniProfile(action) {
  const { success, failure } = contactsActions.saga.getPersonMiniProfile;

  yield callApi(
    call(api.getPersonMiniProfile, action.data),
    success,
    failure
  )
}

function* updateContact(action) {
  const { success, failure } = contactsActions.saga.updateContact;

  function* postApiSuccessCallEffect(res) {
    Swal.fire({
      icon: 'success',
      title: 'Saved successfully!',
      showConfirmButton: false,
      timer: 1500
    })
    yield call(getContactDetails, { ...action, data: { id: action.data.id, columns: action.data.columns } });
  }

  yield callApi(
    call(api.updateContact, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getTags() {
  const { success, failure } = contactsActions.saga.getTags;

  yield callApi(
    call(api.getTags),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(contactsTypes.getContacts, getContacts);
  yield takeLatest(contactsTypes.getContactDetails, getContactDetails);
  yield takeLatest(contactsTypes.getFoodConsiderations, getFoodConsiderations);
  yield takeLatest(contactsTypes.getPersonMiniProfile, getPersonMiniProfile);
  yield takeLatest(contactsTypes.updateContact, updateContact);
  yield takeLatest(contactsTypes.getTags,getTags);
}