import React from 'react';
import {connect} from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {bindActionCreators} from 'redux';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    IconButton,
    MenuItem,
    Select
} from '@mui/material';
import {withStyles} from '@mui/styles';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {useParams} from "react-router-dom";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'end'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    },
    list: {
        listStyle: 'none',
        padding: 0
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        cursor: 'pointer',
        '&:hover': {
            background: '#ebebeb'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    formControl: {
        marginBottom: '.8rem'
    },
    inputLabel: {
        marginBottom: '.5rem'
    }
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-container': {
        '& > .MuiPaper-root': {
            minWidth: '500px',
        }
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    }
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2,}} {...other}>
            {children}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
    );
};

const CompaniesProfileUpdateModel = (props) => {
    const {classes, onClose, open} = props;
    const [queries, setQueries] = React.useState([]);
    const [query, setQuery] = React.useState({operand: null, term: ''});


    const saveColumns = () => {
        onClose();
        Swal.fire({
            icon: 'success',
            title: 'Filters applied!',
            showConfirmButton: false,
            timer: 1500
        });
    }

    const handleAddQuery = () => {
        if (!query.term) return;

        const _queries = [...queries, ...query];

        setQueries(_queries);
        setQuery({term: '', query: 'and'});
    };


    const handleUpdateQuery = (updatedQuery, index) => {
        const _queries = [...queries];
        _queries[index] = updatedQuery;

        setQueries(_queries);
    }

    return (
        <BootstrapDialog onClose={onClose} open={open}>
            <BootstrapDialogTitle onClose={onClose} sx={{textAlign: 'center'}}><b>Apply
                Filters</b></BootstrapDialogTitle>
            <DialogContent>
                <div className={classes.formControl}>
                    <Typography component="h2" variant="h6" className={classes.inputLabel}>
                        <b>Companies Filter</b>
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            defaultValue={30}
                        >
                            <MenuItem value={10}>With ID</MenuItem>
                            <MenuItem value={20}>Without ID</MenuItem>
                            <MenuItem value={30}>Both</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.formControl}>
                    <Typography component="h2" variant="h6" className={classes.inputLabel}>
                        <b>Location</b>
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            defaultValue={10}
                        >
                            <MenuItem value={10}>Denmark</MenuItem>
                            <MenuItem value={20}>Sweden</MenuItem>
                            <MenuItem value={30}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.formControl}>
                    <Typography component="h2" variant="h6" className={classes.inputLabel}>
                        <b>Search</b>
                    </Typography>
                    {queries.map((query, index) =>
                        <div style={{display: 'flex', 'justifyContent': 'space-between', marginBottom: '.8rem'}}>
                            {index !== 0 &&
                                <FormControl sx={{marginRight: '.8rem'}}>
                                    <Select
                                        value={query.operand}
                                        onChange={e => handleUpdateQuery({...query, operand: e.target.value}, index)}
                                    >
                                        <MenuItem value='and'>And</MenuItem>
                                        <MenuItem value='or'>Or</MenuItem>
                                        <MenuItem value='not'>Not</MenuItem>
                                        <MenuItem value='within-name'>Within Name</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            <FormControl sx={{flexGrow: 1}}>
                                <TextField label="Company name" value={query} onChange={(e) => handleUpdateQuery({
                                    ...query,
                                    term: e.target.value
                                }, index)}/>
                            </FormControl>
                        </div>
                    )}
                    <div style={{display: 'flex', 'justifyContent': 'space-between', marginBottom: '.8rem'}}>
                        {queries.length !== 0 &&
                            <FormControl sx={{marginRight: '.8rem'}}>
                                <Select
                                    value={query.operand}
                                    onChange={e => setQuery({...query, operand: e.target.value})}
                                >
                                    <MenuItem value='and'>And</MenuItem>
                                    <MenuItem value='or'>Or</MenuItem>
                                    <MenuItem value='not'>Not</MenuItem>
                                    <MenuItem value='within-name'>Within Name</MenuItem>
                                </Select>
                            </FormControl>
                        }
                        <FormControl sx={{flexGrow: 1}}>
                            <TextField label="Company name" value={query.term}
                                       onChange={(e) => setQuery({...query, term: e.target.value})}/>
                        </FormControl>
                    </div>
                    <Button className={classes.button}
                            sx={{marginTop: '1rem', float: 'right'}}
                            type="button"
                            variant="contained"
                            color="secondary" onClick={handleAddQuery}>Add</Button>
                </div>

            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color="error" onClick={onClose}>Cancel</Button>
                <Button className={classes.button} variant="contained" color='success'
                        onClick={saveColumns}>Filter</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {},
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <CompaniesProfileUpdateModel {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));