import { fetchGet, fetchPatch } from './util';


export const getSales = ({ results, columns, offset }) => {
  return fetchGet(`/purchases?results=${results}&columns=${columns}&offset=${offset}`).then(res => res)
}

export const getSaleDetails = ({ id, columns }) => {
  return fetchGet(`/purchases/${id}?columns=${columns}`).then(res => res)
}

export const getUserStages = ({ id }) => {
  return fetchGet(`/pipelines?equals[user_id]=${id}`).then(res => res)
}

export const getPipelineContent = (data) => {
  return fetchGet(`/pipelines/${data.data.main[0].pipeline_id}/content`).then(res => res)
}

export const updateSale = (data) => {
  return fetchPatch(`/purchases/${data.id}`, data.obj).then(res => res)
}
