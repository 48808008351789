import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
  asyncs: {
    getDashboard: (data) => data,
    getPipelines: (data) => data
  }
}, 'dashboard');

let initialState = asyncInitialState({
  dashboard: null,
  pipelines: null,
  errorMessage: '',
  success: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getDashboard:
    case types.getPipelines:
      return asyncOnRequest(state, action);
    case types.saga.getDashboard.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          dashboard: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getPipelines.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          pipelines: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getDashboard.failure:
    case types.saga.getPipelines.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.dashboard,
  {
    errorMessage: (data) => data.errorMessage,
  }
)

const syncSelector = {
  isLoading: (state) => state.dashboard.loading,
  dashboard: (state) => state.dashboard.data.dashboard,
  pipelines: (state) => state.dashboard.data.pipelines
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)