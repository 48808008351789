import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Autocomplete,
    TextField as MaterialTextField,
    DialogTitle,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DateTimePicker from '../../../Common/CalendarWidget/DateTimePicker';
// import { toDateTime } from '../../Utils/dateUtils';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as accountSelectors, actions as accountActions } from '../../../../Ducks/account';
import { selectors as ContactsSelectors, actions as contactsActions } from "../../../../Ducks/contacts";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Placeholder from "ckeditor5-placeholder";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import TimePickerWidget from '../../../Common/TimePickerWidget';

const editorConfiguration = {
    plugins: [List, Placeholder, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
    toolbar: ['heading', '|', 'Placeholder', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList'],
    alignment: {
        options: ['left', 'right']
    },
    placeholderProps: {
        types: ["EMAIL_SUBJECT", "FROM_EMAIL", "FROM_FULLNAME", "FROM_FIRSTNAME", "FROM_LASTNAME", "TO_EMAIL", "TO_FULLNAME", "TO_FIRSTNAME", "EVENT_DATE", "EVENT_TIME_FROM", "EVENT_TIME_TO", "EVENT_ADDRESS", "EVENT_PARKING_INFO"],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    placeholder: "Insert text here..."
};

const types = [
    'phone_meeting',
    'follow_up'
]

const useStyles = theme => ({
    select: {
        "& ul": {
            display: "grid",
        }
    },
});

function CancelMeetingModal(props) {
    const {
        classes,
        person,
        bookMeeting,
        bookedMeeting,
        row,
        edit,
        reset,
        getBookedMeetingById,
        bookedMeetingById,
        updateBookedMeeting,
        opportunityActiveCallAction,
        event_id,
        participant_id,
        query,
        opportunity,
        startDate,
        action,
    } = props;
    // const [startDate, setStartDate] = React.useState(new Date());
    // const [endDate, setEndDate] = React.useState(moment(new Date()).add(30, 'm').toDate());
    const [date, setDate] = React.useState(new Date());
    const [meetingTitle, setMeetingTitle] = React.useState("");
    const [content, setContent] = React.useState("");
    const [type, setType] = React.useState(types[0]);
    const [missedCall, setMissedCall] = React.useState(true);

    const handleClose = () => {
        if (!edit) {
            restFields();
        }
        props.handleClose()
    };

    const handleSubmit = () => {
        const obj = {
            log_content: content,
            log_title: "Missed Call",
            log_code: "missed_call"
        }
        const data = {
            meeting_status: "CANCELLED"
        }

        if (missedCall) {
            opportunityActiveCallAction({ opportunityId: action.opportunity_id, opportunityActionId: action.opportunity_action_id, person_id: person.person_id, booked_meeting_id: action.booked_meeting_id, obj, query, purchase_id: person.purchase_id });
        } 
        updateBookedMeeting({ person_id: person.person_id, booked_meeting_id: opportunity.meeting.booked_meeting_id, data, query, purchase_id: person.purchase_id, cancelled: true });
        handleClose();
    }

    const restFields = () => {
        setContent("")
    }

    const handleDateChange = (value) => {
        setDate(value)
    }

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    return (
        <div>
            <Dialog width={700} open={props.open} onClose={handleClose}>
                <DialogTitle style={{ paddingTop: '10px', paddingBottom: '10px', boxShadow: '0px 0px 20px 2px #ebebeb' }}>Cancel Meeting</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={missedCall}
                                    onChange={() => setMissedCall(!missedCall)}
                                />
                            }
                            label="Mark this a Missed Call" />
                    </FormGroup>
                    {
                        missedCall && (
                            <>
                                <div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={editorConfiguration}
                                        data={content}
                                        onReady={(editor) => {
                                            console.log("Ready.", editor)
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setContent(data)
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log("Blur.", editor)
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log("Focus.", editor);
                                        }}
                                    />
                                </div>
                            </>
                        )
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        {
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                                style={{ marginRight: '12px' }}
                            >
                                {'Confirm'}
                            </Button>
                        }
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
const mapStateToProps = (state) => ({
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    user: accountSelectors.user(state),
    bookedMeetingById: eventsOverviewSelectors.bookedMeetingById(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        bookMeeting: eventsOverviewActions.bookMeeting,
        getBookedMeetingById: eventsOverviewActions.getBookedMeetingById,
        updateBookedMeeting: eventsOverviewActions.updateBookedMeeting,
        opportunityActiveCallAction: opportunityActions.opportunityActiveCallAction,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(CancelMeetingModal));