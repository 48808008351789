import {useEffect, useRef, useState} from "react";
import {
    Alert,
    Autocomplete,
    Button,
    Card,
    Grid,
    TextField as MaterialTextField,
    Typography
} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {useParams} from "react-router";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useNavigate} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {toast} from "react-toastify";
import {getPersonInfo, postChangePersonGroup, getEventsToProduct} from "../../../API/changePersonGroup";
import {
    FIRST_MEETING_IN_NEXT_GROUP_COOL_OFF_DAYS
} from "../../Utils/constants";
import {addDays} from "../../Utils/dateUtils";
import EventGrid from "./EventGrid";

function ChangePersonGroupRequestForm(props) {
    const {navigate} = props;
    const formRef = useRef(null);
    const [data, setData] = useState(null);
    const [newProducts, setNewProducts] = useState([]);
    const [newProductUpcomingEvents, setNewProductUpcomingEvents] = useState([]);

    const getPersonGroup = () => {
        let personId = props.params.id;

        getPersonInfo(personId).then((res) => {
            setData(res);
            filterProducts(res?.purchase?.product?.product_id, res.products);
            populateForm(res);
        }).catch((err) => {
            toast.error(err.message);
        });
    };

    const populateForm = (data) => {
        formRef.current.setFieldValue("person_title", data?.person?.person_name);
        formRef.current.setFieldValue("current_product", data?.purchase?.product.product_name);
        formRef.current.setFieldValue('meetings_left', data?.purchase?.upcoming_events?.length);
        formRef.current.setFieldTouched("meetings_left", true);

        if (data?.purchase) {
            formRef.current.setFieldValue("purchase", data?.purchase);
        }
    };

    const filterProducts = (productId, productList) => {
        if (productId && productList?.length) {
            let filteredProducts = productList.filter((item) => item.product_id !== productId);
            setNewProducts(filteredProducts);
        }
    }

    const checkEventAfterCoolOffPeriod = (event) => {
        if (event) {
            const eventStartDate = new Date(event.event_start);
            const coolOffPeriod = addDays(new Date(), FIRST_MEETING_IN_NEXT_GROUP_COOL_OFF_DAYS);
            return eventStartDate > coolOffPeriod;
        }

        return false;
    }

    const handleCancelClick = () => {
        let personId = props.params.id;

        navigate(`/contact/id/${personId}`);
    }

    const getFirstEventDate = (date) => {
        formRef.current.setFieldValue('first_meeting_date',date)
    }

    useEffect(() => {
        getPersonGroup();
    }, []);
    
    return (
        <>
            <div>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        person_title: "",
                        current_product: "",
                        new_product_id: "",
                        new_product_name: "",
                        first_meeting_date: "",
                        meetings_left: "",
                        comments: "",
                        purchase: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.person_title) {
                            errors.person_title = 'Required';
                        }

                        if (!values.current_product) {
                            errors.current_product = 'Required';
                        }

                        if (!values.new_product_id) {
                            errors.new_product_id = 'Required';
                        }

                        if (!values.first_meeting_date) {
                            errors.first_meeting_date = 'Required';
                        }

                        if (!values.meetings_left) {
                            errors.meetings_left = 'Required';
                        }

                        return errors;

                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        let personId = props.params.id;

                        let postData = {
                            purchase_id: data?.purchase?.purchase_id,
                            old_product_id: data?.purchase?.product?.product_id,
                            new_product_id: values.new_product_id,
                            first_meeting_date: values.first_meeting_date,
                            meetings_left: values.meetings_left,
                            comments: values.comments
                        };

                        postChangePersonGroup(personId, postData).then((res) => {
                            if (res) {
                                toast.success("Change member group request has been initiated");
                            }

                            setSubmitting(false);
                            navigate(`/contact/id/${personId}`);
                        }).catch((error) => {
                            setSubmitting(false);
                            toast.error(error.message);
                        });
                    }}>
                    {({isSubmitting, values, setFieldValue, setFieldTouched, errors, submitCount}) => (
                        <Form>
                            <Grid container sx={{padding: "20px", textAlign: "left"}} spacing={3}>
                                <Grid item xs={5}>
                                    <Card style={{padding: "12px", marginBottom: "12px"}}>
                                        <Typography variant='h5' component='div'>
                                            Change Member Group
                                        </Typography>
                                        <Grid container sx={{padding: "5px"}}>
                                            <Grid item xs={12}>
                                                <Grid container sx={{padding: "20px"}} spacing={1}>
                                                    <Field
                                                        name='person_title'
                                                        type='text'
                                                        label='Member*'
                                                        variant='outlined'
                                                        margin='normal'
                                                        fullWidth
                                                        component={TextField}
                                                        size={"small"}
                                                        style={{marginTop: "0px"}}
                                                        disabled='true'
                                                    />
                                                </Grid>
                                                <Grid container sx={{padding: "20px"}} spacing={1}>
                                                    <Field
                                                        name='current_product'
                                                        type='text'
                                                        label='Current Group*'
                                                        variant='outlined'
                                                        margin='normal'
                                                        fullWidth
                                                        component={TextField}
                                                        size={"small"}
                                                        style={{marginTop: "0px"}}
                                                        disabled='true'
                                                    />
                                                </Grid>
                                                <Grid container sx={{padding: "20px"}} spacing={1}>
                                                    <Autocomplete
                                                        name='new_product_id'
                                                        label='New Group*'
                                                        disablePortal
                                                        size='small'
                                                        id='new_group'
                                                        options={newProducts}
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(_event, newValue) => {
                                                            const productId = newValue ? newValue.product_id : "";

                                                            if(productId)
                                                            {
                                                                getEventsToProduct(newValue.product_id).then(res => {
                                                                    let _newProductUpcomingEvents = [];
                                                                    let nextMeetingDate = null;

                                                                    res.some((event) => {
                                                                        if (checkEventAfterCoolOffPeriod(event)
                                                                        ) {
                                                                            nextMeetingDate = event;
                                                                            return true;
                                                                        }

                                                                        return false;
                                                                    });

                                                                    if (res.length > 0 && nextMeetingDate) {
                                                                        setFieldValue("first_meeting_date", nextMeetingDate.event_start);
                                                                    } else {
                                                                        setFieldValue("first_meeting_date", null);
                                                                    }
                                                                
                                                                    res.some((event) => {
                                                                        if (checkEventAfterCoolOffPeriod(event)) {
                                                                            _newProductUpcomingEvents.push(event);
                                                                        }
                                                                        return false;
                                                                    });
                                                                    setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                                                });
                                                            }

                                                            setFieldValue("new_product_id", productId);
                                                            setFieldValue("new_product_name", newValue.product_name);


                                                        }}
                                                        getOptionLabel={(option) => option.product_name}
                                                        onBlur={() => {
                                                            setFieldTouched("new_product_id", true);
                                                        }}
                                                        renderInput={(params) => (
                                                            <MaterialTextField {...params} label='New Group*'
                                                                               error={submitCount > 0 && errors.new_product_id}/>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid container sx={{padding: "20px"}} spacing={1}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DateTimePicker
                                                            name='first_meeting_date'
                                                            label='First Meeting Date*'
                                                            inputFormat='dd/MM/yyyy H:mm:ss'
                                                            value={values.first_meeting_date} // Set the initial value
                                                            onChange={(newValue) => {
                                                                setFieldValue("first_meeting_date", newValue);
                                                            }}
                                                            renderInput={(startProps) => (
                                                                <MaterialTextField
                                                                    {...startProps}
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    padding={{
                                                                        top: "1px"
                                                                    }}
                                                                    error={submitCount > 0 && !!errors.first_meeting_date}
                                                                />
                                                            )}
                                                            disabled={true}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.75rem",
                                                                margin: "4px 14px 0px",
                                                            }}></div>
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid container sx={{padding: "20px"}} spacing={1}>
                                                    <Field
                                                        name='meetings_left'
                                                        type='number'
                                                        label='Number of Events Left*'
                                                        variant='outlined'
                                                        margin='normal'
                                                        fullWidth
                                                        component={TextField}
                                                        size={"small"}
                                                        style={{marginTop: "0px"}}
                                                        InputProps={{
                                                            inputProps: {min: 1, max: 5}
                                                        }}
                                                        onInput={(event) => {
                                                            const {value} = event.target;

                                                            event.target.value = value.replace(/[^0-9]/g, ""); // Remove any character that is not a digit
                                                        }}
                                                        onKeyDown={(event) => {
                                                            const {key, target} = event;
                                                            const {value} = target;

                                                            if (key !== "Backspace") {
                                                                // Prevent entering values greater than 100
                                                                if (parseFloat(value + key) > 5) {
                                                                    event.preventDefault();
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <Card style={{padding: "12px", marginBottom: "12px"}}>
                                        <Typography variant='h6' component='div'>
                                            Comments
                                        </Typography>
                                        <Grid container sx={{padding: "5px"}}>
                                            <Grid item xs={12}>
                                                <Grid container sx={{padding: "20px"}} spacing={1}>
                                                    <Field
                                                        component='textarea'
                                                        fullWidth
                                                        rows='5'
                                                        size='large'
                                                        name='comments'
                                                        style={{
                                                            width: "100%",
                                                            padding: "10px",
                                                            fontSize: "16px",
                                                            borderColor: "#c4c4c4",
                                                            borderRadius: "5px",
                                                        }}
                                                        placeholder='Comments'
                                                    />
                                                </Grid>
                                                <div style={{display: "flex", justifyContent: "right"}}>
                                                    <Button variant='contained' color='error' disabled={isSubmitting}
                                                            onClick={() => handleCancelClick()}>
                                                        Cancel
                                                    </Button>
                                                    <LoadingButton
                                                        type='submit'
                                                        variant='contained'
                                                        color='primary'
                                                        style={{marginRight: "12px", marginLeft: "12px"}}
                                                        loading={isSubmitting}>
                                                        Save
                                                    </LoadingButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Card style={{padding: "12px"}}>
                                                <Typography variant='h6' component='h6'>
                                                    Purchase {values?.purchase?.purchase_id}
                                                </Typography>
                                                <Grid container spacing={3} sx={{'marginTop': '15px'}}>
                                                    <Grid item xs={6}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DateTimePicker
                                                                label="First event"
                                                                inputFormat='dd/MM/yyyy H:mm:ss'
                                                                value={values?.purchase?.first_event_date} // Set the initial value
                                                                onChange={() => {
                                                                }}
                                                                renderInput={(startProps) => (
                                                                    <MaterialTextField
                                                                        {...startProps}
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        padding={{
                                                                            top: "1px"
                                                                        }}
                                                                    />
                                                                )}
                                                                disabled={true}
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "0.75rem",
                                                                    margin: "4px 14px 0px",
                                                                }}></div>
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DateTimePicker
                                                                label="Last event"
                                                                inputFormat='dd/MM/yyyy H:mm:ss'
                                                                value={values?.purchase?.last_event_date} // Set the initial value
                                                                onChange={() => {
                                                                }}
                                                                renderInput={(startProps) => (
                                                                    <MaterialTextField
                                                                        {...startProps}
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        padding={{
                                                                            top: "1px"
                                                                        }}
                                                                    />
                                                                )}
                                                                disabled={true}
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "0.75rem",
                                                                    margin: "4px 14px 0px",
                                                                }}></div>
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant='h6' component='h6'>
                                                            Events ({data?.purchase?.product.product_name})
                                                        </Typography>
                                                        {
                                                            values?.purchase?.special_case &&
                                                            <Alert variant="outlined" severity="error"
                                                                   style={{
                                                                       border: 'none',
                                                                       padding: '0px 10px',
                                                                       fontSize: '12px'
                                                                   }}>
                                                                This purchase has
                                                                a {values.purchase.special_case.status} &nbsp;
                                                                {values.purchase.special_case.type} case.
                                                            </Alert>
                                                        }
                                                        <EventGrid events={values?.purchase?.upcoming_events} />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>

                                        {
                                            newProductUpcomingEvents?.length > 0 &&
                                            <Grid item xs={12}>
                                                <Card style={{padding: "12px"}}>
                                                    <Typography variant='h6' component='h6'>
                                                        Upcoming events ({formRef.current.values.new_product_name})
                                                    </Typography>
                                                    <EventGrid events={newProductUpcomingEvents} selectable={true} selectableEventCount={values.meetings_left} getFirstEventDate={getFirstEventDate}/>
                                                </Card>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Grid container sx={{padding: "15px"}} spacing={1}>
                    <Grid item xs={6}></Grid>
                </Grid>
            </div>
        </>
    );
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <ChangePersonGroupRequestForm {...props} params={params} navigate={navigate}/>;
}

export default (WithNavigate);
