import React, {useEffect} from "react";
import {Autocomplete, CardMedia, Grid, TextField} from "@mui/material";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {
    selectors as opportunitySelectors,
    actions as opportunityActions,
} from "../../../../Ducks/opportunity";
import {bindActionCreators} from "redux";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button, Radio} from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LanguageIcon from '@mui/icons-material/Language';
import {LinkedIn, Email, PhoneAndroid} from '@mui/icons-material';
import {fetchGet} from "../../../../API/util";
import {useParams} from "react-router-dom";
import editIcon from '../../../../Assets/images/editIcon.png'
import moment from "moment";
import Swal from "sweetalert2";
import ParticipantJobs from "../../../Common/Overview/ParticipantJobs";
import {LoadingButton} from "@mui/lab";

const useStyles = (_theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    card: {
        display: "flex",
        backgroundColor: "#fff",
    },
    cardMedia: {
        flex: 1,
        width: "85px",
        backgroundColor: "#fff",
    },
    cardContent: {
        flex: 3,
    },
    flexVertical: {
        display: "flex",
        alignItems: "center",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputField: {
        flex: 1
    },
    fieldSet: {
        display: 'flex',
        alignItems: 'end'
    },
    accordionSummary: {
        '& > div': {
            flexDirection: 'column'
        }
    }
});

const Jobs = (props) => {
    const {
        classes,
        jobs,
        opportunity,
        isLoading,
        postNewJob,
        updateExistingJob,
        updateContact,
        updateExistingJob_loading
    } = props;
    const [personObj, setPersonObj] = React.useState([]);
    const [personId, setPersonId] = React.useState("");
    const [showJob, setShowJob] = React.useState(false);
    const [showAddJob, setShowAddJob] = React.useState(false);
    const [newJob, setNewJob] = React.useState({});
    const [jobPrimary, setJobPrimary] = React.useState(0);
    const [alljobs, setAllJobs] = React.useState([]);
    const [payload, setPayload] = React.useState([]);
    const [companies, setCompanies] = React.useState([]);
    const [mobile, setMobile] = React.useState('');
    const [mobileChanged, setMobileChanged] = React.useState(false);
    const [company, setCompany] = React.useState("")
    useEffect(() => {
        if (opportunity !== undefined) {
            let personID = opportunity.related_objects.jobs[opportunity.main.job_id].person_id;
            let obj = opportunity.related_objects.persons[personID];
            setPersonId(personID);
            props.getJobs(personID);
            setPersonObj(obj);
            setMobile(obj.mobile === null ? "" : obj.mobile);
        }

    }, [opportunity]);

    useEffect(() => {
        if (jobs) {
            const dateSortedJobs = jobs.main.sort((a, b) => new Date(b.job_start) - new Date(a.job_start));
            setAllJobs(dateSortedJobs.sort((a, b) => parseInt(b.job_is_primary) - parseInt(a.job_is_primary)));
        }
    }, [jobs]);

    const showProgress = () => (
        <div style={{display: "flex", justifyContent: "center"}}>
            <CircularProgress/>
        </div>
    );

    if (!jobs || isLoading) {
        return showProgress();
    }

    const getYears = (date, end_date) => {
        let d = new Date(date);
        let start = d.getFullYear();

        if (end_date === null) {
            let c = new Date();
            let current = c.getFullYear();
            return Math.ceil(parseInt(current) - parseInt(start));
        } else {
            let e = new Date(end_date);
            let end = e.getFullYear();
            return Math.ceil(parseInt(end) - parseInt(start));
        }
    };

    const getCompanyName = (companyID) => {
        return jobs.related_objects.companies[companyID].company_name;
    };

    // show all jobs in inputfield
    const showJobEdit = () => {
        setShowJob(true);
    };
    // add job
    const addNewRecord = () => {
        setShowAddJob(true);
    };
    //   save job
    const saveJob = () => {
        if (mobileChanged) {
            let contactData = {
                id: personId,
                obj: {person_mobile_number: mobile},
                key: 'person_mobile_number',
                opportunityId: props.params.id
            }
            updateContact(contactData)
            setShowJob(false);
        }

        if (Object.entries(newJob).length > 0 && newJob.division_id && newJob.company_id) {
            let jobCheck = newJob;
            jobCheck["job_is_primary"] = jobPrimary;
            newJob.job_is_primary = "";
            newJob.country_id = 1;
            let data = {
                personId,
                newJob,
                opportunityId: props.params.id
            }
            postNewJob(data);
            setPayload([]);
            setNewJob({...newJob, job_phone: '', job_email: '', job_title: ''});
        }
        if (Object.entries(newJob).length > 0 && !newJob.division_id && !newJob.company_id && company) {
            let data = {
                newJob: {
                    company_name: company,
                    job_is_primary: "",
                    job_email: newJob.job_email,
                    job_phone: newJob.job_phone,
                    job_title: newJob.job_title,
                    country_id: 1,
                },
                personId,
                opportunityId: props.params.id
            }
            postNewJob(data);
            setPayload([]);
            setNewJob({...newJob, job_phone: '', job_email: '', job_title: ''});
            setCompany("")
        }

        if (payload.length > 0) {
            // check for change in primary job email
            const _primary_job = payload.filter((item) => item.job_is_primary === 1);
            if (_primary_job.length) {
                if (personObj.email !== _primary_job[0].job_email) {
                    Swal.fire({
                        title: 'Confirm',
                        input: 'checkbox',
                        inputValue: 1,
                        inputPlaceholder: 'Permission to send email to person?',
                        confirmButtonText: 'Continue'

                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        // update person
                        if (result.value) {
                            const data = {
                                id: personId,
                                obj: {concent_to_send_email: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")},
                                key: 'concent_to_send_email',
                                opportunityId: props.params.id,
                                noSideEffect: true
                            }
                            updateContact(data)
                        } else {
                            const data = {
                                id: personId,
                                obj: {concent_to_send_email: null},
                                key: 'concent_to_send_email',
                                opportunityId: props.params.id,
                                noSideEffect: true
                            }
                            updateContact(data)
                        }

                        const jobsArray = payload.map(({
                                                           override_company_name,
                                                           override_job_title,
                                                           job_title,
                                                           job_phone,
                                                           job_email,
                                                           job_is_primary,
                                                           job_id
                                                       }) => ({
                            override_company_name,
                            override_job_title,
                            job_title,
                            job_phone,
                            job_email,
                            job_is_primary,
                            job_id
                        }));

                        const data = {
                            personId,
                            jobs: jobsArray,
                            opportunityId: props.params.id
                        }
                        updateExistingJob({data: data, setShowJob});
                        setPayload([]);
                        setNewJob([]);
                    })
                } else {
                    const jobsArray = payload.map(({
                                                       override_company_name,
                                                       override_job_title,
                                                       job_title,
                                                       job_phone,
                                                       job_email,
                                                       job_is_primary,
                                                       job_id
                                                   }) => ({
                        override_company_name,
                        override_job_title,
                        job_title,
                        job_phone,
                        job_email,
                        job_is_primary,
                        job_id
                    }));

                    const data = {
                        personId,
                        jobs: jobsArray,
                        opportunityId: props.params.id
                    }
                    updateExistingJob({data: data, setShowJob});
                    setPayload([]);
                    setNewJob([]);
                }
            } else {
                const jobsArray = payload.map(({
                                                   override_company_name,
                                                   override_job_title,
                                                   job_title,
                                                   job_phone,
                                                   job_email,
                                                   job_is_primary,
                                                   job_id
                                               }) => ({
                    override_company_name,
                    override_job_title,
                    job_title,
                    job_phone,
                    job_email,
                    job_is_primary,
                    job_id
                }));

                const data = {
                    personId,
                    jobs: jobsArray,
                    opportunityId: props.params.id
                }
                updateExistingJob({data: data, setShowJob});
                setPayload([]);
                setNewJob([]);
            }
        }
    };

    //   onChange input field
    const handleChange = (e, index, name) => {
        let jb = [...alljobs];
        if (name === "job_is_primary") {
            for (let a = 0; a < jb.length; a++) {
                jb[a][name] = 0;
                setAllJobs(jb);
            }
            jb[index][name] = e;
            setAllJobs(jb);
            if (payload.length === 0) {
                setPayload(jb);
                return false;
            }
        } else {
            jb[index][name] = e.target.value;
            setAllJobs(jb);
            if (payload.length === 0) {
                setPayload([jb[index]]);
                return false;
            }
            const indexNumber = payload.findIndex(
                (item) => item.division_id === jb[index].division_id
            );
            const pl = [...payload];
            if (indexNumber > -1) {
                pl[indexNumber] = jb[index];
            } else {
                pl.push(jb[index]);
                setPayload(pl);
            }
        }
    };
    const getCompanies = (e) => {
        if (e.target.value.length >= 3) {
            fetchGet(`/companies/search?contains[company_name]=${e.target.value}&equals[country_id]=1`).then(({data}) => {
                let companiesArray = data.main.map((item) => {
                    item.label = item.company_name;
                    item.key = item.company_id
                    item.id = item.company_id
                    return item;
                });
                setCompanies(companiesArray)
            }).catch(() => {
                // do nothing
            })
        }
    }
    return (
        <>
            <Grid item xs={12} sx={{marginBottom: "10px"}}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        component="img"
                        alt="person image"
                        image={`https://erp.f5.dk/uploads/scraped_source_images/${personObj.person_token}.jpg`}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" component="div" sx={{marginBottom: '10px'}}>
                            {personObj.person_firstname} {personObj.person_lastname}
                        </Typography>
                        <Typography variant="body2" color="site.main" sx={{display: 'flex', marginBottom: '5px'}}>
                            <BusinessCenterIcon sx={{color: 'icon.main', fontSize: "20px"}}/>
                            <span style={{marginLeft: '8px'}}>{personObj.title} at {personObj.company_name}</span>
                        </Typography>
                        <Typography variant="body2" color="site.main" sx={{display: 'flex', marginBottom: '5px'}}>
                            <LanguageIcon sx={{color: 'icon.main', fontSize: "20px"}}/>
                            <span style={{marginLeft: '8px'}}>{personObj.person_region}</span>
                        </Typography>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className={classes.flexCenter} style={{flex: 3}}>
                                {
                                    personObj && personObj.mobile && !showJob &&
                                    <Typography variant="body2" color="site.main">
                                        <a href={`callto:${personObj.mobile}`} onClick={props.showActiveTab} style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <PhoneAndroid sx={{color: 'icon.main', fontSize: "20px"}}/>
                                            <span style={{marginLeft: '8px'}}>
                                                {personObj.mobile}
                                            </span>
                                        </a>
                                    </Typography>
                                }
                                {
                                    showJob &&
                                    <TextField
                                        type="number"
                                        id="mobile"
                                        label="Mobile"
                                        variant="outlined"
                                        size="small"
                                        value={mobile}
                                        onChange={(e) => {
                                            if (e.target.value === mobile) {
                                                setMobileChanged(false);
                                            } else {
                                                setMobileChanged(true);
                                            }
                                            setMobile(e.target.value);
                                        }}
                                    ></TextField>
                                }
                                {
                                    personObj.email &&
                                    <Typography variant="body2" color="site.link">
                                        <a href={`mailto:${personObj.email}`} style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Email sx={{color: 'icon.main', fontSize: "20px"}}/>
                                            <span style={{marginLeft: '8px'}}>
                                                {personObj.email}
                                            </span>
                                        </a>
                                    </Typography>
                                }
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', flex: 1}}>
                                <Typography variant="body2" color="site.main">
                                    <a target="_blank" href={personObj.person_linkedin_url}><LinkedIn
                                        sx={{color: 'icon.linkedin', fontSize: "44px"}}/></a>
                                </Typography>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid container spacing={2} style={{marginBottom: "10px"}}>
                <Grid item xs={12}>
                    <Typography className={classes.title} gutterBottom variant="h6" component="h6">
                        Jobs
                        {showJob === true ? (
                            <>
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    style={{marginLeft: "5px"}}
                                    onClick={saveJob}
                                    loading={updateExistingJob_loading}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    disabled={updateExistingJob_loading}
                                    style={{marginLeft: "5px"}}
                                    onClick={() => {
                                        setShowJob(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            opportunity.main.self_opportunity && (
                                <img src={editIcon} style={{width: '18px', cursor: 'pointer', marginLeft: '5px'}}
                                     onClick={showJobEdit}/>
                            )
                        )}
                    </Typography>
                </Grid>
            </Grid>

            {showJob === true ? (
                <>
                    <Grid item xs={12} style={{marginTop: "15px"}}>
                        {showAddJob === true ? (
                            <>
                                <Card
                                    className={classes.cardItem}
                                    style={{paddingBottom: "10px"}}
                                >
                                    <div style={{marginBottom: '20px'}} className={classes.fieldSet}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            sx={{minWidth: '170px', marginLeft: "5px"}}
                                            options={companies}
                                            value={company}
                                            onChange={(_event, value) => {
                                                if (value) {
                                                    const job = {...newJob};
                                                    job["division_id"] = value.divisions[0].division_id;
                                                    job["company_id"] = value.divisions[0].company_id;
                                                    setNewJob(job);
                                                }
                                            }}
                                            onKeyUp={(event, value) => {
                                                if (value !== null && value !== "") {
                                                    setCompany(event.target.value);
                                                } else {
                                                    setCompany("")
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} size="small"
                                                                                onChange={getCompanies}
                                                                                label="Company..."/>}
                                        />
                                        <TextField
                                            type="text"
                                            id="job_title"
                                            label="Job Title"
                                            variant="outlined"
                                            size="small"
                                            style={{marginTop: "10px", marginLeft: "5px"}}
                                            value={newJob?.job_title}
                                            onChange={(e) => {
                                                const job = {...newJob};
                                                job["job_title"] = e.target.value;
                                                setNewJob(job);
                                            }}
                                        ></TextField>
                                        <TextField
                                            type="number"
                                            id="phone"
                                            label="Phone"
                                            variant="outlined"
                                            size="small"
                                            style={{marginTop: "10px", marginLeft: "5px"}}
                                            value={newJob?.job_phone}
                                            onChange={(e) => {
                                                const job = {...newJob};
                                                job["job_phone"] = e.target.value;
                                                setNewJob(job);
                                            }}
                                        ></TextField>
                                        <TextField
                                            type="email"
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            style={{marginTop: "10px", marginLeft: "5px"}}
                                            value={newJob?.job_email}
                                            onChange={(e) => {
                                                const job = {...newJob};
                                                job["job_email"] = e.target.value;
                                                setNewJob(job);
                                            }}
                                        ></TextField>
                                        <Radio
                                            size="small"
                                            style={{float: "right"}}
                                            checked={true}
                                            disabled={true}
                                        />


                                    </div>

                                    <Button
                                        variant="contained"
                                        color="error"
                                        style={{marginTop: "5px", marginLeft: "30%"}}
                                        onClick={() => {
                                            setShowAddJob(false);
                                            setJobPrimary(0);
                                        }}
                                    >
                                        Cancel job creation
                                    </Button>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Card
                                    className={classes.cardItem}
                                    style={{paddingBottom: "10px"}}
                                >
                                    <Box
                                        sx={{
                                            "& > :not(style)": {
                                                m: 2,
                                            },
                                        }}
                                    >
                                        <AddCircleOutlineRoundedIcon
                                            style={{marginTop: "5%", marginLeft: "50%"}}
                                            onClick={addNewRecord}
                                        />
                                    </Box>
                                </Card>
                            </>
                        )}
                    </Grid>
                    {alljobs.map((job, index) => {
                        return (
                            <Grid item xs={12} style={{marginTop: "15px"}}>
                                <Card
                                    className={classes.fieldSet}
                                    style={{paddingBottom: "10px"}}
                                >
                                    <TextField
                                        className={classes.inputField}
                                        type="text"
                                        id="override-company"
                                        label="Override Company"
                                        variant="outlined"
                                        size="small"
                                        style={{marginTop: "10px", marginLeft: "5px"}}
                                        value={job.override_company_name}
                                        onChange={(e) => {
                                            handleChange(
                                                e,
                                                index,
                                                "override_company_name",
                                                job.division_id
                                            );
                                        }}
                                    ></TextField>
                                    <TextField
                                        className={classes.inputField}
                                        type="text"
                                        id="override-title"
                                        label="Override Title"
                                        variant="outlined"
                                        size="small"
                                        style={{marginTop: "10px", marginLeft: "5px"}}
                                        value={job.override_job_title}
                                        onChange={(e) => {
                                            handleChange(e, index, "override_job_title");
                                        }}
                                    ></TextField>
                                    <TextField
                                        className={classes.inputField}
                                        id="job-title"
                                        label="Job Title"
                                        variant="outlined"
                                        size="small"
                                        style={{marginTop: "10px", marginLeft: "5px"}}
                                        value={job.job_title}
                                        onChange={(e) => {
                                            handleChange(e, index, "job_title");
                                        }}
                                    ></TextField>
                                    <TextField
                                        className={classes.inputField}
                                        id="phone"
                                        label="Phone"
                                        variant="outlined"
                                        size="small"
                                        style={{marginTop: "10px", marginLeft: "5px"}}
                                        value={job.job_phone}
                                        onChange={(e) => {
                                            handleChange(e, index, "job_phone");
                                        }}
                                    ></TextField>
                                    <TextField
                                        className={classes.inputField}
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        size="small"
                                        style={{marginTop: "10px", marginLeft: "5px"}}
                                        value={job.job_email}
                                        onChange={(e) => {
                                            handleChange(e, index, "job_email");
                                        }}
                                    ></TextField>
                                    <Radio
                                        checked={job.job_is_primary === 1 ? true : false}
                                        size="small"
                                        name="job_is_primary"
                                        value={job.job_is_primary}
                                        style={{float: "right"}}
                                        onChange={(_e) => {
                                            handleChange(1, index, "job_is_primary");
                                        }}
                                    />
                                </Card>
                            </Grid>
                        );
                    })}
                </>
            ) : (
                <>
                    <ParticipantJobs
                        classes={classes}
                        jobs={jobs}
                        personObj={personObj}
                        getCompanyName={getCompanyName}
                        getYears={getYears}
                    />
                </>
            )
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.getJobs_loading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    jobs: opportunitySelectors.jobs(state),
    opportunity: opportunitySelectors.opportunity(state),
    searchedCompanies: opportunitySelectors.searchedCompanies(state),
    updateExistingJob_loading: opportunitySelectors.updateExistingJobLoading(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getJobs: opportunityActions.getJobs,
            postNewJob: opportunityActions.postNewJob,
            updateExistingJob: opportunityActions.updateExistingJob,
            searchCompanies: opportunityActions.searchCompanies,
            updateContact: opportunityActions.updateContact
        },
        dispatch
    );

function WithNavigate(props) {
    let params = useParams();
    return <Jobs {...props} params={params}/>
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(WithNavigate));
