import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
    asyncs: {
        getEventsOverview: (data) => data,
        updateAttendanceStatus: (data) => data,
        updateSendoutEmail: (data) => data,
        getPersonPurchases: (data) => data,
        getPersonOpportunities: (data) => data,
        getPersonOpenOpportunity: (data) => data,
        getPersonHistory: (data) => data,
        getParticipantDetails: (data) => data,
        getNoteTypes: (data) => data,
        getEventDetails: (data) => data,
        addHistoryLog: (data) => data,
        updateNotes: (data) => data,
        updateEmail: (data) => data,
        getNetworkDirectors: (data) => data,
        getEventsOverviewUsers: (data) => data,
        deleteAttachedFile: (data) => data,
        updatePersonStage: (data) => data,
        addPlannedAction: (data) => data,
        editPlannedAction: (data) => data,
        pauseCase: (data) => data,
        registerOnGoingCase: (data) => data,
        bookMeeting: (data) => data,
        getPlannedCallTypes: (data) => data,
        getPersonPlannedCalls: (data) => data,
        createPlannedCall: (data) => data,
        updatePlannedCall: (data) => data,
        getPlannedCall: (data) => data,
        getSpecialCaseDetails: (data) => data,
        getBookedMeetingById: (data) => data,
        updateBookedMeeting: (data) => data,
        getPersonMeetings: (data) => data,
        getCasesOverview: (data) => data,
        getResaleOverview: (data) => data,
        resolveCase: (data) => data,
        getPendingCases: (data) => data,
        getCaseUsersList: (data) => data,
        getEventMembers: (data) => data,
        caseDecline: (data) => data,
        getCasesEventsOverview: (data) => data,
        updateAssignee: (data) => data,
        getPendingCaseDetails: (data) => data,
    }
}, 'eventsOverview');

let initialState = asyncInitialState({
    eventsOverview: null,
    personPurchases: null,
    personOpportunities: null,
    personOpenOpportunity: null,
    personHistory: null,
    participantDetails: null,
    noteTypes: null,
    eventDetails: null,
    networkDirectors: null,
    eventsOverviewUsers: null,
    casesEventsOverview: [],
    plannedCallTypes: null,
    personPlannedCalls: null,
    plannedCall: null,
    pausedCase: null,
    onGoingCase: null,
    specialCaseDetails: [],
    bookedMeetingById: null,
    bookedMeeting: null,
    personMeetings: null,
    casesOverview: null,
    pendingCases: null,
    caseUsersList: null,
    eventMembers: null,
    errorMessage: '',
    success: false,
    caseDecline: null,
    pendingCaseDetails: null
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.getEventsOverview:
        case types.getPersonPurchases:
        case types.getPersonOpportunities:
        case types.getPersonOpenOpportunity:
        case types.getPersonHistory:
        case types.getNoteTypes:
        case types.getParticipantDetails:
        case types.getEventDetails:
        case types.updateAttendanceStatus:
        case types.updateSendoutEmail:
        case types.addHistoryLog:
        case types.updateNotes:
        case types.updateEmail:
        case types.getNetworkDirectors:
        case types.getEventsOverviewUsers:
        case types.deleteAttachedFile:
        case types.updatePersonStage:
        case types.addPlannedAction:
        case types.editPlannedAction:
        case types.pauseCase:
        case types.registerOnGoingCase:
        case types.bookMeeting:
        case types.getPlannedCallTypes:
        case types.getPersonPlannedCalls:
        case types.createPlannedCall:
        case types.updatePlannedCall:
        case types.getPlannedCall:
        case types.getSpecialCaseDetails:
        case types.getBookedMeetingById:
        case types.updateBookedMeeting:
        case types.getPersonMeetings:
        case types.getCasesOverview:
        case types.resolveCase:
        case types.getResaleOverview:
        case types.getPendingCases:
        case types.getEventMembers:
        case types.caseDecline:
        case types.updateAssignee:
        case types.getPendingCaseDetails:
            return { ...asyncOnRequest(state, action), data: { ...state.data, eventMembers: null } }
        case types.getCaseUsersList:
            return asyncOnRequest(state, action);
        case types.saga.getEventsOverview.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    eventsOverview: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.updateAttendanceStatus.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    attendanceStatus: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.updateSendoutEmail.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    sendoutEmail: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getPersonPurchases.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    personPurchases: action.payload.rows,
                    errorMessage: ''
                };
            })
        case types.saga.getPersonOpportunities.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    personOpportunities: action.payload.rows,
                    errorMessage: ''
                };
            })
        case types.saga.getPersonOpenOpportunity.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    personOpenOpportunity: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getPersonHistory.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    personHistory: action.payload.rows,
                    errorMessage: ''
                };
            })
        case types.saga.getNoteTypes.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    noteTypes: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.addHistoryLog.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    historyLog: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getParticipantDetails.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    participantDetails: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getEventDetails.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    eventDetails: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.updateNotes.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedNote: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.updateEmail.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedEmail: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getNetworkDirectors.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    networkDirectors: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getEventsOverviewUsers.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    eventsOverviewUsers: action.payload.data.main,
                    errorMessage: ''
                };
            })
        case types.saga.deleteAttachedFile.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    deletedAttachedFile: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.updatePersonStage.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedPersonStage: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.addPlannedAction.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    addedPlannedAction: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.editPlannedAction.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    editedPlannedAction: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.pauseCase.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    pausedCase: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.registerOnGoingCase.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    onGoingCase: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.bookMeeting.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    bookedMeeting: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getPlannedCallTypes.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    plannedCallTypes: action.payload.data.main,
                    errorMessage: ''
                };
            })
        case types.saga.getPersonPlannedCalls.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    personPlannedCalls: action.payload.data.main,
                    errorMessage: ''
                };
            })
        case types.saga.createPlannedCall.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    plannedCallRes: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.updatePlannedCall.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedPlannedCall: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getPlannedCall.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    plannedCall: action.payload.data.main,
                    errorMessage: ''
                };
            })
        case types.saga.getSpecialCaseDetails.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    specialCaseDetails: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getBookedMeetingById.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    bookedMeetingById: action.payload.data.main[0],
                    errorMessage: ''
                };
            })
        case types.saga.updateBookedMeeting.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedBookedMeeting: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getPersonMeetings.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    personMeetings: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getCasesOverview.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    casesOverview: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.resolveCase.success:
            return asyncOnSuccess(state, action, (data, action) => {
                const { case_id } = action.payload.message;
                const updatedCasesOverview = state.data.casesOverview.filter(caseItem => caseItem.case_id !== case_id);
                return {
                    ...data,
                    resolvedCase: action.payload,
                    casesOverview: updatedCasesOverview,
                    errorMessage: ''
                };
            })
        case types.saga.getResaleOverview.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    resaleOverview: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getPendingCases.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    pendingCases: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getCaseUsersList.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    caseUsersList: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getEventMembers.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    eventMembers: action.payload.data,
                    errorMessage: ''
                };
            })
        case types.saga.caseDecline.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    caseDecline: action.payload,
                    errorMessage: ''
                }
            })
        case types.saga.getCasesEventsOverview.success:
            return asyncOnSuccess(state,action, (data, action) => {
                return {
                    ...data,
                    casesEventsOverview: action.payload.event_overview,
                    errorMessage: ''
                };
            })
        case types.saga.updateAssignee.success:
            return asyncOnSuccess(state,action, (data, action) => {
                return {
                    ...data,
                    errorMessage: ''
                };
            })
        case types.saga.getPendingCaseDetails.success:
            return asyncOnSuccess(state,action, (data, action) => {
                return {
                    ...data,
                    pendingCaseDetails: action.payload,
                    errorMessage: ''
                };
            })
        case types.saga.getEventsOverview.failure:
        case types.saga.getPersonPurchases.failure:
        case types.saga.getPersonOpportunities.failure:
        case types.saga.getPersonOpenOpportunity.failure:
        case types.saga.getPersonHistory.failure:
        case types.saga.getParticipantDetails.failure:
        case types.saga.getEventDetails.failure:
        case types.saga.getNoteTypes.failure:
        case types.saga.updateAttendanceStatus.failure:
        case types.saga.updateSendoutEmail.failure:
        case types.saga.addHistoryLog.failure:
        case types.saga.updateNotes.failure:
        case types.saga.updateEmail.failure:
        case types.saga.getNetworkDirectors.failure:
        case types.saga.getEventsOverviewUsers.failure:
        case types.saga.deleteAttachedFile.failure:
        case types.saga.updatePersonStage.failure:
        case types.saga.addPlannedAction.failure:
        case types.saga.editPlannedAction.failure:
        case types.saga.pauseCase.failure:
        case types.saga.registerOnGoingCase.failure:
        case types.saga.bookMeeting.failure:
        case types.saga.getPlannedCallTypes.failure:
        case types.saga.getPersonPlannedCalls.failure:
        case types.saga.createPlannedCall.failure:
        case types.saga.updatePlannedCall.failure:
        case types.saga.getPlannedCall.failure:
        case types.saga.getSpecialCaseDetails.failure:
        case types.saga.getBookedMeetingById.failure:
        case types.saga.updateBookedMeeting.failure:
        case types.saga.getPersonMeetings.failure:
        case types.saga.getCasesOverview.failure:
        case types.saga.resolveCase.failure:
        case types.saga.getResaleOverview.failure:
        case types.saga.getPendingCases.failure:
        case types.saga.getCaseUsersList.failure:
        case types.saga.getEventMembers.failure:
        case types.saga.caseDecline.failure:
        case types.saga.getCasesEventsOverview.failure:
        case types.saga.updateAssignee:
        case types.saga.getPendingCaseDetails:
            return asyncOnError(state, action)
        default:
            return state
    }
}

const asyncSelector = asyncSelectors(
    (state) => state.eventsOverview,
    {
        errorMessage: (data) => data.errorMessage,
        getEventsOverview_loading: (data) => data.getEventsOverview_loading,
        updateAttendanceStatus_loading: (data) => data.updateAttendanceStatus_loading,
        updateSendoutEmail_loading: (data) => data.updateSendoutEmail_loading,
        getPersonPurchases_loading: (data) => data.getPersonPurchases_loading,
        getPersonOpportunities_loading: (data) => data.getPersonOpportunities_loading,
        getPersonOpenOpportunity_loading: (data) => data.getPersonOpenOpportunity_loading,
        getParticipantDetails_loading: (data) => data.getParticipantDetails_loading,
        getEventDetails_loading: (data) => data.getEventDetails_loading,
        getPersonHistory_loading: (data) => data.getPersonHistory_loading,
        getNoteTypes_loading: (data) => data.getNoteTypes_loading,
        addHistoryLog_loading: (data) => data.addHistoryLog_loading,
        updateNotes_loading: (data) => data.updateNotes_loading,
        updateEmail_loading: (data) => data.updateEmail_loading,
        getNetworkDirectors_loading: (data) => data.getNetworkDirectors_loading,
        getEventsOverviewUsers_loading: (data) => data.getEventsOverviewUsers_loading,
        deleteAttachedFile_loading: (data) => data.deleteAttachedFile_loading,
        updatePersonStage_loading: (data) => data.updatePersonStage_loading,
        addPlannedAction_loading: (data) => data.addPlannedAction_loading,
        editPlannedAction_loading: (data) => data.editPlannedAction_loading,
        pauseCase_loading: (data) => data.pauseCase_loading,
        registerOnGoingCase_loading: (data) => data.registerOnGoingCase_loading,
        bookMeeting_loading: (data) => data.bookMeeting_loading,
        getPlannedCallTypes_loading: (data) => data.getPlannedCallTypes_loading,
        getPersonPlannedCalls_loading: (data) => data.getPersonPlannedCalls_loading,
        createPlannedCall_loading: (data) => data.createPlannedCall_loading,
        updatePlannedCall_loading: (data) => data.updatePlannedCall_loading,
        getPlannedCall_loading: (data) => data.getPlannedCall_loading,
        getSpecialCaseDetails_loading: (data) => data.getSpecialCaseDetails_loading,
        getBookedMeetingById_loading: (data) => data.getBookedMeetingById_loading,
        updateBookedMeeting_loading: (data) => data.updateBookedMeeting_loading,
        getPersonMeetings_loading: (data) => data.getPersonMeetings_loading,
        getCasesOverview_loading: (data) => data.getCasesOverview_loading,
        resolveCase_loading: (data) => data.resolveCase_loading,
        getResaleOverview_loading: (data) => data.getResaleOverview_loading,
        getPendingCases_loading: (data) => data.getPendingCases_loading,
        getCaseUsersList_loading: (data) => data.getCaseUsersList_loading,
        getEventMembers_loading: (data) => data.getEventMembers_loading,
    }
)

const syncSelector = {
    isLoading: (state) => state.eventsOverview.loading,
    eventsOverview: (state) => state.eventsOverview.data.eventsOverview,
    personPurchases: (state) => state.eventsOverview.data.personPurchases,
    personOpportunities: (state) => state.eventsOverview.data.personOpportunities,
    personOpenOpportunity: (state) => state.eventsOverview.data.personOpenOpportunity,
    participantDetails: (state) => state.eventsOverview.data.participantDetails,
    personHistory: (state) => state.eventsOverview.data.personHistory,
    noteTypes: (state) => state.eventsOverview.data.noteTypes,
    eventDetails: (state) => state.eventsOverview.data.eventDetails,
    networkDirectors: (state) => state.eventsOverview.data.networkDirectors,
    eventsOverviewUsers: (state) => state.eventsOverview.data.eventsOverviewUsers,
    plannedCallTypes: (state) => state.eventsOverview.data.plannedCallTypes,
    pausedCase: (state) => state.eventsOverview.data.pausedCase,
    onGoingCase: (state) => state.eventsOverview.data.onGoingCase,
    personPlannedCalls: (state) => state.eventsOverview.data.personPlannedCalls,
    plannedCall: (state) => state.eventsOverview.data.plannedCall,
    specialCaseDetails: (state) => state.eventsOverview.data.specialCaseDetails,
    bookedMeetingById: (state) => state.eventsOverview.data.bookedMeetingById,
    personMeetings: (state) => state.eventsOverview.data.personMeetings,
    bookedMeeting: (state) => state.eventsOverview.data.bookedMeeting,
    casesOverview: (state) => state.eventsOverview.data.casesOverview,
    resaleOverview: (state) => state.eventsOverview.data.resaleOverview,
    pendingCases: (state) => state.eventsOverview.data.pendingCases,
    caseUsersList: (state) => state.eventsOverview.data.caseUsersList,
    eventMembers: (state) => state.eventsOverview.data.eventMembers,
    casesEventsOverview: (state) => state.eventsOverview.data.casesEventsOverview,
    pendingCaseDetails: (state) => state.eventsOverview.data.pendingCaseDetails,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)