import { call } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

import {
  types as divisionsTypes,
  actions as divisionsActions,
} from "../Ducks/divisions";

import { callApi } from "./utils";
import * as api from "../API/companies";

function* getDivisions(action) {
  const { success, failure } = divisionsActions.saga.getDivisions;

  yield callApi(call(api.getDivisions, action.data), success, failure);
}

export default function* rootCustomerSaga() {
  yield takeLatest(divisionsTypes.getDivisions, getDivisions);
}
