import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { Edit, Phone, Save } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import pencilEditIcon from '../../../../Assets/images/pencilEditIcon.PNG'

const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff'
    // height: 200
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '-8px',
    marginBottom: '-8px',
  },
  actionBtnGroup: {
    float: "right",
    marginTop: "20px",
    display: "inlineBlock",
  },
  actionBtnStyling: {
    marginLeft: '5px',
    color: '#fff',
    textTransform: "capitalize"
  }
});

function Company(props) {
  const { classes, company } = props;
  const [companyObj, setCompanyObj] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [companyNumber, setCompanyNumber] = React.useState("")

  useEffect(() => {
    if (company) {
      const divisionID = company.related_objects.jobs[company.main.job_id].division_id;
      const division = company.related_objects.divisions[divisionID];
      // const companyPhone = company.related_objects.companies[division.company_id].company_phone // need to verify
      setCompanyObj(company.related_objects.companies[division.company_id] || {});
      setCompanyNumber(division.division_phone || "");
    }
  }, [company]);

  const handleSave = () => {
    const divisionID = company.related_objects.jobs[company.main.job_id].division_id;
    const data = { divisionID, division_phone: companyNumber, opportunityId: company.main.opportunity_id }
    props.updateDivisionPhone(data)
    setEditMode(false)
  }

  const handleShow = () => {
    setEditMode(true)
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div">
          {companyObj.company_name}
        </Typography>
        {
          companyNumber && !editMode &&
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
            <a href={`callto:${companyNumber}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: "inline-flex", alignItems: 'center' }}>
              <Phone sx={{ color: 'icon.main' }} />
              <span style={{ marginLeft: '8px' }}>
                {companyNumber}
              </span>
            </a>
            {/* <span onClick={() => { setEditMode(true) }} style={{ cursor: 'pointer' }}>Edit</span> */}
          </div>
        }

        {
          !companyNumber && !editMode && company.main.self_opportunity &&
          <Typography variant="body2" color="site.main">
            <div onClick={handleShow} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '6px' }}>
              <span>
                Add company phone number
              </span>
              <img src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer', marginLeft: '5px' }} />
            </div>
          </Typography>
        }

        {
          editMode &&
          <Box sx={{ '& > button': { m: 1 }, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
            <TextField
              id="company_phone"
              name="company_phone"
              label="Phone"
              size="small"
              onChange={(e) => {
                setCompanyNumber(e.target.value)
              }}
              value={companyNumber}
            />
            <div>
              <Button
                className={classes.actionBtnStyling}
                name="cancel"
                size="small"
                color="error"
                onClick={() => {
                  setEditMode(false)
                }}
                loadingPosition="end"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                className={classes.actionBtnStyling}
                name="save"
                size="small"
                color="success"
                onClick={handleSave}
                loadingPosition="start"
                startIcon={<Save />}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </Box>
        }
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  isLoading: opportunitySelectors.isLoading(state),
  divisionPhone: opportunitySelectors.divisionPhone(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    updateDivisionPhone: opportunityActions.updateDivisionPhone,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Company));