import { call } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

import { types as jobsTypes, actions as jobsActions } from "../Ducks/JobsList";

import { callApi } from "./utils";
import * as api from "../API/contacts";

function* getJobs(action) {
  const { success, failure } = jobsActions.saga.getJobs;

  yield callApi(call(api.getJobs, action.data), success, failure);
}

export default function* rootCustomerSaga() {
  yield takeLatest(jobsTypes.getJobs, getJobs);
}
