import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MembersList from '../Widgets/MembersList';
import OpenOpportunities from '../Widgets/OpenOpportunities';
import CompanyProfile from '../Widgets/CompanyProfile';
import Leads from '../Widgets/Leads';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = theme => ({
});

function CompanyTab(props) {
  const { classes, employees, opportunity, isLoading } = props;
  const [companyObj, setCompanyObj] = React.useState(null);

    useEffect(() => {
        if (opportunity) {
            let divisionID = opportunity.related_objects.jobs[opportunity.main.job_id].division_id;
            setCompanyObj(opportunity.related_objects.divisions && opportunity.related_objects.divisions[divisionID] ? opportunity.related_objects.divisions[divisionID] : null);
        }
    }, [opportunity]);

  useEffect(() => {
    companyObj && props.getEmployees({ id: companyObj.company_id });
    console.log('companyObj::', companyObj)
  }, [companyObj]);

  const showProgress = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )

  if(!employees || isLoading) {
    return showProgress()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CompanyProfile showActiveTab={props.showActiveTab} company={opportunity} />
      </Grid>
      <Grid item xs={12}>
        <MembersList employees={employees} />
      </Grid>
      <Grid item xs={12}>
        <OpenOpportunities employees={employees} />
      </Grid>
      <Grid item xs={12}>
        <Leads />
      </Grid>
      <Grid item xs={6}>
        {/* <History stage={opportunity} /> */}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  // isLoading: opportunitySelectors.isLoading(state),
  errorMessage: opportunitySelectors.errorMessage(state),
  employees: opportunitySelectors.employees(state),
  opportunity: opportunitySelectors.opportunity(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getEmployees: opportunityActions.getEmployees,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(CompanyTab));