import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { LinkedIn, Phone, Email, PhoneAndroid, Edit, Clear, Check } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  selectors as opportunitySelectors,
  actions as opportunityActions,
} from "../../../../Ducks/opportunity";
import { bindActionCreators } from 'redux';
import pencilEditIcon from '../../../../Assets/images/pencilEditIcon.PNG'

const useStyles = theme => ({
  card: {
    display: 'flex',
    backgroundColor: '#fff'
    // height: 200
  },
  cardMedia: {
    borderRadius: '50%',
    maxWidth: '80px',
    margin: '15px auto 0'
    // maxHeight: '125px'
  },
  cardContent: {
    flex: 4
  },
  cardActions: {
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: '-8px',
    marginBottom: '-18px',
    justifyContent: 'end'
  }
});

function Persons(props) {
  const { classes, person, updateContact } = props;
  const [personObj, setPersonObj] = React.useState([]);
  const [editMobile, setEditMobile] = React.useState(false);
  const [editPhone, setEditPhone] = React.useState(false);
  const [editCompanyNumber, setEditCompanyNumber] = React.useState(false);
  const [mobile, setMobile] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [companyNumber, setCompanyNumber] = React.useState('');
  const [personId, setPersonId] = React.useState('');

  useEffect(() => {
    if (person && person.length !== 0) {
      let personID = person.related_objects.jobs[person.main.job_id].person_id;
      let obj = person.related_objects.persons[personID]
      setPersonObj(obj);
      setPersonId(personID);
      setMobile(obj.mobile === null ? "" : obj.mobile) // mobile phone of a person
      setPhone(obj.phone === null ? "" : obj.phone); // Job number or direct number

      // Get company number - Comments from Max - It should not be here as this is a person level box
      const divisionID = person.related_objects.jobs[person.main.job_id].division_id;
      const division = person.related_objects.divisions ? person.related_objects.divisions[divisionID] : null;
      // const companyPhone = person.related_objects.companies[companyID].company_phone
      setCompanyNumber(division && division.division_phone ? division.division_phone : '' );
    }
  }, [person]);
  return (
    <Card className={classes.card}>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          <CardMedia
            className={classes.cardMedia}
            component="img"
            alt="person image"
            image={`https://erp.f5.dk/uploads/scraped_source_images/${personObj.person_token}.jpg`}
          />
        </Grid>
        <Grid item xs={8}>
          <CardContent className={classes.cardContent}>
            <div style={{ marginBottom: '10px' }}>
              <Typography style={{ fontSize: '18px' }} variant="h6" component="div">
                {personObj.person_firstname} {personObj.person_lastname}
              </Typography>
              <Typography style={{ marginBottom: "10px" }} variant="body2" color="site.main">
                {personObj.title}
              </Typography>
              <Typography variant="body2" color="site.main">
                <a target="_blank" href={personObj.person_linkedin_url}><LinkedIn sx={{ color: 'icon.linkedin', fontSize: "40px" }} /></a>
              </Typography>
            </div>

            {/* This is mobile number */}
            <div style={{ marginBottom: '10px' }}>
              {
                personObj.mobile !== null && personObj.mobile !== "" && !editMobile ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" color="site.main">
                      <a href={`callto:${personObj.mobile}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <PhoneAndroid sx={{ color: 'icon.main', fontSize: "20px" }} />
                        <Tooltip title="Mobile Number" placement="top">
                          <span style={{ marginLeft: '8px' }}>
                            {/* {personObj.mobile} */}
                            {mobile}
                          </span>
                        </Tooltip>
                      </a>
                    </Typography>
                    {
                      person.main.self_opportunity && (
                        <Typography variant="body2" color="site.main">
                          <Tooltip title="Edit Mobile Number" placement="top">
                            <img src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} onClick={() => {
                              setEditMobile(true)
                            }} />
                          </Tooltip>
                        </Typography>
                      )
                    }
                  </div>
                ) : (
                  !editMobile && person.main.self_opportunity && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="body2" color="site.main">
                        <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                          <PhoneAndroid sx={{ color: 'icon.main', fontSize: "20px" }} />
                          <span style={{ marginLeft: '8px' }}>
                            Add mobile number
                          </span>
                        </div>
                      </Typography>
                      <Typography variant="body2" color="site.main">
                        <img src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} onClick={() => {
                          setEditMobile(true)
                        }} />
                      </Typography>
                    </div>
                  )
                )
              }
              {
                editMobile && person.main.self_opportunity && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                      type="number"
                      id="mobile"
                      label="Mobile"
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    ></TextField>
                    <Typography variant="body2" color="site.main" sx={{ display: 'flex', marginLeft: '5px' }}>
                      <Check sx={{ color: 'green', fontSize: "20px" }} onClick={() => {
                        let contactData = {
                          id: personId,
                          obj: { person_mobile_number: mobile },
                          key: 'person_mobile_number',
                          opportunityId: props.params.id
                        }
                        updateContact(contactData);
                        setEditMobile(false);
                      }} />
                      <Clear sx={{ color: 'red', fontSize: "20px" }} onClick={() => {
                        setEditMobile(false);
                      }} />
                    </Typography>
                  </div>
                )
              }
            </div>

            {/* This is job number or direct number */}
            <div style={{ marginBottom: '10px' }}>
              {
                personObj.phone !== null && personObj.phone !== "" && !editPhone ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" color="site.main">
                      <a href={`callto:${personObj.phone}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <Phone sx={{ color: 'icon.main', fontSize: "20px" }} />
                        <Tooltip title="Direct Number">
                          <span style={{ marginLeft: '8px' }}>
                            {/* {personObj.phone} */}
                            {phone}
                          </span>
                        </Tooltip>
                      </a>
                    </Typography>
                    {
                      person.main.self_opportunity && (
                        <Typography variant="body2" color="site.main">
                          <Tooltip title="Edit Direct Number">
                            <img src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} onClick={() => {
                              setEditPhone(true)
                            }} />
                          </Tooltip>
                        </Typography>
                      )
                    }
                  </div>
                ) : (
                  !editPhone && person.main.self_opportunity && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="body2" color="site.main">
                        <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                          <Phone sx={{ color: 'icon.main', fontSize: "20px" }} />
                          <span style={{ marginLeft: '8px' }}>
                            Add direct number
                          </span>
                        </div>
                      </Typography>
                      <Typography variant="body2" color="site.main">
                        <img src={pencilEditIcon} style={{ width: '22px', cursor: 'pointer' }} onClick={() => {
                          setEditPhone(true)
                        }} />
                      </Typography>
                    </div>
                  )
                )
              }
              {
                editPhone && person.main.self_opportunity && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                      type="number"
                      id="phone"
                      label="Direct Number"
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    ></TextField>
                    <Typography variant="body2" color="site.main" sx={{ display: 'flex', marginLeft: '5px' }}>
                      <Check sx={{ color: 'green', fontSize: "20px" }} onClick={() => {
                        let contactData = {
                          jobID: person.main.job_id,
                          obj: { job_phone: phone },
                          opportunityId: props.params.id
                        }
                        props.updateJobPhone(contactData);
                        setEditPhone(false);
                      }} />
                      <Clear sx={{ color: 'red', fontSize: "20px" }} onClick={() => {
                        setEditPhone(false);
                      }} />
                    </Typography>
                  </div>
                )
              }
            </div>

            {/* This is company number - Correction it's the division number infact */}
            {/* commented as Max Feedback */}
            {/* <div style={{ marginBottom: '10px' }}>
              {
                companyNumber !== "" && !editCompanyNumber ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" color="site.main">
                      <a href={`callto:${companyNumber}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <Phone sx={{ color: 'icon.main', fontSize: "20px" }} />
                        <span style={{ marginLeft: '8px' }}>
                          {companyNumber}
                        </span>
                      </a>
                    </Typography>
                    <Typography variant="body2" color="site.main">
                      <img src={pencilEditIcon} style={{width: '22px', cursor: 'pointer'}} onClick={() => {
                        setEditCompanyNumber(true)
                      }} />
                    </Typography>
                  </div>
                ) : (
                  !editCompanyNumber && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="body2" color="site.main">
                        <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                          <Phone sx={{ color: 'icon.main', fontSize: "20px" }} />
                          <span style={{ marginLeft: '8px' }}>
                            Add company number
                          </span>
                        </div>
                      </Typography>
                      <Typography variant="body2" color="site.main">
                        <img src={pencilEditIcon} style={{width: '22px', cursor: 'pointer'}} onClick={() => {
                          setEditCompanyNumber(true)
                        }} />
                      </Typography>
                    </div>
                  )
                )
              }
              {
                editCompanyNumber && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                      type="text"
                      id="companyNumber"
                      label="Company Number"
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}
                      value={companyNumber}
                      onChange={(e) => {
                        setCompanyNumber(e.target.value);
                      }}
                    ></TextField>
                    <Typography variant="body2" color="site.main" sx={{ display: 'flex', marginLeft: '5px' }}>
                      <Check sx={{ color: 'green', fontSize: "20px" }} onClick={() => {
                        const divisionID = person.related_objects.jobs[person.main.job_id].division_id;
                        const data = { divisionID, division_phone: companyNumber, opportunityId: props.params.id }
                        props.updateDivisionPhone(data)
                        setEditCompanyNumber(false);
                      }} />
                      <Clear sx={{ color: 'red', fontSize: "20px" }} onClick={() => {
                        setEditCompanyNumber(false);
                      }} />
                    </Typography>
                  </div>
                )
              }
            </div> */}

            {
              personObj.email && (
                <Typography variant="body2" color="site.link">
                  <a href={`mailto:${personObj.email}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <Email sx={{ color: 'icon.main', fontSize: "20px" }} />
                    <span style={{ marginLeft: '8px', wordBreak: 'break-all' }}>
                      {personObj.email}
                    </span>
                  </a>
                </Typography>
              )
            }
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button variant="contained" color="secondary" endIcon={<ArrowRightAltIcon />} size="small" onClick={() => props.handleTabChange('person')}>Person</Button>
          </CardActions>
        </Grid>

      </Grid>
    </Card >
  );
}

const mapStateToProps = (state) => ({
  isLoading: opportunitySelectors.updateContact_loading(state),
  errorMessage: opportunitySelectors.errorMessage(state),
  opportunity: opportunitySelectors.opportunity(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateContact: opportunityActions.updateContact,
      updateDivisionPhone: opportunityActions.updateDivisionPhone,
      updateJobPhone: opportunityActions.updateJobPhone,
    },
    dispatch
  );

function WithNavigate(props) {
  let params = useParams();
  return <Persons {...props} params={params} />
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(WithNavigate));