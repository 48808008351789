import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import {
    selectors as adminSelectors,
    actions as adminActions,
} from "../../../../Ducks/admin";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { bindActionCreators } from 'redux';
import { Button, DialogActions, DialogContent, Grid, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

const useStyles = theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        paddingTop: "10px !important"
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    },
    '.MuiTypography-root': {
        paddingBottom: 0
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const AddNewRole = (props) => {
    const { onClose, selectedValue, open, opportunity, isLoading, classes } = props;
    const [closedLostOptions, setClosedLostOptions] = React.useState([]);
    const [jsonObj, setJsonObj] = React.useState({});
    const formRef = React.createRef();

    useEffect(() => {
    }, []);

    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <CircularProgress /> */}
        </div>
    )

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BootstrapDialogTitle>Add a new Role</BootstrapDialogTitle>
            <DialogContent>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        role_name: "", //opportunity && opportunity.main.opportunity_cvr
                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.role_name) {
                            errors.role_name = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        const data = {
                            "role_name": values.role_name
                        }
                        props.addNewRole(data);
                        handleClose();
                    }}
                    render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <Grid container sx={{ marginBottom: '15px' }}>
                                <Grid item xs={12}>
                                    <Field
                                        required
                                        sx={{ display: 'flex', width: '100%', maxWidth: '400px', margin: 'auto' }}
                                        classes={{ input: classes.noPadding }}
                                        name="role_name"
                                        type="text"
                                        label="Role"
                                        variant="outlined"
                                        margin="normal"
                                        component={TextField}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Button className={classes.button} variant="contained" color='error' onClick={handleClose}>Cancel</Button>
                                    <Button className={classes.button} variant="contained" color="inherit" onClick={submitForm}>Add</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                />

            </DialogContent>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    isLoading: adminSelectors.isLoading(state),
    errorMessage: adminSelectors.errorMessage(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        addNewRole: adminActions.addNewRole
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <AddNewRole {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));