import * as React from 'react';
import { TextField } from "formik-material-ui";
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form } from 'formik';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Image from '@ckeditor/ckeditor5-image/src/image';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as accountSelectors, actions as accountActions } from '../../../../Ducks/account';
import {
    Button,
    LinearProgress,
    MenuItem,
    Typography,
    Container,
    Grid,
    ButtonGroup,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';

const editorConfiguration = {
    plugins: [ List, Essentials, Bold, Italic, Paragraph, Heading,SimpleUploadAdapter,ImageUpload,Image, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote ],
    toolbar: ['heading', '|', 'fontColor', 'fontBackgroundColor', '|', 'outdent', 'indent', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'horizontalLine', 'blockQuote','uploadImage', '|', 'bulletedList', 'numberedList', '|', 'alignment:left', 'alignment:right', '|', 'insertTable', '|', 'undo', 'redo'],
    alignment: {
        options: ['left', 'right']
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: 'https://stag2.f5.dk/api/events/uploadimage',

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem('token')
        }
    },
  };

const useStyles = theme => ({
});

function AddCommentModal(props) {
    const { getNoteTypes, noteTypes, user, addHistoryLog } = props;
    const formRef = React.createRef();

    React.useEffect(() => {
        getNoteTypes();
    }, []);

    const handleClose = () => {
        props.handleClose()
    };

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} className="edit-modal" disableEnforceFocus>
                <DialogTitle style={{ paddingBottom: '5px', paddingTop: '5px' }}>Add Entry</DialogTitle>
                <DialogContent>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            type: props.specialCase ? 'special_case': 'person_erp_note',
                            note: ''
                        }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            const _note = noteTypes?.data?.main?.filter((item) => item.type_code === values.type)[0];
                            let data = {
                                person_id: props.personId,
                                person_log_type: _note.type_system,
                                person_log_title: _note.type_title,
                                person_log_content: values.note,
                                person_log_code: values.type,
                                person_log_object_type: '',
                                person_log_object_id: 0,
                                user_id: user.user_details.user_id

                            }
                            addHistoryLog({id: props.personId, obj: data, type: props.type });
                            handleClose();
                        }}
                        render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                            <Form>
                                <Grid container sx={{ marginBottom: '15px', marginTop: '0px' }} spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            select
                                            name="type"
                                            label="Type"
                                            variant="outlined"
                                            margin="normal"
                                            component={TextField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            size={'small'}
                                            style={{ marginTop: '0px' }}
                                        >
                                            {
                                                noteTypes && noteTypes?.data?.main.map(item => <MenuItem value={item.type_code}>{item.type_title}</MenuItem>)
                                            }
                                        </Field>
                                        <Field
                                            name="note"
                                            render={({ field, form }) => {
                                                return (
                                                    <>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={ editorConfiguration }
                                                            data={field.value}
                                                            onChange={(event, editor) => {
                                                                form.setFieldValue(field.name, editor.getData());
                                                            }}
                                                        // onSaveKeystroke={this.props.onSaveKeystroke}
                                                        />
                                                    </>
                                                )
                                            }}
                                        />

                                    </Grid>
                                </Grid>

                                {isSubmitting && <LinearProgress />}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        style={{ marginRight: '12px' }}
                                    >
                                        {'Save'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        disabled={isSubmitting}
                                        onClick={handleClose}
                                    >
                                        {'Cancel'}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: eventsOverviewSelectors.getNoteTypes_loading(state),
    noteTypes: eventsOverviewSelectors.noteTypes(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getNoteTypes: eventsOverviewActions.getNoteTypes,
        addHistoryLog: eventsOverviewActions.addHistoryLog,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AddCommentModal));