import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Autocomplete,
    TextField as MaterialTextField,
    DialogTitle,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DateTimePicker from '../../../Common/CalendarWidget/DateTimePicker';
// import { toDateTime } from '../../Utils/dateUtils';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../../Ducks/eventsOverview';
import { selectors as accountSelectors, actions as accountActions } from '../../../../Ducks/account';
import { selectors as ContactsSelectors, actions as contactsActions } from "../../../../Ducks/contacts";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Placeholder from "ckeditor5-placeholder";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import TimePickerWidget from '../../../Common/TimePickerWidget';

const editorConfiguration = {
    plugins: [List, Placeholder, Essentials, Bold, Italic, Paragraph, Heading, Link, AutoLink, Indent, IndentBlock, Font, Alignment, Underline, Strikethrough, Table, TableToolbar, HorizontalLine, BlockQuote],
    toolbar: ['heading', '|', 'Placeholder', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList'],
    alignment: {
        options: ['left', 'right']
    },
    placeholderProps: {
        types: ['PERSON_FIRSTNAME', 'PERSON_FULLNAME', 'DIVISION_NAME', 'EMAIL_SUBJECT', 'FROM_EMAIL', 'FROM_FULLNAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'TO_FULLNAME', 'TO_FIRSTNAME', 'FIRST_MEETING_DATE', 'FIRST_MEETING_START_TIME', 'FIRST_MEETING_END_TIME', 'FIRST_MEETING_DATE_AND_TIME', 'PRODUCT_NAME', 'PRODUCT_LOCATION', 'PRODUCT_ADDRESS', 'PRODUCT_ZIP_CODE', 'PRODUCT_CITY', 'NETWORK_DIRECTOR_1_FIRSTNAME', 'NETWORK_DIRECTOR_1_FULLNAME', 'NETWORK_DIRECTOR_2_FIRSTNAME', 'NETWORK_DIRECTOR_2_FULLNAME'],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    placeholder: "Add meeting details ..."
};

const types = [
    'phone_meeting',
    'follow_up'
]

const useStyles = theme => ({
    select: {
        "& ul": {
            display: "grid",
        }
    },
});

function BookMeetingModal(props) {
    const {
        classes,
        person,
        bookMeeting,
        bookedMeeting,
        row,
        edit,
        reset,
        getBookedMeetingById,
        bookedMeetingById,
        updateBookedMeeting,
        event_id,
        participant_id,
        query,
        addPlannedAction,
        editPlannedAction,
        opportunity,
        startDate,
        action
    } = props;
    // const [startDate, setStartDate] = React.useState(new Date());
    // const [endDate, setEndDate] = React.useState(moment(new Date()).add(30, 'm').toDate());
    const [date, setDate] = React.useState(new Date());
    const [meetingTitle, setMeetingTitle] = React.useState("");
    const [content, setContent] = React.useState("");
    const [type, setType] = React.useState(types[0]);
    const [enableBookings, setEnableBookings] = React.useState(false);

    React.useEffect(() => {
        if (startDate) {
            setDate(new Date(startDate))
        } else {
            setDate(new Date())
        }
    }, [startDate])

    React.useEffect(() => {
        if (action && action.opportunity_action_type) {
            setType(action.opportunity_action_type)
        } else {
            setType(types[0])
        }
    }, [action]);

    React.useEffect(() => {
        if (opportunity && opportunity.meeting) {
            setContent(opportunity.meeting.meeting_details)
            setMeetingTitle(opportunity.meeting.meeting_title)
            setEnableBookings(!!opportunity.meeting);
        } else {
            setContent("");
            setMeetingTitle("");
            setEnableBookings(true);
        }
    }, [opportunity]);

    // React.useEffect(() => {
    //     if (!edit) {
    //         setEnableBookings(true)
    //     }
    // }, []);

    // React.useEffect(() => {
    //     if (row && edit) {
    //         getBookedMeetingById(row.booked_meeting_id);
    //     }
    // }, [row]);

    // React.useEffect(() => {
    //     if (bookedMeetingById) {
    //         console.log("bookedMeetingById...", bookedMeetingById);
    //         const _parsed_start_date = Date.parse(bookedMeetingById.meeting_start_date);
    //         const _parsed_end_date = Date.parse(bookedMeetingById.meeting_end_date);
    //         setStartDate(new Date(_parsed_start_date));
    //         setEndDate(new Date(_parsed_end_date));
    //         setMeetingTitle(bookedMeetingById.meeting_title);
    //         setContent(bookedMeetingById.meeting_details);
    //     }
    // }, [bookedMeetingById])

    // React.useEffect(() => {
    //     if (!edit) {
    //         restFields();
    //     }
    // }, [reset]);

    const handleClose = () => {
        if (!edit) {
            // restFields();
        }
        props.handleClose()
    };

    const handleSubmit = () => {
        // This is old implementation, keeping it here until testing

        // const data = {
        //     person_id: person.person_id,
        //     purchase_id: person.purchase_id,
        //     meeting_title: meetingTitle,
        //     meeting_start_date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        //     meeting_details: content
        // };

        // if (edit) {
        //     updateBookedMeeting({ person_id: person.person_id, booked_meeting_id: row.booked_meeting_id, data, query, purchase_id: person.purchase_id });
        // } else {
        //     bookMeeting({ person_id: person.person_id, data, event_id, participant_id, query, purchase_id: person.purchase_id });
        // }

        // New implementation as discussed with Max

        if (edit) {
            const obj = {
                opportunity_action_type: type,
                opportunity_action_due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
                opportunity_action_due_time_precise: 1,
                subject: meetingTitle,
                body: content,
                book_meeting: enableBookings
            };
            let d = new Date();
            let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
            editPlannedAction({ id: opportunity.opportunity_id, person_id: person.person_id, obj: obj, date: YearMonth, actionId: action.opportunity_action_id });
        } else {
            const obj = {
                action_type: type,
                due_time: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
                due_time_precise: 1,
                subject: meetingTitle,
                body: content,
                book_meeting: enableBookings
            };
            let d = new Date();
            let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
            addPlannedAction({ id: opportunity.opportunity_id, person_id: person.person_id, obj: obj, date: YearMonth });
        }
        handleClose();
    }

    const restFields = () => {
        setDate(new Date());
        setMeetingTitle("");
        setContent("")
    }

    const handleDateChange = (value) => {
        setDate(value)
    }

    const makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    }

    return (
        <div>
            <Dialog width={700} open={props.open} onClose={handleClose}>
                <DialogTitle style={{ paddingTop: '10px', paddingBottom: '10px', boxShadow: '0px 0px 20px 2px #ebebeb' }}>Planned Action</DialogTitle>
                <DialogContent>
                    <FormControl style={{ width: '100%', margin: '15px 0 10px' }}>
                        <InputLabel id="action-type">Type</InputLabel>
                        <Select
                            fullWidth
                            labelId="action-type"
                            name="type"
                            id="action_type"
                            value={type}
                            label="Age"
                            onChange={(e) => setType(e.target.value)}
                            size={'small'}
                            MenuProps={{ classes: { paper: classes.select } }}
                        >
                            {
                                types.map(item => <MenuItem value={item}>{makeHeader(item)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 0' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date"
                                inputFormat="yyyy/MM/dd"
                                value={date}
                                onChange={(value) => handleDateChange(value)}
                                renderInput={(params) => <MaterialTextField fullWidth size='small' {...params} />}
                            />
                        </LocalizationProvider>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', zIndex: 100, alignItems: 'center', marginLeft: '10px' }}>
                                <TimePickerWidget min={0} max={23} handleChange={(n) => (setDate(moment(date).set({ hours: n })))} number={moment(date).hours()} />
                                <h1 style={{ padding: "0px 8px", margin: 0 }}>:</h1>
                                <TimePickerWidget min={0} max={59} handleChange={(n) => (setDate(moment(date).set({ minute: n })))} number={moment(date).minutes()} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0', justifyContent: 'space-between' }}>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="End Date"
                                inputFormat="yyyy/MM/dd"
                                value={startDate}
                                onChange={(value) => setEndDate(value)}
                                renderInput={(params) => <MaterialTextField fullWidth size='small' {...params} />}
                            />
                        </LocalizationProvider> */}

                        {/* <div style={{ display: 'flex' }}>
                            <p><b>End Time</b></p>
                            <div style={{ display: 'flex', justifyContent: 'center', zIndex: 100, alignItems: 'center', marginLeft: '10px' }}>
                                <TimePickerWidget min={0} max={23} handleChange={(n) => (setEndDate(moment(endDate).set({ hours: n })))} number={moment(endDate).hours()} />
                                <h1 style={{ padding: "0px 8px", margin: 0 }}>:</h1>
                                <TimePickerWidget min={0} max={59} handleChange={(n) => (setEndDate(moment(endDate).set({ minute: n })))} number={moment(endDate).minutes()} />
                            </div>
                        </div> */}
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={enableBookings}
                                    onChange={() => setEnableBookings(!enableBookings)}
                                />
                            }
                            label="Book meeting via calendar sendout" />
                    </FormGroup>
                    {
                        enableBookings && (
                            <>
                                <div style={{ margin: '10px 0' }}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="meeting-title"
                                            labelId="title-label"
                                            label="Subject"
                                            variant="outlined"
                                            value={meetingTitle}
                                            onChange={(e) => setMeetingTitle(e.target.value)}
                                            size="small"
                                        />
                                    </FormControl>
                                </div>

                                <div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={editorConfiguration}
                                        data={content}
                                        onReady={(editor) => {
                                            console.log("Ready.", editor)
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setContent(data)
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log("Blur.", editor)
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log("Focus.", editor);
                                        }}
                                    />
                                </div>
                            </>
                        )
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        {
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                                style={{ marginRight: '12px' }}
                            >
                                {'Confirm'}
                            </Button>
                        }
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
const mapStateToProps = (state) => ({
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    user: accountSelectors.user(state),
    bookedMeetingById: eventsOverviewSelectors.bookedMeetingById(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        bookMeeting: eventsOverviewActions.bookMeeting,
        getBookedMeetingById: eventsOverviewActions.getBookedMeetingById,
        updateBookedMeeting: eventsOverviewActions.updateBookedMeeting,
        addPlannedAction: eventsOverviewActions.addPlannedAction,
        editPlannedAction: eventsOverviewActions.editPlannedAction,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(BookMeetingModal));