import * as React from 'react';
import { withStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const useStyles = theme => ({
    container: {
        textAlign: 'left',
        '& .MuiDataGrid-columnHeaderTitle': {
            textTransform: 'capitalize'
        }
    },
    gridHeading: {
        margin: '20px 0px',
        fontSize: '20px',
        color: '#7b7b7b',
        borderBottom: '1px solid rgb(208, 208, 208)',
        fontWeight: 400,
        paddingBottom: '20px'
    }
});

class UserGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumns: [
                "user_firstname",
                "user_lastname",
                "user_email",
                "user_title",
                "user_phone",
                "user_linkedin",
                "team_name",
                "country_name",
                "user_deactivated"
            ],
            filter: {}
        }
    }

    makeHeader = (key) => {
        key = key.replaceAll('_', ' ');
        let splitStr = key.toLowerCase().split(" ");

        if (splitStr[0] === "team" || splitStr[0] === "country") {
            return splitStr[0];
        }
        return splitStr[1];
    }

    handleRowClick = (data) => {
        const { navigate } = this.props;
        navigate(`/user/id/${data.row.user_id}`);
    }

    render() {
        const { classes, isLoading, contacts, rows, headlineSize, titleStyle } = this.props;
        const { tableColumns } = this.state;

        return (
            <div className={classes.container}>
                <h2 className={classes.gridHeading} style={titleStyle}>
                    Users
                </h2>
                <DataGrid
                    columns={tableColumns.map(key => ({ field: key, minWidth: 150, flex: 1, headerName: this.makeHeader(key) }))}
                    rows={rows}
                    pageSize={25}
                    rowsPerPageOptions={[25]}
                    onRowClick={this.handleRowClick}
                    components={{ Toolbar: GridToolbar }}
                    autoHeight
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

function WithNavigate(props) {
    let navigate = useNavigate();
    return <UserGrid {...props} navigate={navigate} />
}

export default connect(mapStateToProps, {})(withStyles(useStyles)(WithNavigate));
