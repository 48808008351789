import * as React from "react";
import { Formik, Form } from "formik";
import { Card, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import InputElement from "../../Common/InputElement";

const useStyles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: "16px",
    marginTop: 0,
  },
});

const fieldList = [
  {
    title: "Person",
    name: "person",
    type: "textfield",
    disableButton: true,
  },
  {
    title: "Company",
    name: "company",
    type: "textfield",
    disableButton: true,
  },
  {
    title: "Company Phone",
    name: "company_phone",
    type: "textfield",
    disableButton: true,
  },
  {
    title: "Purchase Status",
    name: "purchase_status",
    type: "dropdown",
    list: [
      {
        id: "Normal",
        name: "Normal",
        disabled: true,
      },
      {
        id: "Active",
        name: "Active",
      },
      {
        id: "Canceled",
        name: "Canceled",
      },
      {
        id: "Retention: Sales",
        name: "Retention: Sales",
      },
      {
        id: "Retention: Member Service",
        name: "Retention: Member Service",
      },
    ],
  },
  {
    title: "Purchase Source",
    name: "purchase_source",
    type: "textfield",
  },
  {
    title: "Purchase Creation Time",
    name: "purchase_creation_time",
    type: "textfield",
  },
];

class DetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  handleSaveClick = (e, name) => {
    let formData = this.formRef.current.values;
    this.props.onClick({ [name]: formData[name] });
  };
  render() {
    const { classes, saleDetails } = this.props;

    return (
      <React.Fragment>
        <Card className={classes.paper}>
          <Typography
            style={{ marginBottom: "30px" }}
            component="h6"
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            Purchase
          </Typography>
          <Formik
            innerRef={this.formRef}
            initialValues={saleDetails}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                this.props.saveNews(values);
              }, 500);
            }}
            render={({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form>
                {fieldList.map((item, index) => {
                  return (
                    <InputElement
                      {...item}
                      key={index}
                      onSaveClick={this.handleSaveClick}
                    />
                  );
                })}
              </Form>
            )}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(DetailsForm);
