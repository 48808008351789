import {
    Alert,
    Card,
    Grid, Table, TableBody, TableCell, TableContainer, TableRow,
    Typography, TextField
} from "@mui/material";
import EventGrid from "./EventGrid";
import {dateToString} from "../../Utils/dateUtils";
import {useState} from "react";

const PurchaseEventsOverview = (props) => {
    const {oldEvents, newEvents, specialCase, handleMeetingsCountChange,selectedEventReference,firstMeetingDate} = props;
    const [meetingsCount, setMeetingsCount] = useState(props.initialMeetingsCount);

    return (
        <div style={{
            paddingTop: "30px"
        }}>
            <Grid container sx={{padding: "20px", textAlign: "left"}} spacing={2}>
                <Grid item xs={6} sx={{paddingTop: "20px"}}>
                    <Card style={{padding: "12px"}}>
                        <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                    gutterBottom>
                            Old Purchase Events
                            <Alert variant="outlined" severity="info"
                                   style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                These events are going to be removed from old purchase and cancellations will be sent
                                automatically
                            </Alert>
                        </Typography>
                        <EventGrid events={oldEvents}/>

                    </Card>
                </Grid>
                <Grid item xs={6} sx={{paddingTop: "20px"}}>
                    <Card style={{padding: "12px"}}>
                        <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                    gutterBottom>
                            New Purchase Events
                            <Alert variant="outlined" severity="info"
                                   style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                These events are going to be added in new purchase and invitation of upcoming events
                                will be sent automatically
                            </Alert>
                        </Typography>
                        <Grid container sx={{padding: "20px"}} spacing={1}>
                            <TextField
                                name='meetings_count'
                                type='number'
                                label='Meetings to be added *'
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                style={{marginTop: "0px"}}
                                value={meetingsCount}
                                InputProps={{
                                    inputProps: {min: 1, max: 5}
                                }}
                                onInput={(event) => {
                                    const {value} = event.target;

                                    event.target.value = value.replace(/[^0-9]/g, ""); // Remove any character that is not a digit
                                    handleMeetingsCountChange(event.target.value);
                                    setMeetingsCount(event.target.value);

                                }}
                                onKeyDown={(event) => {
                                    const {key, target} = event;
                                    const {value} = target;

                                    if (key !== "Backspace") {
                                        // Prevent entering values greater than 100
                                        if (parseFloat(value + key) > 5) {
                                            event.preventDefault();
                                        }
                                    }
                                }}
                                error={!(meetingsCount > 0)}
                            />
                            {!(meetingsCount > 0) && <div
                                style={{
                                    color: "red",
                                    fontSize: "0.75rem",
                                    margin: "4px 14px 0px",
                                }}>
                                <span>Please select meetings to add new purchase</span>
                            </div>}
                        </Grid>
                        <EventGrid 
                            events={newEvents} 
                            selectable={true} 
                            selectableEventCount={meetingsCount} 
                            selectedEventReference={selectedEventReference} 
                            firstMeetingDate={firstMeetingDate}/>
                    </Card>
                </Grid>
                {
                    specialCase &&
                    <Grid item xs={6} sx={{paddingTop: "20px"}}>
                        <Card style={{padding: "12px"}}>
                            <Typography component='h1' variant='h6' align='left' color='textPrimary'
                                        gutterBottom>
                                Special Case
                                <Alert variant="outlined" severity="error"
                                       style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                                    This purchase
                                    has {specialCase.status} {specialCase.type} case, so a person's case will be
                                    regenerated with
                                    new group
                                </Alert>
                            </Typography>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><b>Type</b></TableCell>
                                            <TableCell>{specialCase?.type}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Status</b></TableCell>
                                            <TableCell>{specialCase?.status}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Start Date</b></TableCell>
                                            <TableCell>{dateToString(new Date(specialCase?.start_date))}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>End Date</b></TableCell>
                                            <TableCell>{dateToString(new Date(specialCase?.end_date))}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>
                }
            </Grid>
        </div>
    );
}

export default PurchaseEventsOverview;