import React from 'react';
import { withStyles } from '@mui/styles';
import { QueryBuilder, RuleGroupType } from 'react-querybuilder';
import ValueEditor from "./ValueEditor";
// import { materialControlElements } from '@react-querybuilder/material';
import './ElementStyle.css'
import 'react-querybuilder/dist/query-builder.css';

const useStyles = theme => ({
    container: {
        textAlign: 'left',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    headline: {
        margin: '0 0 20px',
        minWidth: '300px',
        fontWeight: '400',
        fontSize: '20px',
        position: 'relative',
        flex: 1
    }
});

const SegmentBuilder = (props) => {
    const { classes } = props;
    const [query, setQuery] = React.useState({
        combinator: 'and',
        rules: [],
    })

    const [fields, setFields] = React.useState([
        {
            name: 'gender',
            label: 'Gender',
            valueEditorType: "select",
            values: [
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
                { value: "other", label: "Other" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'industries',
            label: 'Industries',
            valueEditorType: "select",
            values: [
                { value: "1", label: "Software" },
                { value: "2", label: "Electrical" },
                { value: "3", label: "Mechanical" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'job_titles',
            label: 'Job Titles',
            valueEditorType: "select",
            values: [
                { value: "01", label: "SE" },
                { value: "02", label: "DE" },
                { value: "03", label: "PN" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" },
                { name: "contains", label: "contains" },
                { name: "notContains", label: "not contains" },
                { name: "containsIn", label: "contains in" },
                { name: "notContainsIn", label: "not contains in" }
            ],
            multiple: true
        },
        {
            name: 'lead_sources',
            label: 'Lead Sources',
            valueEditorType: "select",
            values: [
                { value: "1", label: "Software" },
                { value: "2", label: "Electrical" },
                { value: "3", label: "Mechanical" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'regions',
            label: 'Regions',
            valueEditorType: "select",
            values: [
                { value: "01", label: "SE" },
                { value: "02", label: "DE" },
                { value: "03", label: "PN" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" },
                { name: "contains", label: "contains" },
                { name: "notContains", label: "not contains" },
                { name: "containsIn", label: "contains in" },
                { name: "notContainsIn", label: "not contains in" }
            ],
            multiple: true
        },
        { 
            name: 'sectors', 
            label: 'Sectors', 
            valueEditorType: 'radio',
            values: [
                { name: "01", label: "Public Sector" },
                { name: "02", label: "Private Sector" },
            ],
            operators: [
                { name: "=", label: "=" }
            ],
        },
        {
            name: 'segments',
            label: 'Segments',
            valueEditorType: "select",
            values: [
                { value: "1", label: "one" },
                { value: "2", label: "two" },
                { value: "3", label: "three" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'workplace_current',
            label: 'Workplace - Current',
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" },
                { name: "contains", label: "contains" },
                { name: "notContains", label: "not contains" },
                { name: "containsIn", label: "contains in" },
                { name: "notContainsIn", label: "not contains in" }
            ]
        },
        {
            name: 'last_opportunity_completed_events',
            label: 'Last Opportunity: Number of completed activities',
            inputType: "number",
            operators: [
                { name: "=", label: "=" },
                { name: "<", label: "<" },
                { name: "<=", label: "<=" },
                { name: ">", label: ">" },
                { name: ">=", label: ">=" },
            ],
        },
        {
            name: 'last_opportunity_product',
            label: 'Last Opportunity: Product',
            valueEditorType: "select",
            values: [
                { value: "1", label: "one" },
                { value: "2", label: "two" },
                { value: "3", label: "three" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'last_opportunity_user',
            label: 'Last Opportunity: User',
            valueEditorType: "select",
            values: [
                { value: "1", label: "one" },
                { value: "2", label: "two" },
                { value: "3", label: "three" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'last_opportunity_closed_lost',
            label: 'Last Opportunity: Closed Lost Reasons',
            valueEditorType: "select",
            values: [
                { value: "1", label: "one" },
                { value: "2", label: "two" },
                { value: "3", label: "three" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'last_opportunity_closed_stage',
            label: 'Last Opportunity: Closed Stage Name',
            valueEditorType: "select",
            values: [
                { value: "1", label: "one" },
                { value: "2", label: "two" },
                { value: "3", label: "three" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'previous_opportunity',
            label: 'Previous Opportunity',
            inputType: "number",
            operators: [
                { name: "=", label: "=" },
                { name: "<", label: "<" },
                { name: "<=", label: "<=" },
                { name: ">", label: ">" },
                { name: ">=", label: ">=" },
            ],
        },
        {
            name: 'division_tags',
            label: 'Division Tags',
            valueEditorType: "select",
            values: [
                { value: "1", label: "one" },
                { value: "2", label: "two" },
                { value: "3", label: "three" }
            ],
            operators: [
                { name: "in", label: "in" },
                { name: "notIn", label: "not in" }
            ],
            multiple: true
        },
        {
            name: 'day_since_last_opportunity_closed_timestamp',
            label: 'Day since last opportunity closed timestamp',
            inputType: "number",
            defaultValue: 90,
            operators: [
                { name: "=", label: "=" },
                { name: "<", label: "<" },
                { name: "<=", label: "<=" },
                { name: ">", label: ">" },
                { name: ">=", label: ">=" },
            ],
            defaultOperator: ">"
        },
        {
            name: 'day_since_last_scrape',
            label: 'Day since last scrape',
            inputType: "number",
            defaultValue: 90,
            operators: [
                { name: "=", label: "=" },
                { name: "<", label: "<" },
                { name: "<=", label: "<=" },
                { name: ">", label: ">" },
                { name: ">=", label: ">=" },
            ],
            defaultOperator: ">"
        },
        {
            name: 'linked_in_connection_count',
            label: 'Linkedin connection count',
            inputType: "number",
            defaultValue: 0,
            operators: [
                { name: "=", label: "=" },
                { name: "<", label: "<" },
                { name: "<=", label: "<=" },
                { name: ">", label: ">" },
                { name: ">=", label: ">=" },
            ],
            defaultOperator: ">"
        },
        // { name: 'email', label: 'Email', validator: ({ value }) => /^[^@]+@[^@]+/.test(value) },
        // { name: 'isDev', label: 'Is a Developer?', valueEditorType: 'checkbox', defaultValue: false },
    ])

    React.useEffect(() => {
        console.log(query)
    }, [query])

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <h2 className={classes.headline}>
                    Segment Builder
                </h2>
            </div>
            <QueryBuilder
                fields={fields}
                query={query}
                onQueryChange={q => setQuery(q)}
                controlElements={{ valueEditor: ValueEditor }}
            />
        </div>
    );
}

export default (withStyles(useStyles)(SegmentBuilder));