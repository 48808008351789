import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CompanyProfileSetParentModal from '../../Common/CompanyProfilesSetParentModal';
import CompaniesMiniProfileFilterModal from '../../Common/CompaniesMiniProfileFilterModal';
import TextField from '@mui/material/TextField';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { fetchGet } from '../../../API/util';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import EditTable from '../../Common/EditTable';
import { Link as RouterLink } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';


function toTitleCase(str) {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
        return match.toUpperCase();
    });
}

const makeHeader = (title) => {
    let _title = "";
    if (title.includes(".")) {
        _title = title.split(".")[1].split("_");
    } else {
        _title = title.split("_");
    }
    return _title.join(" ");
}

const makeAccessor = (key) => {
    if (key.includes(".")) {
        return key.split(".")[1];
    } else {
        return key
    }
}


const queryDefaults = {
    id: 0,
    operand: 'and',
    term: '',
    filter: 'starts_with'
};

const useStyles = makeStyles({
    dataWrapper: {
        marginBottom: '2rem',

        '& .MuiDataGrid-footerContainer': {
            display: 'none !important',
        },
    },

    cell_bold: {
        fontWeight: 'bold'
    }
});


let counter = 0;


const CompaniesMiniProfilesGrid = (props) => {
    const [openedModal, setOpenedModal] = React.useState('');
    const [queries, setQueries] = React.useState([queryDefaults]);
    const [parentCompanies, setParentCompanies] = React.useState([]);
    const [companyLocations, setCompanyLocations] = React.useState([]);
    const [companyProfiles, setCompanyProfiles] = React.useState([]);
    const [cols, setCols] = React.useState(JSON.parse(localStorage.getItem("user"))?.tableSettings['mini-profiles']?.columns.map(key => ({ column: key, accessor: makeAccessor(key), minWidth: 150, Header: makeHeader(key), filter: 'fuzzyText' })));
    const [pageSize, setPageSize] = React.useState(20);
    const [totalItems, setTotalItems] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true);
    const [parentFilter, setParentFilter] = React.useState('without_parent');
    const [mpCompanyFilter, setMpCompanyFilter] = React.useState('without_company');
    const [locationFilter, setLocationFilter] = React.useState('');
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [exporting, setExporting] = React.useState(false);
    const classes = useStyles();

    const handleSelectionModelChange = (selectionModel) => {
        setSelectedRows(selectionModel);
    };


    const fetchCompanies = async (page = 1, action = 'fetch') => {
        setLoading(true);
        const _queries = [...queries].filter(q => q.term !== '');

        const searchParams = new URLSearchParams();
        searchParams.append('page_size', pageSize);
        searchParams.append('page', page);
        searchParams.append('cols', JSON.stringify(cols.map(c => c.column)));
        
        if(action !== 'reset') {
            searchParams.append('parent_filter', parentFilter);
            searchParams.append('location', locationFilter);
            searchParams.append('mp_company_filter', mpCompanyFilter);
            searchParams.append('queries', JSON.stringify(_queries));
        }

        try {
            const {
                company_profiles,
                total_company_profiles } = await fetchGet(`/persons/mini-profiles?${searchParams.toString()}`);

            setTotalItems(total_company_profiles);
            setCompanyProfiles(company_profiles);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchLocations = async () => {
        const { locations } = await fetchGet(`/persons/mini-profiles/locations`);
        setCompanyLocations(locations);
    };

    React.useEffect(() => {
        fetchCompanies();
        fetchLocations();
    }, [cols]);

    const handleAddQuery = () => {
        const currentQuery = queries.at(-1);
        if (!currentQuery.term) return;

        const _queries = [...queries];
        _queries.push({ ...queryDefaults, id: currentQuery.id + 1 });
        setQueries(_queries);
    }

    React.useEffect(() => {
        fetchCompanies(1);
    }, [parentFilter, locationFilter, mpCompanyFilter]);

    const handleReset = () => {
        setLocationFilter('');
        setQueries([queryDefaults]);
        setParentFilter('without_parent');
        setSelectedRows([]);
        fetchCompanies(1, 'reset');
    }

    const handleSearch = () => {
        setPage(1);
        fetchCompanies();
    };

    const handleSetParent = () => {
        setSelectedRows([]);
        fetchCompanies(page);
    };

    const handleQueryChange = (update, id) => {
        const _queries = [...queries];
        const index = _queries.findIndex(q => q.id === id);
        _queries[index] = { ..._queries[index], ...update };
        setQueries(_queries);
    };

    const handleRemoveQuery = (id) => {
        const _queries = [...queries].filter(q => q.id !== id);
        if (!_queries.length)
            _queries.push(queryDefaults);

        setQueries(_queries);
    }

    const fetchExportData = async () => {
        setExporting(true);
        const _queries = [...queries].filter(q => q.term !== '');
        const searchParams = new URLSearchParams();
        searchParams.append('queries', JSON.stringify(_queries));
        searchParams.append('parent_filter', parentFilter);
        searchParams.append('cols', JSON.stringify(cols.map(c => c.column)));

        const { company_profiles } = await fetchGet(`/persons/mini-profiles/export?${searchParams.toString()}`);
        setExporting(false);
        return company_profiles;
    }

    const handleSearchOnEnter = (e) => {
        if (e.key === 'Enter')
            fetchCompanies();
    };



    return (
        <>
            <div style={{ textAlign: "left" }}>
                <Link to="/manage-companies/companies" component={RouterLink}>Go to: Manage Companies</Link>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className="page-title">Manage Companies - Mini Profiles</h2>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button variant="outlined" color='primary' onClick={() => setOpenedModal('set-parent')}>Set Parent</Button>
                    <Button variant="outlined" color='primary' onClick={() => setOpenedModal('edit-table')}>Edit Table</Button>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <div style={{ width: '60%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="parent_filter_label">Parent Filter</InputLabel>
                                <Select
                                    labelId="parent_filter_label"
                                    id="parent_filter"
                                    value={parentFilter}
                                    label="Parent Filter"
                                    style={{ textAlign: 'left' }}
                                    disabled={loading}
                                    onChange={e => setParentFilter(e.target.value)}
                                >
                                    <MenuItem value='without_parent'>Companies Without Parent</MenuItem>
                                    <MenuItem value='with_parent'>Companies With Parent</MenuItem>
                                    <MenuItem value='both'>Both</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="company_filter_label">Company Filter</InputLabel>
                                <Select
                                    labelId="company_filter_label"
                                    id="company_filter"
                                    value={mpCompanyFilter}
                                    label="Company Filter"
                                    style={{ textAlign: 'left' }}
                                    disabled={loading}
                                    onChange={e => setMpCompanyFilter(e.target.value)}
                                >
                                    <MenuItem value='without_company'>Profiles Without Company</MenuItem>
                                    <MenuItem value='with_company'>Profiles With Company</MenuItem>
                                    <MenuItem value='both'>Both</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="select_company_location_label">Location</InputLabel>
                                <Select
                                    labelId="select_company_location_label"
                                    id="select_company_location"
                                    value={locationFilter}
                                    label="Location"
                                    style={{ textAlign: 'left' }}
                                    disabled={loading}
                                    onChange={(e) => setLocationFilter(e.target.value)}
                                >
                                    {companyLocations?.map(l => <MenuItem value={l}>{l}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        {queries.map((query, index) => (
                            <div key={query.id} style={{ textAlign: 'left', display: 'flex', gap: '1rem' }}>
                                {index !== 0 &&
                                    <FormControl sx={{ marginRight: '.4rem' }}>
                                        <Select
                                            onChange={e => handleQueryChange({ operand: e.target.value }, query.id)}
                                            value={query.operand}
                                            sx={{ width: '150px' }}
                                        >
                                            <MenuItem value='and'>And</MenuItem>
                                            <MenuItem value='or'>Or</MenuItem>
                                            <MenuItem value='not' >Not</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                <FormControl sx={{ flexGrow: 4 }}>
                                    <TextField
                                        label="Company name"
                                        value={query.term}
                                        onChange={e => handleQueryChange({ term: e.target.value }, query.id)}
                                        fullWidth
                                        onKeyDown={handleSearchOnEnter} />
                                </FormControl>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControl sx={{ marginRight: '.4rem' }}>
                                        <Select
                                            onChange={e => handleQueryChange({ filter: e.target.value }, query.id)}
                                            value={query.filter}
                                            sx={{ width: '150px' }}
                                        >
                                            <MenuItem value='starts_with'>Starts with</MenuItem>
                                            <MenuItem value='ends_with'>Ends with</MenuItem>
                                            <MenuItem value='within_name' >Within name</MenuItem>
                                            <MenuItem value='exact' >Exact match</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="error"
                                        sx={{ visibility: query.term ? 'initial' : 'hidden' }}
                                        onClick={() => handleRemoveQuery(query.id)} >Remove</Button>

                                </div>

                            </div>
                        ))}
                        <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            style={{ alignSelf: 'self-start' }}
                            onClick={handleAddQuery} >Add</Button>
                    </div>
                </div>
            </div >
            <hr />
            <div style={{ display: 'flex', alignItems: 'center', gap: '.8rem', marginBottom: '2rem' }}>
                <CsvDownloader disabled={exporting} separator="," columns={cols.map(c => ({ id: c.accessor, displayName: c.Header }))} datas={fetchExportData} filename='mini-profiles'>
                    <Button variant="outlined" color='primary'>
                        {exporting ? "Exporting..." : "Export CSV"}
                    </Button>
                </CsvDownloader>
                <Button variant="contained" color="error" type="button" onClick={handleReset} disabled={loading}>Reset</Button>
                <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading}>Search</Button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                    <span>Total: {totalItems}</span>
                </div>
                <div className="pagination">
                    <Pagination
                        color="primary"
                        count={Math.ceil(totalItems / pageSize)}
                        page={page}
                        onChange={(event, value) => {
                            fetchCompanies(value);
                            setPage(value);
                        }}
                    />
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={page}
                            onChange={e => {
                                const value = e.target.value;
                                if (value === '' || isNaN(value))
                                    return;

                                const page = parseInt(value);
                                fetchCompanies(page);
                                setPage(parseInt(page))
                            }}
                            style={{ width: '100px' }}
                        />
                    </span>{' '}
                    <select
                        style={{ minHeight: '25px', marginLeft: '5px' }}
                        value={pageSize}
                        onChange={e => {
                            fetchCompanies();
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {
                loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }
                    } >
                        <CircularProgress />
                    </div > :
                    <div className={classes.dataWrapper}>
                        <DataGrid
                            autoHeight
                            rows={companyProfiles}
                            getCellClassName={(params) => {
                                if(params.row.is_parent) console.log(params.row);
                                return params.field == 'mp_company_name' && params.row.is_parent ? classes.cell_bold : '';
                            }}
                            columns={cols.map(c => ({
                                ...c,
                                field: c.column,
                                width: c.column === 'mp_profile_id' ? 200 : 400,
                                headerName: c.column == 'parent_company_id' ? 'Parent Company (ID)':  toTitleCase(c.Header),
                                valueGetter: params => params.value === null ? 'None' : params.value
                            }))}
                            getRowId={row => row.mp_profile_id}
                            pageSize={pageSize}
                            checkboxSelection
                            keepNonExistentRowsSelected
                            onSelectionModelChange={handleSelectionModelChange}
                            selectionModel={selectedRows}
                        />
                    </div>
            }

            <CompanyProfileSetParentModal
                open={openedModal == 'set-parent'}
                parentCompanies={parentCompanies}
                selectedCompanies={selectedRows}
                onClose={() => setOpenedModal('')}
                onSetParent={handleSetParent}
            />

            <EditTable
                open={openedModal == 'edit-table'}
                onClose={() => setOpenedModal('')}
                columns={["mini-profiles"]}
                table="mini-profiles"
                setColumns={setCols}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
    },
    dispatch);

function WithNavigate(props) {
    let navigate = useNavigate();
    return <CompaniesMiniProfilesGrid {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);