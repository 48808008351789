import { fetchGet, fetchPost, fetchPatch, fetchDelete } from "./util";

export const getAdminTemplateTags = () => {
  return fetchGet(`/templates/tags`).then((res) => res);
};

export const getAdminTemplates = () => {
  return fetchGet(`/templates`).then((res) => res);
};

export const saveAdminTemplate = (data) => {
  return fetchPatch(`/templates/${data.template_id}`, data).then(res => res);
}

export const createAdminTemplate = (data) => {
  return fetchPost(`/templates`, data).then(res => res);
}

export const saveRoleCapabilities = (data) => {
  return fetchPost(`/roles/capability`, data).then(res => res);
}

export const deleteAdminTemplate = (template_id) => {
  return fetchDelete(`/templates/${template_id}`, {}).then(res => res);
}

export const deleteUserRole = (roleID) => {
  return fetchDelete(`/roles/${roleID}`, {}).then(res => res);
}

export const getUserRoles = () => {
  return fetchGet(`/roles`).then(res => res);
}

export const getCapabilitiesList = () => {
  return fetchGet(`/capabilities`).then(res => res);
}

export const getRoleCapabilities = (id) => {
  return fetchGet(`/roles/${id}/capabilities`).then(res => res);
}

export const addNewRole = (data) => {
  return fetchPost(`/roles`, data).then(res => res);
}

export const getGmailData = (data) => {
  return fetchGet(`/email_extraction_data?${data}`).then(res => res);
}

//NOT AVAILABLE
export const getMyTemplates = () => {
  return fetchGet(`/templates/user`).then(res => res);
}

export const getSiteSettings = () => {
  return fetchGet(`/site-settings`).then(res => res);
}

export const addSiteSettings = (data) => {
  return fetchPost(`/site-settings`,data).then(res => res);
}

export const updateSiteSettings = (data) => {
  return fetchPatch(`/site-settings/${data}`).then(res => res);
}

export const saveReleaseNotes = (data) => {
  return fetchPost(`/release-notes`,data).then(res => res);
}

export const getReleaseNotes = () => {
  return fetchGet(`/release-notes`).then(res => res);
}

export const getBouncedEmails = (data) => {
  return fetchGet(`/bounced-emails?${data}`).then(res => res);
}

export const postBouncedEmails = (data) => {
  return fetchPost(`/bounced-emails`,data).then(res => res);
}

export const getBouncedEmailFiles = (data) => {
  return fetchGet(`/bounced-emails/attachments?${data}`).then(res => res);
}