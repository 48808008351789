import * as React from 'react';
import moment from 'moment';
import { Tooltip, Card, CardContent, CardMedia, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { Language, BusinessCenter, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt } from '@mui/icons-material';

export default function ParticipantEventsOverview(props) {
    const { classes, attendeeDetails, eventOverview, getStatusClass } = props;

    return (
        <div className={classes.flex}>
            {
                attendeeDetails && eventOverview.length > 0 && eventOverview.map((item, index) => {
                    const color = getStatusClass(item);
                    return (
                        <Tooltip key={index} title={<React.Fragment><div>{item.event_name}</div><div>Date: {moment(item.event_start).format("DD/MM/YYYY HH:mm")}</div><div>{item.event_has_happened ? (<>{item.rating_nps ? <div>NPS Rating: {item.rating_nps}</div> : <></>} {item.rating_overall ? <div>Overall Rating: {item.rating_overall}</div> : <></>} </>) : (<></>)}</div></React.Fragment>}>
                            <div className={classes[color]}></div>
                        </Tooltip>
                    )
                })
            }
        </div>
    );
}