import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
  asyncs: {
    getSales: (data) => data,
    getSaleDetails: (data) => data,
    getUserStages: (data) => data,
    getPipelineContent: (data) => data,
    updateSale: (data) => data,
  }
}, 'sales');

let initialState = asyncInitialState({
  sales: null,
  saleDetails: null,
  errorMessage: '',
  success: false,
  stages: null,
  content: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.getSales:
    case types.getSaleDetails:
    case types.updateSale:
    case types.getUserStages:
    case types.getPipelineContent:
      return asyncOnRequest(state, action);
    case types.saga.getSales.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          sales: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.getSaleDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          saleDetails: action.payload.data,
          errorMessage: ''
        };
      })
    case types.saga.updateSale.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          saleDetails: { ...data.saleDetails, main: action.payload.data },
          errorMessage: ''
        };
      })
    case types.saga.getUserStages.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          content: action.payload,
          errorMessage: ''
        };
      })
    case types.saga.getPipelineContent.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          stages: action.payload,
          errorMessage: ''
        };
      })
    case types.saga.getSales.failure:
    case types.saga.getSaleDetails.failure:
    case types.saga.updateSale.failure:
    case types.saga.getUserStages.failure:
    case types.saga.getPipelineContent.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.sales,
  {
    errorMessage: (data) => data.errorMessage,
  }
)

const syncSelector = {
  isLoading: (state) => state.sales.loading,
  sales: (state) => state.sales.data.sales,
  saleDetails: (state) => state.sales.data.saleDetails,
  stages: (state) => state.sales.data.stages,
  content: (state) => state.sales.data.content,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)