import { fetchPost } from "./util";

export const login = (data) => {
  return fetchPost("/auth/login", data).then((response) => response);
};

export const loginAs = (data) => {
  return fetchPost("/auth/login-as", data.obj).then((response) => response);
};

export const loginAlias = (data) => {
  return fetchPost("/auth/login-as", data.obj).then((response) => response);
};

export const setParentUser = (email) => {
  return email;
};

export const azureLogin = (data) => {
  return fetchPost("/auth/login/social/microsoft", data).then((response) => response);
};

export const forgotPassword = (data) => {
  return fetchPost("/password/forget", data).then((response) => response);
};

export const createNewPassword = (data) => {
  return fetchPost("/password/reset",data).then(resp => resp);
}

export const logout = () => {
  return true;
};
