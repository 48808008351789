import React from 'react'
import {selectors as usersSelectors, actions as usersActions} from "../../../../Ducks/users";
import {selectors as accountSelectors} from '../../../../Ducks/account';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {fetchGet} from '../../../../API/util';
import {cloneDeep, isEmpty} from 'lodash';
import CustomGridElement from './CustomGridElement';

const makeHeader = (title) => {
    let _title = "";
    if (title.includes(".")) {
        _title = title.split(".")[1].split("_");
    } else {
        _title = title.split("_");
    }
    return _title.join(" ");
}

const makeAccessor = (key) => {
    if (key.includes(".")) {
        return key.split(".")[1];
    } else {
        return key
    }
}

const TeamUsersGrid = (props) => {
    const {_user, navigate, team, usersState} = props;
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const [cols, _setCols] = React.useState(["user_id", "user_firstname", "user_lastname", "user_email", "user_phone", "user_title", "user_linkedin", "teams.team_name"]
        .map(key => ({
            column: key,
            accessor: makeAccessor(key),
            minWidth: 150,
            Header: makeHeader(key),
            filter: 'fuzzyText',
            filterable: key === "teams.team_name" ? false : true
        })));
    const fetchIdRef = React.useRef(0);

    const fetchData = React.useCallback(({pageSize, pageIndex, sortBy, filters, columns, team, usersState}) => {
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.

        // Parse columns
        const _columns = columns.map((item) => item.column)

        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true)

        // Check filter data
        let query = `&equals[teams.team_id]=${team}`, api = "", sort = "", deactivated = "";
        filters.forEach((item, _index) => {
            query = query + `&contains[${item.id === 'team_name' ? 'teams' : 'users'}.${item.id}]=${item.value}`
        });

        // Check sorting
        if (sortBy.length) {
            sort = `&sortBy=${sortBy[0].desc ? "-" : ""}users.${sortBy[0].id}`;
        }

        api = `/users?results=${pageSize}&columns=${_columns}&offset=${pageIndex + 1}${query}${sort}${usersState}`

        fetchGet(api).then(users => {
            if (fetchId === fetchIdRef.current && users) {
                const _users = cloneDeep(users);
                const teams = users.data.related_objects["teams"]
                const countries = users.data.related_objects["countries"]

                if (!isEmpty(users.data.related_objects)) {
                    _users.data.main.forEach((user, _index) => {
                        if (teams && !isEmpty(teams[user.team_id])) {
                            const _team_obj = cloneDeep(teams[user.team_id])
                            delete _team_obj["team_id"]
                            Object.assign(user, _team_obj);
                        }
                        if (countries && !isEmpty(countries[user.country_id])) {
                            const _country_obj = cloneDeep(countries[user.country_id])
                            delete _country_obj["country_id"]
                            Object.assign(user, _country_obj);
                        }
                    });
                }

                setData(_users.data.main);
                setPageCount(users.data.offset.highest_offset);
                setRowsCount(users.data.offset.rows_count);
                setLoading(false);
            }
        }).catch((error) => {
            setLoading(false);
            return error
        })
    }, []);

    const resetFilters = React.useCallback(() => setFilters([]), [setFilters]);

    const handleRowClick = (row) => {
        navigate("/user/id/" + row.original.user_id);
    }

    return (
        <>
            <CustomGridElement
                team={team}
                usersState={usersState}
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={handleRowClick}
                initialSortBy="user_id"
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: usersSelectors.isLoading(state),
    usersList: usersSelectors.usersList(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUsersList: usersActions.getUsersList
        },
        dispatch
    );

function WithNavigate(props) {
    let navigate = useNavigate();
    return <TeamUsersGrid {...props} navigate={navigate}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);