import { Phone } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as opportunitySelectors } from '../../../../Ducks/opportunity';


const useStyles = () => ({
  card: {
    backgroundColor: '#fff'
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '-8px',
    marginBottom: '-8px'
  }
});


const STATUS_COLORS = {
  'Accepted': 'green',
  'Pending': 'gray',
  'Rejected': '#8B0000',
  'Tentative': '#FFD700'
};

function Action(props) {
  const { classes, overview,plannedAction } = props;
  const [action, setAction] = React.useState({});
  const [job, setJob] = React.useState({});
  const [event, setEvent] = React.useState();
  const ParticipationStatus = styled(Box)`
    height: 15px;
    width: 15px;
    background-color: ${(props) => STATUS_COLORS[props.status]};
    border-radius: 50%;
    margin-left: .4rem;
    display: inline-block;
  `;
  useEffect(() => {
    if (overview && overview.related_objects.opportunity_actions) {
      let actionList = overview.related_objects.opportunity_actions;
      let latestAction = actionList && Object.values(actionList) && Object.values(actionList)[0];

      setAction(latestAction || {})

      let jobId = overview.main.job_id;
      setJob(overview.related_objects.jobs[jobId] || {});
    }
  }, [overview]);

  const makeHeader = (key) => {
    key = key.replaceAll('_', ' ');
    let splitStr = key.toLowerCase().split(" ");

    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

      key = splitStr.join(" ");
    }

    return key;
  }

  React.useEffect(() => {
    const event = props.events?.filter(e => {
      return e.event_id?.split('__').length === 2 && e.event_id?.split('__')[1] == plannedAction?.opportunity_id;
    }).sort((a, b) => new Date(b.created) - new Date(a.created)).at(0);

    if (event) {
      const all_accepted = event.all_accepted;
      const status_rejected = event.attendees.some(e => e.status == 'declined');
      const status_pending = event.attendees.some(e => e.status == 'needs_action');
      const status_tentative = event.attendees.some(e => e.status == 'tentative');
      
      if (all_accepted)
        event['event_status_overall'] = 'Accepted';
      else if (status_rejected)
        event['event_status_overall'] = 'Rejected';
      else if (status_tentative)
        event['event_status_overall'] = 'Tentative'
      else
        event['event_status_overall'] = 'Pending';
    }
    setEvent(event);
  }, [props.events,plannedAction]);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div" gutterBottom>
          Planned Action: {plannedAction ? makeHeader(plannedAction.opportunity_action_type) : 'None'}

        </Typography>
        {
          plannedAction &&
          (props.getAllEvents_loading ? "Loading" :
            <Typography variant="body2" color="site.main" gutterBottom>
              <b>{moment(plannedAction.opportunity_action_due_time).format("DD/MM/YYYY HH:mm")}</b>
              {event && <ParticipationStatus status={event.event_status_overall} />}
            </Typography>
          )
        }
        {
          event && plannedAction && plannedAction.event_synced == 1 &&
            <Typography variant="body2" color="site.main" gutterBottom>
              <div><b>Event Last Updated:</b></div>
              {new Date(event.updated).toLocaleString()} 
            </Typography>
        }
        {
          plannedAction && job && job.job_phone &&
          <Typography variant="body2" color="site.main">
            <a href={`callto:${job.job_phone}`} onClick={props.showActiveTab} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
              <Phone sx={{ color: 'icon.main', fontSize: "20px" }} />
              <span style={{ marginLeft: '8px' }}>
                {job.job_phone}
              </span>
            </a>
          </Typography>
        }
        {
          !plannedAction &&
          <Typography variant="body2" color="site.main">
            There is no planned action for this opportunity
          </Typography>
        }

      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button variant="contained" color="secondary" endIcon={<ArrowRightAltIcon />} size="small" onClick={() => props.handleTabChange('actions')}>Action</Button>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  events: opportunitySelectors.events(state),
  getAllEvents_loading: opportunitySelectors.getAllEvents_loading(state),
  plannedAction: opportunitySelectors.plannedActionResponse(state),
});

export default connect(mapStateToProps)(withStyles(useStyles)(Action));