import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { toDateTime } from '../../../Utils/dateUtils';
import moment from 'moment';

const useStyles = theme => ({
  card: {
    // display: 'flex',
    backgroundColor: '#fff'
    // height: 200
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '-8px',
    marginBottom: '-8px',
  }
});

function History(props) {
  const { classes, stage } = props;
  const [logTimestamp, setLogTimestamp] = React.useState('');
  const [stageObj, setStageObj] = React.useState([]);

  useEffect(() => {
    if (stage.length !== 0) {
      let pipelineStage = stage.related_objects.pipeline_stages[stage.main.pipeline_stage_id];
      setStageObj(pipelineStage);
      stage.related_objects.opportunity_logs && setLogTimestamp(stage.related_objects.opportunity_logs[stage.main.opportunity_logs[stage.main.opportunity_logs.length - 1]].opportunity_log_timestamp);
    }
  }, [stage]);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="div" gutterBottom>
          Previous Action: Status Change
        </Typography>
        <Typography className={classes.typography} variant="body2" color="site.main" gutterBottom>
          <b>{logTimestamp && moment(logTimestamp).format("DD/MM/YYYY HH:mm")}</b>
        </Typography>
        <Typography variant="body2" color="site.main">
          The opportunity <b>status</b> changed to <b>{stageObj.pipeline_stage_title}</b>
        </Typography>
        <Typography variant="body2" color="site.main" sx={{marginTop: '5px'}}>
          <span>Sales Rep: <b>{stage && stage.main && stage.main.sales_rep_name}</b></span>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button variant="contained" color="secondary" endIcon={<ArrowRightAltIcon />} size="small" onClick={() => props.handleTabChange('history')}>History</Button>
      </CardActions>
    </Card>
  );
}

export default withStyles(useStyles)(History);